import { observer } from "mobx-react-lite";
import { useStore } from "../../../root-store-context";
import { Button, Calendar, CalendarProps, Flex, Modal, Popover, Space, Spin } from "antd";
import dayjs, { Dayjs } from "dayjs";
import { useEffect, useState } from "react";
import { CalendarMode, SelectInfo } from "antd/es/calendar/generateCalendar";
import { ArrowRightOutlined, ArrowLeftOutlined } from '@ant-design/icons';
import { CalendarEvent } from "../../../api/BoardVue";
import './calendar.scss';
import { Link, useLocation, useNavigate } from "react-router-dom";

const CalendarTasks = observer(() => {

    const { calendarStore } = useStore();
    const [startDate, setStartDate] = useState<Date>(dayjs().startOf("month").toDate());
    const [endDate, setEndDate] = useState<Date>(dayjs().endOf("month").toDate());
    const navigate = useNavigate();
    const location = useLocation();

    const getItemsByDate = (date: Dayjs) => {
        return calendarStore.calendarEvents.filter(item => {            
            if (date.isSame(dayjs(item.eventDate), 'day')) {
                return true;
            }
        })
    }

    const dateCellRender = (value: Dayjs) => {
        const listData: CalendarEvent[] = getItemsByDate(value);
        const getTooltip = (item: CalendarEvent) => {
            return <>
                <div>Title: {item.title}</div>
                <div>Category: {item.category}</div>
                <div>Description: <div dangerouslySetInnerHTML={{ __html: item.description }}></div></div>
                <div>EventDate: {dayjs(item.eventDate).format('MM/DD/YYYY')}</div>
                <div>EndDate: {dayjs(item.endDate).format('MM/DD/YYYY')}</div>
                <div>Location: {item.location}</div>
            </>
        }
        return (
            <>
                <Button
                    type="text"
                    className="add-button"
                    onClick={() => {
                        calendarStore.setEventModel({ ...calendarStore.eventModel, eventDate: value, endDate: dayjs(value).add(1, 'h') });
                        navigate(location.pathname + "/Create");
                    }}
                >
                    <u>Add</u>
                </Button>
                <ul className="calendar-events">
                    {listData.map((item) => (
                        <li key={item.id}>
                            <Popover title={getTooltip(item)}>
                                <Link
                                    to={location.pathname + `/Edit/${item.id}`}
                                    style={{ marginLeft: 10, fontWeight: 600 }}
                                >
                                    {item.title}
                                </Link>
                            </Popover >
                        </li>
                    ))}
                </ul>
            </>

        );
    };
    const cellRender: CalendarProps<Dayjs>['cellRender'] = (current, info) => {
        return dateCellRender(current);
    };
    const onSelect = (date: Dayjs, selectInfo: SelectInfo) => {
        const firstDayOfMonth = date.startOf('month').toDate();
        const endDayOfMonth = date.endOf("month").toDate();
        if (!dayjs(startDate).isSame(firstDayOfMonth, 'day')) {
            setStartDate(firstDayOfMonth);
        }
        if (!dayjs(endDate).isSame(endDayOfMonth, 'day')) {
            setEndDate(endDayOfMonth);
        }
    }
    useEffect(() => {
        calendarStore.editLoading(true);
        calendarStore.updateCalendarItems(startDate, endDate).then(() => {
            calendarStore.editLoading(false);
        }).catch(err => {
            calendarStore.editLoading(false);
        })
    }, [startDate, endDate])



    const headerRender = ({ value, type, onChange, onTypeChange }: { value: Dayjs, type: CalendarMode, onChange: (date: Dayjs) => void, onTypeChange: (type: CalendarMode) => void }) => {
        return (
            <Flex gap={10} justify="space-between" align="center">
                <Space size="middle" style={{ flex: 1 }}>
                    <Button
                        type="primary"
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        onClick={() => {
                            const firstDayOfPreviousMonth = dayjs(startDate).subtract(1, 'month').startOf('month');
                            onChange(firstDayOfPreviousMonth)
                        }}>
                        <ArrowLeftOutlined />
                        Prev Month
                    </Button>
                    <Button
                        type="primary"
                        style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}
                        onClick={() => {
                            const firstDayOfNextMonth = dayjs(startDate).add(1, 'month').startOf('month');
                            onChange(firstDayOfNextMonth)
                        }}>
                        Next Month
                        <ArrowRightOutlined />
                    </Button>
                </Space>
                <h2 style={{ flex: 1, textAlign: "center" }}>
                    {dayjs(startDate).format('MMMM, YYYY')}
                </h2>
                <div style={{ flex: 1 }}></div>
            </Flex>
        );
    };

    return <Spin spinning={calendarStore.isLoading}>
        <Calendar
            mode="month"
            onChange={(date: Dayjs) => {
            }}
            onSelect={onSelect}
            headerRender={headerRender}
            cellRender={cellRender} />
    </Spin>
})

export default CalendarTasks