import axios, { AxiosResponse } from "axios";
import { ConfigApi } from "./base/ConfigApi";

export type embedRLS = embedItemRLS[]

export type embedItemRLS = {
    clause:string
}

export default class ReportClient {
    private apiUrl: string;
    private configApi: ConfigApi;
    reportAndDashboardUrl: string;
    constructor(configApi: ConfigApi) {
        const url = 'https://reporting.associatedasset.com/'
        this.apiUrl =  url + 'api/v1';
        this.reportAndDashboardUrl = url;
        this.configApi = configApi;
    }

    private getGuestPassUserData = ():{username:string,first_name:string,last_name:string} => {
        return {
            username: "admin",
            first_name: "Justin",
            last_name: "Lonsway"
        }
    }

    private async getTokenLogin(): Promise<string | null> {
        const requestData = {
            password: 'xinzex-1maxty-nenKyq',
            provider: 'db',
            refresh: true,
            username: 'admin'
        };

        try {
            const response: AxiosResponse = await axios.post(this.apiUrl + '/security/login', requestData, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            if (!response.data) {
                console.error(`An error has occurred: ${response.status} - ${response.statusText}`);
                return null;
            }

            return response.data.access_token;
        } catch (err: any) {
            console.error(`An error has occurred: ${err.message}`);
            return null;
        }
    }

    public async getGuestPassByPropertyId(DashboardGuid: string): Promise<string | null> {
        const requestData = {
            user: this.getGuestPassUserData(),
            resources: [
                {
                    type: 'dashboard',
                    id: DashboardGuid
                }
            ],
            rls: [
                {
                    // clause: "CompanyKey = '" + PropertyId + "'",
                    //clause: "CompanyKey = '" + 10825 + "'",
                    clause: "Property = '" + this.configApi.community.communityId + "'",
                    //clause: "Property = '" + 10825 + "'",
                    //dataset: 0
                }
            ]
        };

        try {
            const response: AxiosResponse = await axios.post(this.apiUrl + '/security/guest_token/', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await this.getTokenLogin()}`
                },
            });

            if (!response.data) {
                console.error(`An error has occurred: ${response.status} - ${response.statusText}`);
                return null;
            }
            return response.data.token;
        } catch (error: any) {
            console.error(`An error has occurred: ${error.message}`);
            return null;
        }
    }

    public async getGuestPassByCompanyKey(DashboardGuid: string): Promise<string | null> {
        const requestData = {
            user: this.getGuestPassUserData(),
            resources: [
                {
                    type: 'dashboard',
                    id: DashboardGuid
                }
            ],
            rls: [
                {
                    clause: "CompanyKey = '" + this.configApi.community.communityId + "'",
                    //clause: "CompanyKey = '" + 10825 + "'",
                    //clause: "Property = '" + PropertyId + "'",
                    //clause: "Property = '" + 10825 + "'",
                    //dataset: 0
                }
            ] 
        };

        try {
            const response: AxiosResponse = await axios.post(this.apiUrl + '/security/guest_token/', requestData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${await this.getTokenLogin()}`
                },
            });

            if (!response.data) {
                console.error(`An error has occurred: ${response.status} - ${response.statusText}`);
                return null;
            }
            return response.data.token;
        } catch (error: any) {
            console.error(`An error has occurred: ${error.message}`);
            return null;
        }
    }


    public async getReportUrl2(property: string, reportid: string): Promise<string | null> {
        const requestData = {
                    'property': property,
                    'reportid': reportid
                };

        try {
            const response: AxiosResponse = await axios.post('https://aammetabase.azurewebsites.net/api/getdashboard', requestData, {
                params: { 'default': 'uiJs7MgxmUQ-q4c_h_kXaYKFVrwwrXe5ipirj9spq2tzAzFu9461LA==' },
                headers: {
                    'Content-Type': 'application/json'
                },
            });

            if (!response.data) {
                console.error(`An error has occurred: ${response.status} - ${response.statusText}`);
                return null;
            }
            return response.data;
        } catch (error: any) {
            console.error(`An error has occurred: ${error.message}`);
            return null;
        }
    }
}