import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { AccountContactDataItem } from "../../../api/AccountSummary/AccountSummaryTypes";
import dayjs from "dayjs";

class AccountContactsStore {
    submittableForm: boolean = false;
    isLoading: boolean = false;
    contactModel: AccountContactDataItem = {} as AccountContactDataItem;
    shouldResetForm: boolean = false;
    isAgeRestricted: boolean = false;
    isAllowCreateTennat: boolean = false;
    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api
    }

    editLoading = (loading: boolean) => {
        this.isLoading = loading
    }
    setIsAgeRestricted = async (status: boolean) => {
        this.isAgeRestricted = status;
    }
    setIsAllowCreateTennat = async (status: boolean) => {
        this.isAllowCreateTennat = status;
    }
    setResidentKey = async (residentKey: number) => {
        this.contactModel.residentKey = residentKey;
    }
    setReferenceID = async (referenceID: string) => {
        this.contactModel.contactData.referenceID = referenceID;
    }
    setContactModel = async (model: AccountContactDataItem) => {
        this.isLoading = true;
        this.contactModel = model;
        this.isLoading = false;
    }

    setContactModelValues = async (model: AccountContactDataItem) => {
        this.isLoading = true;
        this.contactModel = {
            ...model,
            phones: model.phones.map((phone, index) => {
                const prevPhone = this.contactModel.phones && this.contactModel.phones[index] || phone;
                return {
                    type: prevPhone.type,
                    contactMethodID: prevPhone.contactMethodID,
                    contactNumber: prevPhone.contactNumber,
                    referenceID: prevPhone.referenceID,
                    disabledRemove: prevPhone.disabledRemove
                }
            })
        };
        this.isLoading = false;
    }

    setSubmittableForm = (submittable: boolean) => {
        this.submittableForm = submittable;
    }

    updateContact = async () => {
        this.isLoading = true;
        const res = await this._api.AccountSummaryApiClient.updateContactData(this.contactModel);
        this.isLoading = false;
        return res.data;
    }

    addContact = async () => {
        this.isLoading = true;
        const rentInfo = this.contactModel.isTenant ? JSON.parse(JSON.stringify(this.contactModel.rentInfo)) : null;
        delete this.contactModel.rentInfo;
        this.contactModel.contactData = {
            ...this.contactModel.contactData,
            firstName: this.contactModel.firstName,
            lastName: this.contactModel.lastName,
        }
        const res = await this._api.AccountSummaryApiClient.addContact(this.contactModel, this.contactModel.isTenant ? rentInfo : null);
        if (res.data) {
            this.setContactModel({} as AccountContactDataItem)
        }
        this.isLoading = false;
        if (res.status === 200) {
            return true;
        } else {
            return false;
        }
    }

    addNewPhone = (type: string) => {
        this.contactModel.phones.push({ type: type, contactNumber: "", contactMethodID: null, referenceID: "" })
    }
    setEmail = (email: string) => {
        this.contactModel.email = email;
    }
    setPhoneByIndex = (phone: string, index: number) => {
        this.contactModel.phones[index].contactNumber = phone;
    }

    resetValue = () => {
        this.shouldResetForm = true;
        this.isAgeRestricted = false;
        this.isAllowCreateTennat = false;
        this.contactModel = {
            isTenant: false,
            birthDate: undefined,
            phones: [
                {
                    contactMethodID: "",
                    referenceID: "",
                    type: "Home",
                    contactNumber: "",
                    disabledRemove: true
                },
                {
                    contactMethodID: "",
                    referenceID: "",
                    type: "Work",
                    contactNumber: "",
                    disabledRemove: true
                },
                {
                    contactMethodID: "",
                    referenceID: "",
                    type: "Mobile",
                    contactNumber: "",
                    disabledRemove: true
                }
            ],
            rentInfo: {
                canCreateAamUser: false,
                isSentEmailsToContact: false,
            }
        } as AccountContactDataItem
    }
}

export default AccountContactsStore

