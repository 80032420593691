import { Button, Flex, Table } from "antd";
import Column from "antd/es/table/Column";
import { SearchOutlined } from '@ant-design/icons';
import { useState } from "react";
import { InfinityLineField, InlineField } from "./AccountInfoCard";
import { dateValidation } from "../../../../helpers/dateValidation";
import { ModalFileViewer } from "../../../../components/ModalFileViewer/ModalFileViewer";
import { useStore } from "../../../../root-store-context";
import FileHelper from "../../../../helpers/FileHelper";

export interface ComplianceProps {
    compliance: ComplianceItem,
}

export type ComplianceItem = {
    complianceKey: number,
    inspictionStr: string,
    employee: string,
    createdStr: string,
    closedStr: string,
    rule: string,
    typeDesc: string,
    com_Violation: string,
    active: boolean,
    complianceSequences: ComplianceSequence[],
}

export type ComplianceSequence = {
    actionDate: string,
    sequence: string,
}

const styles = {
    architecturalCard: {
        flex: '1 1 300px',
        textAlign: 'start' as const,
        alignSelf: 'stretch',
    }
}

const Compliance: React.FC<ComplianceProps> = ({ compliance }) => {
    const complianceSequences = compliance.complianceSequences?.map((el, index) => { return { ...el, key: index } });
    const { applicationStore, modalViewerStore } = useStore();

    return (
        <Flex wrap="wrap" gap="small" style={{ borderBottom: "1px solid lightgray", paddingBottom: 10, paddingTop: 10 }}>
            <div style={styles.architecturalCard}>
                <InlineField label="Resource ID:" value={compliance?.complianceKey?.toString()} />
                <InlineField label="Closed:" value={compliance.closedStr} />
                <InlineField label="Rule:" value={compliance.rule} />
            </div>
            <div style={styles.architecturalCard}>
                <InlineField label="Inspection:" value={compliance.inspictionStr} />
                <InlineField label="Type:" value={compliance.typeDesc} />
            </div>
            <div style={styles.architecturalCard}>
                <InlineField label="Employee:" value={compliance.employee} />
            </div>
            <div style={styles.architecturalCard}>
                <InlineField label="Created:" value={compliance.createdStr} />
            </div>
            <Flex vertical flex={"1 1 100%"} gap="small">
                <InfinityLineField label="Violation:" value={compliance.com_Violation} />
            </Flex>
            <div style={{ flex: "1 1", overflow: "hidden" }}>

                <Flex vertical flex={"1 1 100%"} gap="small">
                    <Table dataSource={complianceSequences} pagination={{ defaultPageSize: 10, showSizeChanger: false, responsive: true }} scroll={{ x: true }}>
                        <Column width="18%" title="Action Date" dataIndex="actionDate" key="actionDate" ellipsis={true} render={(date) => dateValidation(date)} />
                        <Column title="Sequence" dataIndex="sequence" key="sequence" ellipsis={true} />
                        <Column width="10%" key="action"
                            render={(_: any, record: any) => record.fileUrl ? <Button icon={<SearchOutlined />} onClick={() => {
                                console.log(record);
                                const parts = record.fileUrl.split("/");
                                const fileName = parts[parts.length - 1];
                                modalViewerStore.openFileByUrl(record.fileUrl, fileName)
                            }}>View</Button> : <></>} />
                    </Table>
                </Flex>
            </div>

        </Flex>
    );
};

export default Compliance