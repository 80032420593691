import { Card, Flex, Spin, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useStore } from "../../../root-store-context";
import { Link } from "react-router-dom";
import {ArrowLeftOutlined} from '@ant-design/icons';

const ViolationChartReport = observer(() => {

    const { reportStore, applicationStore:{ SelectedAddress } } = useStore();

    const [isLoadReport, setIsLoadReport] = useState<boolean>(true);
    const lastUsedSourceCommunityId = useRef<string|null>(null);
    const embed = async (token:string, guidStr:string, mountPointStr:string) => {
        await embedDashboard({
            id: guidStr, // given by the Superset embedding UI
            supersetDomain: reportStore.getReportUrl(),
            mountPoint: document.getElementById(mountPointStr) as HTMLElement, // html element in which iframe render
            fetchGuestToken: async () => token,
            dashboardUiConfig: {
                hideTitle: true,
                hideChartControls: false,
                hideTab: false,
                filters: {
                    expanded: true,
                },
            },
        })
    }
    useEffect(() => {
        const currentSourceCommunityId = SelectedAddress?.communityId;
        const currentSourceCommunityName = SelectedAddress?.name;
        if (currentSourceCommunityId !== lastUsedSourceCommunityId.current
            && document.getElementById("BV_ViolationChart")
            ) {
                reportStore.getViolationChartToken().then(token =>{
                if(token){
                    embed(token, reportStore.ViolationChartGUID,"BV_ViolationChart");
                    setIsLoadReport(false)
                }
            }).catch(err=> {
                console.log(err)
            })
            lastUsedSourceCommunityId.current = currentSourceCommunityId;
        }
    }, [SelectedAddress?.communityId]);

    return <>
            <Flex vertical={true} gap={10}>
            <Link to="/BoardVue/Reports" style={{ marginLeft: 10 }}><ArrowLeftOutlined /> Back</Link>
                <Card title={<Typography.Title level={5} style={{margin:0}}><b>Violation Chart</b></Typography.Title>}>
                    <div id="BV_ViolationChart" className="reportIframe"></div>
                    {isLoadReport === true ? <>
                        <Flex gap="small" vertical>
                            <Spin/>
                        </Flex>
                    </>: <></>}
                </Card>
            </Flex>
    </>
})

export default ViolationChartReport
