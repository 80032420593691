import { Col, ConfigProvider, Flex } from "antd";
import Card from "antd/es/card";
import Meta from "antd/es/card/Meta";
import { ReactElement } from "react";
import { Link, useNavigate } from "react-router-dom";
import "./card.scss"
const AllAccessCard = ({ title, backgroundString, headTitle, isActive, link, headImg }:
    { title: ReactElement | string, backgroundString: string, headTitle: string, isActive: boolean, link?: string, headImg?: string, }): ReactElement => {
    const navigate = useNavigate();

    if (isActive == false) {
        return <></>
    }

    return (
        <ConfigProvider
            theme={{
                token: {
                    colorLink: "#FFFFFF",
                    colorLinkHover: "#FFFFFF",
                    colorLinkActive: "#FFFFFF",
                    linkHoverDecoration: "underline"
                },
                components: {
                    Card: {
                        borderRadius: 16,
                        borderRadiusLG: 16,
                        colorTextBase: "#FFFFFF",
                        colorTextDescription: "#FFFFFF",
                        colorTextHeading: "#FFFFFF",
                        colorLink: "#FFFFFF",
                    }
                }
            }}>
            <Col md={{ span: 12 }} xs={{ span: 24 }}>
                <Card
                className="allAccessCard"
                    onClick={() => {
                        if (link) {
                            navigate(link);
                        }
                    }}
                    title={<Flex justify="space-between" style={{ paddingTop: 24 }}>
                        {headImg && <div> <img height={50} alt="example" src={headImg} /></div>}
                        <div>{headTitle}</div>
                    </Flex>}
                    styles={{
                        header: {
                            border: "none"
                        },
                    }}
                    style={{
                        flex: "1 1 calc(50% - 15px)",
                        height: "20%",
                        minHeight: "200px",
                        minWidth: "300px",
                        background: backgroundString,
                        backgroundSize: "cover",
                        flexDirection: 'column',
                        justifyContent: "space-between",
                        display: "flex",
                        cursor: "pointer",
                    }}
                >
                    {link ?
                        <Link to={link} style={{ flex: "1 1 50%", marginLeft: 10, cursor: "pointer", }}><Meta title={title} description="" /></Link> :
                        <Meta title={title} description="" />
                    }
                </Card>
            </Col>
        </ConfigProvider>

    )
}

export default AllAccessCard;