import React, { useState } from 'react';
import { App, Button, Col, Divider, Form, Input, Row, Image, Space, UploadFile, UploadProps, Switch } from 'antd';
import { InboxOutlined } from '@ant-design/icons';
import { useStore } from '../../../root-store-context';
import TextArea from 'antd/es/input/TextArea';
import { observer } from 'mobx-react-lite';
import FileHelper from '../../../helpers/FileHelper';
import { useNavigate } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import CustomUploadItem from '../../BoardVueGroup/BoardTasks/CustomUploadItem';
import Title from 'antd/es/typography/Title';
import iconArchitecturalRequests from "../../../images/pageIcons/AccountSummary/ArchitecturalRequests.png"
import { getPhoneFormat } from '../../../helpers/phoneFormat';
import { validateMessages } from '../../../helpers/validationMessages';
import { Attachment } from '../../../api/Common/CommonTypes';
import { ArchitecturalSubmissionItem } from '../../../api/AccountSummary/AccountSummaryTypes';

const ArchitecturalRequestsForm = observer(() => {

    const [form] = Form.useForm();
    const { apiStore, modalViewerStore, architecturalRequestStore } = useStore();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const [isReadRequirements, setIsReadRequirements] = useState<boolean>(false)


    const success = () => {
        message.open({
            type: 'success',
            content: 'New Architectural Requests added successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    const values = Form.useWatch([], form);
    const [fileListAttachments, setFileListAttachments] = useState<UploadFile[]>([]);
    const [fileListColorFiles, setFileListColorFiles] = useState<UploadFile[]>([]);
    const [fileListPlotPlans, setFileListPlotPlans] = useState<UploadFile[]>([]);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                architecturalRequestStore.setSubmittableForm(true);
            },
            () => {
                architecturalRequestStore.setSubmittableForm(false);
            },
        );
    }, [values]);

    React.useEffect(() => {
        if (architecturalRequestStore.shouldResetForm) {
            form.resetFields();
            architecturalRequestStore.shouldResetForm = false;
        }
    }, [architecturalRequestStore.architecturalItem]);

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );

    const onDownloadFile = (file: UploadFile<any>) => {
        if (file.status === "done") {
            architecturalRequestStore.isLoading = true;
            apiStore.AccountSummaryApiClient.getFile(file.uid)
                .then(res => {
                    modalViewerStore.openFileByData(res.data, file.name);
                    architecturalRequestStore.isLoading = false;
                })
                .catch(error => {
                    architecturalRequestStore.isLoading = false;
                    console.log(error)
                });
        }
    }

    const uploadPropsAttachments: UploadProps = {
        onRemove: (file) => {
            if (file.status === "done") {
                return false;
            }

            const updatedFileList = fileListAttachments.filter(
                (attachment) => attachment.uid !== file.uid
            );
            setFileListAttachments(updatedFileList);
            const updatedAttachments = architecturalRequestStore.Attachments.filter(
                (attachment) => attachment.Guid !== file.uid
            );
            architecturalRequestStore.Attachments = updatedAttachments;
        },

        onDownload: onDownloadFile,
        beforeUpload: (file, allFiles) => {
            if (allFiles.length + fileListAttachments.length > 10) {
                if (allFiles[0].uid === file.uid) {
                    message.error('You can attach not more than 10 files.');
                }
                return false;
            }
            var isExist = fileListAttachments.some(i => i.name === file.name);
            if (isExist) {
                message.error('File with the same name already exists.');
                return false;
            }
            if (allFiles.some((i: File) => i.size > 10 * 1024 * 1024)) {
                if (file.size > 10 * 1024 * 1024) {
                    message.error('Unacceptable file size. File upload are limited to 10MB.');
                }
                return false;
            }
            setFileListAttachments((prev) => [...prev, file]);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = e => {
                const arrayBuffer = e.target?.result as ArrayBuffer;
                const byteArray = new Uint8Array(arrayBuffer);
                const newAttachment: Attachment = {
                    Name: file.name,
                    Data: FileHelper.ArrayBufferToBase64(Array.from(byteArray)),
                    Guid: file.uid,
                    Url: ""
                };
                architecturalRequestStore.Attachments = [...architecturalRequestStore.Attachments, newAttachment];
            };
            return false;
        },
        showUploadList: {
            showDownloadIcon: false,
            showPreviewIcon: false,
            showRemoveIcon: false,
        },
    };

    const uploadPropsColorFiles: UploadProps = {
        onRemove: (file) => {
            if (file.status === "done") {
                return false;
            }

            const updatedFileList = fileListColorFiles.filter(
                (attachment) => attachment.uid !== file.uid
            );
            setFileListColorFiles(updatedFileList);
            const updatedAttachments = architecturalRequestStore.ColorFiles.filter(
                (attachment) => attachment.Guid !== file.uid
            );
            architecturalRequestStore.ColorFiles = updatedAttachments;
        },

        onDownload: onDownloadFile,
        beforeUpload: (file, allFiles) => {
            if (allFiles.length + fileListColorFiles.length > 10) {
                if (allFiles[0].uid === file.uid) {
                    message.error('You can attach not more than 10 files.');
                }
                return false;
            }
            var isExist = fileListColorFiles.some(i => i.name === file.name);
            if (isExist) {
                message.error('File with the same name already exists.');
                return false;
            }
            if (allFiles.some((i: File) => i.size > 10 * 1024 * 1024)) {
                if (file.size > 10 * 1024 * 1024) {
                    message.error('Unacceptable file size. File upload are limited to 10MB.');
                }
                return false;
            }
            setFileListColorFiles((prev) => [...prev, file]);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = e => {
                const arrayBuffer = e.target?.result as ArrayBuffer;
                const byteArray = new Uint8Array(arrayBuffer);
                const newAttachment: Attachment = {
                    Name: file.name,
                    Data: FileHelper.ArrayBufferToBase64(Array.from(byteArray)),
                    Guid: file.uid,
                    Url: ""
                };
                architecturalRequestStore.ColorFiles = [...architecturalRequestStore.ColorFiles, newAttachment];
            };
            return false;
        },
        showUploadList: {
            showDownloadIcon: false,
            showPreviewIcon: false,
            showRemoveIcon: false,
        },
    };

    const uploadPropPlotPlanss: UploadProps = {
        onRemove: (file) => {
            if (file.status === "done") {
                return false;
            }
            const updatedFileList = fileListPlotPlans.filter(
                (attachment) => attachment.uid !== file.uid
            );
            setFileListPlotPlans(updatedFileList);
            const updatedAttachments = architecturalRequestStore.PlotPlans.filter(
                (attachment) => attachment.Guid !== file.uid
            );
            architecturalRequestStore.PlotPlans = updatedAttachments;
        },
        onDownload: onDownloadFile,
        beforeUpload: (file, allFiles) => {
            if (allFiles.length + fileListPlotPlans.length > 10) {
                if (allFiles[0].uid === file.uid) {
                    message.error('You can attach not more than 10 files.');
                }
                return false;
            }
            var isExist = fileListPlotPlans.some(i => i.name === file.name);
            if (isExist) {
                message.error('File with the same name already exists.');
                return false;
            }
            if (allFiles.some((i: File) => i.size > 10 * 1024 * 1024)) {
                if (file.size > 10 * 1024 * 1024) {
                    message.error('Unacceptable file size. File upload are limited to 10MB.');
                }
                return false;
            }
            setFileListPlotPlans((prev) => [...prev, file]);
            const reader = new FileReader();
            reader.readAsArrayBuffer(file);
            reader.onload = e => {
                const arrayBuffer = e.target?.result as ArrayBuffer;
                const byteArray = new Uint8Array(arrayBuffer);
                const newAttachment: Attachment = {
                    Name: file.name,
                    Data: FileHelper.ArrayBufferToBase64(Array.from(byteArray)),
                    Guid: file.uid,
                    Url: ""
                };
                architecturalRequestStore.PlotPlans = [...architecturalRequestStore.PlotPlans, newAttachment];
            };
            return false;
        },
        showUploadList: {
            showDownloadIcon: false,
            showPreviewIcon: false,
            showRemoveIcon: false,
        },
    };



    const addAttachGUID = async (item: Attachment) => {
        const attachGUID = await architecturalRequestStore.addAttachmentToBuffer(item);
        if (typeof attachGUID === "string") {
            item.Guid = attachGUID;
            item.Data = null as any;
            item.Url = null as any;
        }
    }

    const onSubmit = async () => {
        architecturalRequestStore.editLoading(true);
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'Saving'
        });

        architecturalRequestStore.architecturalItem.Attachments = architecturalRequestStore.Attachments;
        architecturalRequestStore.architecturalItem.ColorFiles = architecturalRequestStore.ColorFiles;
        architecturalRequestStore.architecturalItem.PlotPlans = architecturalRequestStore.PlotPlans;
        architecturalRequestStore.setNewPhone(architecturalRequestStore.architecturalItem.DaytimePhone.replace(/\D/g, ''))
        architecturalRequestStore.architecturalItem.EmailAddress = architecturalRequestStore.architecturalItem.EmailAddress || apiStore.configApi.userEmail;

        const promisesAttach = architecturalRequestStore.architecturalItem.Attachments.map(addAttachGUID);
        const promisesAttachColorFiles = architecturalRequestStore.architecturalItem.ColorFiles?.map(addAttachGUID);
        const promisesAttachPlotPlans = architecturalRequestStore.architecturalItem.PlotPlans?.map(addAttachGUID);
        const allPromisesAttach = [...promisesAttach, ...promisesAttachColorFiles, ...promisesAttachPlotPlans];
        if (allPromisesAttach) {
            await Promise.all(allPromisesAttach);
        }

        architecturalRequestStore.addArchitecturalRequestStore().then((res) => {
            architecturalRequestStore.resetValue();
            if (res) {
                message.destroy("Saving");
                success()
                navigate('/AccountInfo/ArchitecturalRequests');
            }
            else {
                message.destroy("Saving");
                error()
            }
        })
    }

    return (
        <Row>
            <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                <Space>
                    <Image src={iconArchitecturalRequests} preview={false} />
                    <Title level={2} style={{ marginTop: "10px" }}>New Architectural Requests</Title>
                </Space>
                <Form
                    form={form}
                    variant='outlined'
                    layout="vertical"
                    initialValues={{
                        ...architecturalRequestStore.architecturalItem,
                        EmailAddress: apiStore.configApi.userEmail,
                        DaytimePhone: getPhoneFormat(architecturalRequestStore.architecturalItem.DaytimePhone)
                    }}
                    labelCol={{ span: 24 }}
                    style={{ maxWidth: 900 }}
                    validateMessages={validateMessages}
                    onValuesChange={(changedValues, allValues) => architecturalRequestStore.architecturalItem = { ...allValues }}
                    requiredMark={customizeRequiredMark}
                    autoComplete="off"
                    onFinish={onSubmit}
                    scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
                    <Row>
                        <Col span={24}>
                            <Form.Item<ArchitecturalSubmissionItem> name="UserId" hidden>
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item<ArchitecturalSubmissionItem>
                                label="Daytime Phone"
                                name={"DaytimePhone"}
                                style={{ width: "100%" }}
                                rules={[{ required: true, message: 'Please input your daytime phone!', min: 12 }]}>
                                <Input onChange={(event) => {
                                    const { value } = event.target;
                                    const formattedValue = getPhoneFormat(value);
                                    form.setFieldsValue({
                                        DaytimePhone: formattedValue,
                                    });

                                }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item<ArchitecturalSubmissionItem> label="Email Address" name="EmailAddress" style={{ width: "100%" }}
                                rules={[{ required: true, type: "email" }]} >
                                <Input pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <label>
                                Request Type: i.e. landscaping changes to be made to your property and/or changes to the exterior of your home.
                            </label>
                            <Form.Item<ArchitecturalSubmissionItem> label="Request type" name="RequestType" style={{ width: "100%" }}
                                rules={[{ required: true }]} >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item<ArchitecturalSubmissionItem> label="Materials to be used" name="MaterialsToBeUsed" style={{ width: "100%" }}
                                rules={[{ required: true }]} >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left" orientationMargin="0">
                            File Upload
                        </Divider>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingBottom: 15 }}>
                            <div>
                                <Dragger fileList={fileListAttachments}
                                    multiple
                                    {...uploadPropsAttachments}
                                    itemRender={CustomUploadItem}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined style={{color: "#5a7890"}}/>
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Support for a single or bulk upload.
                                    </p>
                                </Dragger>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item<ArchitecturalSubmissionItem> label="Sample to be sent in" name="SampleToBeSentIn" style={{ width: "100%" }} >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item<ArchitecturalSubmissionItem> label="Colors to be used" name="ColorsToBeUsed" style={{ width: "100%" }}
                                rules={[{ required: true }]} >
                                <Input />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left" orientationMargin="0">
                            Add Example
                        </Divider>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div>
                                <Dragger fileList={fileListColorFiles}
                                    multiple
                                    {...uploadPropsColorFiles}
                                    itemRender={CustomUploadItem}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined style={{color: "#5a7890"}}/>
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Support for a single or bulk upload.
                                    </p>
                                </Dragger>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Divider orientation="left" orientationMargin="0">
                            Submit your Plot Plane
                        </Divider>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingBottom: 15 }}>
                            <div>
                                <Dragger fileList={fileListPlotPlans}
                                    multiple
                                    {...uploadPropPlotPlanss}
                                    itemRender={CustomUploadItem}>
                                    <p className="ant-upload-drag-icon">
                                        <InboxOutlined style={{color: "#5a7890"}}/>
                                    </p>
                                    <p className="ant-upload-text">Click or drag file to this area to upload</p>
                                    <p className="ant-upload-hint">
                                        Support for a single or bulk upload.
                                    </p>
                                </Dragger>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item<ArchitecturalSubmissionItem> label="View Fence" name="ViewFence" style={{ width: "100%" }} >
                                <Switch />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Row>
                        <Col span={24}>
                            <Form.Item<ArchitecturalSubmissionItem> label="Additional Notes" name="AdditionalNotes" rules={[{ required: true }]}>
                                <TextArea rows={4} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ paddingBottom: 15 }}>
                            <Space direction='vertical'>
                                <div>Plot Plan must be submitted indicating location of submittal and include applicable measurements and dimensions. Incomplete submissions will be denied.</div>
                                <div>Homeowner agrees to comply with all applicable city and state laws, and to obtain all necessary permits. Approval by the Architectural Committee shall not be deemed a warranty or Representation as to the quality of such construction, installation, addition, alteration, repair, change or other work, or that work conforms to any applicable building codes or other federal, state or local law, statute, ordinance, rule or regulation.</div>
                                <div>Architectural Design Committee requests will be reviewed within the timeframe specified in your community's CC&Rs from receipt of plot plan, color and material samples. Requests may be approved, denied or returned for additional information.</div>
                                <div>Texas Communities ONLY: I acknowledge full financial responsibility for the any and all damages caused to any Common Areas or other adjoining lots and any improvements on either, including but not limited to curbing, sidewalks, vegetation, surface conditions, removal of mud or other debris on a daily basis from any roads, by any contractors, subcontractors, employees, suppliers or other third parties involved in the proposed modifications.</div>
                            </Space>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <Form.Item label="I have read and understand the Submittal Requirements. *" style={{ width: "100%" }} rules={[{ required: true }]} >
                                <Switch value={isReadRequirements} onChange={(status) => {
                                    setIsReadRequirements(prev => !prev);
                                }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} style={{ textAlign: 'right', marginTop: "16px" }}>
                            <Space>
                                <Button size="middle" onClick={() => {
                                    architecturalRequestStore.resetValue();
                                    navigate('/AccountInfo/ArchitecturalRequests');
                                }}>
                                    Cancel
                                </Button>
                                <Button disabled={!isReadRequirements} size="middle" type="primary" htmlType="submit">Save</Button>
                            </Space>
                        </Col>
                    </Row>
                </Form>
            </Col>
        </Row>
    )
})

export default ArchitecturalRequestsForm