export default class FileHelper {
  static FileToByteArray = async (file: File) => {
    return new Promise<number[]>((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        if (event.target && event.target.result) {
          const arrayBuffer = event.target.result as ArrayBuffer;
          console.log("arrayBuffer", arrayBuffer)
          const uint8Array = new Uint8Array(arrayBuffer);
          resolve(Array.from(uint8Array));
        } else {
          reject(new Error("Error reading file"));
        }
      };

      reader.onerror = () => {
        reject(new Error("Error reading file"));
      };

      reader.readAsArrayBuffer(file);
    });
  };

  static DownloadFile = (byteArray: number[], fileName: string): void => {
    const uint8Array = new Uint8Array(byteArray);
    const blob = new Blob([uint8Array]);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(blob);
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  static DownloadFileByURL = (Url:string, fileName: string): void => {
    const downloadLink = document.createElement('a');
    downloadLink.href = Url;
    downloadLink.download = fileName;
    document.body.appendChild(downloadLink);
    downloadLink.target = '_blank';
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  static ArrayBufferToBase64(buffer: number[]) {
    var binary = '';
    var bytes = new Uint8Array(buffer);
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
      binary += String.fromCharCode(bytes[i]);
    }
    return btoa(binary);
  }

  static FileToBase64 = async (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
  
      reader.onload = (event:any) => {
        if (event.target && event.target.result) {
          const base64String = btoa(
            new Uint8Array(event.target.result)
              .reduce((data, byte) => data + String.fromCharCode(byte), '')
          );
          resolve(base64String);
        } else {
          reject(new Error("Error reading file"));
        }
      };
  
      reader.onerror = () => {
        reject(new Error("Error reading file"));
      };
  
      reader.readAsArrayBuffer(file);
    });
  };
  
}