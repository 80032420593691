import React from 'react';
import { DatePicker, Form } from 'antd';
import dayjs from 'dayjs'; // Import dayjs for date handling

const DateTimePicker = (props) => { 
    const { label, name, rules, size, disabled, formate, onChange, placeHolder, value } = props;

    return (
        <Form.Item
            label={label}
            name={name}
            rules={rules}
        >
            <DatePicker 
                format={formate} 
                onChange={onChange} 
                placeholder={placeHolder} 
                disabledDate={disabled} 
                size={size} 
                style={{ width: '100%' }}
                value={value ? dayjs(value, formate) : null} // Convert state to dayjs object
            />
        </Form.Item>
    );
};

export default DateTimePicker;
