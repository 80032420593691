import { observer } from "mobx-react-lite";
import { Space, Button, Input, Flex, Table } from 'antd';
import { SearchOutlined, CaretLeftFilled, FileTextOutlined, FolderFilled } from '@ant-design/icons';
import FileExplorerList, { FileExplorerColumns, FileItem } from "../../../components/FileExplorer/FileExplorerList";
import { AssociationDocumentItem} from "../../../api/BoardVue";
import { useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import useBreakpoint from "use-breakpoint";
import AssociationDocumentsColumns from "./AssociationDocumentsColumns";
import './style.scss';
import { ColumnsType } from "antd/es/table";
import { DateSort, FileNaturalCompare, TextSort } from "../../../helpers/SorterHelper";
import { dateValidation } from "../../../helpers/dateValidation";
import Column from "antd/es/table/Column";

export type AssociationDocumentGroupedTypeAndYear = {
    type: string;
    data: AssociationDocumentGroupedYear[];
};

export type AssociationDocumentGroupedYear = {
    year: string;
    data: AssociationDocumentItem[];
};

const AssociationDocuments = observer(() => {
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const { associationDocumentsStore, modalViewerStore } = useStore();
    const [searchText, setSearchText] = useState<string>("");
    const [files, setFiles] = useState<AssociationDocumentGroupedTypeAndYear[]>([]);
    const [associationDocuments, setAssociationDocuments] = useState<AssociationDocumentItem[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<AssociationDocumentItem[]>([]);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { apiStore } = useStore();

    const [sortName, setSortName] = useState<'ascend' | 'descend' | null>('ascend');
    const [sortModified, setSortModified] = useState<'ascend' | 'descend' | null>(null);
    const [sortModifiedBy, setSortModifiedBy] = useState<'ascend' | 'descend' | null>(null);
    
    useEffect(() => {
        if (searchText === "") {
            setFilteredFiles([]);
        }else{
            setFilteredFiles(associationDocuments.filter((i)=>i.name?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())));
        }
    }, [searchText])

    useEffect(() => {
        setIsLoading(true);
        const groupedData: { [type: string]: { [year: string]: AssociationDocumentItem[] } } = {};
        associationDocuments.forEach((item) => {
            const year = item.year;
            const type = item.documentType;

            if (!groupedData[type]) {
                groupedData[type] = {};
            }

            if (!groupedData[type][year]) {
                groupedData[type][year] = [];
            }

            groupedData[type][year].push(item);
        });
        const result = Object.keys(groupedData).map(type => {
            const yearsData = Object.keys(groupedData[type]).map(year => ({
                key: Math.random().toString(36).substr(2, 10),
                year: year,
                data: groupedData[type][year].map((d, i) => ({ ...d, key: i })) as AssociationDocumentItem[]
            }));

            return {
                key: Math.random().toString(36).substr(2, 10),
                type: type,
                data: yearsData.map((d, i) => ({ ...d, key: i }))
            };
        })
        setFiles(result);
        setIsLoading(false);
    }, [associationDocuments])

    useEffect(() => {
        setIsLoading(true);
        apiStore.BoardVueApiClient.getAssociationDocuments()
            .then(res => {
                setAssociationDocuments(res.data);
            }).catch(() => { setIsLoading(false) });
    }, [])
    // useEffect(()=>{
    //     openFolderOrFile(null);
    // },[associationDocuments])

    const onRow = (record: AssociationDocumentItem, rowIndex?: number) => {
        return {
            onClick: (event: React.MouseEvent) => {
                event.stopPropagation();
                setIsLoading(true);
                apiStore.BoardVueApiClient.getFile(record.fileUrl)
                    .then(res => {
                        modalViewerStore.openFileByData(res.data, record.name);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setIsLoading(false);
                    });
            }, // click row
            //onDoubleClick: (event: MouseEvent) => {
            //  openFolderOrFile(record.localName)
            //}, // double click row
            //onContextMenu: (event) => {console.log(event,rowIndex,record)}, // right button click row
            //onMouseEnter: (event) => {console.log(event,rowIndex,record)}, // mouse enter row
            //onMouseLeave: (event) => {console.log(event,rowIndex,record)}, // mouse leave row
        };
    }

    const groupedTypeAndYearColumns: ColumnsType<AssociationDocumentGroupedTypeAndYear> = [
        {
            key: "1",
            title: "Name",
            align: 'left',
            width: "40%",
            dataIndex: "type",
            render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
            defaultSortOrder: sortName,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortName(direction);
                } else if (direction === 'descend') {
                    setSortName(direction);
                }
                setSortModified(null);
                setSortModifiedBy(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortName === 'descend') {
                            setSortName(null);
                        }
                    },
                }
            )
        },
        // {
        //     key: "2",
        //     title: "Type",
        //     width: "16%",
        //     dataIndex: "documentType",
        // },
        {
            key: "3",
            title: "Modified",
            width: "16%",
            dataIndex: "modified",
            defaultSortOrder: sortModified,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortModified(direction);
                } else if (direction === 'descend') {
                    setSortModified(direction);
                }
                setSortName(null);
                setSortModifiedBy(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortModified === 'descend') {
                            setSortModified(null);
                        }
                    },
                }
            )
        },
        {
            key: "4",
            title: "Modified By",
            width: "16%",
            dataIndex: "modifiedByName",
            defaultSortOrder: sortModifiedBy,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortModifiedBy(direction);
                } else if (direction === 'descend') {
                    setSortModifiedBy(direction);
                }
                setSortName(null);
                setSortModified(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortModifiedBy === 'descend') {
                            setSortModifiedBy(null);
                        }
                    },
                }
            )
        },
    ];
    
    const groupedTypeAndYearColumnsSmall: ColumnsType<AssociationDocumentGroupedTypeAndYear> = [
        {
            key: "2",
            align: 'left',
            title: "Name",
            dataIndex: "type",
            render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
            defaultSortOrder: sortName,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortName(direction);
                } else if (direction === 'descend') {
                    setSortName(direction);
                }
                setSortModified(null);
                setSortModifiedBy(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortName === 'descend') {
                            setSortName(null);
                        }
                    },
                }
            )
        },
        {
            key: "3",
            width: "22%",
            title: "Modified",
            dataIndex: "modified",
            defaultSortOrder: sortModified,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortModified(direction);
                } else if (direction === 'descend') {
                    setSortModified(direction);
                }
                setSortName(null);
                setSortModifiedBy(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortModified === 'descend') {
                            setSortModified(null);
                        }
                    },
                }
            )
        },
        {
            key: "4",
            width: "22%",
            title: "Modified By",
            dataIndex: "modifiedByName",
            defaultSortOrder: sortModifiedBy,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortModifiedBy(direction);
                } else if (direction === 'descend') {
                    setSortModifiedBy(direction);
                }
                setSortName(null);
                setSortModified(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortModifiedBy === 'descend') {
                            setSortModifiedBy(null);
                        }
                    },
                }
            )
        }
    ];

    const expandedYearRowRender = (record: AssociationDocumentGroupedYear) => {
        return <div>
            {/* sortName: {sortName}<br/>
            sortModified: {sortModified}<br/>
            sortModifiedBy: {sortModifiedBy}<br/> */}
            <Table
            scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
            pagination={false}
            showHeader={false}
            dataSource={record.data}
            onRow={onRow}
        >
            {breakpoint === "mobile" ?
                (<>
                    <Column
                        key="1"
                        width="15%"
                        dataIndex="id"
                        render={() => (
                            <Flex align="center" justify="right">
                                <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
                            </Flex>
                        )}
                    />
                    <Column
                        key="2"
                        align="left"
                        title="Name"
                        dataIndex="name"
                        render={(text) => <div className="wrap-text-anywhere">{text}</div>}
                        sorter={(a: AssociationDocumentItem, b: AssociationDocumentItem, direction) => FileNaturalCompare(a.name, b.name, direction)}
                        sortOrder={sortName}
                        defaultSortOrder={sortName}
                    />
                    <Column
                        key="3"
                        width="22%"
                        title="Modified"
                        dataIndex="modified"
                        render={(date) => dateValidation(date)}
                        sorter={(a, b) => DateSort(a, b, "modified")}
                        sortOrder={sortModified}
                        defaultSortOrder={sortModified}
                    />
                    <Column
                        key="4"
                        width="22%"
                        title="Modified By"
                        dataIndex="modifiedByName"
                        sorter={(a, b) => TextSort(a, b, "modifiedByName")}
                        sortOrder={sortModifiedBy}
                        defaultSortOrder={sortModifiedBy}
                    />
                </>) :
                (<><Column
                    key="1"
                    width="7%"
                    dataIndex="id"
                    render={() => (
                        <Flex align="center" justify="right">
                            <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
                        </Flex>
                    )}
                />
                    <Column
                        key="2"
                        title="Name"
                        align="left"
                        width="40%"
                        dataIndex="name"
                        render={(text) => <div className="wrap-text-anywhere">{text}</div>}
                        sorter={(a: AssociationDocumentItem, b: AssociationDocumentItem, direction) => FileNaturalCompare(a.name, b.name, direction)}
                        sortOrder={sortName}
                        defaultSortOrder={sortName}
                    />
                    {/* <Column
                        key="3"
                        title="Type"
                        width="16%"
                        dataIndex="documentType"
                        sorter={(a, b) => TextSort(a, b, "documentType")}
                    /> */}
                    <Column
                        key="4"
                        title="Modified"
                        width="16%"
                        dataIndex="modified"
                        render={(date) => dateValidation(date)}
                        sorter={(a, b) => DateSort(a, b, "modified")}
                        sortOrder={sortModified}
                        defaultSortOrder={sortModified}
                    />
                    <Column
                        key="5"
                        title="Modified By"
                        width="16%"
                        dataIndex="modifiedByName"
                        sorter={(a, b) => TextSort(a, b, "modifiedByName")}
                        sortOrder={sortModifiedBy}
                        defaultSortOrder={sortModifiedBy}
                    /></>)
            }
        </Table>
        </div>
    }

    const expandedTypeRowRender = (record: AssociationDocumentGroupedTypeAndYear) => {
        return <div> <Table
            scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
            pagination={false}
            showHeader={false}
            columns={
                [
                    {
                        key: "1",
                        align: 'left',
                        dataIndex: "year",
                    },
                    {
                        width: "70%",
                    },
                ]
            }
            expandable={{
                expandedRowRender: expandedYearRowRender,
                defaultExpandedRowKeys: ['0'],
                expandRowByClick: true,
                columnWidth: breakpoint === 'mobile' ? '15%' : '7%',
            }}
            dataSource={record.data}
        /></div>
    }
    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{padding:10}}>
            <div>
                <Space direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Association Documents</h2>
                    <Input value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px' }}/>
                </Space>
            </div>
        </Flex>

        {searchText === "" ?
            (<Table
                loading={isLoading}
                className="associationDocuments"
                pagination={false}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                columns={breakpoint === "mobile" ? groupedTypeAndYearColumnsSmall : groupedTypeAndYearColumns}
                expandable={{
                    expandedRowRender: expandedTypeRowRender,
                    defaultExpandedRowKeys: ['0'],
                    expandRowByClick: true,
                    columnWidth: "3%"
                }}
                dataSource={files}
            />) :
            (<Table
                loading={isLoading}
                // pagination={false}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                columns={breakpoint === "mobile" ? AssociationDocumentsColumns.small : AssociationDocumentsColumns.large}
                dataSource={filteredFiles}
            />)}
    </>
})

export default AssociationDocuments