import { observer } from "mobx-react-lite";
import dayjs from 'dayjs';
import { Space, Table, Col, Row, Button, Input, Select, Form, DatePicker, Collapse, Flex } from 'antd';
import { SearchOutlined, FileTextOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import useBreakpoint from "use-breakpoint";
import { dateValidation } from "../../../helpers/dateValidation";
import { DateSort, TextSort } from "../../../helpers/SorterHelper";
import { useParams } from "react-router-dom";


type RecordFile = {
    fullPath: string;
    localName: string;
    documentType: string;
    originalDocumentDate: Date;
};

const { Column } = Table;

const RecordArchive = observer(() => {
    const id = useParams();
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const { apiStore } = useStore();

    const [currentFilters, setCurrentFilters] = useState();
    const [archiveDocuments, setArchiveDocuments] = useState<Array<RecordFile>>([]);
    const [searchText, setSearchText] = useState<string | null>(null);
    const [form] = Form.useForm();
    const { applicationStore, modalViewerStore } = useStore();
    const [currentPage, setCurrentPage] = useState(1);
    const [isLoading, setIsLoading] = useState(true);

    const onReset = () => {
        form.resetFields();
        form.submit();
    };

    useEffect(() => {
        if (id?.id) {
          console.log(id, "Vals");
          let a = {
            ['Resident Account'] : id?.id
          }
          search(a)
        }
      }, [id]);
      
    
    useEffect(() => {
        if (searchText != null && !id?.id) {
            setIsLoading(true);
            setArchiveDocuments([]);
            const timeout = setTimeout(() => {
                search();
            }, 750)
            return () => clearTimeout(timeout)
        }
    }, [searchText])

    useEffect(() => {
        if(!id?.id){
            setIsLoading(true);
            search();
        }
    }, [])


    const disabledDate = (current: dayjs.Dayjs | null, selectedStartDate?: dayjs.Dayjs | null, selectedEndDate?: dayjs.Dayjs | null) => {
        if (current && current > dayjs().endOf('day')) {
            return true;
        }
        if (current && selectedEndDate && current > selectedEndDate) {
            return true;
        }
        if (current && selectedStartDate && current < selectedStartDate) {
            return true;
        }
        return false; // Enable other dates
    };

    function mapSingleValue([key, value]: [string, any]): string {
        const mappedKey = key
            .replace(/ /g, "")
            .replace(/\//g, "")
            .replace("DocumentType", "SPContentType")
            .replace("ArchitecturalSubmissionStatus", "ArchSubStatus");

        if (key === "StartDate") {
            return `OriginalDocDate:(range(${value.toISOString().slice(0, 10)},`;
        } else if (key === "EndDate") {
            return `${value.toISOString().slice(0, 10)}))`;
        } else {
            return `${mappedKey}:("${value}")`;
        }
    }

    const search = (values?: any) => {
        setCurrentPage(1);
        setIsLoading(true);
        setArchiveDocuments([]);
        if (values === undefined) {
            values = currentFilters;
        }

        let filters: any[] = []

        if (values !== undefined) {
            const stringArray = Object.entries(values).filter(i => i[1] !== undefined && !i[0].includes("Date")).map(mapSingleValue);
            if (values.StartDate && !values.EndDate) {
                stringArray.push(`OriginalDocDate:(range(${dayjs(values.StartDate).format('YYYY-MM-DD')},max))`);
            } else if (!values.StartDate && values.EndDate) {
                stringArray.push(`OriginalDocDate:(range(min,${dayjs(values.EndDate).format('YYYY-MM-DD')}))`);
            } else if (values.StartDate && values.EndDate) {
                stringArray.push(`OriginalDocDate:(range(${dayjs(values.StartDate).format('YYYY-MM-DD')},${dayjs(values.EndDate).format('YYYY-MM-DD')}))`);
            }
            filters = stringArray.filter(item => item !== undefined);
        }
        apiStore.BoardVueApiClient.getAllSharePointArchiveItems(filters as string[], searchText).then((res) => {
            const newArchiveDocuments = res.data.map((item: RecordFile) => ({
                ...item,
                originalDocumentDate: new Date(item.originalDocumentDate),
            }));
            setArchiveDocuments(newArchiveDocuments);
            setIsLoading(false)
        }).catch((error) => {
            setIsLoading(false);
            console.error("Error fetching filtered SharePoint items:", error);
        })
    };

    const onFinish = (values?: any) => {
        setCurrentFilters(values)
        search(values);
    }

    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="middle" style={{ padding: 10 }}>
            <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
                <Space style={{ flexWrap: "wrap" }}>
                    <h2 style={{ width: "280px" }}>Record Archive</h2>
                </Space>
            </div>
        </Flex>
        <Collapse items={[
            {
                key: '1',
                label: 'Advanced Filters',
                children: <Form
                    labelCol={{ lg: { span: 10 }, xl: { span: 12 }, md: { span: 12 }, sm: { span: 24 }, xs: { span: 24 } }}
                    wrapperCol={{ lg: { span: 14 }, xl: { span: 12 }, md: { span: 12 }, sm: { span: 24 }, xs: { span: 24 } }}
                    labelWrap={false}
                    variant='outlined'
                    onFinish={onFinish}
                    form={form}>
                    <Row>
                        {applicationStore.recordArchiveFilters.slice(0, -1).map((filter, index) => (
                            <Col xl={{ span: 12 }} md={{ span: 24 }} sm={{ span: 24, offset: 0 }} xs={{ span: 24 }} key={filter.displayName}>
                                <Form.Item label={filter.displayName} name={filter.displayName}>
                                    <Select allowClear
                                        showSearch
                                        options={filter.items.map((text) => ({ value: text, label: text } as any))}
                                    />
                                </Form.Item>
                            </Col>
                        ))}
                        <Col xl={{ span: 5, offset: 2 }} md={{ span: 8, offset: 8 }} sm={{ span: 24, offset: 0 }} xs={{ span: 24 }}  >
                            <Form.Item label="Start Date" name="StartDate">
                                <DatePicker style={{ width: '100%' }} format={"M/D/YYYY"} disabledDate={(current) => disabledDate(current, null, form.getFieldValue('EndDate'))} />
                            </Form.Item>
                        </Col>
                        <Col xl={{ span: 5, offset: 0 }} md={{ span: 8 }} sm={{ span: 24, offset: 0 }} xs={{ span: 24 }}>
                            <Form.Item label="End Date" name="EndDate" >
                                <DatePicker style={{ width: '100%' }} format={"M/D/YYYY"} disabledDate={(current) => disabledDate(current, form.getFieldValue('StartDate'), null)} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item wrapperCol={{ span: 24 }} style={{ textAlign: 'right' }}>
                        <Space>
                            <Button size="middle" htmlType="button" onClick={onReset}>
                                Clear
                            </Button>
                            <Button size="middle" type="primary" htmlType="submit">
                                Filter
                            </Button>
                        </Space>

                    </Form.Item>
                </Form>,
            },
        ]} />
        <Space direction='horizontal'>
            <Input prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                variant="borderless" placeholder="Search" allowClear
                onChange={(value) => { setSearchText(value.target.value) }} />
        </Space>

        <Table loading={isLoading} dataSource={archiveDocuments.map((el, index) => { return { ...el, key: index } })}
            scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
            pagination={{ defaultPageSize: 10, showSizeChanger: false, total: archiveDocuments.length, responsive: true, current: currentPage, onChange: (pageNum) => setCurrentPage(pageNum) }}
            size={breakpoint === "mobile" ? "small" : "large"}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => {
                        setIsLoading(true);
                        apiStore.BoardVueApiClient.getFile(record.fullPath)
                            .then(res => {
                                modalViewerStore.openFileByData(res.data, record.localName);
                                setIsLoading(false);
                            })
                            .catch(error => {
                                console.error(error);
                                setIsLoading(false);
                            });
                    },
                    onMouseEnter: (event) => {
                        event.currentTarget.style.cursor = 'pointer';
                    }
                };
            }}>
            <Column width="5%" colSpan={0} title="Icon" key="icon" render={(text, record: any) => (
                <Flex align="center" justify="center"><FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} /></Flex>)} />
            <Column colSpan={2} align='left' width="30%" title="Name" dataIndex="localName" key="localName" responsive={["md"]}
                render={(text) => <div className="wrap-text-anywhere">{text}</div>}
                sorter={(a, b) => TextSort(a, b, "localName")}
            />
            <Column colSpan={breakpoint === "mobile" ? 2 : 1} align='left' width="15%" title="Doc Type" dataIndex="documentType" key="documentType"
                sorter={(a, b) => TextSort(a, b, "documentType")}
            />
            <Column width="10%" title="Doc Date" dataIndex="originalDocumentDate" key="originalDocumentDate"
                render={(date) => dateValidation(date)}
                sorter={(a, b) => DateSort(a, b, "originalDocumentDate")} />
            <Column width="20%" title="Address" dataIndex="address" key="address"
                sorter={(a, b) => TextSort(a, b, "address")} />
            <Column width="10%" title="Resident Account #" dataIndex="residentAccount" key="residentAccount"
                sorter={(a, b) => TextSort(a, b, "residentAccount")} />
        </Table>
    </>
})

export default RecordArchive