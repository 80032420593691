import TopViolationsCategories, { TopViolationsCategoryItem } from "./TopViolationsCategories";
import MovinsByMonth, { MovinsByMonthItem } from "./MovinsByMonth";
import ArchitecturalModificationTrends, { MonthlyArchitecturalSubmissionItem } from "./ArchitecturalModificationTrends";
import CardWidget from "./CardWidget";
import MonthlyArchitecturalApprovals from "./MonthlyArchitecturalApprovals";
import MonthlyArchitecturalSubmissions from "./MonthlyArchitecturalSubmissions ";
import TopArchitecturalRequests from "./TopArchitecturalRequests";

type Props = {
    widgetName?: string;
    widgetData?: any
    daysOrYear?: string
}
const Widget = (props:Props):JSX.Element => {
  
    const getBodyOrTitleDaysOrYearStr = (body:string):string=>{
      let bodyTemp = body
          if(props.daysOrYear){
            const validValues: string[] = ["1", "2", "3", "4", "5","6","7","8","9","10","11","12","13","14","15"]
            if(props.daysOrYear.length > 2 && validValues.includes(props.daysOrYear)){
              bodyTemp = `${body} for ${props.daysOrYear} years`
            }
            else if(props.daysOrYear === "365"){
              bodyTemp = `${body} for 1 year`
            }
            else if(props.daysOrYear.length === 4 && (props.daysOrYear.includes("199") || props.daysOrYear.includes("20"))){
              bodyTemp = `${body} for ${props.daysOrYear} year`
            }
            else{
              bodyTemp = `${body} for ${props.daysOrYear} days`
            }
      }
      return bodyTemp
    }

    if(props.widgetName && props.widgetData){

      const widgetData = props.widgetData.data;

      if(widgetData.monthlyResultTable){
        if (props.widgetName === "archapprovalsbymonth") {
          return <MonthlyArchitecturalApprovals data={widgetData.monthlyResultTable} />;
        }
        if (props.widgetName === "archsubmisstionsbymonth") {
          return <MonthlyArchitecturalSubmissions data={widgetData.monthlyResultTable} />;
        }
        if (props.widgetName === "movinsbymonthrolling5years") {
          return <MovinsByMonth data={widgetData.monthlyResultTable} />;
        }
      }
      if (widgetData.typedResultTable) {
        if (props.widgetName === "archcountbymodificationyear") {
          return <ArchitecturalModificationTrends data={widgetData.typedResultTable} />;
        }
        if (props.widgetName === "archcounttop10byyear") {
          return <TopArchitecturalRequests data={widgetData.typedResultTable} />;
        }
        if (props.widgetName === "top10violationtypescount") {
          return <TopViolationsCategories data={widgetData.typedResultTable} />;
        }
      }
      if(widgetData.singleVal || widgetData.singleVal === 0){
        if (props.widgetName === "archsubmisstionslastdays") {
          const body = getBodyOrTitleDaysOrYearStr("Submissions")
          return <CardWidget titleHead={"Architectural Submissions"} bodyText={widgetData.singleVal} bodyColor={"black"} bodyDescription={body} />
        }
        if (props.widgetName === "totalviolationsbyyear") {
          const thisYear: string = '' + new Date().getFullYear();
          return <CardWidget titleHead={`Total Violations this ${thisYear} year`} bodyText={widgetData.singleVal} bodyColor={"black"} bodyDescription={"Violations"} />
        }
        if (props.widgetName === "totalviolationscompleted") {
          return <CardWidget titleHead={"Total Violations Completed"} bodyText={widgetData.singleVal} bodyColor={"green"} bodyDescription={"Violations Completed"} />
        }
        if (props.widgetName === "totalviolationsopen") {
          return <CardWidget titleHead={"Open Violations"} bodyText={widgetData.singleVal} bodyColor={"red"} bodyDescription={"Open Violations"} />
        }
        if (props.widgetName === "pastmovinsbydays") {
          const title = getBodyOrTitleDaysOrYearStr("Move Ins Rolling")
          return <CardWidget titleHead={title} bodyText={widgetData.singleVal} bodyColor={"black"}/>
        }
        if (props.widgetName === "futuremovinsbydays") {
          const title = getBodyOrTitleDaysOrYearStr("Future Movins")
          return <CardWidget titleHead={title} bodyText={widgetData.singleVal} bodyColor={"black"}/>
        }
        if (props.widgetName === "archapprovalslastdays") {
          const body = getBodyOrTitleDaysOrYearStr("Approvals")
          return <CardWidget titleHead={"Architectural Approvals"} bodyText={widgetData.singleVal} bodyColor={"black"} bodyDescription={body} />
        }
      }
    }
    return (
        <WidgetNotFound/>
    )
}

const WidgetNotFound = ():JSX.Element => {
    const containerStyle:React.CSSProperties = {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    };
  
    const textStyle:React.CSSProperties = {
      fontSize: '24px',
      textAlign: 'center',
    };
    return (
      <div style={containerStyle}>
        <p style={textStyle}>Sorry, the widget does not exist.</p>
      </div>
    );
  };
  


export default Widget;