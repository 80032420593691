import React, { useEffect } from 'react';
import { Col, Row, Space, Image, Spin, App } from 'antd';
import { useStore } from '../../../root-store-context';
import Title from 'antd/es/typography/Title';
import newCalendar from "../../../images/pageIcons/BoardVue/calendarForm.png"
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import EventForm from './EventForm';


const CreateEvent = observer(() => {
    const { calendarStore } = useStore();
    const navigate = useNavigate();
    const { message } = App.useApp();

    useEffect(() => {
        let start = calendarStore.eventModel.eventDate;
        let end = calendarStore.eventModel.endDate;
        calendarStore.setDefault();
        calendarStore.setEventModel({ ...calendarStore.eventModel, eventDate: start, endDate: end })
    },[])

    const success = () => {
        message.open({
            type: 'success',
            content: 'New task added successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    const onSubmit = async () => {
        calendarStore.addEvent().then((res) => {
            if (res) {
                calendarStore.setDefault();
                success()
                navigate('/BoardVue/Calendar');
            }
            else {
                error()
            }
        })
    }
    return (
        <>
            <Spin tip="Loading" size="small" spinning={calendarStore.isLoading}>
                <Row>
                    <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                        <Space>
                            <Image src={newCalendar} preview={false} height={25} />
                            <Title level={2} style={{ marginTop: "10px" }}>New Event</Title>
                        </Space>
                        <EventForm submitBtnText='Create' submit={onSubmit} />
                    </Col>
                </Row>
            </Spin>
        </>
    )
})

export default CreateEvent