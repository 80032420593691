import { useEffect, useState } from 'react';
import type { EChartsOption } from "echarts";
import ReactECharts from '../../../../components/ReactECharts/ReactECharts';
import moment from 'moment';

export type MovinsByMonthItem = {
    value: number | null;
    monthNum: number;
    year: number;
}

export interface IMovinsByMonthProps {
    data: MovinsByMonthItem[];
}

const MovinsByMonth = (props: IMovinsByMonthProps) => {
    const [option, setOption] = useState<EChartsOption>({});

    useEffect(() => {
        const data = props.data;
        const yearMonthData: { [year: number]: { [month: number]: MovinsByMonthItem } } = {};

        props.data.forEach(obj => {
            const year = obj.year;
            const month = obj.monthNum;
            if (!yearMonthData[year]) {
                yearMonthData[year] = {};
            }
            yearMonthData[year][month] = obj;
        });

        const minYear = Math.min(...data.map(obj => obj.year));
        const maxYear = Math.max(...data.map(obj => obj.year));
        const minMonth = Math.min(...data.filter(obj => obj.year === minYear).map(obj => obj.monthNum));
        const maxMonth = Math.max(...data.filter(obj => obj.year === maxYear).map(obj => obj.monthNum));

        for (let year = minYear; year <= maxYear; year++) {
            const startMonth = (year === minYear) ? minMonth : 1;
            const endMonth = (year === maxYear) ? maxMonth : 12;
            for (let month = startMonth; month <= endMonth; month++) {
                if (!data.find(obj => obj.year === year && obj.monthNum === month)) {
                    data.push({
                        year: year,
                        monthNum: month,
                        value: null
                    });
                }
            }
        }

        data.sort(function (a, b) {
            if (a.year !== b.year) {
                return a.year - b.year;
            } else {
                return a.monthNum - b.monthNum;
            }
        });

        const xAxisData = data.map(i => {
            if (i.monthNum == 1) {
                // return `${i.movinyear} ${moment().month(0).format('MMM')}`;
                return i.year;
            }
            return moment().month(i.monthNum - 1).format('MMM');
        })

        const yAxisData = data.map(i => i.value);

        const option: EChartsOption = {
            title: {
                text: 'Movins by Month'
            },
            xAxis: {
                type: 'category',
                // boundaryGap: false,
                data: xAxisData
            },
            yAxis: {
                type: 'value'
            },
            series: [
                {
                    data: yAxisData,
                    type: 'bar'
                }
            ],
            // grid: {
            //     left: "30",
            //     right: "30",
            //     // bottom: 60,
            //     // top: 60,
            // }
        };
        setOption(option);
    }, [props]);

    return <ReactECharts option={option} />;
};

export default MovinsByMonth;
