import { RouteObject, useNavigate, useRoutes } from "react-router-dom";
import NotFound from "../components/Layouts/Errors/NotFound";
import { useAuth } from "react-oidc-context";
import { observer } from "mobx-react-lite";
import { useStore } from "../root-store-context";
import { useEffect, useState } from "react";
import Main from "../components/Layouts/Main/Main";
import Error from "../components/Layouts/Errors/Error";
import Loading from "../components/Layouts/Errors/Loading";
import UserNotFound from "../components/Layouts/Errors/UserNotFound";
import NoCommunities from "../components/Layouts/Errors/NoCommunities";
import { AxiosError } from "axios";
import { RouteElement, RouteGroupElement } from "../stores/appStores/ApplicationStore";
import { CommunityProxy, Permission } from "../api/Common/CommonTypes";
import { App } from "antd";

const Router: React.FC = observer(() => {
  const auth = useAuth();
  const rootStore = useStore();
  const { apiStore, applicationStore } = rootStore;
  const [routings, setRoutings] = useState<RouteObject[]>([]);
  const navigate = useNavigate();

  const { notification } = App.useApp();
  
  useEffect(() => {
    if (!auth.isAuthenticated && !auth.isLoading && !auth.error) {
      applicationStore.clearCommunitiesLocalStorage();
      rootStore.clearLocalStorages();
      auth.signinRedirect();
    }
  }, [auth.isLoading]);

  useEffect(() => {
    if (
      routings.length ||
      applicationStore?.SelectedAddress?.id ||
      !applicationStore.CommunityList
    ) {
      applicationStore.editLoading(false);
    }
  }, [routings, applicationStore]);

  useEffect(() => {
    return auth.events.addAccessTokenExpiring(() => {
      auth.signinRedirect();
    });
  }, [auth.events, auth.signinSilent]);
  
  const setStores = (data: CommunityProxy[]) => {
    const firstCommunity = data.length > 0 ? data[0] : undefined;
    if (firstCommunity) {
      const localStorageAddressId = localStorage.getItem("addressId");
      if (localStorageAddressId != null) {
        applicationStore.setSelectedCommunityById(
          localStorageAddressId as string
        );
      }
      if (applicationStore.SelectedAddress.communityId === undefined) {
        applicationStore.setSelectedCommunity(firstCommunity);
      }
      rootStore.refreshStores();
    } else {
      applicationStore.editLoading(false);
    }
  };

  useEffect(() => {
    const newRoutings: RouteObject[] = [];
    applicationStore.routingList.forEach((item) => {
      const items: RouteObject[] = [];
      item.children?.forEach((subItem: RouteElement) => {
        if (applicationStore.isFeatures(subItem.featureID))
          items.push({
            path: `${item.path}${subItem.path}`,
            element: subItem.element,
          });
      });
      if (items.length) {
        newRoutings.push({
          path: `/`,
          element: items[0].element,
        });
      }
      newRoutings.push(...items);
    });

    if (!applicationStore.isLoading && !newRoutings.some((i) => i.path === window.location.pathname)) {
      navigate("/");
    }

    setRoutings(newRoutings);
  }, [applicationStore.routingList]);

  useEffect(() => {
    if (auth.isAuthenticated && auth.user) {
      apiStore.setConfigApi({
        authToken: auth.user.profile.userToken as string,
        userEmail: auth.user.profile.email as string,
        userId: auth.user.profile.userId as string,
        community: {} as CommunityProxy,
        notification: notification,
      });
    }
    if (auth.isAuthenticated && applicationStore.CommunityList.length === 0) {
      const localData = applicationStore.getCommunitiesFromLocalStorage();
      if (localData === null) {
        apiStore.CommonApiClient.getAllForBoardMember()
          .then((res) => {
            // console.log(res);
            applicationStore.setCommunityList(res.data);
            applicationStore.saveCommunitiesToLocalStorage(res.data);
            setStores(res.data);
          })
          .catch((err: AxiosError) => {
            console.log(err);
            applicationStore.editLoading(false);
          });
      } else {
        applicationStore.setCommunityList(localData);
        setStores(localData);
      }      
    }
  }, [auth.isAuthenticated]);

  const onLogOut = () => {
    const args = {
      extraQueryParams: {
        post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI as string,
      },
    };
    void auth.signoutRedirect(args);
  };

  let routes: RouteObject[] = [
    {
      path: "/",
      element: (
        <Main
          key={applicationStore.ApiStore.configApi.community?.communityId}
        />
      ),
      children: [
        ...routings,
        {
          path: "*",
          element: <NotFound />,
        },
      ],
    },
  ];

  if (auth.error) {
    routes = [{ path: "*", element: <Error /> }];
  } else {
    if (auth.isAuthenticated) {
      if (applicationStore.isLoading) {
        routes = [{ path: "*", element: <Loading /> }];
      } else if (auth.user?.profile.userId === undefined) {
        routes = [{ path: "*", element: <UserNotFound /> }];
      } else if (applicationStore.SelectedAddress.communityId === undefined) {
        routes = [{ path: "*", element: <NoCommunities /> }];
      }
    } else {
      routes = [{ path: "*", element: <Loading /> }];
    }
  }
  return useRoutes(routes);
});

export default Router;
