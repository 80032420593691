import { observer } from "mobx-react-lite";
import useBreakpoint from 'use-breakpoint'
import { Space, Button, Input, Flex, Radio, RadioChangeEvent, App, Spin, Result } from 'antd';
import { useEffect, useState } from "react";
import { useStore } from "../../root-store-context";


export type EStatementsSettings = {
    contactKey: string;
    email: string;
    isSendEmail: boolean;
}

const EStatements = observer(() => {
    const { message } = App.useApp();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [eStatementsSettings, setEStatementsSettings] = useState<EStatementsSettings>({} as EStatementsSettings);
    const [isValidemail, setValidEmail] = useState<boolean>(true);
    const [isActive, setIsActive] = useState<boolean>(true);
    const { apiStore } = useStore();
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')

    useEffect(() => {
        setIsLoading(true);
        setEStatementsSettings({ email: apiStore.configApi.userEmail, isSendEmail: false } as EStatementsSettings)
        apiStore.AccountSummaryApiClient.getEStatements().then(async res => {
            if (res !== null) {
                setEStatementsSettings(res);
            }else{
                setIsActive(false);
            }
            setIsLoading(false);
        })
    }, [])

    const onChangeStatement = (e: RadioChangeEvent) => {
        setEStatementsSettings((prev) => {
            return {
                ...prev,
                isSendEmail: e.target.value
            }
        })
    };

    const updateStatements = async () => {
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'Saving'
        });
        await apiStore.AccountSummaryApiClient.updateStatements(eStatementsSettings).then(() => {
            message.destroy("Saving");
            message.open({
                type: 'success',
                content: 'Saved',
            });
        }).catch(() => {
            message.destroy("Saving");
            message.open({
                type: 'error',
                content: 'Sorry, no data for this user. Please contact your Community Manager.',
            });
        });
    }

    if (!isActive) {
        return <Result status="404" title="Sorry, no data for this user. Please contact your Community Manager." />
    }

    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{ padding: 10 }}>
            <div>
                <Space direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Electronic Communication</h2>
                </Space>
            </div>
        </Flex>

        <Spin spinning={isLoading}>
            <Flex vertical gap={"middle"}>
                <div>If you receive a paper statement from your community, you can sign up to get statements by email. You may also use this page to update the email address where we email statements.</div>
                <div>Electronic statements will also appear in the Mail Archive section of this app.</div>
                <div>Preferences will update the primary  billing contact's email on your account, which might be a person other than you.</div>
                <Input style={{ maxWidth: 450 }} type="email" pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" value={eStatementsSettings.email} status={!isValidemail ? "error" : ""} onChange={(event) => {
                    setValidEmail(event.target.checkValidity())
                    setEStatementsSettings((prev) => {
                        return {
                            ...prev,
                            email: event.target.value
                        }
                    })
                }} />
                <Radio.Group onChange={onChangeStatement} value={eStatementsSettings?.isSendEmail} >
                    <Space direction="vertical" size={"middle"}>
                        <Radio value={false}>{"Paper statement \n(Send my statement for this account via mail)"}</Radio>
                        <Radio value={true}>{"Electronic statement \n(Send my statement for this account via email)"}</Radio>
                    </Space>
                </Radio.Group>
                <Button disabled={!isValidemail} size="middle" type="primary" style={{ width: 300, alignSelf: "center" }}
                    onClick={updateStatements}>UPDATE PREFERENCES</Button>
            </Flex>
        </Spin>

    </>
})

export default EStatements;