import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { DwellingCommunityData, GuestPassItem, PostGuestPass } from "../../../api/Association/AssociationTypes";

class GuestPassesStore {
    private _api: ApiStore;
    private dwellingCommunityData?: DwellingCommunityData;
    public editGuestPassModel?: PostGuestPass;
    public guestID?: string;
    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    public async getAuthString(): Promise<string> {
        return await this._api.AssociationApiClient.getAuthString();
    }

    public async getdWellingCommunityData(): Promise<DwellingCommunityData> {
        if(!this.dwellingCommunityData){
            this.dwellingCommunityData = await this._api.AssociationApiClient.getDWellingCommunityData();
        }
        return this.dwellingCommunityData;
    }

    public async getDwGuestsInCommunity(): Promise<GuestPassItem[]> {
        var authString = await this.getAuthString();
        var data = await this.getdWellingCommunityData();
        return await this._api.AssociationApiClient.getDwGuestsInCommunity(authString, data?.dlCommunityId, data?.dlHomeId);
    }

    public async createGuestPass(guestPass:PostGuestPass): Promise<boolean> {
        var authString = await this.getAuthString();
        var data = await this.getdWellingCommunityData();
        return await this._api.AssociationApiClient.createGuestPass(authString, data.dlCommunityId, data.dlHomeId, data.dlUserId, guestPass);
    }

    public async editGuestPass(guestPass:PostGuestPass, guestID: string): Promise<boolean> {
        var authString = await this.getAuthString();
        var data = await this.getdWellingCommunityData();
        return await this._api.AssociationApiClient.editGuestPass(authString, data.dlCommunityId, data.dlHomeId, data.dlUserId, guestPass, guestID);
    }

    public async deleteGuestPass(guestID: string): Promise<boolean> {
        var authString = await this.getAuthString();
        var data = await this.getdWellingCommunityData();
        return await this._api.AssociationApiClient.deleteGuestPass(authString, data.dlCommunityId, data.dlHomeId, data.dlUserId, guestID);
    }
}
export default GuestPassesStore