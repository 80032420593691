import { observer } from "mobx-react-lite";
import { Space, Button, Input, Flex, App } from 'antd';
import { SearchOutlined, CaretLeftFilled } from '@ant-design/icons';
import FileExplorerList, { FileExplorerColumns, FileItem } from "../../../components/FileExplorer/FileExplorerList";
import { FolderFilled, FileTextOutlined } from '@ant-design/icons'
import { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import useBreakpoint from "use-breakpoint";
import getComplianceDocumentsColumns from "./ComplianceDocumentsColumns";
import Link from "antd/es/typography/Link";
import { ComplianceAppealForm } from "./ComplianceAppealForm";
import { ComplianceItem } from "../../../api/AccountSummary/AccountSummaryTypes";
import { DateSort, FileNaturalCompare, TextSort } from "../../../helpers/SorterHelper";
import { dateValidation } from "../../../helpers/dateValidation";
import Table, { ColumnsType } from "antd/es/table";
import Column from "antd/es/table/Column";

export type ComplianceItemGroupedType = {
    key: string;
    violationNo: string;
    data: ComplianceItem[];
};

const Compliance = observer(() => {
    const { message } = App.useApp();

    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const { apiStore, complianceStore, modalViewerStore } = useStore();
    const [searchText, setSearchText] = useState<string>("");
    
    const [filteredFiles, setFilteredFiles] = useState<ComplianceItem[]>([]);
    const [complianceDocuments, setComplianceDocuments] = useState<ComplianceItem[]>([]);
    const [groupedComplianceDocuments, setGroupedComplianceDocuments] = useState<ComplianceItemGroupedType[]>();

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isOpenAppeal, setIsOpenAppeal] = useState<boolean>(false);
    const [appealViolationNo, setAppealViolationNo] = useState<string>("");
    const [appealViolationItemType, setAppealViolationItemType] = useState<string>("");

    const [sortCompliance, setSortCompliance] = useState<'ascend' | 'descend' | null>('ascend');
    const [sortReportDate, setSortReportDate] = useState<'ascend' | 'descend' | null>(null);
    const [sortDescription, setSortDescription] = useState<'ascend' | 'descend' | null>(null);

    useEffect(() => {
        if (searchText === "") {
            setFilteredFiles([]);
            return;
        } else {
            setFilteredFiles(complianceDocuments.filter((i) =>
                i.violationNo?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()) ||
                i.description?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())));
        }
    }, [searchText])


    useEffect(() => {
        setIsLoading(true);
        apiStore.AccountSummaryApiClient.getCompliancies()
            .then(res => {
                const groupedData: { [type: string]: ComplianceItem[] } = {};
                res.forEach((item) => {
                    const type = item.type;
                    if (!groupedData[type]) {
                        groupedData[type] = [];
                    }
                    groupedData[type].push({...item, key: Math.random().toString(36).substr(2, 10)} as ComplianceItem);
                });
                const result = Object.keys(groupedData).map(type => {
                    return {
                        key: Math.random().toString(36).substr(2, 10),
                        violationNo: type,
                        data: groupedData[type].map((d, i) => ({ ...d, key: i }))
                    };
                })
                setComplianceDocuments(res.map((d, i) => ({ ...d, key: i })));
                setGroupedComplianceDocuments(result);
                setIsLoading(false);

            }).catch((error) => {
                setIsLoading(false);
                console.log("getCompliancies: ", error);
            });
    }, [])

    const downloadFilesButton = (data: ComplianceItem): ReactElement => {
        if (data?.files) {
            return <Flex vertical justify="flex-start" align="flex-start">
                {data.files.map((el, index) => {
                    return (
                        <Button
                            style={{ textDecoration: "underline", flex: "1 1 100%", textAlign: "left", padding: 4 }}
                            type="text"
                            block
                            key={index}
                            onClick={async () => {
                                setIsLoading(true);
                                const data = await apiStore.AccountSummaryApiClient.getAttachmentStringData(el.complianceSequenceKey.toString(), el.fileName);
                                setIsLoading(false);
                                if(data){
                                    modalViewerStore.openFileByData(data, el.fileName);
                                }else{
                                    message.open({
                                        type: 'error',
                                        content: 'The file does not exist',
                                    });
                                }
                            }
                            }>
                            <Link style={{ whiteSpace: "wrap" }}>{el.fileName}</Link>
                        </Button>
                    )
                })}
            </Flex>
        } else {
            return (
                <Flex justify="center">
                    <Button onClick={async () => {
                        setIsLoading(true);
                        const res = await apiStore.AccountSummaryApiClient.getSequencesForCompliance(data.violationNo);
                        data.files = res; //!!!
                        setIsLoading(false);
                    }}
                        style={{ whiteSpace: "wrap", display: "flex", height: "100%" }}>View Files</Button>
                </Flex>
            )
        }
    }

    const appealButton = (data: ComplianceItem): ReactElement => {
        return (
            <Flex justify="center">
                <Button onClick={async () => {
                    setIsOpenAppeal(true);
                    setAppealViolationNo(data.violationNo)
                    setAppealViolationItemType(data.type)
                }}>Appeal</Button>
            </Flex>
        )
    }

    const groupedTypeColumns: ColumnsType<ComplianceItemGroupedType> = [
        {
            key: "2",
            title: "Compliance #",
            align: 'left',
            width: "10%",
            dataIndex: "violationNo",
            render:(data)=><div style={{position: "absolute", width: "280px", top: "50%", transform: "translateY(-50%)", zIndex: "1"}}>{data}</div>,
            defaultSortOrder: sortCompliance,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortCompliance(direction);
                } else if (direction === 'descend') {
                    setSortCompliance(direction);
                }
                setSortReportDate(null);
                setSortDescription(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortCompliance === 'descend') {
                            setSortCompliance(null);
                        }
                    },
                }
            )
        },
        {
            key: "4",
            width: "10%",
            title: "Report Date",
            dataIndex: "reportDate",
            defaultSortOrder: sortReportDate,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortReportDate(direction);
                } else if (direction === 'descend') {
                    setSortReportDate(direction);
                }
                setSortCompliance(null);
                setSortDescription(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortReportDate === 'descend') {
                            setSortReportDate(null);
                        }
                    },
                }
            )
        },
        {
            key: "5",
            title: "Description",
            width: "30%",
            dataIndex: "description",
            defaultSortOrder: sortDescription,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortDescription(direction);
                } else if (direction === 'descend') {
                    setSortDescription(direction);
                }
                setSortCompliance(null);
                setSortReportDate(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortDescription === 'descend') {
                            setSortDescription(null);
                        }
                    },
                }
            )
        },
        {
            key: "appeal",
            title: "Appeal",
            width: "10%",
            dataIndex: "isFolder",
        },
        {
            key: "download",
            title: "Files",
            width: "20%",
            dataIndex: "isFolder",
        },
    ];
    
    const groupedTypeColumnsSmall: ColumnsType<ComplianceItemGroupedType> = [
        {
            key: "2",
            title: "Id #",
            align: 'left',
            width: "16%",
            dataIndex: "violationNo",
            render:(data)=><div style={{position: "absolute", width: "280px", top: "50%", transform: "translateY(-50%)", zIndex: "1"}}>{data}</div>
        },
        {
            key: "4",
            title: "Description",
            width: "30%",
            dataIndex: "description",
        },
        {
            key: "appeal",
            title: "Appeal",
            width: "20%",
            dataIndex: "isFolder",
        },
        {
            key: "download",
            title: "Files",
            // width: "30%",
            dataIndex: "isFolder",
        },
    ];

    const expandedTypeRowRender = (record: ComplianceItemGroupedType) => {
        return <div>
            {/* sortName: {sortName}<br/>
        sortModified: {sortModified}<br/>
        sortModifiedBy: {sortModifiedBy}<br/> */}
            <Table
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                pagination={false}
                showHeader={false}
                dataSource={record.data}
            // onRow={onRow}
            >
                {breakpoint === "mobile" ?
                    (<>
                        <Column
                            key="1"
                            colSpan={0}
                            width="5%"
                            dataIndex="isFolder"
                            render={() => (
                                <Flex align="center" justify="center">
                                    <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
                                </Flex>
                            )}
                        />
                        <Column
                            key="2"
                            title="Compliance #"
                            colSpan={2}
                            align="left"
                            width="17%"
                            dataIndex="violationNo"
                            sorter={(a: ComplianceItem, b: ComplianceItem, direction) => FileNaturalCompare(a.violationNo, b.violationNo, direction)}
                            sortOrder={sortCompliance}
                            defaultSortOrder={sortCompliance}
                        />
                        <Column
                            key="4"
                            title="Description"
                            width="28%"
                            dataIndex="description"
                            sorter={(a: ComplianceItem, b: ComplianceItem, direction) => FileNaturalCompare(a.description, b.description, direction)}
                            sortOrder={sortDescription}
                            defaultSortOrder={sortDescription}
                        />
                        <Column
                            key="appeal"
                            title="Appeal"
                            width="20%"
                            render={appealButton}
                        />
                        <Column
                            key="download"
                            title="Files"
                            // width="30%"
                            render={downloadFilesButton}
                        />
                    </>) :
                    (<>
                    <Column
                        key="1"
                        width="4%"
                        dataIndex="isFolder"
                        render={() => (
                            <Flex align="center" justify="right">
                                <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
                            </Flex>
                        )}
                    />
                        <Column
                            key="2"
                            title="Compliance #"
                            align="left"
                            width="12%"
                            dataIndex="violationNo"
                            sorter={(a: ComplianceItem, b: ComplianceItem, direction) => FileNaturalCompare(a.violationNo, b.violationNo, direction)}
                            sortOrder={sortCompliance}
                            defaultSortOrder={sortCompliance}
                        />
                        <Column
                            key="4"
                            title="Report Date"
                            width="12%"
                            dataIndex="reportDate"
                            render={(date) => dateValidation(date)}
                            sorter={(a, b) => DateSort(a, b, "reportDate")}
                            sortOrder={sortReportDate}
                            defaultSortOrder={sortReportDate}
                        />
                        <Column
                            key="5"
                            title="Description"
                            width="36%"
                            dataIndex="description"
                            sorter={(a: ComplianceItem, b: ComplianceItem, direction) => FileNaturalCompare(a.description, b.description, direction)}
                            sortOrder={sortDescription}
                            defaultSortOrder={sortDescription}
                        />
                        <Column
                            key="appeal"
                            title="Appeal"
                            width="11%"
                            render={appealButton}
                        />
                        <Column
                            key="download"
                            title="Files"
                            // width="20%"
                            render={downloadFilesButton}
                        /></>)
                }
            </Table>
        </div>
    }

    const defaultColums = getComplianceDocumentsColumns(downloadFilesButton, appealButton)

    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{ padding: 10 }}>
            <div>
                <Space direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Compliance Issues</h2>
                    <Input value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px' }} />
                </Space>
            </div>
        </Flex>
        <Table
                loading={isLoading}
                // pagination={false}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                columns={breakpoint === "mobile" ? defaultColums.small : defaultColums.large}
                dataSource={searchText === "" ? complianceDocuments : filteredFiles}
            />
        {/* {searchText === "" ?
            (<Table
                loading={isLoading}
                className="associationDocuments"
                pagination={false}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                columns={breakpoint === "mobile" ? groupedTypeColumnsSmall : groupedTypeColumns}
                expandable={{
                    expandedRowRender: expandedTypeRowRender,
                    defaultExpandedRowKeys: ['0'],
                    expandRowByClick: true,
                    columnWidth: "3%"
                }}
                dataSource={groupedComplianceDocuments}
            />) :
            (<Table
                loading={isLoading}
                // pagination={false}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                columns={breakpoint === "mobile" ? defaultColums.small : defaultColums.large}
                dataSource={filteredFiles}
            />)} */}
        <ComplianceAppealForm isOpenModal={isOpenAppeal} dismissModal={() => { setIsOpenAppeal(false); }}
            violationNo={appealViolationNo}
            violationItemType={appealViolationItemType} />
    </>
})

export default Compliance