import { Flex, Image } from "antd";
import {
  RouteElement,
  RouteGroupElement,
} from "../stores/appStores/ApplicationStore";
import { Navigate } from "react-router-dom";
import Elections from "../pages/AssociationGroup/Elections";
import ContactsAndLinks from "../pages/AssociationGroup/ContactsAndLinks";
import PropertyDocuments from "../pages/AssociationGroup/PropertyDocuments/PropertyDocuments";
import FAQ from "../pages/AssociationGroup/FAQ";

import iconRecreationAndTickets from "../images/pageIcons/Association/RecreationAndTickets.png";
import iconGuestPasses from "../images/pageIcons/Association/GuestPasses.png"
import iconResidentDirectory from "../images/pageIcons/Association/ResidentDirectory.png"
import iconElections from "../images/pageIcons/Association/Elections.png";
import iconContactsAndLinks from "../images/pageIcons/Association/ContactsAndLinks.png";
import iconPropertyDocuments from "../images/pageIcons/Association/PropertyDocuments.png";
import iconFAQ from "../images/pageIcons/Association/FAQ.png";

import MyProfile from "../pages/AssociationGroup/ResidentDirectory/MyProfile";
import GuestPasses from "../pages/AssociationGroup/GuestPasses/GuestPasses";
import CreateGuestPass from "../pages/AssociationGroup/GuestPasses/CreateGuestPass";
import EditGuestPass from "../pages/AssociationGroup/GuestPasses/EditGuestPass";
import ABDIGatePasses from "../pages/AssociationGroup/ABDIGatePasses";
import TicketsAndFitness from "../pages/AssociationGroup/TicketsAndFitness";
import { Role } from "../api/Common/CommonTypes";
import ResidentDirectory from "../pages/AssociationGroup/ResidentDirectory/ResidentDirectory";
import RecreationAndTickets from "../pages/AssociationGroup/RecreationAndTickets";

const iconStyle:React.CSSProperties = {width:"1.2em", marginLeft: "10px"}

const associationChildrenRoutes: RouteElement[] = [
  {
    key: "/Elections",
    path: "/Elections",
    icon: <Flex><Image style={iconStyle} src={iconElections} preview={false} /></Flex>,
    label: "Elections",
    element: <Elections />,
    featureID: 26,
  },
  {
    key: "/ContactsAndLinks",
    path: "/ContactsAndLinks",
    icon: <Flex><Image style={iconStyle} src={iconContactsAndLinks} preview={false} /></Flex>,
    label: "Contacts and Links",
    element: <ContactsAndLinks />,
    featureID: 15,
  },
  {
    key: "/ABDIGuestPasses",
    path: "/GuestPasses",
    icon: <Flex><Image style={iconStyle} src={iconGuestPasses} preview={false}/></Flex>,
    label: "Guest Passes",
    element: <ABDIGatePasses />,
    featureID: 27,
  },
  {
    key: '/GuestPasses',
    path: '/GuestPasses',
    icon:  <Flex><Image style={iconStyle} src={iconGuestPasses} preview={false}/></Flex>,
    label: "Guest Passes",
    element: <GuestPasses />,
    featureID: 11,
  },
  {
    key: '/GuestPasses/Create',
    path: '/GuestPasses/Create',
    element: <CreateGuestPass />,
    featureID: 11,
  },
  {
    key: '/GuestPasses/Edit',
    path: '/GuestPasses/Edit',
    element: <EditGuestPass />,
    featureID: 11,
  },
  {
    key: "/ResidentDirectory",
    path: "/ResidentDirectory",
    icon: <Flex><Image style={iconStyle} src={iconResidentDirectory} preview={false} /></Flex>,
    label: "Resident Directory",
    element: <ResidentDirectory />,
    featureID: 16,
  },
  {
    key: "/ResidentDirectory/MyProfile",
    path: "/ResidentDirectory/MyProfile",
    element: <MyProfile />,
    featureID: 16,
  },
  {
    key: "/TicketsAndFitness",
    path: "/TicketsAndFitness",
    icon: <Flex><Image style={iconStyle} src={iconRecreationAndTickets} preview={false} /></Flex>,
    label: "Tickets and Fitness",
    element: <TicketsAndFitness />,
    featureID: 10,
  },
  {
    key: "/RecreationAndTickets",
    path: "/RecreationAndTickets",
    icon: <Flex><Image style={iconStyle} src={iconRecreationAndTickets} preview={false} /></Flex>,
    label: "Recreation and Tickets",
    element: <RecreationAndTickets />,
    featureID: 30,
  },
  {
    key: "/PropertyDocuments",
    path: "/PropertyDocuments",
    icon: <Flex><Image style={iconStyle} src={iconPropertyDocuments} preview={false} /></Flex>,
    label: "Property Documents",
    element: <PropertyDocuments />,
    featureID: 17,
  },
  {
    key: "/FAQ",
    path: "/FAQ",
    icon: <Flex><Image style={iconStyle} src={iconFAQ} preview={false} /></Flex>,
    label: "FAQ",
    element: <FAQ />,
    featureID: 2,
  },
];
const AssociationRoutes: RouteGroupElement[] = [
  {
    key: "/Association",
    path: "/Association",
    label: "Association Info",
    children: associationChildrenRoutes,
    role: [Role.BoardMember, Role.Homeowner],
  },
];

export default AssociationRoutes;
