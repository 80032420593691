import { Col, Flex, Row, Space } from "antd";
import { ReactElement, useEffect, useState } from "react";
import cardBg1 from "../images/cards/1.png";
import cardBg2 from "../images/cards/2.png";
import cardBg3 from "../images/cards/3.png";
import cardBg4 from "../images/cards/4.png";
import cardBg5 from "../images/cards/5.png";
import cardBg6 from "../images/cards/6.png";
import cardBgApp from "../images/cards/app.png";
import icon1 from "../images/CardIcons/payment.png";
import icon2 from "../images/CardIcons/address_book.png";
import icon3 from "../images/CardIcons/tasks.png";
import icon4 from "../images/CardIcons/pencils.png";
import icon5 from "../images/CardIcons/maintenance.png";
import icon6 from "../images/CardIcons/ticket.png";
import appStore from "../images/cards/appStore.png";
import playMarket from "../images/cards/playMarket.png";
import { Link } from "react-router-dom";
import { observer } from "mobx-react-lite";
import AllAccessCard from "../components/Card/AllAccessCard";
import { useStore } from "../root-store-context";
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems";
import { RouteElement } from "../stores/appStores/ApplicationStore";
import cardBg7 from "../images/cards/tenant.png";
import icon7 from "../images/CardIcons/Tenant.svg";

const MainPage = observer((): ReactElement => {
  const { applicationStore, calendarStore, apiStore } = useStore();
  const [balance, setBalance] = useState<number>(0);
  const [complianceOpenItems, setComplianceOpenItems] = useState<number>(0);
  const [archOpenRequests, setArchOpenRequests] = useState<number>(0);
  const [allAccessCardItems, setAllAccessCardItems] = useState<string[]>([]);

  useEffect(() => {
    const cardItems: string[] = [];
    applicationStore.routingList.forEach((route) => {
      if (route.children) {
        route.children.forEach((children) => {
          if (children.path) {
            if (
              route.path === "/" &&
              children.path.length > 0 &&
              children.path[0] === "/"
            ) {
              cardItems.push(`${children.path}`);
            } else {
              cardItems.push(`${route.path}${children.path}`);
            }
          } else {
            cardItems.push(`${route.path}`);
          }
        });
      } else {
        if (route.path) cardItems.push(`${route.path}`);
      }
    });
    setAllAccessCardItems(cardItems);
  }, [applicationStore.routingList]);

  useEffect(() => {
    apiStore.CommonApiClient.getLandingPageInfo()
      .then((result) => {
        if (result.status === 200) {
          setBalance(result.data.item1);
          setComplianceOpenItems(result.data.item2);
          setArchOpenRequests(result.data.item3);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
  return (
    <>
      <h1>AAM All Access</h1>
      <Row gutter={[8, 8]} style={{ maxWidth: "900px" }}>
        <AllAccessCard
          title={"Make a Payment"}
          backgroundString={`linear-gradient(78.02deg, rgba(90, 120, 144, 0.5) 30.47%, rgba(2, 159, 215, 0) 86.39%),
                    linear-gradient(180deg, rgba(90, 120, 144, 0) 58.86%, #2F4B62 100%), url(${cardBg1})`}
          link="/AccountInfo/PayAndViewBalance"
          headImg={icon1}
          isActive={
            (applicationStore.AllFeaturesForCommunityObject.PayandViewBalance
              ?.isActive &&
              allAccessCardItems.includes("/AccountInfo/PayAndViewBalance")) ||
            false
          }
          headTitle={`Amount Due: $${Number(balance).toFixed(2)}`}
        />
        <AllAccessCard
          title={"Resident Directory"}
          backgroundString={`linear-gradient(78.02deg, rgba(90, 120, 144, 0.5) 30.47%, rgba(2, 159, 215, 0) 86.39%),
                    linear-gradient(180deg, rgba(90, 120, 144, 0) 58.86%, #2F4B62 100%), url(${cardBg2})`}
          link="/ResidentDirectory"
          headImg={icon2}
          isActive={
            (applicationStore.AllFeaturesForCommunityObject.ResidentDirectory
              ?.isActive &&
              allAccessCardItems.includes("/ResidentDirectory")) ||
            false
          }
          headTitle=""
        />
        <AllAccessCard
          title={"Compliance Issues"}
          backgroundString={`linear-gradient(78.02deg, rgba(90, 120, 144, 0.5) 30.47%, rgba(2, 159, 215, 0) 86.39%),
                    linear-gradient(180deg, rgba(90, 120, 144, 0) 58.86%, #2F4B62 100%), url(${cardBg3})`}
          link="/AccountInfo/Compliance"
          headImg={icon3}
          isActive={
            (applicationStore.AllFeaturesForCommunityObject.Compliance
              ?.isActive &&
              allAccessCardItems.includes("/AccountInfo/Compliance")) ||
            false
          }
          headTitle={`Open Items: ${complianceOpenItems}`}
        />
        <AllAccessCard
          title={"Architectural Requests"}
          backgroundString={`linear-gradient(180deg, #5C9DD1 0%, rgba(26, 35, 42, 0) 100%),
                    linear-gradient(180deg, rgba(90, 120, 144, 0.45) 0%, rgba(90, 120, 144, 0.44) 100%), url(${cardBg4})`}
          link="/AccountInfo/ArchitecturalRequests"
          headImg={icon4}
          isActive={
            (applicationStore.AllFeaturesForCommunityObject
              .ArchitecturalRequests?.isActive &&
              allAccessCardItems.includes(
                "/AccountInfo/ArchitecturalRequests"
              )) ||
            false
          }
          headTitle={`Open Requests: ${archOpenRequests}`}
        />
        <AllAccessCard
          title={"Maintenance Request"}
          backgroundString={`linear-gradient(78.02deg, rgba(90, 120, 144, 0.5) 30.47%, rgba(2, 159, 215, 0) 86.39%),
                    linear-gradient(180deg, rgba(90, 120, 144, 0) 58.86%, #2F4B62 100%), url(${cardBg5})`}
          link="/Forms/MaintenanceRequest"
          headImg={icon5}
          isActive={
            applicationStore.AllFeaturesForCommunityObject.MaintenanceRequestSP
              ?.isActive ||
            (applicationStore.AllFeaturesForCommunityObject
              .MaintenanceRequestemail?.isActive &&
              allAccessCardItems.includes("/Forms/MaintenanceRequest")) ||
            false
          }
          headTitle=""
        />
          <AllAccessCard
          title={"Tenant Request"}
          backgroundString={`linear-gradient(78.02deg, rgba(90, 120, 144, 0.5) 30.47%, rgba(2, 159, 215, 0) 86.39%),
                    linear-gradient(180deg, rgba(90, 120, 144, 0) 58.86%, #2F4B62 100%), url(${cardBg7})`}
          link="/Forms/TenantRegistration"
          headImg={icon7}
          isActive={
            applicationStore.AllFeaturesForCommunityObject.TenantRegistration
              ?.isActive || false
          }
          headTitle=""
        />
        <AllAccessCard
          title={"Guest Passes"}
          backgroundString={`linear-gradient(78.02deg, rgba(90, 120, 144, 0.5) 30.47%, rgba(2, 159, 215, 0) 86.39%),
                    linear-gradient(180deg, rgba(90, 120, 144, 0) 58.86%, #2F4B62 100%), url(${cardBg6})`}
          link="/Association/GuestPasses"
          headImg={icon6}
          isActive={
            (applicationStore.AllFeaturesForCommunityObject.GuestPasses
              ?.isActive &&
              allAccessCardItems.includes("/Association/GuestPasses")) ||
            false
          }
          headTitle=""
        />
        <AllAccessCard
          title={"Guest Passes"}
          backgroundString={`linear-gradient(78.02deg, rgba(90, 120, 144, 0.5) 30.47%, rgba(2, 159, 215, 0) 86.39%),
                    linear-gradient(180deg, rgba(90, 120, 144, 0) 58.86%, #2F4B62 100%), url(${cardBg6})`}
          link="/Association/GuestPasses"
          headImg={icon6}
          isActive={
            (applicationStore.AllFeaturesForCommunityObject.ABDIGatePasses
              ?.isActive &&
              allAccessCardItems.includes("/Association/GuestPasses")) ||
            false
          }
          headTitle=""
        />
      </Row>
    </>
  );
});

export default MainPage;
