import { Select } from "antd";
import { observer } from "mobx-react-lite";
import { useStore } from "../../../root-store-context";
import useBreakpoint from "use-breakpoint";
import {
  RouteElement,
  RouteGroupElement,
} from "../../../stores/appStores/ApplicationStore";
import { useLocation, useNavigate } from "react-router-dom";
import { CommunityProxy } from "../../../api/Common/CommonTypes";

const NavCommunity = observer(() => {
  const { applicationStore } = useStore();
  const rootStore = useStore();
  const location = useLocation();
  const navigate = useNavigate();

  const BREAKPOINTS = { mobile: 0, desktop: 700 };
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "mobile");

  const onChangeCommunity = async (id: string) => {
    rootStore.clearLocalStorages();
    await applicationStore.setSelectedCommunityById(
      applicationStore.CommunityList.find(
        (el) => el.id.toString() === id
      )?.id.toString() as string
    );
    await rootStore.refreshStores();

    const isExistRoute: boolean[] = [];
    applicationStore.routingList.forEach((item) =>
      isExistRoute.push(isPathExists(item, location.pathname))
    );
    if (isExistRoute.includes(true)) {
      return;
    } else {
      let isBoolean = false;
      applicationStore.routingList.forEach((item) => {
        if (!isBoolean)
          if (item?.children) {
            item?.children.forEach((i: RouteElement) => {
              if (!isBoolean && applicationStore.isFeatures(i.featureID)) {
                isBoolean = true;
                navigate(`${item.path}${i.path}`);
                return;
              }
            });
          }
      });
    }
  };

  const isPathExists = (
    routeGroup: RouteGroupElement,
    path: string
  ): boolean => {
    const pathSplit = path.split("/");
    const tPath = "/" + pathSplit[pathSplit.length - 1];
    return !!routeGroup.children?.some(
      (child: RouteElement) =>
        child.path === tPath && applicationStore.isFeatures(child.featureID)
    );
  };

  return (
    <Select
      value={applicationStore.SelectedAddress.id.toString()}
      style={breakpoint === "mobile" ? { width: "70vw", maxWidth: 500 } : { maxWidth: 500, minWidth: 210 }}
      showSearch
      filterOption={(
        input: string,
        option?: { label: string; value: number }
      ) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase())}
      options={applicationStore.CommunityList?.map(
        (i: CommunityProxy, index) =>
        ({
          value: String(i.id),
          label: `${i.address} (${i.name})`,
          key: index,
        } as any)
      )}
      onChange={onChangeCommunity}
    />
  );
});

export default NavCommunity;
