import { observer } from "mobx-react-lite";
import useBreakpoint from 'use-breakpoint'
import { Space, Table, Button, Input, Flex, Empty, Form, DatePicker, Modal, message } from 'antd';
import { SearchOutlined} from '@ant-design/icons';
import { useEffect, useState } from "react";
import { AnnouncementItem } from "../../../api/BoardVue";
import { useNavigate } from 'react-router-dom';
import { useStore } from "../../../root-store-context";
import { dateValidation } from "../../../helpers/dateValidation";
import { DateSort, FileNaturalCompare } from "../../../helpers/SorterHelper";
import Spinner from '../../../components/Spinner/Spiner';
import dayjs from 'dayjs';
import Swal from "sweetalert2";
const { Column } = Table;


const Announcements = observer(() => {

    const { apiStore } = useStore();

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [announcements, setAnnouncements] = useState<Array<AnnouncementItem>>([]);
    const [searchText, setSearchText] = useState("");
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const navigate = useNavigate();
    const [currentPage, setCurrentPage] = useState(1);
    const [isAddAnnouncementModalOpen, setIsAddAnnouncementModalOpen] = useState(false);
    const [expiryDate, setExpiryDate] = useState<string | null>(null);
    const [loading, setLoading] = useState<boolean>(false);
    const [form] = Form.useForm();

    useEffect(() => {
        setCurrentPage(1);
    }, [announcements, searchText])

    useEffect(() => {
        setIsLoading(true);
        apiStore.BoardVueApiClient.getAnnouncements()
            .then(data => {
                if(data!=null){
                    setAnnouncements(data);
                }
                setIsLoading(false)
            }).catch((error) => { 
                console.error("Error fetching items:", error);
                setIsLoading(false);
            });
    }, [])

    const onRowClck = (item: AnnouncementItem) => {
        navigate(`/BoardVue/Announcement/${item.id}`);
    }
    const openAddAnnouncementModal = () => {
        setIsAddAnnouncementModalOpen(true);
       }
       const handleAddAnnouncementOk = () => {
        form.resetFields();
        setIsAddAnnouncementModalOpen(false);
    }
    const handleAddAnnouncementCancel = () => {
        form.resetFields();
        setIsAddAnnouncementModalOpen(false);
    }
    const handleFormChange = (date: dayjs.Dayjs | null) => {
        const formattedDate = date ? dayjs(date).format('MM/DD/YYYY hh:mm:ss') : null;
        setExpiryDate(formattedDate);
      };
      const handleFormOnFinish = async(values : AnnouncementItem) => {
        handleAddAnnouncementOk();
        setLoading(true);
        const announcement = {
            ...values,
        }
        try {
            const res = await apiStore.BoardVueApiClient.AddAnnouncementItem(announcement);
            if (res.status) {
              Swal.fire({
                title: "Success",
                text: "Successfully Added Announcement.",
                icon: "success",
                confirmButtonColor: "#44637e",
              });
            } else {
              Swal.fire({
                title: "Error",
                text: "Unable to Add Announcement.",
                icon: "error",
                confirmButtonColor: "#44637e",
              });
              message.error(res.data.message || 'Failed to load data');
            }
          } catch (error) {
            message.error('Error updating submittal');
          }
          finally{
            setLoading(false);
          }
      };
    return <>
    {loading && <Spinner />}
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{padding:10}}>
            <div>
                <Space direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Announcements</h2>
                    <Input value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px' }}/>
                        <Button onClick={openAddAnnouncementModal}  
                        style={{
                        position: 'absolute',
                        right: '3rem',  
                        transform: 'translateY(-50%)', 
                        backgroundColor: '#5a7890',
                        color: '#fff',
                    }}>Add Announcement</Button>
                </Space>
            </div>
        </Flex>

        <Table dataSource={announcements.map((el, index) => { return { ...el, key: index } })}
            scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
            pagination={{ defaultPageSize: 10, showSizeChanger: false, responsive: true, current: currentPage, onChange: (pageNum) => setCurrentPage(pageNum) }}
            loading={isLoading}
            size={breakpoint === "mobile" ? "small" : "large"}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => { onRowClck(record) },
                };
            }}
            locale={{ emptyText: <Empty description={'No Announcements'} />}}>
            <Column align='left' width="40%" title="Title" dataIndex="title" key="title"
                sorter={(a: AnnouncementItem, b: AnnouncementItem, direction) => FileNaturalCompare(a.title, b.title, direction)}
                filteredValue={[searchText]}
                onFilter={(text, record) => { return String(record.title).toLowerCase().includes(String(text).toLowerCase()) }}
            />
            <Column width="8%" title="Modified" dataIndex="modified" key="modified"
            sorter={(a, b) => DateSort(a, b, "modified")}
                render={(date) => dateValidation(date)}
            />
            {/* <Column width="8%" title="Expires" dataIndex="expires" key="expires"
            sorter={(a, b) => DateSort(a, b, "expires")}
                render={(date) => dateValidation(date)}
            /> */}
        </Table>
        <Modal
      title="Add Announcements"
      open={isAddAnnouncementModalOpen}
      onOk={handleAddAnnouncementOk}
      onCancel={handleAddAnnouncementCancel}
      footer={(
        <>
          <Button size="middle" onClick={handleAddAnnouncementCancel}>Cancel</Button>
          <Button
            size="middle"
            type="primary"
            style={{ marginTop: 16 }}
            onClick={() => form.submit()}
          >
            Add
          </Button>
        </>
      )}
    >
      <Form
        form={form}
        layout="vertical"
        onFinish={handleFormOnFinish}
      >
        <Form.Item
          name="title"
          label="Title"
          rules={[{ required: true, message: 'Please enter the title' }]}
        >
          <Input placeholder="Enter title" />
        </Form.Item>
        <Form.Item
          name="body"
          label="Announcement"
          rules={[{ required: true, message: 'Please enter the announcement' }]}
        >
          <Input.TextArea rows={4} placeholder="Enter announcement" />
        </Form.Item>
        <Form.Item
          name="expires"
          label="Expires On"
          rules={[{ required: true, message: 'Please select the date' }]}
        >
          <DatePicker showTime format={"MM/DD/YYYY hh:mm:ss"} onChange ={handleFormChange} placeholder="Select date and time" />
        </Form.Item>
      </Form>
    </Modal>
    </>
})

export default Announcements