import { observer } from "mobx-react-lite";
import { Space, Button, Input, Flex, Modal, UploadProps, UploadFile, App } from 'antd';
import { SearchOutlined, InboxOutlined, CaretLeftFilled } from '@ant-design/icons';
import FileExplorerList, { FileItem } from "../../../components/FileExplorer/FileExplorerList";
import { FileDataUpload, SPFileSystemItem } from "../../../api/BoardVue";
import { useEffect, useRef, useState } from "react";
import { useStore } from "../../../root-store-context";
import Dragger from "antd/es/upload/Dragger";
import { useAuth } from "react-oidc-context";
import FileHelper from "../../../helpers/FileHelper";
import BoardDocumentsColumns from "./BoardDocumentsColumns";


const BoardDocuments = observer(() => {
    const auth = useAuth();
    const { applicationStore } = useStore();
    const { apiStore, modalViewerStore } = useStore();

    const { boardDocumentsStore } = useStore();
    const [files, setFiles] = useState<(SPFileSystemItem & FileItem)[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<(SPFileSystemItem & FileItem)[]>([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { message } = App.useApp();

    const previousValues = useRef({
        currentDirectory: null as string | null,
      });
      
    const showModal = () => {
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };


    type FileData = {
        file: UploadFile;
        bytes: number[];
    };
    const [filesData, setFilesData] = useState<FileData[]>([]);

    useEffect(() => {
        boardDocumentsStore.setDirectory("");
        boardDocumentsStore.setSearchText("");
    }, [])

    useEffect(() => {
        if (boardDocumentsStore.disableFirstSearch) {
            boardDocumentsStore.setDisableFirstSearch(false);
            return
        }
        if (boardDocumentsStore.searchText !== "") {
            boardDocumentsStore.editLoading(true)
            boardDocumentsStore.editSearching(true)
            const timeout = setTimeout(() => {
                fetchGetFilesData(boardDocumentsStore.searchText, undefined);
            }, 750)
            return () => clearTimeout(timeout)
        } else {
            boardDocumentsStore.editLoading(false);
            boardDocumentsStore.editSearching(false);
            setFilteredFiles([]);
        }
    }, [boardDocumentsStore.searchText])

    useEffect(() => {
        const { currentDirectory } = boardDocumentsStore;
        const previousDirectory = previousValues.current.currentDirectory;
        if (currentDirectory !== previousDirectory) {
            setFiles([]);
            setFilteredFiles([]);
            fetchGetFilesData(boardDocumentsStore.searchText, boardDocumentsStore.currentDirectory);
            previousValues.current.currentDirectory = currentDirectory;
          }
       
    }, [boardDocumentsStore.currentDirectory, boardDocumentsStore.forceUpdate])

    const props: UploadProps = {
        multiple: true,
        onRemove: (file) => {
            const index = filesData.map((data) => data.file).indexOf(file);
            const newfilesData = filesData.slice();
            newfilesData.splice(index, 1);
            setFilesData(newfilesData);

        },
        beforeUpload: (file, fileList) => {
            const promises: any[] = [];

            if (fileList.some((i: File) => i.size > 25 * 1024 * 1024)) {
                if(file.size > 25 * 1024 * 1024) {
                    message.error('Unacceptable file size. File upload are limited to 25MB.');
                }
                return false;
            }

            fileList.forEach(fileToProcess => {
                const reader = new FileReader();
                promises.push(new Promise((resolve, reject) => {
                    reader.onload = () => {
                        try {
                            const arrayBuffer = reader.result as ArrayBuffer;
                            const byteArray = new Uint8Array(arrayBuffer);
                            const newFileData = { file: fileToProcess, bytes: Array.from(byteArray) };
                            resolve(newFileData);
                        } catch (error) {
                            if(error instanceof RangeError){
                                message.error('File size is too large')
                            }else{
                                message.error('Upload error')
                            }
                        }

                    };
                    reader.onerror = reject;
                    reader.readAsArrayBuffer(fileToProcess);
                }));
            });

            Promise.all(promises)
                .then(fileDataArray => setFilesData([...filesData, ...fileDataArray]))
                .catch(error => message.error('Folder upload is not supported.'));

            return false;
        },
        fileList: filesData.map((data: { file: any; }) => data.file)
    };

    const handleUpload = async () => {
        boardDocumentsStore.editLoading(true);
        handleCancel();
        setTimeout(()=>uploadFilesFromModal(),100);
    };

    const uploadFilesFromModal = () => {
        filesData.map(async (file) => {
            const fileData: FileDataUpload = {
                data: FileHelper.ArrayBufferToBase64(file.bytes),
                folderPath: boardDocumentsStore.currentDirectory,
                name: file.file.name,
                communityId: applicationStore.SelectedAddress.communityId,
                office: applicationStore.SelectedAddress.office,
                email: auth.user?.profile.email as string
            }
            apiStore.BoardVueApiClient.uploadFile(fileData)
                .then((res) => {
                    message.success(file.file.name + ' upload successfully.');
                })
                .catch((res) => {
                    message.error(file.file.name + ' upload failed.');
                }).finally(() => {
                    boardDocumentsStore.editLoading(false);
                    setFilesData([]);
                    fetchGetFilesData(boardDocumentsStore.searchText, boardDocumentsStore.currentDirectory);
                })
            console.log(file)
        });
    };

    const openFolderOrFile = (index: number, isFolder: boolean, name: string, fullPath: string, serverRelativePath: string, event: MouseEvent | any = null) => {
        if (isFolder) {
            boardDocumentsStore.setDirectory(serverRelativePath)
        } else {
            boardDocumentsStore.isLoading = true;
            apiStore.BoardVueApiClient.getFile(fullPath)
                .then(res => {
                    modalViewerStore.openFileByData(res.data, name);
                    boardDocumentsStore.isLoading = false;
                })
                .catch(error => {
                    console.log(error);
                    boardDocumentsStore.isLoading = false;
                });
        }
    }

    const mapDataToFilesItems = (data: any): SPFileSystemItem[] => {
        return data.map((item: any, index: number):SPFileSystemItem => {
            const localName = item.localName == null || item.localName === "" || item.localName === '' ? item.fullPath?.split('/')?.reverse()[0] : item.localName
            return {
                ...item,
                modified: new Date(item.modified),
                localName: localName,
            }
        })
    }

    const fetchGetFilesData = async (searchText?: string, folderName?: string) => {
        boardDocumentsStore.editLoading(true)
        let filesResponsePromise;
        let filteredFilesResponsePromise;
        if (folderName !== undefined) {
            filesResponsePromise = apiStore.BoardVueApiClient.getFilesListInFolder(folderName == null ? "" : folderName);
        }
        if (searchText !== undefined && searchText !== "") {
            filteredFilesResponsePromise = apiStore.BoardVueApiClient.searchFilesListInFolder(searchText);
        }
        if (filteredFilesResponsePromise) {
            let filteredFilesResponse = await filteredFilesResponsePromise;
            setFilteredFiles(mapDataToFilesItems(filteredFilesResponse) as any);
        }
        if (filesResponsePromise) {
            let filesResponse = await filesResponsePromise;
            setFiles(mapDataToFilesItems(filesResponse) as any);
        }
        boardDocumentsStore.editLoading(false)
        boardDocumentsStore.editSearching(false)
    }
    const onRow = (record: SPFileSystemItem, rowIndex: number) => {
        return {
            onClick: (event: MouseEvent) => {
                openFolderOrFile(rowIndex, record.isFolder, record.localName, record.fullPath, record.serverRelativePath, event);

            }, // click row
            //onDoubleClick: (event: MouseEvent) => {
            //  openFolderOrFile(rowIndex,record.isFolder,record.localName,record.fullPath,event)
            //}, // double click row
            //onContextMenu: (event) => {console.log(event,rowIndex,record)}, // right button click row
            //onMouseEnter: (event) => {console.log(event,rowIndex,record)}, // mouse enter row
            //onMouseLeave: (event) => {console.log(event,rowIndex,record)}, // mouse leave row
        };
    }



    const prevOnClick = () => {
        boardDocumentsStore.setPreviewDirectory();
    }

    const onLoadRowFiles = (files: File[], serverRelativePath: string) => {
        uploadFiles(files, serverRelativePath);
    }

    const onLoadFiles = (files: File[]) => {
        uploadFiles(files);
    }

    const uploadFiles = async (files: File[], path: string = boardDocumentsStore.currentDirectory) => {
        try {
            boardDocumentsStore.editLoading(true);
            const filesForUpload: Array<Promise<FileDataUpload>> = files.map(async (file: File) => {
                var base64 = await FileHelper.FileToBase64(file);
                return {
                    data: base64,
                    folderPath: path,
                    name: file.name,
                    communityId: applicationStore.SelectedAddress.communityId,
                    office: applicationStore.SelectedAddress.office,
                    email: auth.user?.profile.email as string
                }
            });
            var fileDataArray: Array<FileDataUpload> = await Promise.all(filesForUpload);
            const fileDataUpload = await Promise.all(fileDataArray);
            var results = await fileDataUpload.map(async (fileData: FileDataUpload) => {
                var res = await apiStore.BoardVueApiClient.uploadFile(fileData);
                if (res.statusText === "OK") {
                    message.success(fileData.name + ' upload successfully.');
                } else {
                    message.success(fileData.name + ' upload failed.');
                }
            });
            await Promise.all(results);
            fetchGetFilesData(boardDocumentsStore.searchText, boardDocumentsStore.currentDirectory);
        } catch (error) {
            message.error('Unexpected error.');
        }
        boardDocumentsStore.editLoading(false);
    }
    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="middle" style={{ padding: 10 }}>
            <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
                <Space style={{ flexWrap: "wrap" }}>
                    <h2 style={{ width: "280px" }}>Board Documents</h2>
                </Space>
                <Space style={{ flex: "1 1 300px", justifyContent: "space-between" }}>
                    <Input value={boardDocumentsStore.searchText}
                        onChange={(e) => { boardDocumentsStore.setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px', display: "flex", flex: "1 1 300px" }} />
                    <Space>
                        <Button size="middle" type="primary" onClick={showModal}>Create</Button>
                    </Space>
                </Space>
            </div>
        </Flex>
        <Modal title="Upload files" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}
            footer={(_) => (
                <>
                    <Button size="middle" onClick={handleCancel}>Cancel</Button>
                    <Button
                        size="middle"
                        type="primary"
                        onClick={handleUpload}
                        disabled={filesData.length === 0}
                        style={{ marginTop: 16 }}
                    >Upload</Button>
                </>
            )}>
            <Dragger {...props}>
                <p className="ant-upload-drag-icon">
                    <InboxOutlined style={{color: "#5a7890"}}/>
                </p>
                <p className="ant-upload-text">Click or drag file to this area to upload</p>
                <p className="ant-upload-hint">
                    Support for a single or bulk upload.
                </p>
            </Dragger>
        </Modal>
        {/* <h4 style={{padding:10,margin:0}}>Path:{boardDocumentsStore.breadСrumbs.map(i=> `/${i}`)}</h4> */}
        {boardDocumentsStore.currentDirectory !== "" && (
            <div style={{ padding: 10, height: 60 }}>
                <Button size="middle" onClick={prevOnClick}>
                    <CaretLeftFilled /> Back
                </Button>
            </div>)
        }
        <FileExplorerList 
            data={(boardDocumentsStore.searchText !== "" || boardDocumentsStore.isSearching) ? filteredFiles : files} 
            onRowFunc={onRow} 
            isLoading={boardDocumentsStore.isLoading} 
            onLoadRowFiles={onLoadRowFiles} 
            onLoadFiles={onLoadFiles} 
            columns={BoardDocumentsColumns as any}/>
    </>
})

export default BoardDocuments