import { ColumnsType } from "antd/es/table";
import { FileExplorerColumns } from "../../../components/FileExplorer/FileExplorerList";
import { dateValidation } from "../../../helpers/dateValidation";
import { Flex } from "antd";
import { FolderFilled, FileTextOutlined } from '@ant-design/icons'
import { DateSort, FileNaturalCompare, TextSort } from "../../../helpers/SorterHelper";
import { MailArchiveFileItem } from "./MailArchive";
import { MailArchiveItem } from "../../../api/AccountSummary/AccountSummaryTypes";

const columns: ColumnsType<MailArchiveFileItem> = [
    
    
    {
        key: "1",
        title: "Document Type",
        colSpan: 1,
        align: 'left',
        width: "40%",
        dataIndex: "name",
        sorter: (a: MailArchiveFileItem, b: MailArchiveFileItem) => FileNaturalCompare(a.name, b.name, 'ascend', a.isFolder, b.isFolder),
        // defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
    {
        key: "2",
        width: "10%",
        title: "Date Submitted",
        dataIndex: "dateSubmitted",
        sorter: (a: MailArchiveItem, b: MailArchiveItem) => DateSort(a, b, "dateSubmitted"),
        render: (date: any) => dateValidation(date)
    },
    {
        key: "3",
        width: "10%",
        title: "Date Processed",
        dataIndex: "date",
        defaultSortOrder: 'descend',
        sorter: (a: MailArchiveItem, b: MailArchiveItem) => DateSort(a, b, "date"),
        render: (date: any) => dateValidation(date)
    },
    // {
    //     key: "3",
    //     title: "Type",
    //     width: "16%",
    //     dataIndex: "docType",
    //     sorter: (a: MailArchiveFileItem, b: MailArchiveFileItem) => TextSort(a, b, "docType")
    // },
    
];

const small: ColumnsType<MailArchiveFileItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "isFolder",
        render: (isFolder: any) => (
            <Flex align="center" justify="center">
                {isFolder ? <FolderFilled style={{ fontSize: 20, color: "#5B7B94" }} /> : <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />}
            </Flex>
        )
    },
    {
        key: "2",
        colSpan: 2,
        align: 'left',
        title: "Name",
        dataIndex: "name",
        sorter: ((a: MailArchiveFileItem, b: MailArchiveFileItem, direction: string) => FileNaturalCompare(a.name, b.name, direction, a.isFolder, b.isFolder)) as any,
        // defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>
    },
    {
        key: "3",
        width: "8%",
        title: "Date",
        dataIndex: "date",
        sorter: (a: MailArchiveItem, b: MailArchiveItem) => DateSort(a, b, "date"),
        defaultSortOrder: 'descend',
        render: (date: any) => dateValidation(date)
    }
];

const MailArchiveDocumentsColumns: FileExplorerColumns<MailArchiveFileItem> = {
    large: columns,
    small: small,
};

export default MailArchiveDocumentsColumns;