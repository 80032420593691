import { useEffect, useState } from 'react';
import type { EChartsOption } from "echarts";
import ReactECharts from '../../../../components/ReactECharts/ReactECharts';

export type MonthlyArchitecturalSubmissionItem = {
    value: number;
    name: string;
}

export interface IArchitecturalModificationTrendsProps {
    data: MonthlyArchitecturalSubmissionItem[];
}

const ArchitecturalModificationTrends = (props: IArchitecturalModificationTrendsProps) => {
    const [option, setOption] = useState<EChartsOption>({});

    useEffect(() => {
        const data = props.data;
        const parsedData = data.map(i => ({
            name: i.name,
            value: i.value
        }));
        const option: EChartsOption = {
            title: {
                text: 'Architectural Modification Trends'
            },
            tooltip: {
                formatter: function (info: any) {
                    return [info.name].join('');
                }
            },
            series: [{
               
                roam: false,
                nodeClick: false,
                breadcrumb: { show: false },
                type: 'treemap',
                data: parsedData,
            }],
        };
        setOption(option);
    }, [props]);
    return <ReactECharts option={option} />;
};

export default ArchitecturalModificationTrends;
