import { AxiosResponse } from "axios";
import MobileWebApiClient, { Tuple } from "../base/MobileWebApiClient";
import {
  CommunityFeature,
  ComplianceConcern,
  ComplianceConcernHistory,
  ContactsAndLinksItem,
  DirectoryProfile,
  DwellingCommunityData,
  FAQResultItem,
  GuestPassItem,
  GuestPassType,
  PostGuestPass,
  PropertyDocumentItem,
  ResidentDirectoryPage,
  ResidentDirectoryProfileTransfer,
  ResidentProfileProxy
} from "./AssociationTypes";

export default class AssociationApiClient extends MobileWebApiClient {
  public async getContactsAndLinks(): Promise<ContactsAndLinksItem> {
    const communityWebSitePromise = this.tryPost(
      "community/getcommunitywebsite",
      this._configApi.community
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
    const communitymanagersPromise = this.tryPost(
      "community/getcommunitymanagers ",
      this._configApi.community
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
    const communityadditionalcontactsPromise = this.tryPost(
      "community/getcommunityadditionalcontacts",
      this._configApi.community
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => []);
    const [
      communityWebSiteResponse,
      communityManagersResponse,
      communityAdditionalContactsResponse,
    ] = await Promise.all([
      communityWebSitePromise,
      communitymanagersPromise,
      communityadditionalcontactsPromise,
    ]);
    const result: ContactsAndLinksItem = {
      WebSite: communityWebSiteResponse,
      ManagerName: communityManagersResponse?.communityManagerName,
      ManagerEmail: communityManagersResponse?.communityManagerEmail,
      AdditionalContacts: communityAdditionalContactsResponse,
    };
    return result;
  }

  public async getCommunityResidentDirectoryByPageNum(
    pageNum: number,
    pageSize: number,
  ): Promise<ResidentDirectoryPage> {
    const communityResidentDirectoryPage = await this.tryPost(
      "community/getcommunityresidentdirectorybypagenum",
      {
        Item1: this._configApi.community,
        Item2: pageNum,
        Item3: pageSize, //page size
      }
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => []);
    return communityResidentDirectoryPage;
  }

  public async searchCommunityResidentDirectoryPage(
    searchText: string,
    pageNum: number,
    pageSize: number,
  ): Promise<ResidentDirectoryPage> {
    const communityResidentDirectoryPage = await this.tryPost(
      "community/searchcommunityresidentdirectorybypagenum",
      {
        Item1: this._configApi.community.communityId,
        Item2: searchText,
        Item3: pageNum,
        Item4: pageSize, //page size 
      }
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => []);
    return communityResidentDirectoryPage;
  }

  public async getCommunityResidentDirectoryProfile(): Promise<DirectoryProfile> {
    const communityResidentDirectoryProfile = await this.tryPost(
      "community/getcommunityresidentdirectoryprofile",
      {
        Item1: this._configApi.userEmail,
        Item2: this._configApi.community.residentKey,
      }, true
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
    return communityResidentDirectoryProfile;
  }

  public async updateCommunityResidentDirectoryProfile(
    profile: ResidentProfileProxy
  ): Promise<boolean> {
    const communityResidentDirectoryProfile = await this.tryPost(
      "community/updatecommunityresidentdirectoryprofile",
      profile
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : false))
      .catch(() => false);
    return communityResidentDirectoryProfile;
  }

  public async getAuthString(): Promise<string> {
    const communityResidentDirectoryProfile = await this.post(
      "community/getdwellingauthstringforcommunity",
      String(this._configApi.community.communityId)
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
    return communityResidentDirectoryProfile;
  }

  public async getDWellingCommunityData(): Promise<DwellingCommunityData> {
    const communityResidentDirectoryProfile = await this.post(
      "community/getdwellingcommunitydata",
      this._configApi.community
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
    return communityResidentDirectoryProfile;
  }

  public async getDwGuestsInCommunity(
    authString: string,
    communityId: number,
    homeId: number
  ): Promise<GuestPassItem[]> {
    const communityResidentDirectoryProfile = await this.tryPost(
      "community/getdwguestsincommunity",
      {
        AuthString: authString,
        CommunityId: communityId, //DLCommunityId
        HomeId: homeId,
      }
    )
      .then((res) =>
        res.statusText === "OK" && res.data && res.data.guests
          ? res.data.guests
          : []
      )
      .catch(() => []);
    return communityResidentDirectoryProfile;
  }

  public async getDwPassTypesForCommunity(
    authString: string,
    communityId: number,
    homeId: number
  ): Promise<Array<GuestPassType>> {
    const communityResidentDirectoryProfile = await this.post(
      "community/getdwpasstypesforcommunity",
      {
        AuthString: authString,
        CommunityId: communityId, //DLCommunityId
        // HomeId: homeId
      }
    )
      .then((res) =>
        res.statusText === "OK" && res.data && res.data.PassTypes
          ? res.data.PassTypes
          : []
      )
      .catch(() => []);
    return communityResidentDirectoryProfile;
  }

  public async createGuestPass(
    authString: string,
    communityId: number,
    homeId: number,
    userId: number,
    guestPass: PostGuestPass,
  ): Promise<boolean> {
    const communityResidentDirectoryProfile = await this.tryPost(
      "community/PostDwNewGuest",
      {
        AuthString: authString,
        CommunityId: communityId,
        HomeId: homeId,
        UserId: userId,
        GuestInfo: guestPass
      }
    )
      .then((res) => res.statusText === "OK" && res.data ? true : false)
      .catch(() => false);
    return communityResidentDirectoryProfile;
  }

  public async editGuestPass(
    authString: string,
    communityId: number,
    homeId: number,
    userId: number,
    guestPass: PostGuestPass,
    guestID: string
  ): Promise<boolean> {
    const communityResidentDirectoryProfile = await this.tryPost(
      "community/EditDwGuest",
      {
        AuthString: authString,
        CommunityId: communityId,
        HomeId: homeId,
        UserId: userId,
        GuestInfo: guestPass,
        GuestId: guestID
      }
    )
      .then((res) => res.statusText === "OK" ? true : false)
      .catch(() => false);
    return communityResidentDirectoryProfile;
  }

  public async deleteGuestPass(
    authString: string,
    communityId: number,
    homeId: number,
    userId: number,
    guestID: string
  ): Promise<boolean> {
    const communityResidentDirectoryProfile = await this.tryPost(
      "community/DeleteDwGuest",
      {
        AuthString: authString,
        CommunityId: communityId,
        HomeId: homeId,
        UserId: userId,
        GuestId: guestID
      }
    )
      .then((res) => res.statusText === "OK" ? true : false)
      .catch(() => false);
    return communityResidentDirectoryProfile;
  }

  public async electionHasActive(): Promise<boolean> {
    const electionHasActive = await this.post("community/electionhasactive", {
      Item1: this._configApi.community.residentKey,
      Item2: this._configApi.community.communityId,
    })
      .then((res) => (res.statusText === "OK" ? true : false))
      .catch(() => false);
    return electionHasActive;
  }

  public async electionGetHtmlCode(): Promise<string> {
    const electionHasActive = await this.tryPost("community/electiongethtmlcode", {
      Item1: this._configApi.community.residentKey,
      Item2: this._configApi.community.communityId,
    })
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
      console.log('electionGetHtmlCode', electionHasActive);
    return electionHasActive;
  }

  
  public async getCommunityFeatureById(featureId: number): Promise<CommunityFeature> {
    const getComplianceHistory = await this.tryPost(
      "community/GetCommunityFeatureById",
      {
        Item1: this._configApi.community,
        Item2: featureId,
      }
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
    return getComplianceHistory;
  }

  public async getAbdiPageToken(communityId: number): Promise<string> {
    const getComplianceHistory = await this.tryPost(
      "community/getabdipagetoken",
      {
        Item1: communityId,
        Item2: this._configApi.community.residentKey,
      }
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
    return getComplianceHistory;
  }

  public async getPropertyDocuments(): Promise<PropertyDocumentItem[]> {
    try {
      var res = await this.tryPost('sharepoint/getdisclosureslist', {
        "item1": this._configApi.community.communityId,
        "Item2": this._configApi.community.office,
      });
      if (res.statusText === 'OK' && res.data) {
        return res.data.map((item: PropertyDocumentItem): PropertyDocumentItem => ({
          ...item,
          modified: new Date(item.modified),
          disclosureExpDate: item.disclosureExpDate ? new Date(item.disclosureExpDate) : null,
        }))
      } else {
        return [];
      }
    } catch (error) {
      console.error('Error fetching BoardDocuments:', error);
      return [];
    }
  }

  public getFile = async (url: string): Promise<AxiosResponse> => {
    return await this.tryPost('sharepoint/getfile', {
      "Item1": url,
      "Item2": this._configApi.community.communityId,
      "Item3": this._configApi.community.office,
    }).then((res)=>res.data);
  }

  public async GetFaq(): Promise<AxiosResponse<Array<FAQResultItem>, any>> {
    var res = await this.tryPost(
      `community/getcommunityfaq`,
      JSON.stringify(this._configApi.community)
    );
    return res;
  }

  public async SearchFaq(
    searchStr: string
  ): Promise<AxiosResponse<Array<FAQResultItem>, any>> {
    var res = await this.tryPost(`community/searchcommunityfaq`, {
      Item1: this._configApi.community,
      Item2: searchStr,
    });
    return res;
  }
}
