import { makeAutoObservable } from "mobx";
import { ConfigApi } from "../../api/base/ConfigApi";
import BoardVueApi from "../../api/BoardVue";
import ReportClient from "../../api/Report";
import RicApiClient from "../../api/RIC";
import AssociationApiClient from "../../api/Association/Association";
import AccountSummaryApiClient from "../../api/AccountSummary/AccountSummary";
import FormsApiClient from "../../api/Forms/Forms";
import CommonApiClient from "../../api/Common/Common";


class ApiStore {
    CommonApiClient!: CommonApiClient

    AccountSummaryApiClient!: AccountSummaryApiClient
    AssociationApiClient!: AssociationApiClient
    FormsApiClient!: FormsApiClient

    BoardVueApiClient!: BoardVueApi
    RicApiClient!: RicApiClient
    ReportAndDashboardClient!: ReportClient
    
    configApi: ConfigApi = {} as ConfigApi;
    constructor() {
        makeAutoObservable(this);
    }

    setConfigApi = (configApi: ConfigApi) => {
        this.configApi = configApi;
        this.FormsApiClient = new FormsApiClient(configApi);
        this.AccountSummaryApiClient = new AccountSummaryApiClient(configApi);
        this.BoardVueApiClient = new BoardVueApi(configApi);
        this.AssociationApiClient = new AssociationApiClient(configApi);
        this.ReportAndDashboardClient = new ReportClient(configApi);
        this.RicApiClient = new RicApiClient(configApi);
        this.CommonApiClient = new CommonApiClient(configApi);
    }
}
export default ApiStore

