import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import { Button, Col, Flex, Row, Spin, Table } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { ComplianceConcernHistoryItem } from "../../../api/Association/AssociationTypes";
import { DateSort, TextSort } from "../../../helpers/SorterHelper";
import { dateValidation } from "../../../helpers/dateValidation";
import { ArrowLeftOutlined } from "@ant-design/icons";

const ComplianceConcernHistory = observer((): ReactElement => {
  const { complianceConcernStore } = useStore();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState<boolean>(false);


  useEffect(() => {
    if (!(complianceConcernStore.complianceHistory?.length > 0)) {
      setIsLoading(true);
      complianceConcernStore.getHistory().then(() => {
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      })
    }
  }, []);


  const columns = [
    {
      key: "created",
      title: "Submission Date",
      dataIndex: "created",
      width: "20%",
      sorter: (a: ComplianceConcernHistoryItem, b: ComplianceConcernHistoryItem) => DateSort(a, b, "created"),
      render: (date: any) => dateValidation(date)
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      width: "20%",
      sorter: (a: ComplianceConcernHistoryItem, b: ComplianceConcernHistoryItem) => TextSort(a, b, "status")
    },
    {
      key: "description",
      title: "Concern",
      dataIndex: "description",
      width: "60%",
      sorter: (a: ComplianceConcernHistoryItem, b: ComplianceConcernHistoryItem) => TextSort(a, b, "description"),
      render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Flex vertical={true} gap={10}>
        <Flex gap={20} align="center">
          <Button
            size="middle"
            type="primary"
            onClick={() => {
              navigate("/Forms/ComplianceConcern");
            }}
          >
            Back
          </Button>
          <h1>Compliance Concern History</h1>
        </Flex>
        <Table dataSource={complianceConcernStore.complianceHistory?.map((el, index) => { return { ...el, key: index } })} pagination={false} columns={columns} />
      </Flex>
    </Spin>
  );
});

export default ComplianceConcernHistory;
