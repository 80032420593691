import { Button, Col, Divider, Form, Input, Modal, Row, Space, UploadFile, UploadProps, message } from "antd";
import TextArea from "antd/es/input/TextArea";
import { InboxOutlined } from '@ant-design/icons';
import { ReactElement, useState } from "react";
import { Appeal } from "../../../api/AccountSummary/AccountSummaryTypes";
import Dragger from "antd/es/upload/Dragger";
import FileHelper from "../../../helpers/FileHelper";
import CustomUploadItem from "../../BoardVueGroup/BoardTasks/CustomUploadItem";
import { useStore } from "../../../root-store-context";
import { validateMessages } from "../../../helpers/validationMessages";
import { Attachment } from "../../../api/Common/CommonTypes";
import { getPhoneFormat } from "../../../helpers/phoneFormat";



export const ComplianceAppealForm = (props: { isOpenModal: boolean, dismissModal: () => void, violationNo: string, violationItemType: string }): ReactElement => {
   const [form] = Form.useForm();
   const { apiStore, modalViewerStore, applicationStore, complianceAppealStore } = useStore();

   const { isOpenModal, dismissModal } = props;
   const [fileListAttachments, setFileListAttachments] = useState<UploadFile[]>([]);
   const success = () => {
      message.open({
         type: 'success',
         content: 'New Appeal added successfully!',
      });
   };

   const error = () => {
      message.open({
         type: 'error',
         content: 'Error occurred during creation. Please check the entered data.',
      });
   };

   const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
      <>
         <span style={{ fontWeight: 500 }}>{label}</span>
      </>
   );

   const uploadPropsAttachments: UploadProps = {
      onRemove: (file) => {
         if (file.status === "done") {
            return false;
         }

         const updatedFileList = fileListAttachments.filter(
            (attachment) => attachment.uid !== file.uid
         );
         setFileListAttachments(updatedFileList);
         const updatedAttachments = complianceAppealStore.Attachments.filter(
            (attachment) => attachment.Guid !== file.uid
         );
         complianceAppealStore.Attachments = updatedAttachments;
      },

      onDownload: (file) => {
         if (file.status === "done") {
            complianceAppealStore.isLoading = true;
            apiStore.AccountSummaryApiClient.getFile(file.uid)
               .then(res => {
                  modalViewerStore.openFileByData(res.data, file.name);
                  complianceAppealStore.isLoading = false;
               })
               .catch(error => {
                  complianceAppealStore.isLoading = false;
                  console.log(error)
               });
         }
      },
      beforeUpload: (file, allFiles) => {
         if (allFiles.length + fileListAttachments.length > 10) {
            if (allFiles[0].uid === file.uid) {
               message.error('You can attach not more than 10 files.');
            }
            return false;
         }
         var isExist = fileListAttachments.some(i => i.name === file.name);
         if (isExist) {
            message.error('File with the same name already exists.');
            return false;
         }
         if (allFiles.some((i: File) => i.size > 10 * 1024 * 1024)) {
            if (file.size > 10 * 1024 * 1024) {
               message.error('Unacceptable file size. File upload are limited to 10MB.');
            }
            return false;
         }
         setFileListAttachments((prev) => [...prev, file]);
         const reader = new FileReader();
         reader.readAsArrayBuffer(file);
         reader.onload = e => {
            const arrayBuffer = e.target?.result as ArrayBuffer;
            const byteArray = new Uint8Array(arrayBuffer);
            const newAttachment: Attachment = {
               Name: file.name,
               Data: FileHelper.ArrayBufferToBase64(Array.from(byteArray)),
               Guid: file.uid,
               Url: ""
            };
            complianceAppealStore.Attachments = [...complianceAppealStore.Attachments, newAttachment];
         };
         return false;
      },
      showUploadList: {
         showDownloadIcon: false,
         showPreviewIcon: false,
         showRemoveIcon: false,
      },
   };

   const onSubmit = async () => {
      complianceAppealStore.editLoading(true);
      complianceAppealStore.complianceAppeal.Attachments = complianceAppealStore.Attachments;
      complianceAppealStore.setNewPhone(complianceAppealStore.complianceAppeal.PhoneNumber.replace(/\D/g, ''))
      complianceAppealStore.complianceAppeal.EmailAddress = complianceAppealStore.complianceAppeal.EmailAddress || apiStore.configApi.userEmail;
      complianceAppealStore.complianceAppeal.ComplianceIssue = `${props.violationNo} - ${props.violationItemType}`;

      const allPromisesAttach = complianceAppealStore.complianceAppeal?.Attachments?.map(async item => {
         const attachGUID = await complianceAppealStore.addAttachmentToBuffer(item);
         if (typeof attachGUID === "string") {
            item.Guid = attachGUID;
            item.Data = null as any;
            item.Url = null as any;
         }
      });

      if (allPromisesAttach) {
         await Promise.all(allPromisesAttach);
      }

      complianceAppealStore.complianceAppeal.Community = applicationStore.SelectedAddress;
      complianceAppealStore.complianceAppeal.UserId = applicationStore.SelectedAddress.userId.toString();
      complianceAppealStore.addAppealRequestStore().then((res) => {
         if (res) {
            success()
            dismissModal()
         }
         else {
            error()
         }
      })
   }

   return (
      <Modal
         title={""}
         style={{ top: "1vh" }}
         styles={{
            body: {
               display: "flex",
               flexDirection: "column",
               alignItems: "center",
               width: "100%"
            },
         }}
         width={"90%"}
         open={isOpenModal}
         footer={null}
         onCancel={() => { dismissModal() }}
      >
         <div style={{ width: "100%", textAlign: "left" }}>
         <h1>Violation Appeals Form</h1>
         <p>Please complete the form below to appeal a fine.<br></br>
         Requests for appeal must be received within the number of days stated on your non-compliance/fine letter.<br></br>
         Completion of this form DOES NOT automatically remove the fine.<br></br>
         If you wish to appeal more than one violation, a separate appeal must be submitted.</p>
         </div>
         <Form
            form={form}
            variant='outlined'
            layout="vertical"
            initialValues={{ ...complianceAppealStore.complianceAppeal, 
               EmailAddress: apiStore.configApi.userEmail,
               DaytimePhone: getPhoneFormat(complianceAppealStore.complianceAppeal.PhoneNumber)
             }}
            labelCol={{ span: 24 }}
            style={{ maxWidth: 900, width: "100%" }}
            validateMessages={validateMessages}
            onValuesChange={(changedValues, allValues) => complianceAppealStore.complianceAppeal = { ...allValues }}
            requiredMark={customizeRequiredMark}
            autoComplete="off"
            onFinish={onSubmit}
            scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
            <Row>
               <Col span={24}>
                  <Form.Item<Appeal> name="UserId" hidden>
                     <Input />
                  </Form.Item>
               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  <Form.Item<Appeal> label="Reason For Appeal" name="ReasonForAppeal" rules={[{ required: true }]}>
                     <TextArea rows={4} />
                  </Form.Item>
               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  <Form.Item<Appeal> label="Appeal Amount" name={"AppealAmount"} style={{ width: "100%" }}
                     rules={[{
                        required: true,
                        validator: (_, value) => {
                           if (!value || /^(\d+(\.\d{1,2})?)$/.test(value)) {
                              return Promise.resolve();
                           }
                           return Promise.reject('Please enter a valid value');
                        },
                     }]}>
                     <Input prefix="$" />
                  </Form.Item>
               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  <Form.Item<Appeal> label="Phone Number" name={"PhoneNumber"} style={{ width: "100%" }}
                     rules={[{ required: true, message: 'Please input your phone number!', min: 12 }]}>
                     <Input onChange={(event) => {
                        const { value } = event.target;
                        const formattedValue = getPhoneFormat(value);
                        form.setFieldsValue({
                           PhoneNumber: formattedValue,
                        });

                     }} />
                  </Form.Item>
               </Col>
            </Row>
            <Row>
               <Col span={24}>
                  <Form.Item<Appeal> label="Email Address" name="EmailAddress" style={{ width: "100%" }}
                     rules={[{ required: true, type: "email" }]} >
                     <Input pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"/>
                  </Form.Item>
               </Col>
            </Row>
            <Row>
               <Divider orientation="left" orientationMargin="0">
                  File Upload
               </Divider>
            </Row>
            <Row>
               <Col span={24} style={{ paddingBottom: 15 }}>
                  <div>
                     <Dragger fileList={fileListAttachments}
                        multiple
                        {...uploadPropsAttachments}
                        itemRender={CustomUploadItem}>
                        <p className="ant-upload-drag-icon">
                           <InboxOutlined style={{color: "#5a7890"}}/>
                        </p>
                        <p className="ant-upload-text">Click or drag file to this area to upload</p>
                        <p className="ant-upload-hint">
                           Support for a single or bulk upload.
                        </p>
                     </Dragger>
                  </div>
               </Col>
            </Row>
            <Row>
               <Col span={24} style={{ textAlign: 'right', marginTop: "16px" }}>
                  <Space>
                     <Button size="middle" onClick={() => { dismissModal() }}>
                        Cancel
                     </Button>
                     <Button size="middle" type="primary" htmlType="submit">Save</Button>
                  </Space>
               </Col>
            </Row>
         </Form>
      </Modal>
      
   )
}