import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { DocumentFilter } from "../../../api/BoardVue";

export const defaultRecordFilters:DocumentFilter[] = [
    { displayName: "Document Type", items: [] },
    { displayName: "Resident Account", items: [] },
    { displayName: "Architectural Submission Status", items: [] },
    { displayName: "Construction Type", items: [] },
    { displayName: "Correspondence Type", items: [] },
    { displayName: "Deed Type", items: [] },
]
class RecordArchiveStore {
    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }
}
export default RecordArchiveStore