import React, { useCallback, useEffect, useState } from 'react';
import { App, Button, Checkbox, Col, DatePicker, Divider, Dropdown, Form, Input, MenuProps, Row, Select, Space } from 'antd';
import { useStore } from '../../../root-store-context';
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import { validateMessages } from '../../../helpers/validationMessages';
import { AccountContactDataItem, ContactPhone } from '../../../api/AccountSummary/AccountSummaryTypes';
import {
    DeleteFilled,
    PlusOutlined,
    HomeOutlined,
    MobileOutlined,
    PhoneOutlined
} from '@ant-design/icons';
import { getPhoneFormat } from '../../../helpers/phoneFormat';

const ContactForm = observer((props: { submitBtnText: string, submit: () => void, isCreate?: boolean }) => {

    const { apiStore } = useStore();
    const [form] = Form.useForm();
    const { accountContactsStore } = useStore();
    const navigate = useNavigate();
    const { message } = App.useApp();
    const [prevEmail, setPrevEmail] = useState<string>("");
    const [errorEmail, setErrorEmail] = useState('');
    const values = Form.useWatch([], form);
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout>();

    useEffect(() => {
        setPrevEmail(accountContactsStore.contactModel.email);
    }, [accountContactsStore.isLoading]);

    useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                accountContactsStore.setSubmittableForm(true);
            },
            () => {
                accountContactsStore.setSubmittableForm(false);
            },
        );
    }, [values]);

    useEffect(() => {
        form.setFieldsValue(accountContactsStore.contactModel);
        if (accountContactsStore.shouldResetForm) {
            form.resetFields();
            accountContactsStore.shouldResetForm = false;
        }

    }, [accountContactsStore, accountContactsStore.contactModel, accountContactsStore.contactModel.phones]);


    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );


    const validateZipCode = (zipCode: string): boolean => {
        return zipCode.length >= 5 && zipCode.length <= 7;
    };

    const items: MenuProps['items'] = [
        {
            key: 'Home',
            label: <><HomeOutlined /> Home</>,
            onClick: () => {
                accountContactsStore.addNewPhone("Home");
            }
        },
        {
            key: 'Work',
            label: <><PhoneOutlined /> Work</>,
            onClick: () => {
                accountContactsStore.addNewPhone("Work");
            }
        },
        {
            key: 'Mobile',
            label: <><MobileOutlined /> Mobile</>,
            onClick: () => {
                accountContactsStore.addNewPhone("Mobile");
            }
        },
    ];

    const handleInputChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        accountContactsStore.setEmail(newValue);
        clearTimeout(timeoutId);
        
        if(newValue == "" || newValue == undefined){
            setErrorEmail("Email is required!");
            return;
        }

        const pattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
        if(!pattern.test(newValue)){
            setErrorEmail("Email is not a valid email!");
            return;
        }

        const newTimeoutId = setTimeout(() => {
            validateEmail(newValue);
        }, 750);
        setTimeoutId(newTimeoutId)
    };

    const validateEmail = async (email: string) => {
        if(email == "" || email == undefined){
            setErrorEmail("Email is required!");
            return;
        }

        const pattern = /^[^@\s]+@[^@\s]+\.[^@\s]+$/;
        if(!pattern.test(email)){
            setErrorEmail("Email is not a valid email!");
            return;
        }

        message.open({
            type: 'loading',
            content: 'Checking email...',
            key: 'checkingEmail'
        });
        if (accountContactsStore.contactModel.isTenant) {
            await apiStore.AccountSummaryApiClient.getExistInternalUserByEmailForTenant(email).then((statusIsAllow) => {
                message.destroy("checkingEmail");
                if (statusIsAllow === null) {
                    setErrorEmail("Active renter record with this email address exists on another property. Please contact the management company to remove the other email address, if this is an error.")
                } else {
                    setErrorEmail('');
                }
            })
        } else {
            await apiStore.AccountSummaryApiClient.getExistInternalUserByEmail(email).then((res) => {
                message.destroy("checkingEmail");
                if (res !== null) {
                    if(res[values.residentKey] != undefined && res[values.residentKey].includes(values.contactKey)){
                        setErrorEmail('');
                    }else{
                        setErrorEmail("Two accounts may not share the same email address as all user names must be unique.  Please change other contact`s email address or clear it.")
                    }
                } else {
                    setErrorEmail('');
                }
            })
        }
    }

    return (
        <Form
            form={form}
            variant='outlined'
            layout="vertical"
            initialValues={accountContactsStore.contactModel}
            labelCol={{ span: 24 }}
            style={{ maxWidth: 900 }}
            onValuesChange={(changedValues, allValues) => accountContactsStore.setContactModelValues(allValues)}
            validateMessages={validateMessages}
            requiredMark={customizeRequiredMark}
            autoComplete="off"
            onFinish={async () => {
                if (prevEmail !== accountContactsStore.contactModel.email) {
                    await validateEmail(accountContactsStore.contactModel.email);
                }
                if (errorEmail === "") {
                    props.submit()
                }
            }}
            scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
            <Row>
                <Col span={24}>
                    <Form.Item<AccountContactDataItem> name="contactKey" hidden >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item<AccountContactDataItem> name="residentKey" hidden >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item<AccountContactDataItem> name={['contactData', 'referenceID']} hidden >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            {props.isCreate &&
                <Row>
                    <Col span={24}>
                        <Form.Item<AccountContactDataItem> label="Type (Tenant or Household Member):" name={['isTenant']} style={{ width: "100%", margin: 0 }} rules={[{ required: true }]} >
                            <Select options={[
                                { value: true, label: "Tenant", key: "Tenant", disabled: !accountContactsStore.isAllowCreateTennat },
                                { value: false, label: "Household Member", key: "Household" }
                            ]} style={{ width: "100%" }} />
                        </Form.Item>
                        {accountContactsStore.contactModel.isTenant &&
                            <Form.Item<AccountContactDataItem> label="" valuePropName="checked" name={['rentInfo', 'canCreateAamUser']} style={{ width: "100%" }}>
                                <Checkbox>Allow user to create All Access login</Checkbox>
                            </Form.Item>
                        }
                    </Col>
                </Row>
            }
            <Row>
                <Divider orientation="left" orientationMargin="0">
                    Contact Info:
                </Divider>
            </Row>
            <Row>
                <Col md={{ span: 11, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<AccountContactDataItem> label="First Name" name="firstName" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={{ span: 11, offset: 2 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<AccountContactDataItem> label="Last Name" name="lastName" style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Divider orientation="left" orientationMargin="0">
                    Address Information:
                </Divider>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item<AccountContactDataItem> label="Address 1" name={['contactData', 'contactAddress', 'mailingAddress1']} style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item<AccountContactDataItem> label="Address 2" name={['contactData', 'contactAddress', 'mailingAddress2']} style={{ width: "100%" }} rules={[{ required: false }]} >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item<AccountContactDataItem> label="City" name={['contactData', 'contactAddress', 'mailingCity']} style={{ width: "100%" }} rules={[{ required: true }]} >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 11, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<AccountContactDataItem>
                        label="State"
                        name={['contactData', 'contactAddress', 'mailingRegion']}
                        rules={[
                            { required: true },
                            ({ getFieldValue }) => ({
                                validator(_, value: string) {
                                    if (value) {
                                        if (value.length === 2 && /^[a-zA-Z]{2}$/.test(value)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                new Error(
                                                    "Please enter a valid state code. State codes should be entered as a two-letter abbreviation."
                                                )
                                            );
                                        }
                                    } else {
                                        return Promise.reject(new Error());
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
                <Col md={{ span: 11, offset: 2 }} xs={{ span: 24, offset: 0 }}>
                    <Form.Item<AccountContactDataItem>
                        label="Zip Code"
                        name={['contactData', 'contactAddress', 'mailingPostalCode']}
                        rules={[
                            { required: true },
                            ({ getFieldValue }) => ({
                                validator(_, value: string) {
                                    if (value) {
                                        if (validateZipCode(value)) {
                                            return Promise.resolve();
                                        } else {
                                            return Promise.reject(
                                                new Error("Please enter a valid ZIP.")
                                            );
                                        }
                                    } else {
                                        return Promise.reject(new Error());
                                    }
                                },
                            }),
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Col>
            </Row>
            <Row>
                <Divider orientation="left" orientationMargin="0">
                    Email Information:
                </Divider>
            </Row>
            <Row>
                <Col span={24}>
                    <Form.Item<AccountContactDataItem>
                        label=""
                        name={'email'}
                        validateStatus={errorEmail ? 'error' : ''}
                        help={errorEmail}
                        rules={[
                            { required: true },
                            { type: "email" },
                        ]}
                        validateTrigger="onChange"
                    >
                        <Input onChange={handleInputChangeEmail} pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
                    </Form.Item>
                </Col>
            </Row>
            {accountContactsStore.isAgeRestricted &&
                <Row>
                    <Col span={24}>
                        <Form.Item<AccountContactDataItem>
                            label="Birth Date"
                            name="birthDate"
                            rules={[{ required: true }]}>
                            <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                        </Form.Item>
                    </Col>
                </Row>}
            <Row>
                <Divider orientation="left" orientationMargin="0">
                    Phone Information:
                </Divider>
            </Row>
            {accountContactsStore.contactModel.phones?.map((phone: ContactPhone, index) => {
                return (<Row key={index}>
                    <Col span={24}>
                        <Form.Item<AccountContactDataItem>
                            label={phone.type}
                            name={['phones', index]} style={{ width: "100%" }}
                            valuePropName='contactnumber'
                            rules={[({ getFieldValue }) => ({
                                validator(_, value: ContactPhone) {
                                    if (value && value.contactNumber && value.contactNumber.length > 0 && value.contactNumber?.length < 12) {
                                        return Promise.reject(
                                            new Error(
                                                "Please enter a valid phone number"
                                            )
                                        );
                                    } else {
                                        return Promise.resolve();
                                    }
                                },
                            }),]}>
                            <Input
                                addonAfter={<Button disabled={phone.disabledRemove} size='small' shape="circle" onClick={() => {
                                    accountContactsStore.contactModel.phones.splice(index, 1)
                                }} icon={<DeleteFilled size={10} />}></Button>} value={phone.contactNumber || ""}
                                onChange={(event) => {
                                    const { value } = event.target;
                                    const formattedValue = getPhoneFormat(value);
                                    accountContactsStore.setPhoneByIndex(formattedValue, index)
                                    form.setFieldValue(['phones'], accountContactsStore.contactModel.phones);
                                }}
                            />
                        </Form.Item>
                    </Col>
                </Row>)
            })}
            <Row>

                <Dropdown menu={{ items }} trigger={['click']} >
                    <Button size='middle' icon={<PlusOutlined size={10} />}>Add New Phone</Button>
                </Dropdown>
            </Row>
            {accountContactsStore.contactModel.isTenant &&
                <>
                    <Row>
                        <Col md={{ span: 11, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                            <Form.Item<AccountContactDataItem>
                                label="Lease Start"
                                name={['rentInfo', 'leaseStart']}
                                rules={[{ required: true }]}>
                                <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                            </Form.Item>
                        </Col>

                        <Col md={{ span: 11, offset: 2 }} xs={{ span: 24, offset: 0 }}>
                            <Form.Item<AccountContactDataItem>
                                label="Lease End"
                                name={['rentInfo', 'leaseEnd']}
                                dependencies={["leaseStart"]}
                                rules={[{ required: true }, ({ getFieldValue }) => ({
                                    validator(_, value) {
                                        if (value && getFieldValue(['rentInfo', 'leaseStart']) && value.startOf('day').diff(getFieldValue(['rentInfo', 'leaseStart']).startOf('day')) > 0) {
                                            return Promise.resolve();
                                        }
                                        return Promise.reject(new Error('The Lease End must be greater than the Lease Start'));
                                    },
                                }),]}>
                                <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} >
                            <Form.Item<AccountContactDataItem> label="" valuePropName="checked" name={['rentInfo', 'isSentEmailsToContact']} style={{ width: "100%" }} >
                                <Checkbox>I would like the tenant to also receive a copy of violation letters</Checkbox>
                            </Form.Item>
                        </Col>
                    </Row>
                </>
            }
            <Row>
                <Col span={24} style={{ textAlign: 'right', marginTop: "16px" }}>
                    <Space>
                        <Button size="middle" onClick={() => {
                            accountContactsStore.resetValue();
                            navigate('/AccountContacts')
                        }}>
                            Cancel
                        </Button>
                        <Button size="middle" type="primary" htmlType="submit" onClick={async () => {
                            await validateEmail(accountContactsStore.contactModel.email);
                        }}>{props.submitBtnText}</Button>
                    </Space>
                </Col>
            </Row>
        </Form>
    )
})

export default ContactForm