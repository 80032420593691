import { observer } from "mobx-react-lite";
import { Space} from 'antd';
import DashBoardLayout from "./components/DashBoardLayout";


const Dashboard = observer(() => {

    return <>
        <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <DashBoardLayout/>
        </Space>
    </>
})

export default Dashboard