import axios from "axios";
import CryptoJS from "crypto-js";
import { ConfigApi } from "./ConfigApi";

class SpaToolsRicApiClient {
    protected _configApi: ConfigApi;
    private BASE_URL: string;
    private xApiKey: string;
    
    constructor(configApi: ConfigApi) {
        this.BASE_URL = process.env.REACT_APP_API_URL as string;
        this.xApiKey = process.env.REACT_APP_XAPI_KEY as string;
        this._configApi = configApi;
    }

    private randomString = (): string => Math.random().toString(36).slice(-8);

    private formatString = (inputString: string, desiredLength: number, paddingChar: string): string => {
        let formattedString = inputString;
        while (formattedString.length < desiredLength) {
            formattedString += paddingChar;
        }
        return formattedString;
    };

    private checkEmail = (str: string): string => {
        const validEmails: string[] = [
        "ven.pasynsoftdev4@associatedasset.com",
        "ven.pasynsoftdev2@associatedasset.com",
        "tmctesterson@aamaz.com",
        ];

        return validEmails.includes(str) ? "tnpatterson@associatedasset.com" : str;
    };

    private getRicHeader = (): Record<string, string> => {
        let randomizing = this.randomString();
        let KEY = this.formatString(this.xApiKey?.replace(/[-:;,._\\@/*&^%$#!()=+]/g, ""), 32, "0");
        let IV = this.formatString(
        `${randomizing}WWRQYHBC2iwkjqq366wfgWQ2qqiWEqD123SDfaAgxk5q1EfBshryYLo${randomizing}edKxjDqw4qqxWKFRf6E${randomizing}ZUC5qweq234JU6ozwUVLao`.replace(
            /[-:;,._\\@/*&^%$#!()=+]/g,
            ""
        ),
        16,
        "0"
        );

        let key = CryptoJS.enc.Utf8.parse(KEY);
        let iv = CryptoJS.enc.Utf8.parse(IV);

        let encrypted;

        let srcs = CryptoJS.enc.Utf8.parse(this.xApiKey);
        encrypted = CryptoJS.AES.encrypt(srcs, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        });

        let encryptedE;

        let srcsE = CryptoJS.enc.Utf8.parse(this.checkEmail(this._configApi.userEmail));

        encryptedE = CryptoJS.AES.encrypt(srcsE, key, {
        iv: iv,
        mode: CryptoJS.mode.CBC,
        padding: CryptoJS.pad.Pkcs7,
        });

        return {
        "public-key": encrypted.ciphertext.toString(),
        "www": IV,
        "Email": encryptedE.ciphertext.toString(),
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "PUT, POST, GET, DELETE, PATCH, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Max-Age": "1800",
        "Access-Control-Allow-Credentials": "true",
        };
    };

    // created base methods
    protected Get = async(url:string): Promise<any> =>{
        return await axios({
            method: 'get',
            baseURL: this.BASE_URL,
            url: url,
            headers: this.getRicHeader(),
        });
    }

    protected Post = async (url: string, data?: Record<any, any>): Promise<any> => {
        const config = {
            method: 'post',
            baseURL: this.BASE_URL,
            url: url,
            headers: this.getRicHeader(),
            ...(data !== undefined && { data: data }),
        };
        return await axios(config);
    }
}

export default SpaToolsRicApiClient