import { MenuProps } from "antd";

export type WidgetsSize = {
    key:string,
    h:number,
    w:number,
}

export const widgetsSize:WidgetsSize[] = [
    { key:"archsubmisstionslastdays",h:2,w:3 },
    { key:"archapprovalslastdays",h:2,w:3 },
    { key:"archapprovalsbymonth",h:4,w:6 },
    { key:"archsubmisstionsbymonth",h:4,w:6 },
    { key:"archcounttop10byyear",h:4,w:6 },
    { key:"totalviolationsbyyear",h:2,w:3 },
    { key:"totalviolationsopen",h:2,w:3 },
    { key:"top10violationtypescount",h:4,w:6 },
    { key:"movinsbymonthrolling5years",h:4,w:6 },
    { key:"pastmovinsbydays",h:2,w:3 },
    { key:"futuremovinsbydays",h:2,w:3 },
    { key:"archcountbymodificationyear",h:4,w:6 },
    { key:"totalviolationscompleted",h:2,w:3 },
]

export const widgetsList = ():MenuProps['items'] =>{
    const list:MenuProps['items'] = [
        { key: 'archsubmisstionslastdays', label: 'Architectural Submissions',
            children: [
                {key: 'archsubmisstionslastdays|30', label: 'Architectural Submissions 30 days'},
                {key: 'archsubmisstionslastdays|60', label: 'Architectural Submissions 60 days'},
                {key: 'archsubmisstionslastdays|90', label: 'Architectural Submissions 90 days'},
                {key: 'archsubmisstionslastdays|365', label: 'Architectural Submissions year'},
            ]    
        },
        { key: 'archapprovalslastdays', label: 'Architectural Approvals',
            children: [
                { key: 'archapprovalslastdays|30', label: 'Architectural Approvals 30 days' },
                { key: 'archapprovalslastdays|60', label: 'Architectural Approvals 60 days' },
                { key: 'archapprovalslastdays|90', label: 'Architectural Approvals 90 days' },
                { key: 'archapprovalslastdays|365', label: 'Architectural Approvals year' },
            ]
        },
        { key: 'archapprovalsbymonth', label: 'Monthly Architectural Approvals' },
        { key: 'archsubmisstionsbymonth', label: 'Monthly Architectural Submissions' },
        { key: 'archcountbymodificationyear|2023', label: 'Architectural Modification Trends' },
        { key: 'archcounttop10byyear|2023', label: 'Top 10 Architectural Requests' },
        { key: 'totalviolationsbyyear', label: 'Total Violations this year' },
        { key: 'totalviolationscompleted', label: 'Violations Completed' },
        { key: 'totalviolationsopen', label: 'Open Violations' },
        { key: 'top10violationtypescount', label: 'Top 10 Violations Categories' },
        { key: 'movinsbymonthrolling5years', label: 'Movins by Month'},
        { key: 'pastmovinsbydays', label: 'Movins Rolling',
            children: [
                { key: 'pastmovinsbydays|30', label: 'Movins Rolling 30 days' },
                { key: 'pastmovinsbydays|60', label: 'Movins Rolling 60 days' },
                { key: 'pastmovinsbydays|90', label: 'Movins Rolling 90 days' },
                { key: 'pastmovinsbydays|365', label: 'Movins Rolling year' },
                { key: 'pastmovinsbydays|1825', label: 'Movins Rolling 5 years' },
            ]
        },
        { key: 'futuremovinsbydays', label: 'Future Movins',
            children: [
                { key: 'futuremovinsbydays|30', label: 'Future Movins 30 days' },
                { key: 'futuremovinsbydays|60', label: 'Future Movins 60 days' },
                { key: 'futuremovinsbydays|90', label: 'Future Movins 90 days' },
            ]
        }
    ]

    return JSON.parse(JSON.stringify(list)) as MenuProps['items']
}
