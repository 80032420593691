import { ColumnsType } from "antd/es/table";
import { FileExplorerColumns } from "../../../components/FileExplorer/FileExplorerList";
import { dateValidation } from "../../../helpers/dateValidation";
import { Flex } from "antd";
import { FolderFilled, FileTextOutlined } from '@ant-design/icons'
import { DateSort, TextSort } from "../../../helpers/SorterHelper";
import { ArchitecturalRequestsItem } from "../../../api/AccountSummary/AccountSummaryTypes";
import { ReactElement } from "react";

const columns: ColumnsType<ArchitecturalRequestsItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "isFolder",
        render: (isFolder: any) => (
            <Flex align="center" justify="center">
                <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
            </Flex>
        )
    },
    {
        key: "2",
        title: "Description",
        colSpan: 2,
        align: 'left',
        width: "10%",
        dataIndex: "description",
        sorter: (a: ArchitecturalRequestsItem, b: ArchitecturalRequestsItem) => TextSort(a.description, b.description, 'description'),
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
    {
        key: "3",
        title: "Request #",
        width: "15%",
        dataIndex: "requestNo",
        sorter: (a: ArchitecturalRequestsItem, b: ArchitecturalRequestsItem) => TextSort(a, b, "requestNo")
    },
    {
        key: "4",
        title: "Status",
        width: "15%",
        dataIndex: "status",
        sorter: (a: ArchitecturalRequestsItem, b: ArchitecturalRequestsItem) => TextSort(a, b, "status")
    },
    {
        key: "5",
        title: "Status Notes",
        width: "15%",
        dataIndex: "statusNotes",
        sorter: (a: ArchitecturalRequestsItem, b: ArchitecturalRequestsItem) => TextSort(a, b, "statusNotes")
    },
    {
        key: "6",
        width: "10%",
        title: "Received Date",
        dataIndex: "requestDate",
        sorter: (a: ArchitecturalRequestsItem, b: ArchitecturalRequestsItem) => DateSort(a, b, "requestDate"),
        render: (date: any) => dateValidation(date)
    },
    {
        key: "7",
        width: "10%",
        title: "Closed Date",
        dataIndex: "approvedDate",
        sorter: (a: ArchitecturalRequestsItem, b: ArchitecturalRequestsItem) => DateSort(a, b, "approvedDate"),
        render: (date: any) => dateValidation(date)
    }
];

const small: ColumnsType<ArchitecturalRequestsItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "isFolder",
        render: (isFolder: any) => (
            <Flex align="center" justify="center">
                <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
            </Flex>
        )
    },
    {
        key: "2",
        title: "Description",
        colSpan: 2,
        align: 'left',
        width: "30%",
        dataIndex: "description",
        sorter: (a: ArchitecturalRequestsItem, b: ArchitecturalRequestsItem) => TextSort(a.description, b.description, 'description'),
        defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
    {
        key: "3",
        title: "Request",
        width: "10%",
        dataIndex: "requestNo",
        sorter: (a: ArchitecturalRequestsItem, b: ArchitecturalRequestsItem) => TextSort(a, b, "requestNo")
    },
    {
        key: "4",
        title: "Status",
        width: "15%",
        dataIndex: "status",
        sorter: (a: ArchitecturalRequestsItem, b: ArchitecturalRequestsItem) => TextSort(a, b, "status")
    }
];

const getArchitecturalRequestsDocumentsColumns = (downloadFilesButton: (data: ArchitecturalRequestsItem) => ReactElement): FileExplorerColumns<ArchitecturalRequestsItem> => {
    const newColumns = [...columns, {
        key: "download",
        title: "Files",
        width: "10%",
        render: downloadFilesButton
    }]

    const newSmall = [...small, {
        key: "download",
        title: "Files",
        width: "10%",
        render: downloadFilesButton
    }]

    return {
        large: newColumns,
        small: newSmall,
    }
}

export default getArchitecturalRequestsDocumentsColumns;