import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { FAQResultItem } from "../../../api/Association/AssociationTypes";

class FAQStore {
  private _api: ApiStore;

  isLoading: boolean = false;
  faqListResult: FAQResultItem[] = [];

  constructor(api: ApiStore) {
    makeAutoObservable(this);
    this._api = api;
  }

  async getFAQ() {
    try {
      this.isLoading = true;
      const faqData = await this._api.AssociationApiClient.GetFaq();
      this.faqListResult = faqData.data;
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }

  async getSearchFAQ(searchStr: string) {
    try {
      this.isLoading = true;
      const faqData = await this._api.AssociationApiClient.SearchFaq(searchStr);
      this.faqListResult = faqData.data;
    } catch (error) {
      console.error(error);
    }
    this.isLoading = false;
  }
}
export default FAQStore;
