import { Attachment, CommunityProxy } from "../Common/CommonTypes";

export type AccountTransactionItem = {
    charges: number;
    credits: number;
    date: Date;
    description: string;
}

export type ContactInfoChangeItem = {
    Community: CommunityProxy;
    UserId: string;
    DaytimePhone: string;
    CellPhone: string;
    EmailAddress: string;
    NewAddress: string;
    NewAddress2: string;
    NewCity: string;
    NewState: string;
    NewZip: string;
}

export type EntryAccessRequestItem = {
    Community: CommunityProxy;
    UserId: string;
    DaytimePhone: string;
    EmailAddress: string;
    Description: string;
    SelectedEntryAccessitem: string;
    StartDate: Date;
    DueDate: Date;
}

export type FeeWaiverItem = {
    Community: CommunityProxy;
    UserId: string;
    FeeType: string;
    Amount: string;
    DaytimePhone: string;
    EmailAddress: string;
    ReasonForRequest: string;
}

export type MaintenanceRequestHistory = {
    taskList: MaintenanceRequestHistoryItem[];
    nextBlockUrl: string;
}

export type MaintenanceRequestItem = {
    Community: CommunityProxy;
    UserId: string;
    DaytimePhone: string;
    EmailAddress: string;
    Description: string;
    Attachments: Array<Attachment>;
    MaintenanceLocation: string;
}

export type ResidentDirectoryProfileTransfer = {
    name: string;
    address: string;
    listingVisible: boolean;
    email: string;
    emailVisible: boolean;
    homePhone: string;
    homePhoneVisible: boolean;
    cellPhone: string;
    cellPhoneVisible: boolean;
    nickname: string;
}

export type ResidentDirectoryProfile = {
    EmailAddress: string,
    DaytimePhone: string
} 

export type MaintenanceRequestHistoryItem = {
    created: Date;
    description: string;
    status: string;
    backgroundColor: string;
}

export enum FeeTypeItems {
    "Late Fee",
    "Lien Fee",
    "Demand Fee",
    "Violation Fine"
}

export enum EntryAccessItems {
    "Gate Code",
    "Gate Remote",
    "Pedestrian Gate Key",
    "Pool Key",
    "Other"
}

export type ContactAddress = {
    referenceID: string;
    mailingName: string;
    mailingName2: string;
    mailingSalutation: string;
    mailingAddress1: string;
    mailingAddress2: string;
    mailingCity: string;
    mailingRegion: string;
    mailingCountry: string;
    mailingPostalCode: string;
}

export type ContactMethod = {
    contactMethodID: string;
    referenceID: string;
    type: string;
    contactNumber: string;
}

export type ContactInfoItem = {
    contactID: string;
    referenceID: string;
    createDate: string | null;
    lastName: string;
    firstName: string;
    salutation: string;
    primaryOwner: string;
    synchronizeInformation: string;
    userID: string | null;
    secretWordType: string | null;
    contactAddress: ContactAddress;
    email: string;
    password: string | null;
    international: string;
    optOut: string;
    sendMail: string;
    type: string;
    date1: string;
    date2: string;
    date3: string;
    contactMethods: ContactMethod[];
    contactFullName: string;
}