import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";

class ElectionsStore {
    private _api: ApiStore;
    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    public async electionHasActive(): Promise<boolean> {
        return await this._api.AssociationApiClient.electionHasActive();
    }

    public async electionGetHtmlCode(): Promise<string> {
        return await this._api.AssociationApiClient.electionGetHtmlCode();
    }
}
export default ElectionsStore