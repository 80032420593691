import React, { useEffect, useState } from 'react';
import { Col, Row, Space, Image, Spin, App, Result } from 'antd';
import { useStore } from '../../../root-store-context';
import Title from 'antd/es/typography/Title';
import newCalendar from "../../../images/pageIcons/BoardVue/calendarForm.png"
import { observer } from 'mobx-react-lite';
import { useNavigate, useParams } from 'react-router-dom';
import EventForm from './EventForm';


const EditEvent = observer(() => {
    const { calendarStore, apiStore } = useStore();
    const { message } = App.useApp();
    const { eventId } = useParams();
    const [isNotFound, setIsNotFound] = useState(false);
    const navigate = useNavigate();

    const success = () => {
        message.open({
            type: 'success',
            content: 'Event saved successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    useEffect(() => {
        return () => {
            calendarStore.setDefault();
        };
    }, []);
    useEffect(() => {
        setEventCalendar(parseInt(eventId!));
    }, [])

    const setEventCalendar = async (id: number) => {
        if (calendarStore.eventModel.id !== id) {
            calendarStore.editLoading(true);
            const eventData = calendarStore.calendarEvents.find(el => el.id === id);
            var task = await apiStore.BoardVueApiClient.getEventById(id, eventData?.eventDate || null);
            if (task != null) {
                task.recurrencePattern = task.recurrencePattern;
                calendarStore.setEventModel(task);
            } else {
                setIsNotFound(true);
            }
        }
    }

    const onSubmit = async () => {
        calendarStore.editLoading(true);
        calendarStore.updateEvent().then((res) => {
            if (res) {
                success();
                navigate('/BoardVue/Calendar');
            }
            else {
                error()
            }
            calendarStore.editLoading(false);

        }).catch(() => {
            calendarStore.editLoading(false);
        })
    }

    const onDelete = async ()=>{
        calendarStore.deleteEvent().then((res) => {
            console.log(res);
            message.success("Event has been deleted!")
            navigate('/BoardVue/Calendar');
        }).catch((err) => {
            message.success("Error the event was not deleted!")
        })
    }

    if (isNotFound) {
        return <Result status="404" title="The event does not exist" />
    }

    return (
        <>
            <Spin tip="Loading" size="small" spinning={calendarStore.isLoading}>
                <Row>
                    <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                        <Space>
                            <Image src={newCalendar} preview={false} height={25} />
                            <Title level={2} style={{ marginTop: "10px" }}>Edit Event</Title>
                        </Space>
                        <EventForm submitBtnText='Save' submit={onSubmit} delete={onDelete}/>
                    </Col>
                </Row>
            </Spin>
        </>
    )
})

export default EditEvent