import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import useBreakpoint from "use-breakpoint";
import { useStore } from "../../root-store-context";
import { Card, Descriptions, Flex, Space, Spin } from "antd";
import { MailOutlined } from "@ant-design/icons";
import { ContactsAndLinksItem } from "../../api/Association/AssociationTypes";

const ContactsAndLinks = observer((): ReactElement => {
  const BREAKPOINTS = { mobile: 0, desktop: 768 };
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "mobile");
  const { contactsAndLinksStore, applicationStore } = useStore();
  const [contactsAndLinks, setContactsAndLinks] =
    useState<ContactsAndLinksItem>();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    contactsAndLinksStore.getContactsAndLinks().then((res) => {
      setContactsAndLinks(res);
      setIsLoading(false);
    });
  }, []);

  const renderText = (text: any): ReactElement => {
    return (
      <div className="wrap-text-anywhere" style={{ paddingBottom: "15px" }}>
        {text}
      </div>
    );
  };

  const renderLink = (text: any): ReactElement => {
    return renderText(
      <a href={text} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  const renderEmail = (text: any, icon?: boolean): ReactElement => {
    return renderText(
      <a href={"mailto:" + text} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  const renderPeople = (text: any, email: any): ReactElement => {
    return renderText(
      <a href={"mailto:" + email} target="_blank" rel="noopener noreferrer">
        {text} <MailOutlined />
      </a>
    );
  };

  const renderPhone = (text: any): ReactElement => {
    return renderText(
      <a href={"tel:" + text} target="_blank" rel="noopener noreferrer">
        {text}
      </a>
    );
  };

  return (
    <Spin spinning={isLoading}>
      <Flex justify="space-between" wrap="wrap" align="center" gap="middle" style={{ padding: 10 }}>
        <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
          <Space style={{ flexWrap: "wrap" }}>
            <h2 style={{ width: "280px" }}>Contacts And Links</h2>
          </Space>
        </div>
      </Flex>
      <Card key={"ContactsAndLinks"}>
        <Descriptions
          title={applicationStore.SelectedAddress.name}
          layout={"vertical"}
        >
          {contactsAndLinks?.ManagerName && (
            <Descriptions.Item label="Community Manager">
              {renderPeople(
                contactsAndLinks?.ManagerName,
                contactsAndLinks?.ManagerEmail
              )}
            </Descriptions.Item>
          )}
          {contactsAndLinks?.WebSite && (
            <Descriptions.Item label="Community Website">
              {renderLink(contactsAndLinks?.WebSite)}
            </Descriptions.Item>
          )}
          {/* <Descriptions.Item label="Homeowner Tools Website">
            {renderLink("http://www.homeownerresources.com")}
          </Descriptions.Item> */}
          {/* <Descriptions.Item label="Board Info Website">
            {renderLink("http://www.associatedasset.com")}
          </Descriptions.Item> */}
          {contactsAndLinks && contactsAndLinks?.AdditionalContacts.map((v,i) => {
            var text = renderText(v.contactDetails);
            if (v.contactType === "email") {
              text = renderEmail(v.contactDetails);
            }
            if (v.contactType === "phone") {
              text = renderPhone(v.contactDetails);
            }
            return (
              <Descriptions.Item key={Math.random().toString(36).substr(2, 9)} label={v.header}>{text}</Descriptions.Item>
            );
          })}
        </Descriptions>
      </Card>
    </Spin>
  );
});

export default ContactsAndLinks;
