import React, { ReactElement, useEffect, useState } from "react";
import {
  App,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  FormInstance,
  Input,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  Tabs,
  Typography,
} from "antd";
import { useStore } from "../../../root-store-context";
import TextArea from "antd/es/input/TextArea";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import {
  CalendarEvent,
  Months,
  RepeatType,
  WeekdayOfMonth,
  WeeklyDays,
} from "../../../api/BoardVue";
import dayjs, { Dayjs } from "dayjs";
import { CheckboxValueType } from "antd/es/checkbox/Group";

const RecurrenceForm = observer((props: { form: FormInstance<any> }) => {
  const { form } = props;
  const { applicationStore, calendarStore } = useStore();
  const [dailyEveryWeekday, setDailyEveryWeekday] = useState(
    calendarStore.eventModel.recurrencePattern?.frequency === 0 &&
      calendarStore.eventModel.recurrencePattern?.dailyWeekday === true
  );
  const [endDateOption, setEndDateOption] = useState(
    calendarStore.eventModel?.recurrencePattern?.endlessRepeating === false &&
      calendarStore.eventModel?.recurrencePattern?.repeatCount > 0
      ? "endAfter"
      : calendarStore.eventModel?.recurrencePattern?.endlessRepeating ===
          false &&
        dayjs(calendarStore.eventModel?.recurrencePattern?.repeatingEndDate) >
          dayjs("2000/01/01")
      ? "endBy"
      : "noEnd"
  );
  const [repeatCount, setRepeatCount] = useState<number>(
    calendarStore.eventModel?.recurrencePattern?.repeatCount
  );

  useEffect(() => {
    if (
      calendarStore.eventModel?.recurrencePattern?.repeatCount !== repeatCount
    ) {
      setRepeatCount(calendarStore.eventModel?.recurrencePattern?.repeatCount);
    }
  }, [calendarStore.eventModel?.recurrencePattern?.repeatCount]);

  const repeatTypeForm: Record<string, ReactElement> = {};
  const onChangeFrequency = (event: React.ChangeEvent<HTMLInputElement>) => {
    calendarStore.setFrequency(parseInt(event.target.value));
  };

  const daysOptions = [
    { label: "Sunday", value: "su" },
    { label: "Monday", value: "mo" },
    { label: "Tuesday", value: "tu" },
    { label: "Wednesday", value: "we" },
    { label: "Thursday", value: "th" },
    { label: "Friday", value: "fr" },
    { label: "Saturday", value: "sa" },
  ];

  repeatTypeForm["daily"] = (
    <>
      <Row>
        <Radio.Group
          value={dailyEveryWeekday}
          onChange={(e) => {
            console.log(e.target.value);
            if (e.target.value) {
              calendarStore.setFrequency(1);
              calendarStore.setDailyWeekday(true);
            } else {
              calendarStore.setFrequency(1);
              calendarStore.setDailyWeekday(false);
            }
            setDailyEveryWeekday(e.target.value);
          }}
        >
          <Col xs={{ span: 24, offset: 0 }}>
            <Radio value={false}>
              Every{" "}
              <Input
                disabled={dailyEveryWeekday}
                min={1}
                value={calendarStore.eventModel.recurrencePattern?.frequency}
                type="number"
                style={{ width: 50 }}
                onChange={onChangeFrequency}
              />{" "}
              day(s)
            </Radio>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} style={{ marginTop: 10 }}>
            <Radio value={true}>Every weekday</Radio>
          </Col>
        </Radio.Group>
      </Row>
    </>
  );
  repeatTypeForm["weekly"] = (
    <>
      <Row>
        <Radio.Group
          value={dailyEveryWeekday}
          onChange={(e) => {
            if (e.target.value) {
              calendarStore.setFrequency(1);
            } else {
              calendarStore.setFrequency(1);
            }
          }}
        >
          <Col xs={{ span: 24, offset: 0 }}>
            <div>
              Recur every{" "}
              <Input
                defaultValue={
                  calendarStore.eventModel.recurrencePattern?.frequency
                }
                type="number"
                min={1}
                max={5}
                style={{ width: 50 }}
                onChange={onChangeFrequency}
              />{" "}
              week(s) on:
            </div>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} style={{ marginTop: 10 }}>
            <Checkbox.Group
              options={daysOptions}
              value={calendarStore.eventModel.recurrencePattern?.weeklyDays}
              onChange={(checkedValues: CheckboxValueType[]) => {
                calendarStore.setWeeklyDays(checkedValues as WeeklyDays[]);
              }}
            />
          </Col>
        </Radio.Group>
      </Row>
    </>
  );
  repeatTypeForm["monthlyByDay"] = repeatTypeForm["monthly"] = (
    <>
      <Row>
        <Radio.Group
          value={calendarStore.eventModel.recurrencePattern.repeatType}
          onChange={(e) => {
            calendarStore.setRepeatTypeForm(e.target.value);
          }}
        >
          <Col xs={{ span: 24, offset: 0 }}>
            <Radio value={"monthly"}>
              Day{" "}
              <Input
                disabled={dailyEveryWeekday}
                defaultValue={
                  calendarStore.eventModel.recurrencePattern?.frequency
                }
                type="number"
                min={1}
                style={{ width: 50 }}
                onChange={onChangeFrequency}
              />{" "}
              of every{" "}
              <Input
                disabled={dailyEveryWeekday}
                defaultValue={
                  calendarStore.eventModel.recurrencePattern?.frequency
                }
                type="number"
                min={1}
                style={{ width: 50 }}
                onChange={(e) => {
                  calendarStore.setDayNumber(parseInt(e.target.value));
                }}
              />{" "}
              month(s)
            </Radio>
          </Col>
          <Col xs={{ span: 24, offset: 0 }} style={{ marginTop: 10 }}>
            <Radio value={"monthlyByDay"} style={{ margin: 0 }}>
              The
            </Radio>
            <Select
              style={{ width: 120 }}
              defaultValue={
                calendarStore.eventModel.recurrencePattern.weekdayOfMonth
              }
              options={Object.values(WeekdayOfMonth).map((key) => {
                return {
                  value: WeekdayOfMonth[key],
                  label: WeekdayOfMonth[key],
                };
              })}
              onChange={(weekdayOfMonth) => {
                calendarStore.setWeekdayOfMonth(weekdayOfMonth);
              }}
            ></Select>
            <Select
              style={{ width: 120, marginLeft: 10 }}
              defaultValue={
                calendarStore.eventModel.recurrencePattern?.oneDayOfWeek
              }
              options={daysOptions}
              onChange={(oneDayOfWeek) => {
                calendarStore.setOneDayOfWeek(oneDayOfWeek);
              }}
            ></Select>{" "}
            of every
            <Input
              type="number"
              value={calendarStore.eventModel.recurrencePattern?.frequency}
              onChange={onChangeFrequency}
              min={1}
              style={{ width: 50, marginLeft: 10 }}
            />{" "}
            month(s)
          </Col>
        </Radio.Group>
      </Row>
    </>
  );
  repeatTypeForm["yearlyByDay"] = repeatTypeForm["yearly"] = (
    <>
      <Row>
        <Radio.Group
          value={calendarStore.eventModel.recurrencePattern.repeatType}
          onChange={(e) => {
            calendarStore.setRepeatTypeForm(e.target.value);
          }}
        >
          <Col xs={{ span: 24, offset: 0 }}>
            <Radio value={"yearly"} style={{ margin: 0 }}>
              Every
            </Radio>
            <Select
              style={{ width: 120 }}
              defaultValue={
                calendarStore.eventModel.recurrencePattern.monthNumber
              }
              options={Object.keys(Months)
                .filter((key) => isNaN(parseInt(key)))
                .map((key, index) => {
                  return { value: index, label: key };
                })}
              onChange={(monthNumber) => {
                calendarStore.setMonthNumber(monthNumber);
              }}
            ></Select>
            <Input
              disabled={dailyEveryWeekday}
              value={calendarStore.eventModel.recurrencePattern?.frequency}
              type="number"
              min={1}
              style={{ width: 50, marginLeft: 10 }}
              onChange={onChangeFrequency}
            />
          </Col>
          <Col xs={{ span: 24, offset: 0 }} style={{ marginTop: 10 }}>
            <Radio value={"yearlyByDay"} style={{ margin: 0 }}>
              The
            </Radio>
            <Select
              style={{ width: 120 }}
              defaultValue={
                calendarStore.eventModel.recurrencePattern.weekdayOfMonth
              }
              options={Object.values(WeekdayOfMonth).map((key) => {
                return {
                  value: WeekdayOfMonth[key],
                  label: WeekdayOfMonth[key],
                };
              })}
              onChange={(weekdayOfMonth) => {
                calendarStore.setWeekdayOfMonth(weekdayOfMonth);
              }}
            ></Select>
            <Select
              style={{ width: 120, marginLeft: 10 }}
              defaultValue={
                calendarStore.eventModel.recurrencePattern?.oneDayOfWeek
              }
              options={daysOptions}
              onChange={(oneDayOfWeek) => {
                calendarStore.setOneDayOfWeek(oneDayOfWeek);
              }}
            ></Select>{" "}
            of
            <Select
              style={{ width: 120, marginLeft: 10 }}
              defaultValue={
                calendarStore.eventModel.recurrencePattern.monthNumber
              }
              options={Object.keys(Months)
                .filter((key) => isNaN(parseInt(key)))
                .map((key, index) => {
                  return { value: index, label: key };
                })}
              onChange={(monthNumber) => {
                calendarStore.setMonthNumber(monthNumber);
              }}
            ></Select>
          </Col>
        </Radio.Group>
      </Row>
    </>
  );

  const mergeRadio = (type: string) => {
    if (type === RepeatType.monthlyByDay) {
      return RepeatType.monthly;
    }
    if (type === RepeatType.yearlyByDay) {
      return RepeatType.yearly;
    }
    return type;
  };

  return (
    <div>
      <Row>
        <Col md={{ span: 6 }} xs={{ span: 6, offset: 0 }}>
          <Radio.Group
            value={mergeRadio(
              calendarStore.eventModel.recurrencePattern?.repeatType
            )}
            onChange={(e) => {
              calendarStore.setRepeatTypeForm(e.target.value);
            }}
          >
            <Space direction="vertical">
              <Radio value={"daily"}>Daily</Radio>
              <Radio value={"weekly"}>Weekly</Radio>
              <Radio value={"monthly"}>Monthly</Radio>
              <Radio value={"yearly"}>Yearly</Radio>
            </Space>
          </Radio.Group>
        </Col>
        <Col md={{ span: 18 }} xs={{ span: 18, offset: 0 }}>
          <h3>Pattern</h3>
          {
            repeatTypeForm[
              calendarStore.eventModel.recurrencePattern?.repeatType || "daily"
            ]
          }
          <div style={{ marginTop: 20 }}>
            <h3>Date Range</h3>
            <Row>
              <Col xs={{ span: 10, offset: 0 }}>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <div>Start Time</div>
                  <Form.Item<CalendarEvent>
                    label=""
                    name="eventDate"
                    rules={[{ required: true }]}
                  >
                    <DatePicker
                      value={calendarStore.eventModel.eventDate}
                      title="Start Date"
                      style={{ width: "200px" }}
                      format={"M/D/YYYY"}
                    />
                  </Form.Item>
                </Space>
              </Col>
              <Col xs={{ span: 12, offset: 2 }}>
                <div>End Time</div>
                <Space direction="vertical" style={{ width: "100%" }}>
                  <Radio.Group
                    value={endDateOption}
                    onChange={(e) => {
                      setEndDateOption(e.target.value);
                      form.validateFields(["repeatingEndDateForm"]);
                      switch (e.target.value) {
                        case "noEnd":
                          // EndDate = null
                          calendarStore.setEndlessRepeating(true);
                          calendarStore.setRepeatCount(0);
                          break;
                        case "endAfter":
                          //repeatInstances
                          calendarStore.setEndlessRepeating(false);
                          calendarStore.setRepeatCount(1);
                          break;
                        case "endBy":
                          //EndDate
                          calendarStore.setEndlessRepeating(false);
                          calendarStore.setRepeatCount(0);
                          break;
                      }
                    }}
                  >
                    <Radio value={"noEnd"}>No end date</Radio>
                    <Radio value={"endAfter"}>
                      End after:{" "}
                      <Input
                        type="number"
                        style={{ width: 70 }}
                        value={repeatCount}
                        min={endDateOption === "endAfter" ? 1 : 0}
                        onChange={(event) => {
                          calendarStore.setRepeatCount(
                            parseInt(event.target.value)
                          );
                        }}
                        disabled={endDateOption !== "endAfter"}
                      />{" "}
                      occurrence(s)
                    </Radio>
                    <Radio value={"endBy"} style={{ width: "100%" }}>
                      End by:
                      <Form.Item<CalendarEvent>
                        label=""
                        name="repeatingEndDateForm"
                        rules={[
                          {
                            required: endDateOption === "endBy",
                          },
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (endDateOption !== "endBy") {
                                return Promise.resolve();
                              }
                              if (
                                value &&
                                getFieldValue("eventDate") &&
                                value.isAfter(getFieldValue("eventDate"))
                              ) {
                                return Promise.resolve();
                              }
                              return Promise.reject(
                                new Error(
                                  "The End by Date must be greater than the Start Date"
                                )
                              );
                            },
                          }),
                        ]}
                      >
                        <DatePicker
                          disabled={endDateOption !== "endBy"}
                          title=""
                          onChange={(date): void => {
                            if (date) {
                              const saveDate = date
                                .set("hour", 23)
                                .set("minute", 59);
                              calendarStore.setRepeatingEndDate(saveDate);
                            } else {
                              calendarStore.setRepeatingEndDate(undefined);
                            }
                          }}
                          style={{ width: "200px" }}
                          format={"M/D/YYYY"}
                        />
                      </Form.Item>
                    </Radio>
                  </Radio.Group>
                </Space>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
});

export default RecurrenceForm;
