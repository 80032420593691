import { observer } from "mobx-react-lite";
import { Space, Button, Input, Flex, Table } from 'antd';
import { SearchOutlined, CaretLeftFilled } from '@ant-design/icons';
import FileExplorerList, { FileItem } from "../../../components/FileExplorer/FileExplorerList";
import { FolderFilled, FileTextOutlined } from '@ant-design/icons'
import { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import useBreakpoint from "use-breakpoint";
import { ArchitecturalRequestsItem } from "../../../api/AccountSummary/AccountSummaryTypes";
import { useLocation, useNavigate } from "react-router-dom";
import getArchitecturalRequestsDocumentsColumns from "./ArchitecturalRequestsDocumentsColumns";
import Link from "antd/es/typography/Link";
import Column from "antd/es/table/Column";
import { ColumnsType } from "antd/es/table";
import { dateValidation } from "../../../helpers/dateValidation";
import { DateSort, FileNaturalCompare, TextSort } from "../../../helpers/SorterHelper";

export type ArchitecturalRequestGroupedType = {
    key: string;
    requestNo: string;
    data: ArchitecturalRequestsItem[];
};

const ArchitecturalRequests = observer(() => {
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const { apiStore, architecturalRequestsStore, applicationStore, modalViewerStore } = useStore();
    const [searchText, setSearchText] = useState<string>("");

    const [filteredFiles, setFilteredFiles] = useState<ArchitecturalRequestsItem[]>([]);
    const [architecturalRequests, setArchitecturalRequests] = useState<ArchitecturalRequestsItem[]>([]);
    const [groupedArchitecturalRequests, setGroupedArchitecturalRequests] = useState<ArchitecturalRequestGroupedType[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(true);
    const navigate = useNavigate();
    const location = useLocation();

    const [sortRequest, setSortRequest] = useState<'ascend' | 'descend' | null>('ascend');
    const [sortStatus, setSortStatus] = useState<'ascend' | 'descend' | null>(null);
    const [sortStatusNotes, setSortStatusNotes] = useState<'ascend' | 'descend' | null>(null);
    const [sortRequestDate, setSortRequestDate] = useState<'ascend' | 'descend' | null>(null);
    const [sortApprovedDate, setSortApprovedDate] = useState<'ascend' | 'descend' | null>(null);

    useEffect(() => {
        if (searchText === "") {
            setFilteredFiles([]);
            return;
        } else {
            setFilteredFiles(architecturalRequests.filter((i) =>
                String(i.requestNo).includes(searchText.toLocaleLowerCase()) ||
                i.description?.toLocaleLowerCase().includes(searchText.toLocaleLowerCase())));
        }
    }, [searchText])

    useEffect(() => {
        setIsLoading(true);
        apiStore.AccountSummaryApiClient.getArchitecturalRequests()
            .then(res => {
                const groupedData: { [type: string]: ArchitecturalRequestsItem[] } = {};
                res.forEach((item) => {
                    const type = item.description;
                    if (!groupedData[type]) {
                        groupedData[type] = [];
                    }
                    groupedData[type].push({...item, key: Math.random().toString(36).substr(2, 10)} as ArchitecturalRequestsItem);
                });
                const result = Object.keys(groupedData).map(type => {
                    return {
                        key: Math.random().toString(36).substr(2, 10),
                        requestNo: type,
                        data: groupedData[type].map((d, i) => ({ ...d, key: i }))
                    };
                })
                setGroupedArchitecturalRequests(result);
                setArchitecturalRequests(res.map((d, i) => ({ ...d, key: i })))
                setIsLoading(false);
            }).catch((error) => {
                setIsLoading(false);
                console.log("getArchitecturalRequests: ", error);
            });
    }, [])

    const downloadFilesButton = (data: ArchitecturalRequestsItem): ReactElement => {
        if (data.files) {
            return <Flex vertical justify="flex-start" align="flex-start">
                {data.files.map((el, index) => {
                    return (
                        <Button
                            style={{ textDecoration: "underline", flex: "1 1 100%", textAlign: "left", padding: 4 }}
                            type="text"
                            block
                            key={index}
                            onClick={async () => {
                                setIsLoading(true);
                                const data = await apiStore.AccountSummaryApiClient.getAttachmentArchtectStringData(el.ArchitecturalNoteKey.toString(), el.FileName);
                                setIsLoading(false);
                                modalViewerStore.openFileByData(data, el.FileName);
                            }
                            }>
                            <Link style={{ whiteSpace: "wrap" }}>{el.FileName}</Link>
                        </Button>
                    )
                })}
            </Flex>
        } else {
            return (
                <Flex justify="center">
                    <Button onClick={async () => {
                        setIsLoading(true);
                        const res = await apiStore.AccountSummaryApiClient.getArchitecturalAttaches(data.requestNo.toString());
                        data.files = res;
                        setIsLoading(false);
                    }}>Download files</Button>
                </Flex>
            )
        }
    }

    const expandedTypeRowRender = (record: ArchitecturalRequestGroupedType) => {
        return <div>
            <Table
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                pagination={false}
                showHeader={false}
                dataSource={record.data}
            >
                {breakpoint === "mobile" ?
                    (<>
                        <Column
                            key="1"
                            width="5%"
                            dataIndex="isFolder"
                            render={() => (
                                <Flex align="center" justify="center">
                                    <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
                                </Flex>
                            )}
                        />,
                        <Column
                            key="3"
                            title="Request"
                            width="13%"
                            dataIndex="requestNo"
                            sorter={(a, b) => TextSort(a, b, "requestNo")}
                            sortOrder={sortRequest}
                            defaultSortOrder={sortRequest}
                        />,
                        <Column
                            key="4"
                            title="Status"
                            dataIndex="status"
                            sorter={(a, b) => TextSort(a, b, "status")}
                            sortOrder={sortStatus}
                            defaultSortOrder={sortStatus}
                        />,
                        <Column
                            key="download"
                            title="Files"
                            width="36%"
                            render={downloadFilesButton}
                        />,
                    </>) :
                    (<>
                        <Column
                            key="1"
                            width="4%"
                            dataIndex="isFolder"
                            render={(isFolder) => (
                                <Flex align="center" justify="center">
                                    <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
                                </Flex>
                            )}
                        />,
                        <Column
                            key="3"
                            title="Request #"
                            width="12%"
                            dataIndex="requestNo"
                            sorter={(a, b) => TextSort(a, b, "requestNo")}
                            sortOrder={sortRequest}
                            defaultSortOrder={sortRequest}
                        />,
                        <Column
                            key="4"
                            title="Status"
                            width="12%"
                            dataIndex="status"
                            sorter={(a, b) => TextSort(a, b, "status")}
                            sortOrder={sortStatus}
                            defaultSortOrder={sortStatus}
                        />,
                        <Column
                            key="5"
                            title="Status Notes"
                            // width="15%"
                            dataIndex="statusNotes"
                            sorter={(a, b) => TextSort(a, b, "statusNotes")}
                            sortOrder={sortStatusNotes}
                            defaultSortOrder={sortStatusNotes}
                        />,
                        <Column
                            key="6"
                            width="15%"
                            title="Request Date"
                            dataIndex="requestDate"
                            render={dateValidation}
                            sorter={(a, b) => DateSort(a, b, "requestDate")}
                            sortOrder={sortRequestDate}
                            defaultSortOrder={sortRequestDate}
                        />,
                        <Column
                            key="7"
                            width="15%"
                            title="Approved Date"
                            dataIndex="approvedDate"
                            render={dateValidation}
                            sorter={(a, b) => DateSort(a, b, "approvedDate")}
                            sortOrder={sortApprovedDate}
                            defaultSortOrder={sortApprovedDate}
                        />,
                        <Column
                            key="download"
                            title="Files"
                            width="21%"
                            render={downloadFilesButton}
                        />,
                    </>)
                }
            </Table>
        </div>
    }

    const groupedTypeColumns: ColumnsType<ArchitecturalRequestGroupedType> = [
        {
            key: "3",
            title: "Request #",
            width: "13%",
            dataIndex: "requestNo",
            render:(data)=><div style={{position: "absolute", width: "280px", top: "50%", transform: "translateY(-50%)", zIndex: "1"}}>{data}</div>,
            defaultSortOrder: sortRequest,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortRequest(direction);
                } else if (direction === 'descend') {
                    setSortRequest(direction);
                }
                // setSortRequest(null);
                setSortStatus(null);
                setSortStatusNotes(null);
                setSortRequestDate(null);
                setSortApprovedDate(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortRequest === 'descend') {
                            setSortRequest(null);
                        }
                    },
                }
            )
        },
        {
            key: "4",
            title: "Status",
            width: "12%",
            dataIndex: "status",
            defaultSortOrder: sortStatus,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortStatus(direction);
                } else if (direction === 'descend') {
                    setSortStatus(direction);
                }
                setSortRequest(null);
                // setSortStatus(null);
                setSortStatusNotes(null);
                setSortRequestDate(null);
                setSortApprovedDate(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortStatus === 'descend') {
                            setSortStatus(null);
                        }
                    },
                }
            )
        },
        {
            key: "5",
            title: "Status Notes",
            // width: "10%",
            dataIndex: "statusNotes",
            defaultSortOrder: sortStatusNotes,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortStatusNotes(direction);
                } else if (direction === 'descend') {
                    setSortStatusNotes(direction);
                }
                setSortRequest(null);
                setSortStatus(null);
                // setSortStatusNotes(null);
                setSortRequestDate(null);
                setSortApprovedDate(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortStatusNotes === 'descend') {
                            setSortStatusNotes(null);
                        }
                    },
                }
            )
        },
        {
            key: "6",
            width: "15%",
            title: "Request Date",
            dataIndex: "requestDate",
            defaultSortOrder: sortRequestDate,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortRequestDate(direction);
                } else if (direction === 'descend') {
                    setSortRequestDate(direction);
                }
                setSortRequest(null);
                setSortStatus(null);
                setSortStatusNotes(null);
                // setSortRequestDate(null);
                setSortApprovedDate(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortRequestDate === 'descend') {
                            setSortRequestDate(null);
                        }
                    },
                }
            )
        },
        {
            key: "7",
            width: "15%",
            title: "Approved Date",
            dataIndex: "approvedDate",
            defaultSortOrder: sortApprovedDate,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortApprovedDate(direction);
                } else if (direction === 'descend') {
                    setSortApprovedDate(direction);
                }
                setSortRequest(null);
                setSortStatus(null);
                setSortStatusNotes(null);
                setSortRequestDate(null);
                // setSortApprovedDate(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortApprovedDate === 'descend') {
                            setSortApprovedDate(null);
                        }
                    },
                }
            )
        },
        {
            key: "download",
            title: "Files",
            width: "21%",
            dataIndex: "isFolder",
        },
    ];
    
    const groupedTypeColumnsSmall: ColumnsType<ArchitecturalRequestGroupedType> = [
        {
            key: "3",
            title: "Request",
            width: "10%",
            dataIndex: "requestNo",
            render:(data)=><div style={{position: "absolute", width: "280px", top: "50%", transform: "translateY(-50%)", zIndex: "1"}}>{data}</div>,
            defaultSortOrder: sortRequest,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortRequest(direction);
                } else if (direction === 'descend') {
                    setSortRequest(direction);
                }
                // setSortRequest(null);
                setSortStatus(null);
                setSortStatusNotes(null);
                setSortRequestDate(null);
                setSortApprovedDate(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortRequest === 'descend') {
                            setSortRequest(null);
                        }
                    },
                }
            )
        },
        {
            key: "4",
            title: "Status",
            dataIndex: "status",
            defaultSortOrder: sortStatus,
            sorter: (a, b, direction) => {
                if (direction === 'ascend') {
                    setSortStatus(direction);
                } else if (direction === 'descend') {
                    setSortStatus(direction);
                }
                setSortRequest(null);
                // setSortStatus(null);
                setSortStatusNotes(null);
                setSortRequestDate(null);
                setSortApprovedDate(null);
                return 0;
            },
            onHeaderCell: () => (
                {
                    onClick: (event: React.MouseEvent) => {
                        if (sortStatus === 'descend') {
                            setSortStatus(null);
                        }
                    },
                }
            )
        },
        {
            key: "download",
            title: "Files",
            width: "37%",
            dataIndex: "isFolder",
        },
    ];

    const defaultColums = getArchitecturalRequestsDocumentsColumns(downloadFilesButton)

    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{ padding: 10 }}>
            <div>
                <Space direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Architectural Requests</h2>
                    <Input value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px' }} />
                </Space>
            </div>
            {applicationStore.AllFeaturesForCommunityObject.CreateArchitecturalRequestFeature?.isActive &&
                <Button size="middle" type="primary"
                    onClick={() => { navigate(location.pathname + '/Add'); }}>Add</Button>
            }
        </Flex>
        {/* {architecturalRequestsStore.currentDirectory !== null && (
            <div style={{ padding: 10, height: 60 }}>
                <Button size="middle" onClick={prevOnClick}>
                    <CaretLeftFilled /> Back
                </Button>
            </div>)
        } */}
        <Table
                loading={isLoading}
                // pagination={false}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                columns={breakpoint === "mobile" ? defaultColums.small : defaultColums.large}
                dataSource={searchText === "" ? architecturalRequests : filteredFiles}
            />
        {/* {searchText === "" ?
            (<Table
                loading={isLoading}
                className="associationDocuments"
                pagination={false}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                columns={breakpoint === "mobile" ? groupedTypeColumnsSmall : groupedTypeColumns}
                expandable={{
                    expandedRowRender: expandedTypeRowRender,
                    defaultExpandedRowKeys: ['0'],
                    expandRowByClick: true,
                    columnWidth: "3%"
                }}
                dataSource={groupedArchitecturalRequests}
            />) :
            (<Table
                loading={isLoading}
                // pagination={false}
                scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
                columns={breakpoint === "mobile" ? defaultColums.small : defaultColums.large}
                dataSource={filteredFiles}
            />)} */}
        {/* <FileExplorerList data={searchText !== "" ? filteredFiles : files} onRowFunc={onRow} isLoading={isLoading} columns={getArchitecturalRequestsDocumentsColumns(downloadFilesButton)} /> */}
    </>
})

export default ArchitecturalRequests