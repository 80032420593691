import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { BvReportsDataRequest } from "../../../api/BoardVue";

export type LayoutItems = LayoutItem[]

export type LayoutItem = GridStoreItem & {
    element?: JSX.Element, data?: any
}
export type GridStoreItems = GridStoreItem[]

export type GridStoreItem = {
    i: string;
    x: number;
    y: number;
    w: number;
    h: number;
    name?:string;
}

export type WidgetRequestData = {
    key:string;
    name:string;
    days?:string
}

export type WidgetResponseData = {
    key:string;
    name:string;
    data:any
}

class DashBoardStore {
    private _api: ApiStore;

    layout: GridStoreItems = []
    widgetData: WidgetResponseData[] = []
    isLoading: boolean = false

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    private getJSONLayout(layout:LayoutItems):string{
        const saveLayout = layout.map((item:LayoutItem)=>{
            const returnItem:GridStoreItem = {
                i:item.i,
                x: item.x,
                y: item.y,
                w: item.w,
                h: item.h,
                name:item.name,
            }
            return returnItem
        })
        return JSON.stringify(saveLayout)
    }

    async saveLayout(layout:LayoutItems):Promise<boolean>{
        this.isLoading = true
        try{
            if(await this._api.BoardVueApiClient.UpdateBvDashboardSettings(this.getJSONLayout(layout))){
                this.layout = layout
                this.widgetData = layout.map((item:LayoutItem)=>{
                    return {
                        name:item.i,
                        data:item.data
                    } as WidgetResponseData
                })
                this.isLoading = false
                return true
            }
        } catch(error){
            console.error(error)
        }
        this.isLoading = false
        return false
    }

    async getLayout(){
        this.isLoading = true
        try {
            const layoutResponse = await this._api.BoardVueApiClient.GetBvDashboardSettings()
            const layoutData = layoutResponse.data
            if(layoutData){
                const layout = layoutData as GridStoreItems
                this.layout = layout
            }
            this.isLoading = false
        } catch (error) {
            console.error(error)
        }
        this.isLoading = false
    }

    async getDataWidgets(widgetData: WidgetRequestData[]):Promise<WidgetResponseData[]>{
        this.isLoading = true

        //TODO optimize data

        const BvReportsRequestData:BvReportsDataRequest[] = widgetData.map((item:WidgetRequestData)=>{
            return {
                Name: item.name,
                RequestParameter: item.days ? item.days : ""
            } as BvReportsDataRequest })

        const apiBvReportsData = await this._api.BoardVueApiClient.GetBvReportsData(BvReportsRequestData);
        
        let result:WidgetResponseData[] = widgetData.map((item:WidgetRequestData,index:number)=>{
            return {
                key:item.key ,name:item.name, data:apiBvReportsData.data[index] ? apiBvReportsData.data[index] : undefined
            } as WidgetResponseData
        })
        this.widgetData = result
        this.isLoading = false
        return result
    }

}
export default DashBoardStore