import { makeAutoObservable, runInAction } from "mobx";
import ApiStore from "../ApiStore";
import { LocalTask } from "../../../api/BoardVue";
import { Attachment } from "../../../api/Common/CommonTypes";

class BoardTaskStore {
    submittableForm: boolean = false;
    isLoading: boolean = false;
    taskModel: LocalTask = {} as LocalTask;
    attachments: Array<Attachment> = [];
    shouldInitAttachments: boolean = false;
    shouldResetForm: boolean = false;
    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api
    }

    editLoading = (loading: boolean) => {
        this.isLoading = loading
    }

    setTaskModel = async (model: LocalTask) => {
        this.isLoading = true;
        await runInAction(async () => {
            if (model.id > 0) {
                var res = await this._api.BoardVueApiClient.getSharePointTaskAttachments(model.id);
                var updatedAttachments = res.data.map((attach: any) => {
                    return {
                        Name: attach.fileName,
                        Url: attach.serverUrl,
                        Guid: "",
                        Data: null as any
                    } as Attachment
                })
                this.attachments = updatedAttachments;
                this.shouldInitAttachments = true;
                this.shouldResetForm = true;
            }
            this.taskModel = model;
        })

        this.isLoading = false;
    }

    setSubmittableForm = (submittable: boolean) => {
        this.submittableForm = submittable;
    }

    updateSharePointTask = async () => {
        this.isLoading = true;

        this.taskModel.startDate = this.taskModel.startDate.format('YYYY-MM-DD');
        this.taskModel.dueDate = this.taskModel.dueDate.format('YYYY-MM-DD');
        if (this.taskModel.completionDate) {
            this.taskModel.completionDate = this.taskModel.completionDate.format('YYYY-MM-DD');
        }

        var res = await this._api.BoardVueApiClient.updateSharePointTask(this.taskModel);
        if (res.data) {
            this.setTaskModel({} as LocalTask)
            this.attachments = [];
        }

        this.isLoading = false;

        return res.data;
    }

    addAttachmentToBuffer = async (attachment: Attachment): Promise<string | void> => {
        return this._api.CommonApiClient.addAttachmentToBuffer(attachment)
    }

    addSharePointTask = async () => {
        this.isLoading = true;

        this.taskModel.startDate = this.taskModel.startDate.format('YYYY-MM-DD');
        this.taskModel.dueDate = this.taskModel.dueDate.format('YYYY-MM-DD');
        if (this.taskModel.completionDate) {
            this.taskModel.completionDate = this.taskModel.completionDate.format('YYYY-MM-DD');
        }

        var res = await this._api.BoardVueApiClient.addSharePointTask(this.taskModel);
        if (res.data) {
            this.setTaskModel({} as LocalTask)
            this.attachments = [];
        }

        this.isLoading = false;
        return res.data;
    }
}

export default BoardTaskStore

