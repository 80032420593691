import { AxiosResponse } from "axios";
import SpaToolsRicApiClient from "./base/SpaToolsRicApiClient";

export default class RicApiClient extends SpaToolsRicApiClient {
    // created methods
    public getSearchFilters = async(): Promise<AxiosResponse> =>{
        return await this.Get(`VMS/GetFilterList?propertyId=${this._configApi.community.communityId}`)
    }

    public getContactInformation = async(account: string|number): Promise<AxiosResponse> =>{
        return await this.Get(`ContactInfo/contact-info?accountNumber=${account}`)
    }

    public getAccountInformation = async(account: string|number): Promise<AxiosResponse> =>{
        return await this.Get(`AccountInformation/getAccountInformation?accountNumber=${account}`)
    }

    public getComplianceInformation = async(account: string|number): Promise<AxiosResponse> =>{
        return await this.Get(`Compliance/get?accountNumber=${account}`)
    }

    public getNotesInformation = async(account: string|number): Promise<AxiosResponse> =>{
        return await this.Get(`notes?accountNumber=${account}&userLogin=${this._configApi.userEmail}`)
    }

    public getNordisInformation = async(account: string|number): Promise<AxiosResponse> =>{
        return await this.Get(`nordisdirect/get?accountID=${account}`)
    }

    public getArchitecturalInformation = async(account: string|number): Promise<AxiosResponse> =>{
        return await this.Get(`Architectural/architectural?accountNumber=${account}`)
    }
    public getAccountDetail = async(account: string|number): Promise<AxiosResponse> =>{
        return await this.Get(`Financial/financial/${account}`)
    }
    public getMailArchive = async(account: string|number): Promise<AxiosResponse> =>{
        return await this.Get(`nordisdirect/get?accountID=${account}`)
    }
    public getCompliance = async(account: string|number): Promise<AxiosResponse> =>{
        return await this.Get(`compliance/get?accountNumber=${account}`)
    }
    public getPdfByDataRecordId = async(dataRecordId: number): Promise<AxiosResponse> =>{
        return await this.Get(`nordisdirect/generetePdfUrl?dataRecordId=${dataRecordId}`)
    }
    public getLotDocumentGetProperies = async(dataRecordId: string): Promise<AxiosResponse> =>{
        return await this.Get(`/VMS/LotDocumentGetProperies?accountNumber=${dataRecordId}`)
    }
}