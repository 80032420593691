export type Attachment = {
    Name: string,
    Guid: string,
    Data: string,
    Url: string
}

export enum Role {
    Homeowner = 0,
    BoardMember = 1,
}
export type Permission = {
    id: number;
    feature: string;
    menuLabel: string;
    isBoardVuePermission: boolean;
  };
export type CommunityProxy = {
    id: number;
    communityId: string;
    name: string;
    address: string;
    residentKey: number;
    isVms: number;
    userId: number;
    role: Role;
    office: string;
    phone: string;
    residentContactKey: number;
};

export type AllFeaturesForCommunity = {
    communityId: number;
    endpoint: string | null;
    exceptionText: string | null;
    featureId: number;
    id: number;
    isActive: boolean;
    sharedSecret: any;
}
export type AllFeaturesForBoard = {
    userId : number;
    permissionId: number | null;
    isActive: boolean;
    id:number;
    isBoardVuePermission:boolean
}
export type AllFeaturesForCommunityObject = {
    CreateArchitecturalRequestFeature?: AllFeaturesForCommunity,
    FAQMenuFeature?: AllFeaturesForCommunity,
    MailArchiveMenuFeature?: AllFeaturesForCommunity,
    eStatementsMenuFeature?: AllFeaturesForCommunity,
    eTrakRoomSchedules?: AllFeaturesForCommunity,
    MembershipCard?: AllFeaturesForCommunity,
    NOTUSED?: AllFeaturesForCommunity,
    TicketsandFitness?: AllFeaturesForCommunity,
    GuestPasses?: AllFeaturesForCommunity,
    PayandViewBalance?: AllFeaturesForCommunity,
    Compliance?: AllFeaturesForCommunity,
    ArchitecturalRequests?: AllFeaturesForCommunity,
    ContactsandLinks?: AllFeaturesForCommunity,
    ResidentDirectory?: AllFeaturesForCommunity,
    PropertyDocuments?: AllFeaturesForCommunity,
    ComplianceConcernemail?: AllFeaturesForCommunity,
    MaintenanceRequestemail?: AllFeaturesForCommunity,
    ContactInfoChange?: AllFeaturesForCommunity,
    FeeWaiver?: AllFeaturesForCommunity,
    Notifications?: AllFeaturesForCommunity,
    ComplianceConcernSP?: AllFeaturesForCommunity,
    MaintenanceRequestSP?: AllFeaturesForCommunity,
    EntryAccessRequest?: AllFeaturesForCommunity,
    Election?: AllFeaturesForCommunity,
    ABDIGatePasses?: AllFeaturesForCommunity,
    SCHHTickets?: AllFeaturesForCommunity,
    SCHHFitnessClasses?: AllFeaturesForCommunity,
    TicketsandRecreation?: AllFeaturesForCommunity,
    TenantRegistration?: AllFeaturesForCommunity,
}