import { AxiosResponse } from "axios";
import MobileWebApiClient, { Tuple } from "../base/MobileWebApiClient";
import { ComplianceConcern, ComplianceConcernHistory, DirectoryProfile } from "../Association/AssociationTypes";
import {
  AccountTransactionItem,
  ContactInfoChangeItem,
  ContactInfoItem,
  EntryAccessRequestItem,
  FeeWaiverItem,
  MaintenanceRequestHistory,
  MaintenanceRequestItem,
  ResidentDirectoryProfile,
  ResidentDirectoryProfileTransfer
} from "./FormsTypes";
import { getPhoneFormat } from "../../helpers/phoneFormat";
import ApiStore from "../../stores/appStores/ApiStore";

export default class FormsApiClient extends MobileWebApiClient {
  public addFeeWaiverItem = async (feeWaiverItem: FeeWaiverItem): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/feewaiversend', feeWaiverItem);
  }
  
  public addEntryAccessRequestItem = async (entryAccessRequestItem: EntryAccessRequestItem): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/entryaccessrequestsend', entryAccessRequestItem);
  }

  public addMaintenanceRequest = async (maintenanceRequestItem: MaintenanceRequestItem): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/maintenancerequestsend', maintenanceRequestItem);
  }

  public addContactInfoChange = async (contactInfoChangeItem: ContactInfoChangeItem): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/ChangeOfResidentAddress', contactInfoChangeItem);
  }

  public async getMaintenanceHistory(): Promise<MaintenanceRequestHistory> {
    const getMaintenanceHistory = await this.tryPost(
      "sharepoint/getmaintenancerequesttasklist",
      {
        Item1: this._configApi.community.communityId,
        Item2: this._configApi.community.office,
        Item3: this._configApi.community.residentKey,
      }
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => false);
    return getMaintenanceHistory;
  }

  public async getGetAccountTransactions(): Promise<Array<AccountTransactionItem>> {
    var res = await this.tryPost('community/GetAccountTransactions', this._configApi.community);
    return res.data.map((item: AccountTransactionItem): AccountTransactionItem => ({
      ...item,
      date: new Date(item.date),
    }))
  }

  public async GetOwnerContactInfo(): Promise<ContactInfoItem | null> {
    return await this.post("user/GetOwnerContactInfo", this._configApi.community)
    .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
    .catch(() => null);
  }

  public getFile = async (url: string): Promise<AxiosResponse> => {
    return await this.tryPost('sharepoint/getfile', {
      "Item1": url,
      "Item2": this._configApi.community.communityId,
      "Item3": this._configApi.community.office,
    });
  }

  public async getComplianceDisclaimer(): Promise<Tuple<boolean, string>> {
    const getComplianceDisclaimerResponsive = await this.tryPost(
      "community/checkshowcompliancedisclamer",
      this._configApi.community.communityId
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);
    return getComplianceDisclaimerResponsive;
  }

  public async getResidentDirectoryProfile(): Promise<ResidentDirectoryProfile> {
    const getComplianceFormResponsive: ResidentDirectoryProfileTransfer = await this.tryPost(
      "community/getcommunityresidentdirectoryprofile",
      {
        item1: this._configApi.userEmail,
        item2: this._configApi.community.residentKey,
      } as Tuple<string, number>, true
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => null);

    const profile = {} as ResidentDirectoryProfile;
    profile.EmailAddress = getComplianceFormResponsive?.email;
    if(profile.EmailAddress == undefined){
      profile.EmailAddress = this._configApi.userEmail;
    }
    if (getComplianceFormResponsive?.cellPhone.length > 0) {
      profile.DaytimePhone = getComplianceFormResponsive.cellPhone;
    } else if (getComplianceFormResponsive?.homePhone.length > 0) {
      profile.DaytimePhone = getComplianceFormResponsive.homePhone;
    }
    profile.DaytimePhone = getPhoneFormat(profile.DaytimePhone);
    return profile;
  }

  public async saveComplianceForm(data: ComplianceConcern): Promise<boolean> {
    const saveComplianceFormResponsive = await this.tryPost(
      "user/complianceconcernsend",
      {
        UserId: this._configApi.userId,
        Community: this._configApi.community,
        ...data,
      } as ComplianceConcern
    )
      .then((res) => (res.statusText === "OK" ? true : false))
      .catch(() => false);
    return saveComplianceFormResponsive;
  }

  public async getComplianceHistory(): Promise<ComplianceConcernHistory> {
    const getComplianceHistory = await this.tryPost(
      "sharepoint/getcomplianceconcerntasklist",
      {
        Item1: this._configApi.community.communityId,
        Item2: this._configApi.community.office,
        Item3: this._configApi.community.residentKey,
      }
    )
      .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
      .catch(() => false);
    return getComplianceHistory;
  }

  public async GetDisclaimersAndFee(residentKey: string): Promise<Object | null> {
    try {
      const response = await this.tryTenantGet(
        `user/GetDisclaimersAndAdditionalFee?residentKey=${residentKey}`  // Modify the URL to include the Id parameter
      );
      return response ? response : null;
    } catch (error) {
      console.error('Error fetching disclaimers and fee:', error);
      return null;
    }
  } 

  public PostTenantRegistrationForms = async (tenantRequest: Object): Promise<AxiosResponse<Object>> => {
    return await this.tryTenantPost('user/TenantRegistrationRequestLoggedInUser', tenantRequest);
  }
  public GetAllTenantSubmissions = async (): Promise<AxiosResponse<Object | null>> => {
    return await this.tryTenantGet('user/GetAllTenantSubmissions');
  }
  public UpdateTenantRequestStatus = async (id:number, status:string, reason: string, userId :string): Promise<AxiosResponse<Object | null>> => {
    return await this.tryTenantPost(`user/UpdateTenantRequestStatus?id=${id}&status=${status}&reason=${reason}&approvalBy=${userId}`);
  }
  public async GetAllStatesCitiesAndCommunities(): Promise<Object | null> {
    try {
      const response = await this.tryTenantGet(
        `user/GetAllStatesCitiesAndCommunities`  // Modify the URL to include the Id parameter
      );
      return response ? response : null;
    } catch (error) {
      console.error('Error fetching GetAllStatesCitiesAndCommunities:', error);
      return null;
    }
  }
}

export { FormsApiClient };
