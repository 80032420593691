import React, { useEffect, useState } from "react";
import { Row, Col, Button, Select, Form } from "antd";
import { Link, useNavigate } from "react-router-dom"; // Import Link
import Component from "../../../components";
import Spinner from "../../../components/Spinner/Spiner";
import { useStore } from "../../../root-store-context";
import "./SubmissionDetails.css"; 

const Submissions = () => {
  const navigate = useNavigate();
  const { apiStore, applicationStore } = useStore();
  const { DynamicTable, Modal, TextArea } = Component;
  const { Option } = Select;
  const [isLoadingSubmissions, setIsLoadingSubmissions] = useState(true); // Initialize as true
  const [isApprovalStatusSet, setIsApprovalStatusSet] = useState(false); // Initialize as true
  const [submissions, setSubmissions] = useState([]);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalAction, setModalAction] = useState("");
  const [selectedSubmissionId, setSelectedSubmissionId] = useState(null);
  const [reason, setReason] = useState("");
  const [statusFilter, setStatusFilter] = useState("All");
  const [filteredSubmissions, setFilteredSubmissions] = useState([]);
  const [form] = Form.useForm();
  const handleFilterSubmissions = (value) => {
    if (value === "All") {
      setFilteredSubmissions(submissions);
    } else {
      const filteredData = submissions.filter((item) => item.status === value);
      setFilteredSubmissions(filteredData);
    }
  };
  const transformData = (data) => {
    return data.map((item) => ({
      id: item.id,
      name:
        `${item.tenants.find((tenant) => !tenant.under18)?.firstName} ${
          item.tenants.find((tenant) => !tenant.under18)?.lastName
        }` ?? "",
      address:
        `${item.tenants.find((tenant) => !tenant.under18)?.mailingAddress1}` ??
        "",
      property: item.property ?? "",
      submissionDate: item.submissionDate != null ? item.submissionDate : "",
      submissionType: item.userId != null ? "Home Owner" : "Non Home Owner",
      status: item.status ?? "",
      details: item, // Full details for each submission
    }));
  };

  const columns = [
    {
      title: "Person Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Address",
      dataIndex: "address",
      key: "address",
    },
    {
      title: "Property Name",
      dataIndex: "property",
      key: "property",
    },
    {
      title: "Submission Date",
      dataIndex: "submissionDate",
      key: "submissionDate",
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
    },
    {
      title: "Submission Type",
      dataIndex: "submissionType",
      key: "submissionType",
    },
    {
      title: "Details",
      key: "details",
      render: (text, record) => (
        <Button
          style={{
            borderRadius: 19,
            border: "1px solid #fff",
            color: "#fff",
            backgroundColor: "#5A7890",
            height: "2rem",
            width: "7rem",
          }}
          className="dark-btn-hover"
          onClick={() =>
            navigate(`/Forms/SubmissionDetails/${record?.id}`, {
              state: { record },
            })
          }
        >
          View Details
        </Button>
      ),
    },
    {
      title: "Actions",
      key: "actions",
      render: (text, record) => (
        <div>
          {record.status === "Pending" ? (
            <>
              <Button
                style={{
                  borderRadius: 19,
                  border: "1px solid #5A7890",
                  color: "#5A7890",
                  backgroundColor: "#fff",
                  height: "2rem",
                  width: "7rem",
                }}
                className="light-btn-hover"
                onClick={() => openModal(record.id, "Approved")}
              >
                Approve
              </Button>
              <Button
                className="light-btn-hover"
                style={{
                  marginTop:4,
                  borderRadius: 19,
                  border: "1px solid #5A7890",
                  color: "#5A7890",
                  backgroundColor: "#fff",
                  height: "2rem",
                  width: "7rem",
                }}
                onClick={() => openModal(record.id, "Rejected")}
              >
                Reject
              </Button>
            </>
          ) : (
            <span></span> // Display status if not 'Pending'
          )}
        </div>
      ),
    },
  ];
  const openModal = (id, action) => {
    setModalAction(action);
    setSelectedSubmissionId(id);
    setIsModalVisible(true);
  };
  const handleOk = () => {
    if (reason.trim()) {
      handleApproval(selectedSubmissionId, modalAction, reason);
      setIsModalVisible(false);
      setReason("");
    } else {
      // Handle empty reason case if needed
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setReason("");
  };
  useEffect(() => {
    apiStore.FormsApiClient.GetAllTenantSubmissions()
      .then((res) => {
        const transformedData = transformData(res?.data);
        setSubmissions(transformedData);
        setFilteredSubmissions(transformedData);
        setIsLoadingSubmissions(false);
      })
      .catch(() => setIsLoadingSubmissions(false)); // Handle errors
  }, [apiStore]);
  const handleApproval = async (id, status, reason) => {
    setIsApprovalStatusSet(true);
    apiStore.FormsApiClient.UpdateTenantRequestStatus(
      id,
      status,
      reason,
      apiStore?.configApi?.userEmail?.toString()
    )
      .then((res) => {
        console.log(res?.data);
        setIsApprovalStatusSet(false);
        form.resetFields();
        setReason("");
      })
      .catch(() => setIsApprovalStatusSet(false)); // Handle errors
  };

  return (
    <>
      {isLoadingSubmissions && <Spinner />}
      <Row justify={"center"}>
        <Col sm={20} className="text-center">
          <div className="text-center mt-5">
            <h1 className="heading-maintenance" style={{ color: "#5A7890" }}>
              Tenant Submissions
            </h1>
          </div>
        </Col>
        <Col
          sm={20}
          className="text-center mb-3"
          style={{ marginBottom: "20px", marginTop: "30px" }}
        >
          <Select
            defaultValue="All"
            style={{ width: 200, height: 40 }}
            onChange={(value) => handleFilterSubmissions(value)}
          >
            <Option value="All">All</Option>
            <Option value="Approved">Approved</Option>
            <Option value="Rejected">Rejected</Option>
            <Option value="Pending">Pending</Option>
          </Select>
        </Col>
        <Col sm={20}>
          <DynamicTable columns={columns} data={filteredSubmissions} />
        </Col>
      </Row>
      <Modal
        title={`Reason for ${modalAction}`}
        centered={true}
        open={isModalVisible}
        Ok={handleOk}
        Cancel={handleCancel}
        width={600}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Cancel
          </Button>,
          <Button key="submit" type="primary" onClick={handleOk}>
            Submit
          </Button>,
        ]}
      >
        {(pageName) => (
          <Form form={form}>
            <TextArea
              rows={4}
              name="reason"
              value={reason}
              onChange={(e) => setReason(e.target.value)}
              placeholder={`Enter reason for ${modalAction}`}
            />
          </Form>
        )}
      </Modal>
    </>
  );
};

export default Submissions;
