import { ColumnsType } from "antd/es/table";
import { AssociationDocumentItem } from "../../../api/BoardVue";
import { FileExplorerColumns } from "../../../components/FileExplorer/FileExplorerList";
import { dateValidation } from "../../../helpers/dateValidation";
import { Flex } from "antd";
import { FolderFilled, FileTextOutlined } from '@ant-design/icons'
import { DateSort, FileNaturalCompare, TextSort } from "../../../helpers/SorterHelper";

const columns: ColumnsType<AssociationDocumentItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "id",
        render: () => (
            <Flex align="center" justify="center">
                 <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
            </Flex>
        )
    },
    {
        key: "2",
        title: "Name",
        colSpan: 2,
        align: 'left',
        width: "40%",
        dataIndex: "name",
        sorter: (a: AssociationDocumentItem, b: AssociationDocumentItem) => FileNaturalCompare(a.name, b.name, 'ascend'),
        defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
    {
        key: "3",
        title: "Type",
        width: "16%",
        dataIndex: "documentType",
        sorter: (a: AssociationDocumentItem, b: AssociationDocumentItem) => TextSort(a, b, "documentType")
    },
    {
        key: "4",
        title: "Modified",
        width: "16%",
        dataIndex: "modified",
        sorter: (a: AssociationDocumentItem, b: AssociationDocumentItem) => DateSort(a, b, "modified"),
        render: (date: any) => dateValidation(date),
    },
    {
        key: "5",
        title: "Modified By",
        width: "16%",
        dataIndex: "modifiedByName",
        sorter: (a: AssociationDocumentItem, b: AssociationDocumentItem) => TextSort(a, b, "modifiedByName"),
    },
];

const small: ColumnsType<AssociationDocumentItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "id",
        render: () => (
            <Flex align="center" justify="center">
                <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
            </Flex>
        )
    },
    {
        key: "2",
        colSpan: 2,
        align: 'left',
        title: "Name",
        dataIndex: "name",
        sorter: ((a: AssociationDocumentItem, b: AssociationDocumentItem, direction: string) => FileNaturalCompare(a.name, b.name, direction)) as any,
        defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>
    },
    {
        key: "3",
        width: "8%",
        title: "Modified",
        dataIndex: "modified",
        sorter: (a: AssociationDocumentItem, b: AssociationDocumentItem) => DateSort(a, b, "modified"),
        render: (date: any) => dateValidation(date)
    },
    {
        key: "4",
        width: "37%",
        title: "Modified By",
        dataIndex: "modifiedByName",
        sorter: (a: AssociationDocumentItem, b: AssociationDocumentItem) => TextSort(a, b, "modifiedByName")
    }
];

const AssociationDocumentsColumns: FileExplorerColumns<AssociationDocumentItem> = {
    large: columns,
    small: small,
};

export default AssociationDocumentsColumns;