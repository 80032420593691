import { Card, Flex, Result, Spin, Typography } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect, useRef, useState } from "react";
import { embedDashboard } from "@superset-ui/embedded-sdk";
import { useStore } from "../../../root-store-context";
import { Link } from "react-router-dom";
import {ArrowLeftOutlined} from '@ant-design/icons';

const FAQsReport = observer(() => {

    const { reportStore, applicationStore:{ SelectedAddress } } = useStore();
    const [reportUrl, setReportUrl] = useState<string>("");
    const [isLoadReport, setIsLoadReport] = useState<boolean>(true);
    const [isLoadReportFAQ, setIsLoadReportFAQ] = useState<boolean>(true);

    const lastUsedSourceCommunityId = useRef<string|null>(null);

    

    useEffect(() => {
        const currentSourceCommunityId = SelectedAddress?.communityId;
        const currentSourceCommunityName = SelectedAddress?.name;
        if (currentSourceCommunityId !== lastUsedSourceCommunityId.current
            && document.getElementById("BV_FAQ_Report")
            ) {
                setIsLoadReport(true);
                reportStore.getReportUrl2(currentSourceCommunityName, "16").then(url => {
                if(url){
                    console.log(currentSourceCommunityId);
                    setReportUrl(url);
                    setIsLoadReport(false);
                }
            }).catch(err=> {
                console.log(err)
            })
            lastUsedSourceCommunityId.current = currentSourceCommunityId;
        }
    }, [SelectedAddress?.communityId]);

    return <>
            <Flex vertical={true} gap={10}>
            <Link to="/BoardVue/Reports" style={{ marginLeft: 10 }}><ArrowLeftOutlined /> Back</Link>
                {/* <Card title={<Typography.Title level={5} style={{margin:0}}><b>FAQs</b></Typography.Title>}> */}
                <Card>
                    {/* <div id="BV_FAQ_Report" className="reportIframe"></div> */}
                    <iframe id="BV_FAQ_Report" className="reportIframe"
                        src={reportUrl}
                        width="100%"
                        height="1000"
                        allowTransparency={true}
                        frameBorder={0}
                    ></iframe>
                    {isLoadReport === true ? <>
                        <Flex gap="small" vertical>
                            <Spin/>
                        </Flex>
                    </>: <></>}
                </Card>
            </Flex>
    </>
})

export default FAQsReport
