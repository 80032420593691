import { Flex, Menu } from "antd";
import { ReactElement, useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Logo from "./Logo";
import "./sidebar.scss";
import useBreakpoint from "use-breakpoint";
import { useStore } from "../../../root-store-context";
import { ItemType, MenuItemType } from "antd/es/menu/hooks/useItems";
import { observer } from "mobx-react-lite";
import { RouteElement } from "../../../stores/appStores/ApplicationStore";
import backIcon from "../../../images/pageIcons/back.png";
import iconResidentDirectory from "../../../images/pageIcons/Association/ResidentDirectory.png";

import { Image } from "antd";

const SideBar = observer(
  ({
    collapsed,
    onCollapsed,
  }: {
    collapsed: boolean;
    onCollapsed: () => void;
  }): ReactElement => {
    const { applicationStore } = useStore();
    const [navItems, setNavItems] = useState<ItemType<MenuItemType>[]>([]);
    const [currentKey, setCurrentKey] = useState("");
    const [openKey, setOpenKey] = useState<string[]>([]);
    const { breakpoint } = useBreakpoint({ mobile: 0, desktop: 992 });

    const location = useLocation();
    const navigate = useNavigate();

    function extractSubPath(path: string): string {
      let parts: string[] = path.split("/");
      if (parts.length >= 3) {
        return parts.slice(0, 3).join("/");
      } else {
        return path;
      }
    }

    function backToComunity() {
      window.open(applicationStore.communityWebSite, "_blank");
    }

    function toAAMSite() {
      window.open("https://www.associatedasset.com/", "_blank");
    }

    function extractMainPath(path: string): string {
      let parts: string[] = path.split("/");
      if (parts.length >= 2) {
        return parts.slice(0, 2).join("/");
      } else {
        return path;
      }
    }

    function addAdditionalOptionsForSidebar(items: ItemType<MenuItemType>[]): ItemType<MenuItemType>[] {
      const backToSiteItem = {
        key: "backItem",
        label: <b style={{ fontSize: 14, fontWeight: 600 }}>Community Site</b>,
        onClick: backToComunity
      };
      const backToAAMSiteItem = {
        key: "backAAMItem",
        label: <b style={{ fontSize: 14, fontWeight: 600, marginLeft: 10 }}>Return to associatedasset.com</b>,
        icon: <Flex><Image style={{ maxHeight: "1.2em", maxWidth: "1.2em" }} src={backIcon} preview={false} /></Flex>,
        onClick: toAAMSite
      };
      if (applicationStore.communityWebSite.length > 3) {
        items = [backToSiteItem, ...items]
      }
      if (breakpoint === "mobile") {
        items = [...items, backToAAMSiteItem]
      }
      return items;
    }
    
    useEffect(() => {//for home page
      let currentPath = location.pathname;
      if (currentPath === "/" && openKey.length === 0) {
        setOpenKey(["/AccountInfo"]);
        setCurrentKey("/AccountInfo");
      } else {
        currentPath = currentPath === "/" ? "/AccountInfo" : currentPath;
        let subPath: string = extractSubPath(currentPath);
        setOpenKey([extractMainPath(currentPath)]);
        setCurrentKey(subPath);
      }
    }, [location, collapsed]);

    useEffect(() => {
      let items: ItemType<MenuItemType>[] = [];
      applicationStore.routingList.forEach((item) => {
        const childrens = item.children
          ?.filter(
            (filterItem: RouteElement) =>
              filterItem.label !== undefined &&
              applicationStore.isFeatures(filterItem.featureID)
          )
          .map((el: RouteElement) => {
            return {
              key: `${item.path}${el.path}`,
              icon: el.icon,
              label: (
                <Link
                  to={`${item.path}${el.path}`}
                  style={{ marginLeft: 10, fontWeight: 600 }}
                >
                  {el.label}
                </Link>
              ),
              className: item.className,
            };
          });
        if (item.label) {
          items.push({
            key: item.key,
            icon: item.icon,
            label: <b style={{ fontSize: 18 }}>{item.label}</b>,
            children: childrens,
          });
        }
      });
      items = addAdditionalOptionsForSidebar(items);
      setNavItems(items);
    }, [applicationStore.routingList, breakpoint]);
    
    return (
      <div style={{ maxWidth: "300px", zIndex: "100" }}>
        <Logo />
        <Menu
          mode="inline"
          selectedKeys={[currentKey]}
          openKeys={breakpoint === "mobile" && collapsed ? [] : openKey}
          onOpenChange={(e: string[]) => {
            if (e.length === 0) {
              setOpenKey(e);
            } else if (e.some(e => e === "/AccountInfo") && !openKey.some(e => e === "/AccountInfo")) {
              setOpenKey(e);
              navigate('/');
            } else {
              if (!collapsed || breakpoint === "desktop") {
                setOpenKey(e);
              }
              if (breakpoint === "mobile" && collapsed) {
                setOpenKey(e);
              }
            }
          }}
          onClick={onCollapsed}
          items={navItems}
        />
      </div>
    );
  }
);

export default SideBar;
