import React from 'react';
import { Col, Row, Space, Image, Spin, App } from 'antd';
import TaskForm from './TaskForm';
import { useStore } from '../../../root-store-context';
import Title from 'antd/es/typography/Title';
import newBoardTask from "../../../images/pageIcons/BoardVue/taskForm.png"
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';

const CreateTask = observer(() => {
    const { boardTaskStore } = useStore();
    const navigate = useNavigate();
    const {message} = App.useApp();

    const success = () => {
        message.open({
            type: 'success',
            content: 'New task added successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    const onSubmit = async () =>{
        boardTaskStore.editLoading(true);
        boardTaskStore.taskModel.attachments = boardTaskStore.attachments;
        const allPromisesAttach = boardTaskStore.taskModel?.attachments?.map(async item=>{
            const attachGUID = await boardTaskStore.addAttachmentToBuffer(item);
            if (typeof attachGUID === "string") {
                item.Guid = attachGUID;
                item.Data = null as any;
                item.Url = null as any;
            }
        })
        if(allPromisesAttach){
            await Promise.all(allPromisesAttach);
        }
        if(!Array.isArray(boardTaskStore.taskModel.assignedTo)){
            boardTaskStore.taskModel.assignedTo = [boardTaskStore.taskModel.assignedTo];
        }
        boardTaskStore.addSharePointTask().then((res) => {
            if (res) {
                success()
                navigate('/BoardVue/BoardTasks');
            }
            else {
                error()
            }
        })
    }
    return (
        <>
            <Spin tip="Loading" size="small" spinning={boardTaskStore.isLoading}>
                <Row>
                    <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                        <Space>
                            <Image src={newBoardTask} preview={false} height={25}/>
                            <Title level={2} style={{ marginTop: "10px" }}>New Board Task</Title>
                        </Space>
                        <TaskForm submitBtnText='Create' submit={onSubmit}/>
                    </Col>
                </Row>
            </Spin>
        </>
    )
})

export default CreateTask