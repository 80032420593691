import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { CommunityProxy } from "../../../api/Common/CommonTypes";
import { EntryAccessRequestItem } from "../../../api/Forms/FormsTypes";

class EntryAccessRequestStore {
    submittableForm: boolean = false;
    isLoading: boolean = false;
    entryAccessRequestItem: EntryAccessRequestItem = {
        DaytimePhone: "",
        EmailAddress: "",
        Description: "",
    } as EntryAccessRequestItem;
    shouldInitAttachments: boolean = false;
    shouldResetForm: boolean = false;
    private _api: ApiStore;



    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api
    }

    resetValue = () => {
        this.entryAccessRequestItem = {
            DaytimePhone: "",
            EmailAddress: "",
            Description: "",
        } as EntryAccessRequestItem;
        this.shouldResetForm = true;
    }

    editLoading = (loading: boolean) => {
        this.isLoading = loading
    }

    setSubmittableForm = (submittable: boolean) => {
        this.submittableForm = submittable;
    }

    setDaytimePhone = (phone: string) => {
        this.entryAccessRequestItem.DaytimePhone = phone;
    }

    setNewEmail = (email: string) => {
        this.entryAccessRequestItem.EmailAddress = email;
    }

    setEntryAccessRequestItem = (newMaintenanceRequestItem: EntryAccessRequestItem) => {
        this.entryAccessRequestItem = newMaintenanceRequestItem;
    }

    addEntryAccessRequestItem = async (selectedCommunity: CommunityProxy, userId: string) => {
        this.isLoading = true;
        const startDate = new Date();
        let dueDate = new Date();
        dueDate.setDate(dueDate.getDate() + 1);
        dueDate.setMinutes(dueDate.getMinutes() + 1439);
        await this._api.FormsApiClient.addEntryAccessRequestItem({
            ...this.entryAccessRequestItem,
            Community: selectedCommunity,
            UserId: userId,
            StartDate: startDate,
            DueDate: dueDate,
        });
        this.isLoading = false;
        return true
    }

    getOwnerContactInfo = async () => {
        this.isLoading = true;
        const residentDirectoryProfile = await this._api.FormsApiClient.getResidentDirectoryProfile();
        const newFormData = residentDirectoryProfile as EntryAccessRequestItem;
        this.setEntryAccessRequestItem(newFormData);
        this.isLoading = false;
      };
}

export default EntryAccessRequestStore

