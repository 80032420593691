import { AutoComplete, Button } from "antd";
import { ReactElement, useEffect, useState } from "react"

export type FilterItemObject = {
    item1: string;
    item2: number
}
export type RicFilterList = {
    accounts: string[],
    addressSearch: FilterItemObject[],
    namesSearch: FilterItemObject[],
    eTrakAccounts: FilterItemObject[]
}
export type SearchFormItem = {
    accounts: { value: number | null, disabled: boolean },
    addressSearch: { value: string | null, disabled: boolean },
    namesSearch: { value: string | null, disabled: boolean },
    eTrakAccounts: { value: string | null, disabled: boolean }
}

type SearchOption = {
    value: number;
    key: string;
    label: string;
    name: string;
}

const styles = {
    container: {
        display: "flex",
        justifyContent: "center",
        flexFlow: "row wrap"
    },
    formField: {
        height: "38px",
        maxHeight: "38px",
        fontSize: "1rem",
        margin: "4px",
    },
    buttonReset: {
        minWidth: 110,
        flex: "0 1 110px",
        backgroundColor: "#8c8c8c",
        borderRadius: 10,
        padding: 0
    }
}
const SearchForm = (props: { item: RicFilterList, selectedAccount?: string | number | undefined, selectCallBack: (itemId: number) => void, resetCallBack?: () => void }): ReactElement => {
    const { item, selectCallBack, resetCallBack } = props;
    const defaultValue = {
        accounts: { value: null, disabled: false },
        eTrakAccounts: { value: null, disabled: false },
        namesSearch: { value: null, disabled: false },
        addressSearch: { value: null, disabled: false }
    };
    const disableValue = {
        accounts: { value: null, disabled: true },
        eTrakAccounts: { value: null, disabled: true },
        namesSearch: { value: null, disabled: true },
        addressSearch: { value: null, disabled: true }
    };
    const [autoCompleteValues, setAutoCompleteValues] = useState<SearchFormItem>(defaultValue);

    const handleReset = () => {
        if (resetCallBack) {
            resetCallBack()
        }
        setAutoCompleteValues(defaultValue);
    };

    const onChange = (value: any, name: string) => {
        if (value.toString().length === 0) {
            setAutoCompleteValues({ ...defaultValue })
        } else {
            setAutoCompleteValues({ ...disableValue, [name]: { value: value, disabled: false } })
        }
    }

    const selectCallBackLocal = (value: any, option: SearchOption) => {
        setAutoCompleteValues({
            ...disableValue,
            [option.name]: { value: value, disabled: false },
            accounts: { value: option.value, disabled: option.name === "accounts" ? false : true }
        });
        selectCallBack(option.value)
    }

    const getNumber = (str: string): number => {
        const digitRegex = /\d+/;
        const match = str.match(digitRegex);
        if (match && match.length > 0) {
            return parseInt(match[0], 10);
        } else {
            throw new Error("Search account ID not a number");
        }
    }

    const customFilterOption = (inputValue: string, option: any) => {
        return option!.label?.toUpperCase().includes(inputValue.toUpperCase())
    };

    const optionRender = (oriOption: any, index: any) => {
        return <div key={index} style={{ width: "100%", height: "100%" }}>{oriOption.label}</div>;
    }

    useEffect(() => {
        if (props.selectedAccount !== undefined) {
            setAutoCompleteValues(prev => {
                if (prev.accounts.disabled === true) {
                    for (const key in prev) {
                        const fieldKey: keyof SearchFormItem = key as keyof SearchFormItem;
                        if (prev[fieldKey].disabled === false) {
                            if (typeof item[fieldKey as keyof RicFilterList][0] !== "string") {// check FilterItemObject[] or string[]
                                const findItem = (item[fieldKey as keyof RicFilterList] as FilterItemObject[]).find(el => {
                                    const elType = el as FilterItemObject;
                                    if (elType.item2.toString() === props.selectedAccount?.toString()) {
                                        return true
                                    } else {
                                        return false
                                    }
                                }) as FilterItemObject;
                                prev[fieldKey].value = findItem.item1;
                            }
                        }
                    }
                } else {
                    prev = { ...disableValue, accounts: { value: parseInt(props.selectedAccount ? props.selectedAccount.toString() : ""), disabled: false } }
                }
                return { ...prev }
            })
        }
    }, [props])

    return (
        <div style={styles.container}>
            <AutoComplete
                style={{ ...{ minWidth: 150, flex: "1 1 150px" }, ...styles.formField }}
                options={item.accounts.map((el, index) => ({ value: getNumber(el), key: el + index, label: el, name: "accounts" }))}
                placeholder="Account Search"
                value={autoCompleteValues.accounts.value}
                onChange={(value) => { onChange(value, "accounts") }}
                onSelect={selectCallBackLocal}
                filterOption={customFilterOption}
                optionRender={optionRender}
                disabled={autoCompleteValues.accounts.disabled}
            />
            {item.eTrakAccounts.length > 0 &&
                <AutoComplete
                    style={{ ...{ minWidth: 150, flex: "1 1 150px" }, ...styles.formField }}
                    options={item.eTrakAccounts.map((el, index) => ({ value: el.item2, key: el.item1 + index, label: el.item1.toString(), name: "eTrakAccounts" }))}
                    placeholder="Contact ID Search"
                    value={autoCompleteValues.eTrakAccounts.value}
                    onChange={(value) => { onChange(value, "eTrakAccounts") }}
                    onSelect={selectCallBackLocal}
                    filterOption={customFilterOption}
                    optionRender={optionRender}
                    disabled={autoCompleteValues.eTrakAccounts.disabled}
                />
            }
            <AutoComplete
                style={{ ...{ minWidth: 220, flex: "1 1 220px" }, ...styles.formField }}
                options={item.namesSearch.map((el, index) => ({ value: el.item2, key: el.item1 + index, label: el.item1, name: "namesSearch" }))}
                placeholder="Name Search"
                value={autoCompleteValues.namesSearch.value}
                onChange={(value) => { onChange(value, "namesSearch") }}
                onSelect={selectCallBackLocal}
                filterOption={customFilterOption}
                optionRender={optionRender}
                disabled={autoCompleteValues.namesSearch.disabled}
            />
            <AutoComplete
                style={{ ...{ minWidth: 220, flex: "1 1 220px" }, ...styles.formField }}
                options={item.addressSearch.map((el, index) => ({ value: el.item2, key: el.item1 + index, label: el.item1, name: "addressSearch" }))}
                placeholder="Address Search"
                value={autoCompleteValues.addressSearch.value}
                onChange={(value) => { onChange(value, "addressSearch") }}
                onSelect={selectCallBackLocal}
                filterOption={customFilterOption}
                optionRender={optionRender}
                disabled={autoCompleteValues.addressSearch.disabled}
            />
            <Button
                style={{ ...styles.buttonReset, ...styles.formField }}
                type="primary"
                onClick={handleReset}>Reset Filter</Button>
        </div>
    )
}
export default SearchForm;