/* eslint-disable no-loop-func */
/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  Card,
  Collapse,
  CollapseProps,
  Flex,
  Input,
  Result,
  Spin,
  Typography,
} from "antd";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../root-store-context";
import { SearchOutlined } from "@ant-design/icons";
import { FAQResultItem } from "../../api/Association/AssociationTypes";

type CollapseItem = {
  key: string;
  label: string;
  children: JSX.Element;
};

const FAQ = observer((): ReactElement => {
  const { faqStore } = useStore();

  const [items, setItems] = useState<CollapseProps["items"]>([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (searchText === "") {
      faqStore.getFAQ();
    } else {
      faqStore.getSearchFAQ(searchText);
    }
  }, [searchText]);

  useEffect(() => {
    setItems(getRenderList(faqStore.faqListResult));
  }, [faqStore.faqListResult, faqStore.faqListResult.length]);

  const groupByCategory = (
    items: FAQResultItem[]
  ): { [key: string]: FAQResultItem[] } => {
    const grouped: { [key: string]: FAQResultItem[] } = {};

    items.forEach((item) => {
      if (!grouped[item.category]) {
        grouped[item.category] = [];
      }
      grouped[item.category].push(item);
    });

    return grouped;
  };

  const getRenderList = (items: FAQResultItem[]): CollapseProps["items"] => {
    const groupeList = groupByCategory(items);
    return transformToCollapseItems(groupeList);
  };

  const createElement = (
    item: FAQResultItem,
    index: number,
    keyCounter: number
  ) => {
    return (
      <Card
        key={`${keyCounter}-${index}`}
        title={
          <Flex
            justify="space-between"
            style={{
              whiteSpace: "break-spaces",
              paddingBottom: "5px",
              paddingTop: "5px",
            }}
          >
            {item.question}
          </Flex>
        }
        size="small"
      >
        <Flex justify="space-between" style={{ whiteSpace: "break-spaces" }}>
          {item.answer}
        </Flex>
      </Card>
    );
  };

  const transformToCollapseItems = (
    data: Record<string, FAQResultItem[]>
  ): CollapseProps["items"] => {
    const collapseItems: CollapseItem[] = [];
    let keyCounter = 1;

    for (const category in data) {
      if (data.hasOwnProperty(category)) {
        const faqItems = data[category];
        const children = faqItems.map((item, index) =>
          createElement(item, index, keyCounter)
        );
        collapseItems.push({
          key: keyCounter.toString(),
          label: category,
          children: (
            <Flex vertical={true} gap={5}>
              {children}
            </Flex>
          ),
        });
        keyCounter++;
      }
    }

    return collapseItems;
  };

  let timeout: NodeJS.Timeout;

  const handleSearch = (value: string) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setSearchText(value);
    }, 750);
  };

  return (
    <>
      <Spin spinning={faqStore.isLoading}>
        <Flex vertical={true} gap={10}>
          <Flex gap={20} align="center">
            <h2>FAQ</h2>
            <Input
              prefix={
                <Button shape="circle" type="text" icon={<SearchOutlined />} />
              }
              variant="borderless"
              placeholder="Search"
              allowClear
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
          </Flex>
          {items && items.length > 0 && (
            <Collapse items={items} bordered={false} />
          )}
          {items && items.length === 0 && searchText !== "" && (
            <>
              <Result
                status="warning"
                title="The question for your request was not found."
              />
            </>
          )}
        </Flex>
      </Spin>
    </>
  );
});

export default FAQ;
