import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './style.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from 'react-oidc-context';
import { WebStorageStateStore } from "oidc-client-ts";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const oidcConfig = {
  authority: process.env.REACT_APP_IDP as string,
  client_id: process.env.REACT_APP_CLIENT_ID as string,
  redirect_uri: process.env.REACT_APP_REDIRECT_URI as string,
  client_secret: process.env.REACT_APP_CLIENT_SECRET,
  extraQueryParams:{
    user_type:"board_member"
  },
  userStore: new WebStorageStateStore({ store: window.localStorage }),
  automaticSilentRenew: false,
};
const onSigninCallback = (): void => {
  window.history.replaceState(
    {},
    document.title,
    window.location.pathname
  )
}

root.render(
  <React.StrictMode>
    <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback}>
      <App />
    </AuthProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
