import { List, Tabs } from "antd";
import { ReactElement } from "react";
import { useStore } from "../../../../root-store-context";
import ContactInfo from "./ContactInfo";
import MailArhive from "./MailArhive";
import AccountDetail from "./AccountDetail";
import Architectural from "./Architectural";
import Compliances from "./Compliances";
const TableAccountInfo = (): ReactElement => {
    const { ricStore } = useStore();

    return (
        <Tabs type="card"
            items={[
                {
                    label: "Contact Info",
                    key: "Contact Info",
                    children:
                        <List
                            dataSource={ricStore.contactInformation}
                            renderItem={(item, index) => (
                                <ContactInfo key={`contactInfo${index}`} contactInfo={item} />
                            )}
                        />
                }, {
                    label: "Account Detail",
                    key: "Account Detail",
                    children: <AccountDetail dataSource={ricStore.accountDetails} />,
                }, {
                    label: "Compliance Issues",
                    key: "Compliance",
                    children: <Compliances dataSource={ricStore.complianceInformation} />,
                }, {
                    label: "Architectural",
                    key: "Architectural",
                    children:
                        <Architectural architecturalData={ricStore.architecturalInformation} />
                    ,
                }, {
                    label: "Mail Archive",
                    key: "Mail Archive",
                    children:
                        <MailArhive mailItems={ricStore.mailArchive} openFileCallback={(dataRecordId) => {
                            return ricStore.getPdfByDataRecordId(dataRecordId);
                        }} />,
                }
            ]}
        />
    )
}
export default TableAccountInfo;