import { useEffect, useState } from 'react';
import type { EChartsOption } from "echarts";
import ReactECharts from '../../../../components/ReactECharts/ReactECharts';
import { Flex } from 'antd';

export type TopViolationsCategoryItem = {
    value: number;
    name: string;
}

export interface ITopViolationsCategoriesProps {
    data: TopViolationsCategoryItem[];
}

const TopViolationsCategories = (props: ITopViolationsCategoriesProps) => {
    const [option, setOption] = useState<EChartsOption>({});

    useEffect(() => {
        const data = props.data;
        const parsedData = data.map(i => ({
            name: i.name,
            value: i.value
        }));
        const total = parsedData.reduce((acc, val) => acc + val.value, 0);
        const option: EChartsOption = {
            title: {
                text: 'Top 10 Violations Categories',
            },
            tooltip: {
                trigger: 'item'
            },
            legend: {
                type: "scroll",
                top: '15%',
                left: 'center',
                selectedMode: false
            },
            series: [
                {
                    type: 'pie',
                    radius: '50%',
                    center: ['50%', '70%'],
                    data: parsedData,
                    emphasis: {
                        itemStyle: {
                            shadowBlur: 10,
                            shadowOffsetX: 0,
                            shadowColor: 'rgba(0, 0, 0, 0.5)'
                        }
                    }
                }
            ]
        };
        setOption(option);
    }, [props]);
    return <>
        <ReactECharts option={option} />
    </>;
};

export default TopViolationsCategories;
