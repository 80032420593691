import { ColumnsType } from "antd/es/table";
import { FileExplorerColumns } from "../../../components/FileExplorer/FileExplorerList";
import { dateValidation } from "../../../helpers/dateValidation";
import { Flex } from "antd";
import { FolderFilled, FileTextOutlined } from '@ant-design/icons'
import { VendorAgreementFileItem } from "./VendorAgreements";
import { BoolSort, DateSort, FileNaturalCompare, TextSort } from "../../../helpers/SorterHelper";


const columns: ColumnsType<VendorAgreementFileItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "isFolder",
        render: (isFolder: any) => (
            <Flex align="center" justify="center">
                {isFolder ? <FolderFilled style={{ fontSize: 20, color: "#5B7B94" }} /> : <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />}
            </Flex>
        )
    },
    {
        key: "2",
        title: "Name",
        colSpan: 2,
        align: 'left',
        width: "40%",
        dataIndex: "title",
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => FileNaturalCompare(a.title, b.title, 'ascend', a.isFolder, b.isFolder),
        defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
    // {
    //     key: "3",
    //     title: "Type",
    //     width: "16%",
    //     dataIndex: "CommunityContractType",
    //     sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => TextSort(a, b, "CommunityContractType"),
    // },
    {
        key: "3",
        width: "16%",
        title: "Contract Start Date",
        dataIndex: "ContractStartDate",
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => DateSort(a, b, "ContractStartDate"),
        render: (date: any) => dateValidation(date)
    },
    {
        key: "4",
        width: "16%",
        title: "Contract Term/Expiration Date",
        dataIndex: "ContractTermExpirationDate",
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => DateSort(a, b, "ContractTermExpirationDate"),
        render: (date: any) => dateValidation(date)
    },
    {
        key: "5",
        title: "Current Version",
        width: "10%",
        dataIndex: "CurrentVersion",
        render: (currentVersion: any) => (currentVersion ? "Yes" : ""),
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => BoolSort(a,b,"CurrentVersion"),
    },
    {
        key: "6",
        width: "16%",
        title: "Community Contract Status",
        dataIndex: "CommunityContractStatus",
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => TextSort(a, b, "CommunityContractStatus")
    },
    {
        key: "7",
        title: "Modified",
        width: "16%",
        dataIndex: "modified",
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => DateSort(a, b, "modified"),
        render: (date: any) => dateValidation(date),
    }
];

const small: ColumnsType<VendorAgreementFileItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "isFolder",
        render: (isFolder: any) => (
            <Flex align="center" justify="center">
                {isFolder ? <FolderFilled style={{ fontSize: 20, color: "#5B7B94" }} /> : <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />}
            </Flex>
        )
    },
    {
        key: "2",
        colSpan: 2,
        align: 'left',
        title: "Name",
        dataIndex: "title",
        sorter: ((a: VendorAgreementFileItem, b: VendorAgreementFileItem, direction: string) => FileNaturalCompare(a.title, b.title, direction, a.isFolder, b.isFolder)) as any,
        defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>
    },
    {
        key: "3",
        width: "16%",
        title: "Contract Start Date",
        dataIndex: "ContractStartDate",
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => DateSort(a, b, "ContractStartDate"),
        render: (date: any) => dateValidation(date)
    },
    {
        key: "4",
        width: "16%",
        title: "Contract Term/Expiration Date",
        dataIndex: "ContractTermExpirationDate",
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => DateSort(a, b, "ContractTermExpirationDate"),
        render: (date: any) => dateValidation(date)
    },
    {
        key: "5",
        title: "Current Version",
        width: "10%",
        dataIndex: "CurrentVersion",
        render: (currentVersion: any) => (currentVersion ? "Yes" : ""),
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => BoolSort(a,b,"CurrentVersion"),
    },
    {
        key: "6",
        width: "16%",
        title: "Community Contract Status",
        dataIndex: "CommunityContractStatus",
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => TextSort(a, b, "CommunityContractStatus")
    },
    {
        key: "7",
        width: "8%",
        title: "Modified",
        dataIndex: "modified",
        sorter: (a: VendorAgreementFileItem, b: VendorAgreementFileItem) => DateSort(a,b,"modified"),
        render: (date: any) => dateValidation(date)
    }
];

const VendorAgreementsColumns: FileExplorerColumns<VendorAgreementFileItem> = {
    large: columns,
    small: small,
};

export default VendorAgreementsColumns;