import { makeAutoObservable, runInAction } from "mobx";
import { RicFilterList } from "../../../pages/BoardVueGroup/RIC/components/SearchForm";
import { AccountInformation } from "../../../pages/BoardVueGroup/RIC/components/AccountInfoCard";
import { ContactInformation } from "../../../pages/BoardVueGroup/RIC/components/ContactInfo";
import { AccountDetailItem } from "../../../pages/BoardVueGroup/RIC/components/AccountDetail";
import { MailItem } from "../../../pages/BoardVueGroup/RIC/components/MailArhive";
import { ArchitecturalData } from "../../../pages/BoardVueGroup/RIC/components/Architectural";
import { ComplianceItem } from "../../../pages/BoardVueGroup/RIC/components/Compliance";
import { message } from 'antd';
import ApiStore from "../ApiStore";
class RicStore {
    private _api: ApiStore;

    public IsLoadingApp: boolean = false;
    public FilterList: RicFilterList = {
        accounts: [],
        addressSearch: [],
        namesSearch: [],
        eTrakAccounts: [],
    }
    public accountInformation!: AccountInformation;
    public contactInformation: ContactInformation[] = [];
    public accountDetails: AccountDetailItem[] = [];
    public mailArchive: MailItem[] = [];
    public architecturalInformation: ArchitecturalData[] = [];
    public complianceInformation: ComplianceItem[] = [];

    public SelectedAccount: number | string | undefined = undefined

    public IsShowRicInfo: boolean = false

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    public getFilterList = () => {
        this.IsLoadingApp = true;
        this._api.RicApiClient.getSearchFilters()
            .then(result => {
                this.IsLoadingApp = false
                this.FilterList = {
                    accounts: result.data["accounts"],
                    addressSearch: result.data["addresSearch"],
                    eTrakAccounts: result.data["eTrakAccounts"],
                    namesSearch: result.data["namesSearch"]
                } as RicFilterList
            }).catch(error => {
                console.log(error);
                this.IsLoadingApp = false
            })
    }

    public setAccount = (account: number | string | undefined) => {
        this.SelectedAccount = account
    }

    public setShowRicInfo = (status: boolean) => {
        this.IsShowRicInfo = status;
    }

    public getPdfByDataRecordId = async (dataRecordId: number): Promise<string> => {
        const respose = await this._api.RicApiClient.getPdfByDataRecordId(dataRecordId);
        if (respose.status === 200) {
            return respose.data
        } else {
            return `Error ${JSON.stringify(respose, undefined, 2)}`
        }
    }

    public getUlrLotDocument = async (accountNumber: string): Promise<string> => {
        let numberIsString = typeof (accountNumber) == 'string'
        const accNumber = `=${numberIsString ? accountNumber.trim().split(" ")[0].trim().split("-")[0].trim().split("_")[0].trim() : accountNumber}`;
        return await this._api.RicApiClient.getLotDocumentGetProperies(accNumber)
            .then((response) => {
                return response.data.url;
            })
            .catch((err) => {
                console.log(err);
            });
    }

    public async fetchAccountInformation(accountId: string) {
        this.setAccount(accountId)
    }

    public async fetchIsAccessToProperty(accountId: string) {
        this.accountInformation = {
            ...this.accountInformation,
            loading: true,
            autocompleteLoading: false,
            autocompleteOpen: false,
            textAccountNumber: accountId,
            accountNumber: accountId,
            isAccessToProperty: true,
            propertyName: "",
            companyKey: this.accountInformation.selectedProperty && this.accountInformation.selectedProperty[0].id,
        }
        await this.fetchAccountInformation(accountId);
    }

    public toMainAcc = () => {
        this.accountInformation.isMainAcc = true;
        this.fetchIsAccessToProperty(this.accountInformation.mainAccNumb);
    }
    public accesAccountFuture = () => {
        this.accountInformation.isMainAcc = false;
        this.fetchIsAccessToProperty(this.accountInformation.activeFutures[0].residentKey);
    }
    public accesAccountRenter = () => {
        this.accountInformation.isMainAcc = false;
        this.fetchIsAccessToProperty(this.accountInformation.activeRenters[0].residentKey);
    }
    public getRicInfo = async (): Promise<void> => {
        if (this.SelectedAccount) {
            const account = this.SelectedAccount;
            this.IsLoadingApp = true;
            this.IsShowRicInfo = false;
            runInAction(() => {
                const contactInformationPromise = new Promise(async (resolve, reject) => {
                    try {
                        const getData = await this._api.RicApiClient.getContactInformation(account);
                        if (getData.status === 200) {
                            this.contactInformation = getData.data || []
                            resolve({ contactInformation: getData.data })
                        } else {
                            this.contactInformation = []
                            reject("Contact Information don`t load :" + JSON.stringify(getData, undefined, 2))
                        }
                    } catch (error) {
                        this.contactInformation = []
                        reject("Contact Information don`t load :" + JSON.stringify(error, undefined, 2))
                    }
                });
                const accountInformationPromise = new Promise(async (resolve, reject) => {
                    try {
                        const getData = await this._api.RicApiClient.getAccountInformation(account);
                        if (getData.status === 200) {
                            this.accountInformation = {
                                ...getData.data,
                                isMainAcc: getData.data.isMain,
                                futuresAccNumbers: getData.data.futures,
                                mainAccNumb: getData.data.mainAcc
                            }
                            resolve({ accountInformation: getData.data })
                        } else {
                            this.accountInformation = {} as AccountInformation;
                            reject("Account Information don`t load :" + JSON.stringify(getData, undefined, 2))
                        }
                    } catch (error) {
                        this.accountInformation = {} as AccountInformation;
                        reject("Account Information don`t load :" + JSON.stringify(error, undefined, 2))
                    }
                });
                const complianceInformationPromise = new Promise(async (resolve, reject) => {
                    try {
                        const getData = await this._api.RicApiClient.getComplianceInformation(account);
                        if (getData.status === 200) {
                            this.complianceInformation = getData.data || [];
                            resolve({ complianceInformation: getData.data })
                        } else {
                            this.complianceInformation = [];
                            reject("Compliance Information don`t load :" + JSON.stringify(getData, undefined, 2))
                        }
                    } catch (error) {
                        this.complianceInformation = [];
                        reject("Compliance Information don`t load :" + JSON.stringify(error, undefined, 2))
                    }
                });
                // const notesInformationPromise = new Promise(async (resolve, reject) => {
                //     try {
                //         const getData = await this._api.RicApiClient.getNotesInformation(account);
                //         if (getData.status === 200) {
                //             resolve({ notesInformation: getData.data })
                //         } else {
                //             reject("Notes Information don`t load :" + JSON.stringify(getData, undefined, 2))
                //         }
                //     } catch (error) {
                //         reject("Notes Information don`t load :" + JSON.stringify(error, undefined, 2))
                //     }
                // });
                // const nordisInformationPromise = new Promise(async (resolve, reject) => {
                //     try {
                //         const getData = await this._api.RicApiClient.getNordisInformation(account);
                //         if (getData.status === 200) {
                //             resolve({ nordisInformation: getData.data })
                //         } else {
                //             reject("Nordis Information don`t load :" + JSON.stringify(getData, undefined, 2))
                //         }
                //     } catch (error) {
                //         reject("Nordis Information don`t load :" + JSON.stringify(error, undefined, 2))
                //     }
                // });
                const architecturalInformationPromise = new Promise(async (resolve, reject) => {
                    try {
                        const getData = await this._api.RicApiClient.getArchitecturalInformation(account);
                        if (getData.status === 200) {
                            this.architecturalInformation = getData.data || [];
                            resolve({ architecturalInformation: getData.data })
                        } else {
                            this.architecturalInformation = [];
                            reject("Architectura lInformation don`t load :" + JSON.stringify(getData, undefined, 2));
                        }
                    } catch (error) {
                        this.architecturalInformation = [];
                        reject("ArchitecturalInformation don`t load :" + JSON.stringify(error, undefined, 2))
                    }
                });
                const accountDetailPromise = new Promise(async (resolve, reject) => {
                    try {
                        const getData = await this._api.RicApiClient.getAccountDetail(account);
                        if (getData.status === 200) {
                            this.accountDetails = getData.data || []
                            resolve({ accountDetail: getData.data })
                        } else {
                            this.accountDetails = [];
                            reject("AccountDetail don`t load :" + JSON.stringify(getData, undefined, 2))
                        }
                    } catch (error) {
                        this.accountDetails = [];
                        reject("AccountDetail don`t load :" + JSON.stringify(error, undefined, 2))
                    }
                });
                const mailArchivePromise = new Promise(async (resolve, reject) => {
                    try {
                        const getData = await this._api.RicApiClient.getMailArchive(account);
                        console.log(getData);
                        if (getData.status === 200) {
                            this.mailArchive = getData.data || []
                            resolve({ mailArchive: getData.data })
                        } else {
                            reject("MailArchive don`t load :" + JSON.stringify(getData, undefined, 2))
                        }
                    } catch (error) {
                        reject("MailArchive don`t load :" + JSON.stringify(error, undefined, 2))
                    }
                });

                const getAllDataPromise = [
                    contactInformationPromise,
                    accountInformationPromise,
                    complianceInformationPromise,
                    architecturalInformationPromise,
                    accountDetailPromise,
                    mailArchivePromise
                ];

                Promise.all(getAllDataPromise).then((allData: any[]) => {
                    console.log(allData);
                    this.IsLoadingApp = false;
                    this.IsShowRicInfo = true;
                }).catch(err => {
                    this.IsLoadingApp = false;
                    this.IsShowRicInfo = true;
                    const index = err.indexOf(':');
                    const errorName = index !== -1 ? err.slice(0, index) : err;
                    message.error(errorName);
                    console.log(err);
                })
            })
        } else {
            this.accountInformation = {} as AccountInformation;
            this.contactInformation = []
            this.accountDetails = []
            this.mailArchive = []
            this.architecturalInformation = []
            this.complianceInformation = []
        }
    }

}
export default RicStore