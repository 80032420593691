import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { DirectoryProfile, ResidentDirectoryPage, ResidentProfileProxy } from "../../../api/Association/AssociationTypes";

class ResidentDirectoryStore {
    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    public async getCommunityResidentDirectoryByPageNum(pageNum:number, pageSize:number): Promise<ResidentDirectoryPage> {
        return this._api.AssociationApiClient.getCommunityResidentDirectoryByPageNum(pageNum, pageSize);
    }

    public async searchCommunityResidentDirectoryPage(searchText:string, pageNum:number, pageSize:number): Promise<ResidentDirectoryPage> {
        return this._api.AssociationApiClient.searchCommunityResidentDirectoryPage(searchText, pageNum, pageSize);
    }

    public async getCommunityResidentDirectoryProfile(): Promise<DirectoryProfile> {
        return this._api.AssociationApiClient.getCommunityResidentDirectoryProfile();
    }

    public async updateCommunityResidentDirectoryProfile(profile:ResidentProfileProxy): Promise<boolean> {
        return this._api.AssociationApiClient.updateCommunityResidentDirectoryProfile(profile);
    }
}
export default ResidentDirectoryStore