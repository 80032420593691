import { observer } from "mobx-react-lite";
import { Button, Flex, Space, Spin } from "antd";
import { ReactElement } from "react";
import AccountInfoCard from "./AccountInfoCard";
import { useStore } from "../../../../root-store-context";

const AccountInfoBlock = observer((): ReactElement => {
  const { ricStore } = useStore();

  return (
    <>
      {ricStore.accountInformation?.accountNumber && (
        <Flex
          align="start"
          wrap="wrap"
          style={{ display: "flex", flexDirection: "row", margin: " 15px 0" }}
        >
          <div style={{ flex: "1 1 60%" }}>
            <AccountInfoCard
              key={"accountInfo"}
              accountInfo={ricStore.accountInformation}
            />
          </div>
          <Space
            style={{
              flex: "1 1 0%",
              alignItems: "stretch",
            }}
          >
            <Space
              style={{
                flex: "1 1 0%",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              {ricStore.accountInformation?.rentersAccNumbers &&
              ricStore.accountInformation.rentersAccNumbers.length > 0 &&
              ricStore.accountInformation.isMainAcc === true ? (
                <Button block onClick={ricStore.accesAccountRenter}>
                  Access Linked Renter Account
                </Button>
              ) : null}
              {ricStore.accountInformation?.futuresAccNumbers &&
              ricStore.accountInformation.futuresAccNumbers.length > 0 &&
              ricStore.accountInformation.isMainAcc === true ? (
                <Button block onClick={ricStore.accesAccountFuture}>
                  Access Linked Future Account
                </Button>
              ) : null}
              {ricStore.accountInformation?.rentersAccNumbers &&
              (ricStore.accountInformation.rentersAccNumbers.length > 0 ||
                ricStore.accountInformation.futuresAccNumbers.length > 0) ? (
                <section className="right-menu-button-link">
                  {ricStore.accountInformation.isMainAcc !== true ? (
                    <Button block onClick={ricStore.toMainAcc}>
                      Return to Main Account
                    </Button>
                  ) : null}
                </section>
              ) : null}
            </Space>
            <Space
              style={{
                flex: "1 1 0%",
                flexDirection: "column",
                alignItems: "stretch",
              }}
            >
              <Button
                block
                onClick={() => {
                  window.open(
                    `https://allaccess.associatedasset.com/BoardVue/RecordArchive/${encodeURIComponent(ricStore.accountInformation?.accountNumber)}`
                  );
                }}
                // onClick={async () => {
                //   const url = await ricStore.getUlrLotDocument(
                //     ricStore.accountInformation?.accountNumber
                //   );
                //   const newWindow = window.open(
                //     url,
                //     "_blank",
                //     "noopener,noreferrer"
                //   );
                //   if (newWindow) newWindow.opener = null;
                // }}
              >
                Lot Documents
              </Button>
              <Button
                block
                onClick={() => {
                  window.open(
                    "https://allaccess.associatedasset.com/BoardVue/Reports/FAQsReport"
                  );
                }}
              >
                Property FAQs
              </Button>
            </Space>
          </Space>
        </Flex>
      )}
    </>
  );
});

export default AccountInfoBlock;
