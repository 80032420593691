import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../root-store-context";
import useBreakpoint from "use-breakpoint";
import { useLocation, useNavigate } from "react-router-dom";
import { Flex, Result, Space, Spin } from "antd";

const Elections = observer((): ReactElement => {
    const { electionsStore, applicationStore } = useStore();
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [htmlCode, setHtmlCode] = useState<string>("");

    useEffect(()=>{
        electionsStore.electionHasActive().then((res)=>{
            setIsActive(res);
            if(res){
                electionsStore.electionGetHtmlCode().then((code)=>{
                    setHtmlCode(code);
                    setIsLoading(false);
                })
            }else{
                setIsLoading(false);
            }
        })
    },[])

    if (!isActive) {
        return <Result status="404" title="No active election" />
    }
    
    return (
        <>
            <Flex justify="space-between" wrap="wrap" align="center" gap="middle" style={{ padding: 10 }}>
                <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
                    <Space style={{ flexWrap: "wrap" }}>
                        <h2 style={{ width: "280px" }}>Elections</h2>
                    </Space>
                </div>
            </Flex>
            <Spin spinning={isLoading}>
                <iframe srcDoc={htmlCode} style={{ border: "none", width: "100%", height: breakpoint === "desktop" ? "68vh" : "70vh" }}></iframe>
            </Spin>
        </>
    )
})

export default Elections;