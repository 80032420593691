import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { CommunityFeature } from "../../../api/Association/AssociationTypes";

class ABDIGatePassesStore {
    private _api: ApiStore;
    private communityFeature?: CommunityFeature;
    private abdiPageToken?: string;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    private async getCommunityFeature(): Promise<CommunityFeature> {
        if(!this.communityFeature){
            this.communityFeature = await this._api.AssociationApiClient.getCommunityFeatureById(27);
        }
        return this.communityFeature;
    }

    private async getAbdiPageToken(communityId:number): Promise<string> {
        if(!this.abdiPageToken){
            this.abdiPageToken = await this._api.AssociationApiClient.getAbdiPageToken(communityId);
        }
        return this.abdiPageToken;
    }

    public async getIframeLink(): Promise<string | null> {
        var communityFeature = await this.getCommunityFeature();
        var abdiPageToken = await this.getAbdiPageToken(communityFeature.communityId);
        console.log(1,communityFeature)
        console.log(2,abdiPageToken)

        if(!communityFeature || !abdiPageToken){
            return null;
        }
        return `${communityFeature.endpoint}ShowPage.aspx?pagetoken=${abdiPageToken}`;
    }
}
export default ABDIGatePassesStore