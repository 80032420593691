import { Flex } from "antd";
import Card from "antd/es/card";
import { ReactElement } from "react";

const CardWidget = ({ titleHead, bodyText, bodyColor = "#000000", bodyDescription }: { titleHead: string, bodyText?: string, bodyDescription?: string, bodyColor?: string }): ReactElement => {
    return (
        <Card
            title={<Flex justify="space-between" style={{whiteSpace:"break-spaces"}}>
                {titleHead}
            </Flex>}
            styles={{
                header: {
                    border: "none",
                    padding: 10,
                    fontSize: 20
                },
                body: {
                    padding: 10,
                    height: "100%",
                    whiteSpace:"wrap"
                }
            }}
            style={{
                width:"100%",
                height:"100%",
                flex: "1 1 ",
                maxHeight: "100%",
                background: "#FFFFFF",
                flexDirection: 'column',
                justifyContent: "flex-start",
                display: "flex",
                border:"none"
            }}
        >
            <Flex vertical justify="center" style={{ height: "100%" }} >
                <div style={{ fontWeight: "600", fontSize: 40, color: bodyColor }}>{bodyText}</div>
                <div>{bodyDescription}</div>
            </Flex>
        </Card>

    )
}

export default CardWidget;