import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";


class ReportStore {

    private _api: ApiStore;
    GeneralLedgerGUID: string = "7f682543-f860-40e4-b8ce-bf84b43526b7"
    FaqGUID: string = "42c6dd2f-b13f-4f62-ba4b-0c446956d959"
    ResidentMoveInGUID: string = "6fe664a9-e12d-4466-aa4a-ab4f4dcd84cd"
    ResidentDirectoryGUID: string = "d6290391-3f27-4388-9a8b-a150ce075852"
    BodAndCommitteeDirectoryGUID: string = "e4d50838-010d-4218-adda-7d20c5373fb4"
    CommunityVendorListGUID: string = "23870cba-b084-4533-a940-9ee7212c3569"
    CurrentBalancesWithAgingByResidentGUID: string = "1bc136a8-da07-43f6-803e-b85e4b814a1e"
    ArchitecturalStatusCSVExportGUID: string = "bd354d34-35f3-41a6-9b3d-561cc67d8b82"
    VMSTrusteeSalesProgressGUID: string = "aa76b5fb-52bf-4f99-841b-896cebb421dd"
    ViolationChartGUID: string = "7582413a-0b23-4bed-bafa-9d363a91e94d"
    DelinquencyDetailLienAndDemandGUID: string = "87e59fea-a144-4fd5-8477-b70a2fdd623c"
    DelinquencySummaryGUID: string = "a4e3bc81-8ac9-4145-8eac-f4d725afdd99"
    DelinquencyDetailCheckboxSummaryGUID: string = "626a0b44-2cb5-4973-abb4-74cb6db829da"
    PaidInvoicesGUID: string = "63443dea-da46-4d79-bbce-1a4012beba65"
    BalanceWithCategoriesByResidentGUID: string = "79473ee9-e04f-463d-b40b-c5d997c29b2c"
    ComplianceDetailGUID: string = "54fafd83-ff99-4357-b65d-221944efab92"

    // CommunityVendorList
    // CurrentBalancesWithAgingByResident
    // ArchitecturalStatusCSVExport
    // VMSTrusteeSalesProgress
    // ViolationChart
    // DelinquencyDetailLienAndDemand
    // DelinquencySummary
    // DelinquencyDetailCheckboxSummary
    // PaidInvoices
    // BalanceWithCategoriesByResident
    // ComplianceDetail

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    getGeneralLedgerToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.GeneralLedgerGUID)
    }

    getFAQToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.FaqGUID)
    }
    
    getResidentMoveInToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.ResidentMoveInGUID)
    }

    getResidentDirectoryToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.ResidentDirectoryGUID)
    }

    getBodAndCommitteeDirectoryToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.BodAndCommitteeDirectoryGUID)
    }

    getCommunityVendorListToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.CommunityVendorListGUID)
    }

    getCurrentBalancesWithAgingByResidentToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.CurrentBalancesWithAgingByResidentGUID)
    }

    getArchitecturalStatusCSVExportToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.ArchitecturalStatusCSVExportGUID)
    }

    getVMSTrusteeSalesProgressToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.VMSTrusteeSalesProgressGUID)
    }

    getViolationChartToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.ViolationChartGUID)
    }

    getDelinquencyDetailLienAndDemandToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.DelinquencyDetailLienAndDemandGUID)
    }

    getDelinquencySummaryToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.DelinquencySummaryGUID)
    }

    getDelinquencyDetailCheckboxSummaryToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.DelinquencyDetailCheckboxSummaryGUID)
    }

    getPaidInvoicesToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.PaidInvoicesGUID)
    }

    getBalanceWithCategoriesByResidentToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.BalanceWithCategoriesByResidentGUID)
    }

    getComplianceDetailToken = async (): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getGuestPassByCompanyKey(this.ComplianceDetailGUID)
    }

    getReportUrl = ():string => {
        return this._api.ReportAndDashboardClient.reportAndDashboardUrl
    }

    getReportUrl2 = async (property:string, reportid:string): Promise<string | null> => {
        return await this._api.ReportAndDashboardClient.getReportUrl2(property, reportid)
    }

}
export default ReportStore