import React from "react";
import { Table } from "antd";

const calculateColumnWidth = (text, padding = 16) => {
  return text?.length * 8 + padding;
};

const generateColumnsWithWidth = (columns, data) => {
  if (!Array.isArray(data)) {
    console.error("Data is not an array");
    return columns; // or return empty array, depending on requirements
  }

  return columns.map((column) => {
    const longestValue = data.reduce((max, row) => {
      const value = row[column.dataIndex];
      return value && value?.length > max?.length ? value : max;
    }, column.title || "");

    const width = calculateColumnWidth(longestValue);
    return { ...column, width, className: "custom-column-class" };
  });
};

const DynamicTable = ({ columns, data, onRowClick, onActionClick }) => {
  const columnsWithWidth = generateColumnsWithWidth(columns, data);

  // Modify the onRowClick functionality
  const handleRowClick = (record) => {
    if (onRowClick) {
      onRowClick(record);
    }
  };

  // Add event handlers for actions
  const enhancedColumns = columnsWithWidth.map((col) => ({
    ...col,
    render: (text, record) => {
      if (col.dataIndex === "Actions" && onActionClick) {
        return (
          <div>
            {col.actions.map((action, idx) => (
              <button
                key={idx}
                className="btn btn-link"
                onClick={() => onActionClick(action.type, record)}
              >
                {action.label}
              </button>
            ))}
          </div>
        );
      }
      return col.render ? col.render(text, record) : text;
    },
  }));

  return (
    <Table
    scroll={{ x: 400 }}
      columns={enhancedColumns}
      dataSource={data}
      pagination
      rowKey="id"
      bordered
      onRow={(record) => ({
        onClick: () => handleRowClick(record),
      })}
    />
  );
};

export default DynamicTable;
