import { observer } from "mobx-react-lite";
import { Space, Button, Input, Flex } from 'antd';
import { SearchOutlined, CaretLeftFilled } from '@ant-design/icons';
import FileExplorerList, { FileItem } from "../../../components/FileExplorer/FileExplorerList";
import { useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import useBreakpoint from "use-breakpoint";
import PropertyDocumentsColumns from "./PropertyDocumentsColumns";
import { PropertyDocumentItem } from "../../../api/Association/AssociationTypes";

export type PropertyDocumentFileItem = (PropertyDocumentItem & FileItem);

const PropertyDocuments = observer(() => {
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const { propertyDocumentsStore, modalViewerStore } = useStore();
    const [files, setFiles] = useState<PropertyDocumentFileItem[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<PropertyDocumentFileItem[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (searchText === "") {
            setFilteredFiles([]);
            return;
        }
        let filteredItems = files.filter(i=>i.localName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        let newFilteredFiles: PropertyDocumentFileItem[] = filteredItems.map((data: PropertyDocumentItem): PropertyDocumentFileItem => ({
            ...data,
            isFolder: false,
            modified: new Date(data.modified),
        }));
        setFilteredFiles(newFilteredFiles);
    }, [searchText])

    useEffect(() => {
        setSearchText("");
    }, [propertyDocumentsStore.currentDirectory])

    useEffect(() => {
        setIsLoading(true);
        propertyDocumentsStore.getPropertyDocuments().then((res)=>{
            setFiles(res);
            setIsLoading(false)
        })
    }, [])


    const onRow = (record:PropertyDocumentFileItem, rowIndex: number) => {
        return {
          onClick: (event: MouseEvent) => {
            event.stopPropagation();
            openFolderOrFile(record);
          }, // click row
          //onDoubleClick: (event: MouseEvent) => {
          //  openFolderOrFile(record.localName)
          //}, // double click row
          //onContextMenu: (event) => {console.log(event,rowIndex,record)}, // right button click row
          //onMouseEnter: (event) => {console.log(event,rowIndex,record)}, // mouse enter row
          //onMouseLeave: (event) => {console.log(event,rowIndex,record)}, // mouse leave row
        };
    }

    const openFolderOrFile = (fileItem: PropertyDocumentFileItem, isBack: boolean = false) => {
        setIsLoading(true);
        propertyDocumentsStore.getFile(fileItem.fullPath)
            .then(res => {
                modalViewerStore.openFileByData(res, fileItem.localName);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
            });
    }

    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{padding:10}}>
            <div>
                <Space direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Property Documents</h2>
                    <Input value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px' }}/>
                </Space>
            </div>
        </Flex>
        <FileExplorerList data={searchText !== "" ? filteredFiles : files} onRowFunc={onRow} isLoading={isLoading} columns={PropertyDocumentsColumns}/>
    </>
})

export default PropertyDocuments