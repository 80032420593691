import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";

class BoardDocumentsStore {

    disableFirstSearch: boolean = false
    isLoading: boolean = false
    isSearching: boolean = false
    forceUpdate: boolean = false
    searchText: string ="";
    currentDirectory: string = "";
    previousDirectory: Array<{name:string, date:number, searchText:string}> = [];

    breadCrumbs: Array<string | null> = []

    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api
    }

    setLatestDocumentName() {
        const sortedDocuments = this.previousDirectory.slice().sort((a, b) => b.date - a.date);
        if (sortedDocuments.length > 0) {
            this.searchText = sortedDocuments[0].searchText;
            this.currentDirectory = sortedDocuments[0].name;
            const newArray = sortedDocuments.slice(1);
            this.previousDirectory = newArray
            this.breadCrumbs = [...this.previousDirectory.slice().sort((a, b) => b.date - a.date).map(i=>i.name),sortedDocuments[0].name];
        }
    }

    getLatestDocumentName(): {name:string, date:number, searchText:string} | undefined {
        const sortedDocuments = this.previousDirectory.slice().sort((a, b) => b.date - a.date);
        if (sortedDocuments.length > 0) {
            return sortedDocuments[0];
        }
    }

    setPreviewDirectory(){
        const sortedDocuments = this.previousDirectory.slice().sort((a, b) => b.date - a.date);
        if (sortedDocuments.length > 0) {
            const previewDirectory = sortedDocuments[0];
            const sortedArray = this.previousDirectory.slice().sort((a, b) => b.date - a.date);
            const newArray = sortedArray.slice(1);
            this.previousDirectory = newArray
            // this.disableFirstSearch = true;
            this.disableFirstSearch = this.currentDirectory !== previewDirectory.name;
            this.currentDirectory = previewDirectory.name;
            this.breadCrumbs = [...this.previousDirectory.slice().sort((a, b) => b.date - a.date).map(i=>i.name),previewDirectory.name];
            this.searchText = previewDirectory.searchText;
        }
    }

    editLoading = (loading: boolean) => {
        this.isLoading = loading
    }

    editSearching = (isSearching: boolean) => {
        this.isSearching = isSearching
    }

    editCurrentDirectory = (str: string) => {
        this.currentDirectory = str
    }

    setSearchText = (str: string) => {
        this.searchText = str
    }

    setDisableFirstSearch = (disableFirstSearch: boolean) => {
        this.disableFirstSearch = disableFirstSearch
    }

    setDirectory = (fileOrFolder: string | null) => {
        this.previousDirectory = [{name: this.currentDirectory, date:Date.now(), searchText: this.searchText}, ...this.previousDirectory]
        this.currentDirectory = fileOrFolder == null ? "" : fileOrFolder
        this.breadCrumbs = [...this.previousDirectory.slice().sort((a, b) => b.date - a.date).map(i=>i.name),fileOrFolder];
        this.forceUpdate = !this.forceUpdate;
        if(this.searchText !== "")
        {
            this.isSearching = true;
        }
        this.disableFirstSearch = true;
        this.searchText = "";
    }
}
export default BoardDocumentsStore