import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import { Button, Flex, Spin, Table } from "antd";
import {  useNavigate } from "react-router-dom";
import { DateSort, TextSort } from "../../../helpers/SorterHelper";
import { dateValidation } from "../../../helpers/dateValidation";
import { MaintenanceRequestHistoryItem } from "../../../api/Forms/FormsTypes";

const MaintenanceRequestHistory = observer((): ReactElement => {
  const { maintenanceRequestStore } = useStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (!(maintenanceRequestStore.maintenanceHistory?.length > 0)) {
      setIsLoading(true);
      maintenanceRequestStore.getHistory().then(() => {
        setIsLoading(false);
      }).catch(() => {
        setIsLoading(false);
      })
    }
  }, []);

  const columns = [
    {
      key: "description",
      title: "Description",
      dataIndex: "description",
      width: "60%",
      sorter: (a: MaintenanceRequestHistoryItem, b: MaintenanceRequestHistoryItem) => TextSort(a, b, "description"),
      render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
    {
      key: "created",
      title: "Created Date",
      dataIndex: "created",
      width: "20%",
      sorter: (a: MaintenanceRequestHistoryItem, b: MaintenanceRequestHistoryItem) => DateSort(a, b, "created"),
      render: (date: any) => dateValidation(date)
    },
    {
      key: "status",
      title: "Status",
      dataIndex: "status",
      width: "20%",
      sorter: (a: MaintenanceRequestHistoryItem, b: MaintenanceRequestHistoryItem) => TextSort(a, b, "status")
    },
  ];

  return (
    <Spin spinning={isLoading}>
      <Flex vertical={true} gap={10}>
        <Flex gap={20} align="center">
          <Button
            size="middle"
            type="primary"
            onClick={() => {
              navigate("/Forms/MaintenanceRequest");
            }}
          >
            Back
          </Button>
          <h1>Maintenance Request History</h1>
        </Flex>
        <Table 
        dataSource={maintenanceRequestStore.maintenanceHistory?.map((el, index) => { return { ...el, key: index } })} 
        pagination={false} 
        columns={columns} 
        />
      </Flex>
    </Spin>
  );
});

export default MaintenanceRequestHistory;
