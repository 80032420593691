import { observer } from "mobx-react-lite";
import { Outlet } from "react-router-dom";
import { Layout, Skeleton, Spin, theme } from "antd";
import useBreakpoint from "use-breakpoint";
import { useStore } from "../../../root-store-context";
import SideBar from "./SideBar";
import NavBar from "./NavBar";
import { ModalFileViewer } from "../../ModalFileViewer/ModalFileViewer";
import "./main.css";
import { useEffect, useState } from "react";

const { Header, Content, Footer, Sider } = Layout;

const Main = observer(() => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const BREAKPOINTS = { mobile: 0, desktop: 768 };
  const { breakpoint } = useBreakpoint(BREAKPOINTS, "mobile");
  const { breakpoint: breakpointSidebar } = useBreakpoint({
    mobile: 0,
    desktop: 992,
  });
  const { applicationStore } = useStore();
  const { modalViewerStore } = useStore();
  const [collapsed, setCollapsed] = useState<boolean>(
    breakpointSidebar === "mobile"
  );

  const [isRenderOutlet, setIsRenderOutlet] = useState(false);

  useEffect(() => {
    if (!applicationStore.isLoadingCommunity) {
      setTimeout(() => {
        setIsRenderOutlet(true);
      }, 100);
    } else {
      setIsRenderOutlet(false);
    }
  }, [applicationStore.isLoadingCommunity]);
  const renderContent = () => (
    <Content
      style={{
        display: "table",
        marginTop: "24px",
        marginLeft: breakpoint === "desktop" ? 16 : 0,
        marginRight: breakpoint === "desktop" ? 16 : 0,
      }}
    >
      <div
        style={{
          padding: breakpoint === "desktop" ? 24 : 8,
          minHeight: 360,
          height: "100%",
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        {!applicationStore.isLoadingCommunity && isRenderOutlet ? (
          <>
            <Outlet />
          </>
        ) : (
          <div>
            <Skeleton active />
            <Skeleton active />
          </div>
        )}
      </div>
    </Content>
  );

  return (
    <>
      <Spin
        fullscreen
        size="large"
        spinning={applicationStore.isLoadingCommunity}
      />
      <Layout className="main-layout">
        <Sider
          className="main-sidebar"
          width="300px"
          breakpoint="lg"
          collapsed={collapsed}
          collapsedWidth="0"
          onBreakpoint={(broken) => {
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            setCollapsed(collapsed);
          }}
        >
          <SideBar
            collapsed={collapsed}
            onCollapsed={() => {
              setCollapsed(breakpointSidebar === "mobile");
            }}
          />
        </Sider>
        <Layout
          className="content-layout"
          onClick={() => {
            setCollapsed(breakpointSidebar === "mobile");
          }}
        >
          <Header
            style={{
              background: colorBgContainer,
              paddingLeft: 16,
              paddingRight: 16,
              display: "grid",
              alignItems: "center",
            }}
          >
            <NavBar />
          </Header>
          {renderContent()}
          <Footer style={{ textAlign: "center" }}>
            AAM, LLC ©{new Date().getFullYear()}
          </Footer>
        </Layout>
      </Layout>
      <ModalFileViewer
        isOpenModal={modalViewerStore.isOpenModal}
        dismissModal={modalViewerStore.dissmissModal}
        urlModal={modalViewerStore.urlModal}
        isImage={modalViewerStore.isLocalFileForIframe}
        documentName={modalViewerStore.documentName}
      />
    </>
  );
});

export default Main;
