import React from 'react';
import { App, Button, Col, Divider, Form, Input, Row, Image, Space, Flex, Spin, Select } from 'antd';
import maintenance from "../../images/pageIcons/Forms/FeeWaiver.png";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../root-store-context';
import Title from 'antd/es/typography/Title';
import { getPhoneFormat } from '../../helpers/phoneFormat';
import TextArea from 'antd/es/input/TextArea';
import { validateMessages } from '../../helpers/validationMessages';
import { FeeTypeItems, FeeWaiverItem } from '../../api/Forms/FormsTypes';

const FeeWaiver = observer(() => {

    const [form] = Form.useForm();
    const { applicationStore, feeWaiverStore } = useStore();
    const { message } = App.useApp();

    const success = () => {
        message.open({
            type: 'success',
            content: 'Fee Waiver saved successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                feeWaiverStore.setSubmittableForm(true);
            },
            () => {
                feeWaiverStore.setSubmittableForm(false);
            },
        );
    }, [values]);

    React.useEffect(() => {
        if (feeWaiverStore.shouldResetForm) {
            form.resetFields();
            feeWaiverStore.shouldResetForm = false;
        }
    }, [feeWaiverStore.shouldResetForm]);

    React.useEffect(() => {
        resetForm();
    }, []);

    const resetForm = async () => {
        await feeWaiverStore.resetForm();
        form.resetFields();
    };

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );

    const onSubmit = async () => {
        feeWaiverStore.editLoading(true);
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'Saving'
        });

        feeWaiverStore.setDaytimePhone(feeWaiverStore.feeWaiverItem.DaytimePhone.replace(/\D/g, ''))
        feeWaiverStore.feeWaiverItem.UserId = applicationStore.SelectedAddress.userId.toString();
        feeWaiverStore.addFeeWaiverItem(applicationStore.SelectedAddress, applicationStore.SelectedAddress.userId.toString()).then((res) => {
            console.log(res)
            if (res) {
                message.destroy("Saving");
                success()
                resetForm();
            }
            else {
                message.destroy("Saving");
                error()
            }
        })
    }

    return (
        <Spin spinning={feeWaiverStore.isLoading}>
            <Row>
                <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Flex align='center' wrap='wrap' justify='space-between'>
                        <Space style={{ flex: "1 1 400px" }}>
                            <Image src={maintenance} preview={false} height={25}/>
                            <Title level={2} style={{ marginTop: "10px" }}>Fee Waiver</Title>
                        </Space>

                    </Flex>
                    <Form
                        form={form}
                        variant='outlined'
                        layout="vertical"
                        initialValues={{
                            ...feeWaiverStore.feeWaiverItem
                        }}
                        labelCol={{ span: 24 }}
                        style={{ maxWidth: 900 }}
                        validateMessages={validateMessages}
                        onValuesChange={(changedValues, allValues) => feeWaiverStore.feeWaiverItem = { ...allValues }}
                        requiredMark={customizeRequiredMark}
                        autoComplete="off"
                        onFinish={onSubmit}
                        scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item<FeeWaiverItem> name="UserId" hidden>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <p>
                                    Prior to submitting your request, your HOA assessments must be current.
                                    Once we have received your fee/fine waiver request, it will be forwarded to your Board of Directors for consideration. This request does not guarantee or approve the waiver of any fees/fines by your Board of Directors. Your association Board of Directors will review your request at their next scheduled board meeting. Please be aware their meeting schedule can vary so a decision may take between 30 – 90 days.
                                    Once a decision has been made, you will be notified in writing of the decision by your Community Manager.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<FeeWaiverItem> label="Fee Type" name="FeeType" rules={[{ required: true }]}>
                                    <Select options={Object.values(FeeTypeItems).filter(value => typeof value === 'string').map((value) => ({
                                        value,
                                        label: value
                                    }))} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<FeeWaiverItem> label="Amount" name={"Amount"} style={{ width: "100%" }}
                                    rules={[
                                        {
                                            validator: (_, value) => {
                                                if (/^(\d+(\.\d{1,2})?)$/.test(value)) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('Please enter a valid value');
                                            },
                                        }
                                    ]}>
                                    <Input prefix="$" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<FeeWaiverItem>
                                    label="Daytime Phone"
                                    name={"DaytimePhone"}
                                    style={{ width: "100%" }}
                                    rules={[{ required: true, message: 'Please input your daytime phone!', min: 12 }]}>
                                    <Input onChange={(event) => {
                                        const { value } = event.target;
                                        const formattedValue = getPhoneFormat(value);
                                        form.setFieldsValue({
                                            DaytimePhone: formattedValue,
                                        });

                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<FeeWaiverItem> label="Email Address" name="EmailAddress" style={{ width: "100%" }} rules={[{ required: true, type: "email" }]} >
                                    <Input pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<FeeWaiverItem> label="Reason For Request" name="ReasonForRequest" rules={[{ required: true }]}>
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right', marginTop: "16px" }}>
                                <Space>
                                    <Button size="middle" onClick={resetForm}>
                                        Clear
                                    </Button>
                                    <Button size="middle" type="primary" htmlType="submit">Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Spin>
    )
})

export default FeeWaiver;