import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { ContactsAndLinksItem } from "../../../api/Association/AssociationTypes";

class ContactsAndLinksStore {
    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    public async getContactsAndLinks(): Promise<ContactsAndLinksItem> {
        return this._api.AssociationApiClient.getContactsAndLinks();
    }
}
export default ContactsAndLinksStore