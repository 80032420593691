import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { MailArchiveFileItem } from "../../../pages/AccountSummaryGroup/MailArchive/MailArchive";


class MailArchiveStore {
    currentDirectory: MailArchiveFileItem | null = null;
    previousDirectory: Array<{folder:MailArchiveFileItem | null, date:number}> = [];
    breadCrumbs: Array<MailArchiveFileItem | null> = []

    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }
    
    getLatestDocumentName(): MailArchiveFileItem | null {
        const sortedDocuments = this.previousDirectory.slice().sort((a, b) => b.date - a.date);
        if (sortedDocuments.length > 0) {
            return sortedDocuments[0].folder;
        } else {
            return null;
        }
    }

    setDirectory = (folder: MailArchiveFileItem | null, isBack: boolean = false) => {
        if(isBack){
            const sortedArray = this.previousDirectory.slice().sort((a, b) => b.date - a.date);
            const newArray = sortedArray.slice(1);
            this.previousDirectory = newArray
        }else{
            this.previousDirectory = [{folder: this.currentDirectory, date:Date.now()}, ...this.previousDirectory]
        }
        this.currentDirectory = folder
        this.breadCrumbs = [...this.previousDirectory.slice().sort((a, b) => b.date - a.date).map(i=>i.folder),folder];
    }
}
export default MailArchiveStore