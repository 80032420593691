import { Button, Col, Divider, List, Row, Select, Table } from "antd";
import Column from "antd/es/table/Column";
import dayjs from 'dayjs';
import { SearchOutlined } from '@ant-design/icons';
import { useEffect, useState } from "react";
import Compliance, { ComplianceItem } from "./Compliance";

export interface CompliancesProps {
    dataSource: ComplianceItem[];
}

const Compliances: React.FC<CompliancesProps> = ({ dataSource }) => {
    const [status, setStatus] = useState<string>("all");
    const [filteredDataSource, setFilteredDataSource] = useState<ComplianceItem[]>([]);
    useEffect(() => {
        switch (status) {
            case "all":
                setFilteredDataSource(dataSource);
                break;
            case "opened":
                setFilteredDataSource(dataSource.filter((i) => i?.active));
                break;
            case "closed":
                setFilteredDataSource(dataSource.filter((i) => !i?.active));
                break;
        }
    }, [status])
    return (
        <List
            dataSource={filteredDataSource}
            header={
                <Select
                    defaultValue={status}
                    style={{ width: 300, marginBottom: 10 }}
                    onChange={(value: string) => setStatus(value)}
                    options={[
                        { value: 'all', label: 'All' },
                        { value: 'opened', label: 'Opened' },
                        { value: 'closed', label: 'Closed', },
                    ]}
                />}
            renderItem={(item => <>
                <Compliance compliance={item} />
            </>)} />
    );
};

export default Compliances