import { Col, Row, Space, Image, Spin, Result, Form, Input, DatePicker, Card, Switch, App, Button, Skeleton } from 'antd';
import { observer } from 'mobx-react-lite';
import Title from 'antd/es/typography/Title';
import { useStore } from '../../../root-store-context';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import TextArea from 'antd/es/input/TextArea';
import announcementIcon from "../../../images/AnnouncementIcon.png"
import { Typography } from 'antd';
import { getPhoneFormat } from '../../../helpers/phoneFormat';
import { DirectoryProfile, ResidentProfileProxy } from '../../../api/Association/AssociationTypes';

const { Text } = Typography;

const MyProfile = observer(() => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { announcementId } = useParams();
    const [isNotFound, setIsNotFound] = useState<boolean | undefined>();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [profile, setProfile] = useState<DirectoryProfile>({} as DirectoryProfile);
    const { residentDirectoryStore, applicationStore } = useStore();
    const {message} = App.useApp();
    useEffect(() => {
        form.resetFields();
    }, [profile])

    useEffect(() => {
        loadProfile();
    }, [])

    const loadProfile = async () => {
        var profile = await residentDirectoryStore.getCommunityResidentDirectoryProfile();
        if (profile != null) {
            profile.homePhone = getPhoneFormat(profile.homePhone);
            profile.cellPhone = getPhoneFormat(profile.cellPhone);
            setProfile(profile);
            form.resetFields();
        }
        setIsNotFound(profile == null);
    }

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500, display:"contents" }}>{label}</span>
        </>
    );

    const success = () => {
        message.open({
            type: 'success',
            content: 'Profile updated!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during updating. Please check the entered data.',
        });
    };

    const onSubmit = async (formProfile:DirectoryProfile) =>{
        setIsLoading(true);
        var newProfile:ResidentProfileProxy = {
            ResidentKey: applicationStore.SelectedAddress.residentKey,
            Email: applicationStore.ApiStore.configApi.userEmail,
            NewEmail: formProfile.email,
            CellPhone: formProfile.cellPhone.replace(/\D/g, ''),
            HomePhone: formProfile.homePhone.replace(/\D/g, ''),
            ListingVisible: formProfile.listingVisible,
            EmailVisible: formProfile.emailVisible,
            CellPhoneVisible: formProfile.cellPhoneVisible,
            HomePhoneVisible: formProfile.homePhoneVisible,
            Nickname: formProfile.nickname
        };
        var res = await residentDirectoryStore.updateCommunityResidentDirectoryProfile(newProfile);
        setIsLoading(false);
        if (res) {
            success()
            navigate('/ResidentDirectory');
        }
        else {
            error()
        }
    }

    if (isNotFound === undefined) {
        return (<Spin spinning={true}><div style={{height:"50vh"}}></div></Spin>);
    }

    if (isNotFound) {
        return <Result status="404" title="The profile does not exist" />
    }
    
    return (<>
        <Spin spinning={isLoading}>
            <Row>
                <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Space>
                        {/* <Image src={announcementIcon} width='3em' preview={false} /> */}
                        <Title level={2} style={{ marginTop: "10px" }}>My Directory Profile</Title>
                    </Space>
                    <Form
                        form={form}
                        variant='outlined'
                        layout="vertical"
                        initialValues={profile}
                        labelCol={{ span: 24 }}
                        style={{ maxWidth: 900 }}
                        // onValuesChange={(changedValues, allValues) => setProfile(allValues)}
                        // validateMessages={validateMessages}
                        autoComplete="off"
                        onFinish={onSubmit}
                        requiredMark={customizeRequiredMark}
                        scrollToFirstError={{ block: 'center', inline: 'nearest' }}>

                        <Form.Item<DirectoryProfile> name="address" hidden >
                            <Input />
                        </Form.Item>
                        <Form.Item<DirectoryProfile> name="name" hidden >
                            <Input />
                        </Form.Item>

                        <Row>
                            <Col span={19}>
                                <Form.Item<DirectoryProfile> label="Name and Address">
                                    <div>
                                        <Text>{profile.name}</Text>
                                        <br />
                                        <Text>{profile.address}</Text>
                                    </div>
                                </Form.Item>
                            </Col>

                            <Col offset={1} span={4}>
                                <Form.Item<DirectoryProfile> label="Public" name="listingVisible">
                                    <Switch />
                                </Form.Item>    
                            </Col>
                        </Row>
                        <Row >
                            <Col span={19}>
                                <Form.Item<DirectoryProfile> label="Nickname" name="nickname">
                                    <Input style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={19}>
                                <Form.Item<DirectoryProfile> label="Email" name="email" rules={[{ required: true, type: "email" }]}>
                                    <Input style={{ width: "100%" }} pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$"/>
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={4}>
                                <Form.Item<DirectoryProfile> label=" " name="emailVisible">
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={19}>
                                <Form.Item<DirectoryProfile> label="Cell" name="cellPhone" rules={[{ required: true, message: 'Please input your phone!', min: 12 }]}>
                                    <Input style={{ width: "100%" }} onChange={(event) => {
                                        const { value } = event.target;
                                        const cleanedValue = value.replace(/\D/g, '');
                                        let cellPhone = '';
                                        if (cleanedValue.length <= 3) {
                                            cellPhone = cleanedValue;
                                        } else if (cleanedValue.length <= 6) {
                                            cellPhone = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3)}`;
                                        } else {
                                            cellPhone = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 10)}`;
                                        }
                                        form.setFieldsValue({
                                            cellPhone: cellPhone,
                                        });
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={4}>
                                <Form.Item<DirectoryProfile> label=" " name="cellPhoneVisible">
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={19}>
                                <Form.Item<DirectoryProfile> label="Home" name="homePhone" rules={[{ required: true, message: 'Please input your phone!', min: 12 }]}>
                                    <Input style={{ width: "100%" }} onChange={(event) => {
                                        const { value } = event.target;
                                        const cleanedValue = value.replace(/\D/g, '');
                                        let homePhone = '';
                                        if (cleanedValue.length <= 3) {
                                            homePhone = cleanedValue;
                                        } else if (cleanedValue.length <= 6) {
                                            homePhone = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3)}`;
                                        } else {
                                            homePhone = `${cleanedValue.slice(0, 3)}-${cleanedValue.slice(3, 6)}-${cleanedValue.slice(6, 10)}`;
                                        }
                                        form.setFieldsValue({
                                            homePhone: homePhone,
                                        });
                                    }} />
                                </Form.Item>
                            </Col>
                            <Col offset={1} span={4}>
                                <Form.Item<DirectoryProfile> label=" " name="homePhoneVisible">
                                    <Switch />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 22}} xs={{ span: 24 }} style={{ textAlign: 'right', marginTop: "16px" }}>
                                <Space>
                                    <Button size="middle" onClick={() => navigate('/ResidentDirectory')}>
                                        Cancel
                                    </Button>
                                    <Button size="middle" type="primary" htmlType="submit">Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Spin>
    </>
    )
})

export default MyProfile