import { Flex, Image } from "antd";
import dashboard from "../images/Dashboard.png";
import bv_logo from "../images/bv_logo.png";
import iconEStatements from "../images/pageIcons/AccountSummary/eStatements.png";
import iconResidentDirectory from "../images/pageIcons/Association/ResidentDirectory.png";

import {
  RouteElement,
  RouteGroupElement,
} from "../stores/appStores/ApplicationStore";
import MainPage from "../pages/MainPage";
import EStatements from "../pages/AccountSummaryGroup/EStatements";
import ResidentDirectory from "../pages/AssociationGroup/ResidentDirectory/ResidentDirectory";
import { Role } from "../api/Common/CommonTypes";
import MyProfile from "../pages/AssociationGroup/ResidentDirectory/MyProfile";
import ContactInfoChange from "../pages/FormsGroup/ContactInfoChange";
import AccountContacts from "../pages/AccountSummaryGroup/AccountContacts/AccountContacts";
import CreateContact from "../pages/AccountSummaryGroup/AccountContacts/CreateContact";
import EditContact from "../pages/AccountSummaryGroup/AccountContacts/EditContact";

const mainChildrenRoutes: RouteElement[] = [
  {
    key: "/",
    path: "",
    icon: <Flex><Image width={"1.2em"} src={dashboard} preview={false} /></Flex>,
    element: <MainPage />,
    featureID: null,
  },
  {
    key: "/eStatements",
    path: "/eStatements",
    icon: <Image width={"1.2em"} src={iconEStatements} preview={false} />,
    label: "Electronic Communication",
    element: <EStatements />,
    featureID: 4,
  },
  {
    key: "/ContactInfoChange",
    path: "/ContactInfoChange",
    // icon: <Flex><Image style={iconStyle} src={iconContactInfoChange} preview={false} /></Flex>,
    label: "Contact Info Change",
    element: <ContactInfoChange />,
    featureID: 20,
  },
  {
    key: "/AccountContacts",
    path: "/AccountContacts",
    // icon: <Flex><Image style={iconStyle} src={iconAccountSummary} preview={false} /></Flex>,
    label: "Account Contacts",
    element: <AccountContacts />,
    featureID: null,
  },
  {
    key: "/AccountContacts/Add",
    path: "/AccountContacts/Add",
    element: <CreateContact />,
    featureID: null,
  },
  {
    key: "/AccountContacts/Edit/:residentKey/:contactKey",
    path: "/AccountContacts/Edit/:residentKey/:contactKey",
    element: <EditContact />,
    featureID: null,
  },
];
const MainRoutes: RouteGroupElement[] = [
  {
    key: "/main",
    path: "/",
    icon: <Image width="34px" src={bv_logo} preview={false} />,
    label: null,
    children: mainChildrenRoutes,
    role: [Role.BoardMember, Role.Homeowner],
    className: "boardvue-elements",
  },

];

export default MainRoutes;
