import { Button, Result } from "antd"
import { useAuth } from "react-oidc-context";

const UserNotFound: React.FC = () => {
    const auth = useAuth();
    const onLogOut = () => {
        const args = {
            extraQueryParams: {
                post_logout_redirect_uri: process.env.REACT_APP_REDIRECT_URI as string
            }
        };
        void auth.signoutRedirect(args);
    }
    return (<Result status="404"
        title="User not found."
        extra={<Button onClick={onLogOut}>Log Out</Button>} />)
}

export default UserNotFound;