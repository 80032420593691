import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { PropertyDocumentFileItem } from "../../../pages/AssociationGroup/PropertyDocuments/PropertyDocuments";
import { PropertyDocumentItem } from "../../../api/Association/AssociationTypes";

class PropertyDocumentsStore {
    currentDirectory: PropertyDocumentFileItem | null = null;
    previousDirectory: Array<{folder:PropertyDocumentFileItem | null, date:number}> = [];
    breadCrumbs: Array<PropertyDocumentFileItem | null> = []

    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    getLatestDocumentName(): PropertyDocumentFileItem | null {
        const sortedDocuments = this.previousDirectory.slice().sort((a, b) => b.date - a.date);
        if (sortedDocuments.length > 0) {
            return sortedDocuments[0].folder;
        } else {
            return null;
        }
    }

    setDirectory = (folder: PropertyDocumentFileItem | null, isBack: boolean = false) => {
        if(isBack){
            const sortedArray = this.previousDirectory.slice().sort((a, b) => b.date - a.date);
            const newArray = sortedArray.slice(1);
            this.previousDirectory = newArray
        }else{
            this.previousDirectory = [{folder: this.currentDirectory, date:Date.now()}, ...this.previousDirectory]
        }
        this.currentDirectory = folder
        this.breadCrumbs = [...this.previousDirectory.slice().sort((a, b) => b.date - a.date).map(i=>i.folder),folder];
    }

    public async getPropertyDocuments(): Promise<PropertyDocumentItem[]> {
        const documents = await this._api.AssociationApiClient.getPropertyDocuments();
        return documents;
    }

    public async getFile(path: string): Promise<any> {
        return await this._api.AssociationApiClient.getFile(path);
    }
}
export default PropertyDocumentsStore