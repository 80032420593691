import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { useStore } from "../../root-store-context";
import useBreakpoint from "use-breakpoint";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Flex, Result, Space, Spin } from "antd";

const RecreationAndTickets = observer((): ReactElement => {
    const { applicationStore, recreationAndTicketsStore } = useStore();
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [isActive, setIsActive] = useState<boolean>(true);
    const [src, setSrc] = useState<string>("");

    useEffect(() => {
        recreationAndTicketsStore.getIframeLink().then((res) => {
            console.log(res)
            if (res != null) {
                setSrc(res);
                window.open(res, "_blank", "noopener,noreferrer")
            } else {
                setIsActive(false);
            }
            setIsLoading(false);
        }).catch(error=>{
            setSrc("");
            setIsLoading(false);
            console.log(error);
        })
    }, [])

    if (!isActive) {
        return <Result status="404" title="No active guest passes" />
    }
    
    return (
        <Spin spinning={isLoading}>
            <Flex justify="space-between" wrap="wrap" align="center" gap="middle" style={{ padding: 10 }}>
                <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
                    <Space style={{ flexWrap: "wrap" }}>
                        <h2 style={{ width: "280px" }}>Recreation and Tickets</h2>
                    </Space>
                </div>
            </Flex>
            <Button type="primary" disabled={!src} onClick={()=>window.open(src, "_blank", "noopener,noreferrer")}>Open in new Tab</Button>
            {/* <iframe src={src} style={{ border: "none", width: "100%", height: breakpoint === "desktop" ? "68vh" : "70vh" }}></iframe> */}
        </Spin>
    )
})

export default RecreationAndTickets;