import React, { useState } from 'react';
import { App, Button, Col, Divider, Form, Input, Row, Image, Space, Flex, Spin, Result } from 'antd';
import contactInfoChange from "../../images/pageIcons/Forms/ContactInfoChange.png";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../root-store-context';
import Title from 'antd/es/typography/Title';
import { getPhoneFormat } from '../../helpers/phoneFormat';
import { validateMessages } from '../../helpers/validationMessages';
import { ContactInfoChangeItem, ContactMethod } from '../../api/Forms/FormsTypes';

const ContactInfoChange = observer(() => {

    const [form] = Form.useForm();
    const { applicationStore, contactInfoChangeStore } = useStore();
    const { message } = App.useApp();
    const [isActive, setIsActive] = useState<boolean>(true);

    const success = () => {
        message.open({
            type: 'success',
            content: 'Contact Info saved successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                contactInfoChangeStore.setSubmittableForm(true);
            },
            () => {
                contactInfoChangeStore.setSubmittableForm(false);
            },
        );
    }, [values]);

    React.useEffect(() => {
        if (contactInfoChangeStore.shouldResetForm) {
            form.resetFields();
            contactInfoChangeStore.shouldResetForm = false;
        }
    }, [contactInfoChangeStore.shouldResetForm]);

    React.useEffect(() => {
        contactInfoChangeStore.editLoading(true);
        contactInfoChangeStore.getOwnerContactInfo().then((result) => {
            console.log(result)
            if (result !== null) {
                if (result.contactMethods.some(_ => _.type === "Home")) {
                    contactInfoChangeStore.contactInfoChangeItem.DaytimePhone = result.contactMethods.find((el: ContactMethod) => el.type === "Home")?.contactNumber || "";
                }
                if (result.contactMethods.some(_ => _.type === "Mobile")) {
                    contactInfoChangeStore.contactInfoChangeItem.CellPhone = result.contactMethods.find((el: ContactMethod) => el.type === "Mobile")?.contactNumber || "";
                }
                if (result.contactMethods.some(_ => _.type === "Email")) {
                    contactInfoChangeStore.contactInfoChangeItem.EmailAddress = result.contactMethods.find((el: ContactMethod) => el.type === "Email")?.contactNumber || "";
                }

                contactInfoChangeStore.contactInfoChangeItem.NewAddress = result.contactAddress.mailingAddress1;
                contactInfoChangeStore.contactInfoChangeItem.NewAddress2 = result.contactAddress.mailingAddress2;
                contactInfoChangeStore.contactInfoChangeItem.NewCity = result.contactAddress.mailingCity;
                contactInfoChangeStore.contactInfoChangeItem.NewState = result.contactAddress.mailingRegion;
                contactInfoChangeStore.contactInfoChangeItem.NewZip = result.contactAddress.mailingPostalCode;

                form.setFieldsValue({
                    ...contactInfoChangeStore.contactInfoChangeItem,
                    DaytimePhone: getPhoneFormat(contactInfoChangeStore.contactInfoChangeItem.DaytimePhone),
                    CellPhone: getPhoneFormat(contactInfoChangeStore.contactInfoChangeItem.CellPhone)
                });
            } else {
                setIsActive(false);
            }
            contactInfoChangeStore.editLoading(false);
        })
    }, []);

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );

    const onSubmit = async () => {
        contactInfoChangeStore.editLoading(true);
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'Saving'
        });

        contactInfoChangeStore.setDaytimePhone(contactInfoChangeStore.contactInfoChangeItem.DaytimePhone.replace(/\D/g, ''))
        contactInfoChangeStore.setCellPhone(contactInfoChangeStore.contactInfoChangeItem.CellPhone.replace(/\D/g, ''))
        contactInfoChangeStore.contactInfoChangeItem.UserId = applicationStore.SelectedAddress.userId.toString();
        contactInfoChangeStore.addContactInfoChangeItem(applicationStore.SelectedAddress, applicationStore.SelectedAddress.userId.toString()).then((res) => {
            if (res) {
                message.destroy("Saving");
                success()
            }
            else {
                message.destroy("Saving");
                error()
            }
        })
    }

    if (!isActive) {
        return <Result status="404" title="There is no data for current resident." />
    }

    return (
        <Spin spinning={contactInfoChangeStore.isLoading}>
            <Row>
                <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Flex align='center' wrap='wrap' justify='space-between'>
                        <Space style={{ flex: "1 1 400px" }}>
                            <Image src={contactInfoChange} preview={false} height={25}/>
                            <Title level={2} style={{ marginTop: "10px" }}>Contact Info Change</Title>
                        </Space>

                    </Flex>
                    <Form
                        form={form}
                        variant='outlined'
                        layout="vertical"
                        initialValues={{
                            ...contactInfoChangeStore.contactInfoChangeItem
                        }}
                        labelCol={{ span: 24 }}
                        style={{ maxWidth: 900 }}
                        validateMessages={validateMessages}
                        onValuesChange={(changedValues, allValues) => contactInfoChangeStore.contactInfoChangeItem = { ...allValues }}
                        requiredMark={customizeRequiredMark}
                        autoComplete="off"
                        onFinish={onSubmit}
                        scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item<ContactInfoChangeItem> name="UserId" hidden>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <p>
                                    Below is the information we have on file for you. Update the needed field and tap Save.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Divider orientation="left" orientationMargin="0">
                                Personal Information
                            </Divider>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<ContactInfoChangeItem>
                                    label="Home Phone"
                                    name={"DaytimePhone"}
                                    style={{ width: "100%" }}
                                    rules={[{ required: false, message: 'Please input your daytime phone!', min: 12 }]}>
                                    <Input onChange={(event) => {
                                        const { value } = event.target;
                                        const formattedValue = getPhoneFormat(value);
                                        form.setFieldsValue({
                                            DaytimePhone: formattedValue,
                                        });

                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<ContactInfoChangeItem>
                                    label="Cell Phone"
                                    name={"CellPhone"}
                                    style={{ width: "100%" }}
                                    rules={[{ required: false, message: 'Please input your daytime phone!', min: 12 }]}>
                                    <Input onChange={(event) => {
                                        const { value } = event.target;
                                        const formattedValue = getPhoneFormat(value);
                                        form.setFieldsValue({
                                            CellPhone: formattedValue,
                                        });

                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<ContactInfoChangeItem> label="Email Address" name="EmailAddress" style={{ width: "100%" }}
                                    rules={[{ required: true, type: "email" },
                                    ]} >
                                    <Input pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Divider orientation="left" orientationMargin="0">
                                Communication Information
                            </Divider>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <p>
                                    Changing the mailing address will update the location where you receive mailed communication from us such as statements and compliance letters.
                                </p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<ContactInfoChangeItem> label="Address" name="NewAddress" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<ContactInfoChangeItem> label="Address 2" name="NewAddress2" rules={[{ required: false }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<ContactInfoChangeItem> label="City" name="NewCity" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<ContactInfoChangeItem> label="State" name="NewState" rules={[{ required: true }, {
                                    pattern: /^[a-zA-Z]{2}$/,
                                    message: 'State must contain exactly two alphabetical characters!'
                                }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<ContactInfoChangeItem> label="Zip Code" name="NewZip" rules={[{ required: true }, {
                                    pattern: /^.{5,7}$/,
                                    message: 'Zip Code must contain 5-7 symbols!'
                                }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right', marginTop: "16px" }}>
                                <Space>
                                    <Button size="middle" onClick={() => {
                                        contactInfoChangeStore.resetValue();
                                    }}>
                                        Clear
                                    </Button>
                                    <Button size="middle" type="primary" htmlType="submit">Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Spin>
    )
})

export default ContactInfoChange;