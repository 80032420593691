import React, { useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Row, Col, Card, List, Button, Typography } from "antd";
import DynamicTable from "../../../components/Table/Table"; // Import the DynamicTable component
import ModalComponent from "../../../components/Modal/Modal";
import "./SubmissionDetails.css"; // Import your CSS file

const { Title, Text } = Typography;

const SubmissionDetails = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const details = location.state?.record?.details; // Access the passed details
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedTenant, setSelectedTenant] = useState(null);
  console.log(details);
  if (!details) {
    return <div>No details available</div>;
  }

  // Define column configurations for each type
  const tenantColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Email", dataIndex: "email", key: "email" },
    { title: "Phone", dataIndex: "phone", key: "phone" },
    { title: "Address", dataIndex: "address", key: "address" },
    {
      title: "Violation",
      dataIndex: "violation",
      key: "violation",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Under 18",
      dataIndex: "under18",
      key: "under18",
      render: (text) => (text ? "Yes" : "No"),
    },
    {
      title: "Designation",
      dataIndex: "designation",
      key: "designation",
    },
    {
      title: "Emergency Contacts",
      dataIndex: "emergencyContacts",
      key: "emergencyContacts",
      render: (emergencyContacts, record) =>
        record.emergencyContacts.length > 0 ? (
          <Button
            style={{
              borderRadius: 19,
              border: "1px solid #5A7890",
              color: "#5A7890",
              backgroundColor: "#fff",
              height: "2rem",
              width: "7.2rem",
            }}
            className="light-btn-hover"
            onClick={() => handleViewContacts(record)}
          >
            View Contacts
          </Button>
        ) : (
          <span>No Contacts</span>
        ),
    },
  ];

  const vehicleColumns = [
    { title: "Make", dataIndex: "make", key: "make" },
    { title: "Model", dataIndex: "model", key: "model" },
    { title: "Year", dataIndex: "year", key: "year" },
    { title: "Color", dataIndex: "color", key: "color" },
    { title: "License", dataIndex: "license", key: "license" },
    { title: "State", dataIndex: "state", key: "state" },
    { title: "Spot", dataIndex: "spot", key: "spot" },
  ];

  const petColumns = [
    { title: "Name", dataIndex: "name", key: "name" },
    { title: "Type", dataIndex: "type", key: "type" },
    { title: "Breed", dataIndex: "breed", key: "breed" },
    {
      title: "Rabies Due Date",
      dataIndex: "rabiesDueDate",
      key: "rabiesDueDate",
    },
    { title: "License", dataIndex: "license", key: "license" },
    { title: "Age", dataIndex: "age", key: "age" },
  ];

  // Transform data for DynamicTable
  const transformTenants = details.tenants.map((tenant) => ({
    key: tenant.id,
    name: `${tenant.firstName} ${tenant.lastName}`,
    email: tenant.email,
    phone: tenant.phone,
    address: `${tenant.mailingAddress1}, ${tenant.city}, ${tenant.state}, ${tenant.zip}`,
    violation: tenant.violation,
    under18: tenant.under18,
    designation: tenant.designation,
    emergencyContacts: tenant.emergencyContacts,
  }));

  const transformVehicles = details.vehicles.map((vehicle) => ({
    key: vehicle.requestId,
    make: vehicle.make,
    model: vehicle.model,
    year: vehicle.year,
    color: vehicle.color,
    license: vehicle.license,
    state: vehicle.state,
    spot: vehicle.spot,
  }));

  const transformPets = details.pets.map((pet) => ({
    key: pet.name, // Use a unique key here if available
    name: pet.name,
    type: pet.type,
    breed: pet.breed,
    rabiesDueDate: pet.rabiesDueDate,
    license: pet.license,
    age: pet.age,
  }));

  const handleViewContacts = (tenant) => {
    setSelectedTenant(tenant);
    setIsModalVisible(true);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    setSelectedTenant(null);
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    setSelectedTenant(null);
  };

  return (
    <Row  justify="center">
      <Col span={22}>
        <Button
          style={{
            borderRadius: 19,
            border: "1px solid #fff",
            color: "#fff",
            backgroundColor: "#5A7890",
            height: "2rem",
            width: "7rem",
          }}
          className="dark-btn-hover"
          onClick={() => navigate(`/Forms/Submissions`)}
        >
          Back To List
        </Button>
        <Card bordered={false} className="submission-card">
          <Title level={2} className="section-title">
            General Information
          </Title>
          <div className="general-info-section">
            <Row gutter={[16,16]} justify={'space-between'}>
      
      {details.userId && (
              <>
              <Col sm={8}>
              <Text strong>User Email:</Text> <Text>{details.userId}</Text>
                <br />
                <Text strong>User Name:</Text> <Text>{details.userName}</Text>
              </Col>
              </>
            )}
     
      <Col sm={5}>
      <Text strong>State:</Text> <Text>{details.state}</Text>
      </Col>
      <Col sm={5}>
      <Text strong>City:</Text> <Text>{details.city}</Text>
      </Col>
      <Col sm={5}>
      <Text strong>Property:</Text> <Text>{details.property}</Text>
      </Col>
      <Col sm={5}>
      {details.amount && (
              <>
                <Text strong>Amount:</Text> <Text>${details.amount}</Text>
                <br />
                <Text strong>Card Holder Name:</Text>{" "}
                <Text>{details.cardHolderName}</Text>
                <br />
                <Text strong>Payment Transaction Id:</Text>{" "}
                <Text>{details.transactionId}</Text>
              </>
            )}
      </Col>
     
       
          
            </Row>
          </div>
          <Title level={2} className="section-title">
            Tenants Information
          </Title>
       
          <DynamicTable columns={tenantColumns} data={transformTenants ?? []} />

          <Title level={2} className="section-title">
            Vehicles Information
          </Title>

          <DynamicTable
            columns={vehicleColumns}
            data={transformVehicles ?? []}
          />

          <Title level={2} className="section-title">
            Pets Information
          </Title>

          <DynamicTable columns={petColumns} data={transformPets ?? []} />

          <Title level={2} className="section-title">
            Lease Information
          </Title>
          <div className="general-info-section">
            <Text strong>Lease Type:</Text>{" "}
            <Text>{details.leaseInfo.newOrRenew}</Text>
            <br />
            <Text strong>Start Day:</Text>{" "}
            <Text>
              {new Date(details.leaseInfo.startDay).toLocaleDateString()}
            </Text>
            <br />
            <Text strong>End Day:</Text>{" "}
            <Text>
              {new Date(details.leaseInfo.endDay).toLocaleDateString()}
            </Text>
          </div>

          <Title level={2} className="section-title">
            Supporting Documents
          </Title>
          <List
            dataSource={details?.documents ?? []}
            renderItem={(doc) => (
              <List.Item>
                <a href={doc.url} download>
                  {doc.originalFileName}
                </a>
              </List.Item>
            )}
          />

          {details.status !== "Pending" && (
            <>
              <Title level={2} className="section-title">
                Approval Information
              </Title>
              <div className="general-info-section">
                <Text strong>Approval Status:</Text>{" "}
                <Text>{details.status}</Text>
                <br />
                <Text strong>Approval Remark:</Text>{" "}
                <Text>{details.approvalRemark}</Text>
                <br />
                <Text strong>Approval By:</Text>{" "}
                <Text>{details.approvalBy}</Text>
                <br />
                <Text strong>Approval Date:</Text>{" "}
                <Text>{details.approvalDate}</Text>
              </div>
            </>
          )}
        </Card>
        {selectedTenant && (
          <ModalComponent
            title="Emergency Contacts"
            centered
            open={isModalVisible}
            Ok={handleModalOk}
            Cancel={handleModalCancel}
            width={800}
            footer={[
              <Button key="back" onClick={handleModalCancel}>
                Close
              </Button>,
            ]}
            children={() => (
              <DynamicTable
                columns={[
                  { title: "Name", dataIndex: "name", key: "name" },
                  { title: "Email", dataIndex: "email", key: "email" },
                  { title: "Phone", dataIndex: "phone", key: "phone" },
                ]}
                data={
                  selectedTenant.emergencyContacts.map((contact) => ({
                    key: contact.email,
                    name: `${contact.firstName} ${contact.lastName}`,
                    email: contact.email,
                    phone: contact.phone,
                  })) ?? []
                }
              />
            )}
          />
        )}
      </Col>
    </Row>
  );
};

export default SubmissionDetails;
