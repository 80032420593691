import { useEffect, useState } from "react";
import Sharepoint, { FileDataUpload, DisclosureDocumentItem } from "../../../api/BoardVue";
import { useStore } from "../../../root-store-context";
import FileExplorerList, { FileItem } from "../../../components/FileExplorer/FileExplorerList";
import { Button, Flex, Input, Space } from "antd";
import useBreakpoint from "use-breakpoint";
import { SearchOutlined, PlusOutlined, SyncOutlined, InboxOutlined, CaretLeftFilled } from '@ant-design/icons';
import FileHelper from "../../../helpers/FileHelper";
import { useAuth } from "react-oidc-context";
import ApiStore from "../../../stores/appStores/ApiStore";
import { getMimeImageType } from "../../../components/ModalFileViewer/ModalFileViewer";
import DisclosuresColumns from "./DisclosuresColumns";
const Disclosures: React.FC = () => {
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const [isLoading, setLoading] = useState<boolean>(true);
    const [disclosuresList, setDisclosuresList] = useState<DisclosureDocumentItem[]>([]);
    const [filteredDisclosuresList, setFilteredDisclosuresList] = useState<DisclosureDocumentItem[]>([]);
    const { applicationStore } = useStore();
    const [searchText, setSearchText] = useState<string>("");

    const auth = useAuth();
    const { apiStore, modalViewerStore } = useStore();

    useEffect(() => {
        loadFiles();
    }, []);

    useEffect(() => {
        const newFilteredDisclosuresList = disclosuresList.filter(i => i.localName.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        setFilteredDisclosuresList(newFilteredDisclosuresList);
    }, [searchText, disclosuresList])

    const mapDataToFilesItems = (data: any): DisclosureDocumentItem[] => {
        return data.map((item: any, index: number):DisclosureDocumentItem => {
            const localName = item.localName == null || item.localName == "" || item.localName == '' ? item.fullPath?.split('/')?.reverse()[0] : item.localName
            return {
                ...item,
                localName: localName,
            }
        })
    }

    const loadFiles = async () => {
        setLoading(true);
        const filesResponse = await apiStore.BoardVueApiClient.getDisclosuresList();
        setDisclosuresList(mapDataToFilesItems(filesResponse));
        setLoading(false);
    }

    const onRow = (record: DisclosureDocumentItem, rowIndex: number) => {
        return {
            onClick: (event: MouseEvent) => {
                openFolderOrFile(rowIndex, record.isFolder, record.localName, record.fullPath, record.serverRelativePath, event);
            },
        };
    }

    const openFolderOrFile = (index: number, isFolder: boolean, name: string, fullPath: string, serverRelativePath: string, event: MouseEvent | any = null) => {
        setLoading(true);
        apiStore.BoardVueApiClient.getFile(fullPath)
            .then(res => {
                modalViewerStore.openFileByData(res.data, name);
                setLoading(false);
            })
            .catch(error => {
                console.log(error);
                setLoading(false);
            });
    }

    const refreshTable = () => {
        loadFiles();
        setSearchText("");
        setDisclosuresList([]);
        setFilteredDisclosuresList([]);
    }

    return (<>
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{padding:10}}>
            <div>
                <Space direction={breakpoint == 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Disclosures</h2>
                    <Input value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px' }}/>
                </Space>
            </div>
        </Flex>
        <FileExplorerList readOnly data={filteredDisclosuresList} onRowFunc={onRow} isLoading={isLoading} columns={DisclosuresColumns}/>
    </>)
}

export default Disclosures;