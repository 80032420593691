import { Col, Row, Space, Image, Spin, Result, Form, Input, DatePicker, Card } from 'antd';
import { observer } from 'mobx-react-lite';
import Title from 'antd/es/typography/Title';
import { useStore } from '../../../root-store-context';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { AnnouncementItem } from '../../../api/BoardVue';
import TextArea from 'antd/es/input/TextArea';
import announcementIcon from "../../../images/pageIcons/BoardVue/announcementForm.png"

const Announcement = observer(() => {
    const { apiStore } = useStore();
    const [form] = Form.useForm();
    const { announcementId } = useParams();
    const [isNotFound, setIsNotFound] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [announcement, setAnnouncement] = useState<AnnouncementItem>({} as AnnouncementItem);

    useEffect(() => {
        setAnnouncementById(parseInt(announcementId!));
    }, [])

    useEffect(() => {
        form.resetFields();
    }, [announcement])

    const setAnnouncementById = async (id: number) => {
        setIsLoading(true);
        var announcement = await apiStore.BoardVueApiClient.getAnnouncement(id);
        if (announcement != null) {

            console.log(announcement)
            setAnnouncement(announcement);
            form.resetFields();
        } else {
            setIsNotFound(true);
        }
        setIsLoading(false);
    }

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );

    if (isNotFound) {
        return <Result status="404" title="The announcement does not exist" />
    }

    return (<>
        <Spin spinning={isLoading} style={{ margin: 10 }}>
            <Row>
                <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Space>
                        <Image src={announcementIcon} preview={false} height={25}/>
                        <Title level={2} style={{ marginTop: "10px" }}>Announcement {announcementId}</Title>
                    </Space>
                    <Form
                        disabled={true}
                        form={form}
                        variant='outlined'
                        layout="vertical"
                        initialValues={announcement}
                        labelCol={{ span: 24 }}
                        style={{ maxWidth: 900 }}
                        // onValuesChange={(changedValues, allValues) => announcementStore.announcement =allValues}
                        // validateMessages={validateMessages}
                        requiredMark={customizeRequiredMark}
                        autoComplete="off"
                        // onFinish={submit}
                        scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item<AnnouncementItem> name="id" hidden >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 8, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<AnnouncementItem> label="Title" name="title" style={{ width: "100%" }}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 8, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<AnnouncementItem>
                                    label="Expires"
                                    name="expires">
                                    <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<AnnouncementItem> label="Body" name="body" >
                                    <Card style={{ backgroundColor: "rgba(0, 0, 0, 0.04)", borderColor: "#d9d9d9" }}>
                                        <div style={{ color: "rgba(0, 0, 0, 0.25)", margin:"-30px -15px" }} dangerouslySetInnerHTML={{ __html: announcement.body }}></div>
                                    </Card>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 8, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<AnnouncementItem> label="Created" name="created">
                                    <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                                </Form.Item>
                            </Col>

                            <Col md={{ span: 8, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<AnnouncementItem> label="Modified" name="modified">
                                    <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 8, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<AnnouncementItem> label="Created By" name="authorName">
                                    <Input style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>

                            <Col md={{ span: 8, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<AnnouncementItem> label="Modified By" name="editorName">
                                    <Input style={{ width: "100%" }} />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Spin>
    </>
    )
})

export default Announcement