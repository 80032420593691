import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { Appeal } from "../../../api/AccountSummary/AccountSummaryTypes";
import { Attachment, CommunityProxy } from "../../../api/Common/CommonTypes";

class ComplianceAppealStore {
    submittableForm: boolean = false;
    isLoading: boolean = false;
    complianceAppeal: Appeal = {
        ReasonForAppeal: "",
        PhoneNumber: "",
        EmailAddress: "",
        Community: {} as CommunityProxy,
        UserId: "",
        ComplianceIssue: "",
        AppealAmount: 0,
        Attachments: [],
    } as Appeal;
    Attachments: Array<Attachment> = [];
    shouldInitAttachments: boolean = false;
    shouldResetForm: boolean = false;
    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api
    }

    editLoading = (loading: boolean) => {
        this.isLoading = loading
    }

    setSubmittableForm = (submittable: boolean) => {
        this.submittableForm = submittable;
    }

    addAttachmentToBuffer = async (attachment: Attachment): Promise<string | void> => {
        return this._api.CommonApiClient.addAttachmentToBuffer(attachment)
    }
    setNewPhone = (phone: string) => {
        this.complianceAppeal.PhoneNumber = phone;
    }
    setAppeal = (architecturalItem: Appeal) => {
        this.complianceAppeal = architecturalItem;
    }

    addAppealRequestStore = async () => {
        this.isLoading = true;
        const res = await this._api.AccountSummaryApiClient.addAppeal(this.complianceAppeal);
        if (res.data) {
            this.complianceAppeal = {} as Appeal;
            this.Attachments = [];
        }

        this.isLoading = false;
        return {} as Appeal;
    }
}

export default ComplianceAppealStore