import React from 'react';
import { App, Button, Col, Form, Input, Row, Image, Space, Flex, Spin, Select } from 'antd';
import entryAccessRequest from "../../images/pageIcons/Forms/EntryAccessRequest.png";
import { observer } from 'mobx-react-lite';
import { useStore } from '../../root-store-context';
import Title from 'antd/es/typography/Title';
import { getPhoneFormat } from '../../helpers/phoneFormat';
import TextArea from 'antd/es/input/TextArea';
import { validateMessages } from '../../helpers/validationMessages';
import { EntryAccessItems, EntryAccessRequestItem } from '../../api/Forms/FormsTypes';

const EntryAccessRequest = observer(() => {

    const [form] = Form.useForm();
    const { applicationStore, entryAccessRequestStore } = useStore();
    const { message } = App.useApp();

    const success = () => {
        message.open({
            type: 'success',
            content: 'Access Device Request saved successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    const values = Form.useWatch([], form);

    React.useEffect(() => {
        form.validateFields({ validateOnly: true }).then(
            () => {
                entryAccessRequestStore.setSubmittableForm(true);
            },
            () => {
                entryAccessRequestStore.setSubmittableForm(false);
            },
        );
    }, [values]);

    React.useEffect(() => {
        if (entryAccessRequestStore.shouldResetForm) {
            form.resetFields();
            entryAccessRequestStore.shouldResetForm = false;
        }
    }, [entryAccessRequestStore.shouldResetForm]);

    const loadDefaultData = () => {
        entryAccessRequestStore.editLoading(true);
        entryAccessRequestStore.getOwnerContactInfo().then((result) => {
            if(result !== undefined){
                form.setFieldsValue({
                    ...entryAccessRequestStore.entryAccessRequestItem,
                    DaytimePhone: getPhoneFormat(entryAccessRequestStore.entryAccessRequestItem.DaytimePhone),
                    Amount: "",
                    ReasonForRequest: "",
                });
            }
            entryAccessRequestStore.editLoading(false);
        }).catch((error) => {
            entryAccessRequestStore.editLoading(false);
            message.open({
                type: 'error',
                content: 'Don`t load profile phone/email',
            });
        })
    }
    React.useEffect(() => {
        loadDefaultData()
    }, []);

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );

    const onSubmit = async () => {
        entryAccessRequestStore.editLoading(true);
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'Saving'
        });

        entryAccessRequestStore.setDaytimePhone(entryAccessRequestStore.entryAccessRequestItem.DaytimePhone.replace(/\D/g, ''))
        entryAccessRequestStore.entryAccessRequestItem.UserId = applicationStore.SelectedAddress.userId.toString();
        entryAccessRequestStore.addEntryAccessRequestItem(applicationStore.SelectedAddress, applicationStore.SelectedAddress.userId.toString()).then((res) => {
            if (res) {
                message.destroy("Saving");
                success()
                entryAccessRequestStore.resetValue();
                loadDefaultData();
            }
            else {
                message.destroy("Saving");
                error()
            }
        })
    }

    return (
        <Spin spinning={entryAccessRequestStore.isLoading}>
            <Row>
                <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Flex align='center' wrap='wrap' justify='space-between'>
                        <Space style={{ flex: "1 1 400px" }}>
                            <Image src={entryAccessRequest} preview={false} height={25}/>
                            <Title level={2} style={{ marginTop: "10px" }}>Access Device Request</Title>
                        </Space>
                        <Space style={{ flex: "1 1", justifyContent: "flex-end" }}>
                            <p style={{ width: "100%" }}>
                            Please complete this form to request an access device for your community gate(s) and/or amenities. This access device will be provided in accordance with Association Policy, which may require that your account balance be current.  Access devices are only provided to property owners; tenants please contact the property owner. <br> </br>
                            If you have any questions please contact your Community Manager.  Thank you.
                            </p>
                        </Space>
                    </Flex>
                    <Form
                        form={form}
                        variant='outlined'
                        layout="vertical"
                        initialValues={{
                            ...entryAccessRequestStore.entryAccessRequestItem
                        }}
                        labelCol={{ span: 24 }}
                        style={{ maxWidth: 900 }}
                        validateMessages={validateMessages}
                        onValuesChange={(changedValues, allValues) => entryAccessRequestStore.entryAccessRequestItem = { ...allValues }}
                        requiredMark={customizeRequiredMark}
                        autoComplete="off"
                        onFinish={onSubmit}
                        scrollToFirstError={{ block: 'center', inline: 'nearest' }}>
                        <Row>
                            <Col span={24}>
                                <Form.Item<EntryAccessRequestItem> name="UserId" hidden>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<EntryAccessRequestItem>
                                    label="Daytime Phone"
                                    name={"DaytimePhone"}
                                    style={{ width: "100%" }}
                                    rules={[{ required: true, message: 'Please input your daytime phone!', min: 12 }]}>
                                    <Input onChange={(event) => {
                                        const { value } = event.target;
                                        const formattedValue = getPhoneFormat(value);
                                        form.setFieldsValue({
                                            DaytimePhone: formattedValue,
                                        });

                                    }} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<EntryAccessRequestItem> label="Email Address" name="EmailAddress" style={{ width: "100%" }}
                                    rules={[{ required: true, type: "email" },
                                    ]} >
                                    <Input pattern="^[^@\s]+@[^@\s]+\.[^@\s]+$" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<EntryAccessRequestItem> label="Entry Access Item Requested" name="SelectedEntryAccessitem" rules={[{ required: true }]}>
                                    <Select options={Object.values(EntryAccessItems).filter(value => typeof value === 'string').map((value) => ({
                                        value,
                                        label: value
                                    }))} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item<EntryAccessRequestItem> label="Additional Information" name="Description" rules={[{ required: true }]}>
                                    <TextArea rows={4} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24} style={{ textAlign: 'right', marginTop: "16px" }}>
                                <Space>
                                    <Button size="middle" onClick={() => {
                                        entryAccessRequestStore.resetValue();
                                    }}>
                                        Clear
                                    </Button>
                                    <Button size="middle" type="primary" htmlType="submit">Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Spin>
    )
})

export default EntryAccessRequest;
