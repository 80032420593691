import { CSSProperties, useEffect, useRef, useState } from 'react';
import { init, getInstanceByDom } from "echarts";
import type { EChartsOption, ECharts, SetOptionOpts } from "echarts";

export interface ReactEChartsProps {
    option: EChartsOption;
    style?: CSSProperties;
    settings?: SetOptionOpts;
    loading?: boolean;
    theme?: "light" | "dark";
}

const ReactECharts:React.FC<ReactEChartsProps> = ({ option, style, settings, loading, theme, }: ReactEChartsProps) => {
    const chartRef = useRef(null);
    const [divSize, setDivSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            const { width, height } = entries[0].contentRect;
            setDivSize({ width, height });
        });

        if (chartRef.current) {
            resizeObserver.observe(chartRef.current);
        }

        return () => {
            if (chartRef.current) {
                resizeObserver.unobserve(chartRef.current);
            }
        };
    }, []);

    useEffect(() => {
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart?.resize({
                width: divSize.width,
                height: divSize.height
            });
        }
    }, [divSize]);

    useEffect(() => {
        let chart: ECharts | undefined;
        if (chartRef.current !== null) {
            chart = init(chartRef.current, theme);
        }
        function resizeChart() {
            chart?.resize();
        }
        window.addEventListener("resize", resizeChart);
        return () => {
            chart?.dispose();
            window.removeEventListener("resize", resizeChart);
        };
    }, [theme]);

    useEffect(() => {
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            chart?.setOption(option, settings);
        }
    }, [option, settings, theme]);

    useEffect(() => {
        if (chartRef.current !== null) {
            const chart = getInstanceByDom(chartRef.current);
            loading === true ? chart?.showLoading() : chart?.hideLoading();
        }
    }, [loading, theme]);

    return <div ref={chartRef} style={{ width: '100%', height: '100%' }}></div>;
};

export default ReactECharts;
