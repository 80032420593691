import { ColumnsType } from "antd/es/table";
import { FileExplorerColumns } from "../../../components/FileExplorer/FileExplorerList";
import { dateValidation } from "../../../helpers/dateValidation";
import { App, Button, Flex } from "antd";
import { FolderFilled, FileTextOutlined } from '@ant-design/icons'
import { DateSort, FileNaturalCompare, TextSort } from "../../../helpers/SorterHelper";
import { ComplianceItem } from "../../../api/AccountSummary/AccountSummaryTypes";
import { ReactElement } from "react";

const columns: ColumnsType<ComplianceItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "isFolder",
        render: (isFolder: any) => (
            <Flex align="center" justify="center">
                <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
            </Flex>
        )
    },
    {
        key: "2",
        title: "Compliance #",
        colSpan: 2,
        align: 'left',
        width: "10%",
        dataIndex: "violationNo",
        sorter: (a: ComplianceItem, b: ComplianceItem) => TextSort(a.violationNo, b.violationNo, 'violationNo'),
        defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
    {
        key: "status",
        title: "Status",
        width: "7%",
        dataIndex: "status",
        sorter: (a: ComplianceItem, b: ComplianceItem) => TextSort(a.violationNo, b.violationNo, 'status'),
        render: (data: any) => <>{data===""?"Open":"Resolved"}</>
    },
    // {
    //     key: "3",
    //     title: "Type",
    //     width: "10%",
    //     dataIndex: "type",
    //     sorter: (a: ComplianceItem, b: ComplianceItem) => TextSort(a, b, "type")
    // },
    {
        key: "4",
        width: "10%",
        title: "Report Date",
        dataIndex: "reportDate",
        sorter: (a: ComplianceItem, b: ComplianceItem) => DateSort(a, b, "reportDate"),
        render: (date: any) => dateValidation(date)
    },
    {
        key: "5",
        title: "Description",
        width: "30%",
        dataIndex: "description",
        sorter: (a: ComplianceItem, b: ComplianceItem) => TextSort(a, b, "description")
    },
];

const small: ColumnsType<ComplianceItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "isFolder",
        render: (isFolder: any) => (
            <Flex align="center" justify="center">
                <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />
            </Flex>
        )
    },
    {
        key: "2",
        title: "Compliance #",
        colSpan: 2,
        align: 'left',
        width: "15%",
        dataIndex: "violationNo",
        sorter: (a: ComplianceItem, b: ComplianceItem) => TextSort(a.violationNo, b.violationNo, 'violationNo'),
        defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
    {
        key: "4",
        title: "Description",
        width: "30%",
        dataIndex: "description",
        sorter: (a: ComplianceItem, b: ComplianceItem) => TextSort(a, b, "description")
    }
];

const ComplianceDocumentsColumns: FileExplorerColumns<ComplianceItem> = {
    large: columns,
    small: small,
};

const getComplianceDocumentsColumns = (downloadFilesButton: (data: ComplianceItem) => ReactElement, appealButton: (data: ComplianceItem) => ReactElement): FileExplorerColumns<ComplianceItem> => {
    const newColumns = [...columns, {
        key: "appeal",
        title: "Appeal",
        width: "10%",
        render: appealButton
    },
    {
        key: "download",
        title: "Files",
        width: "20%",
        render: downloadFilesButton
    }]

    const newSmall = [...small, {
        key: "appeal",
        title: "Appeal",
        width: "20%",
        render: appealButton
    },
    {
        key: "download",
        title: "Files",
        width: "30%",
        render: downloadFilesButton
    }]

    return {
        large: newColumns,
        small: newSmall,
    }
}

export default getComplianceDocumentsColumns;