import { useEffect, useState } from 'react';
import type { EChartsOption } from "echarts";
import ReactECharts from '../../../../components/ReactECharts/ReactECharts';

export type TopArchitecturalRequestItem = {
  value: number;
  name: string;
}

export interface ITopArchitecturalRequestsProps {
  data: TopArchitecturalRequestItem[];
}

const TopArchitecturalRequests = (props: ITopArchitecturalRequestsProps) => {
  const [option, setOption] = useState<EChartsOption>({});

  useEffect(() => {
    const data = props.data;
    const parsedData = data.map(i => ({
      name: i.name,
      value: i.value
    }));
    const option: EChartsOption = {
      title: {
        text: 'Top 10 Architectural Requests',
      },
      tooltip: {
        trigger: 'item'
      },
      legend: {
        type: "scroll",
        top: '15%',
        left: 'center',
        selectedMode: false
      },
      series: [
        {
          type: 'pie',
          radius: '50%',
          center: ['50%', '70%'],
          data: parsedData,
          emphasis: {
            itemStyle: {
              shadowBlur: 10,
              shadowOffsetX: 0,
              shadowColor: 'rgba(0, 0, 0, 0.5)'
            }
          }
        }
      ]
    };
    setOption(option);
  }, [props]);
  return <ReactECharts option={option} />;
};

export default TopArchitecturalRequests;
