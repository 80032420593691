import { Flex, Image } from "antd";
import form from "../images/routingIcon/form.png";
import EntryAccessRequest from "../pages/FormsGroup/EntryAccessRequest";
import FeeWaiver from "../pages/FormsGroup/FeeWaiver";
import MaintenanceRequest from "../pages/FormsGroup/MaintenanceRequest/MaintenanceRequest";
import ComplianceConcern from "../pages/FormsGroup/ComplianceConcern/ComplianceConcern";
import {
  RouteElement,
  RouteGroupElement,
} from "../stores/appStores/ApplicationStore";
import { Navigate, Link } from "react-router-dom";
import ComplianceConcernHistory from "../pages/FormsGroup/ComplianceConcern/ComplianceConcernHistory";
import MaintenanceRequestHistory from "../pages/FormsGroup/MaintenanceRequest/MaintenanceRequestHistory";
import ContactInfoChange from "../pages/FormsGroup/ContactInfoChange";

import AccountContacts from "../pages/AccountSummaryGroup/AccountContacts/AccountContacts";
import iconComplianceConcern from "../images/pageIcons/Forms/ComplianceConcern.png";
import iconAccountSummary from "../images/pageIcons/AccountSummary/AccountSummary.png";
import iconMaintenanceRequest from "../images/pageIcons/Forms/MaintenanceRequest.png";
import iconContactInfoChange from "../images/pageIcons/Forms/ContactInfoChange.png";
import iconFeeWaiver from "../images/pageIcons/Forms/FeeWaiver.png";
import iconEntryAccessRequest from "../images/pageIcons/Forms/EntryAccessRequest.png";
import { Role } from "../api/Common/CommonTypes";
import TenantRegistration from "../pages/FormsGroup/TenantRegistration/TenantRegistration";
import Submissions from "../pages/FormsGroup/TenantRegistration/Submissions";
import SubmissionDetails from "../pages/FormsGroup/TenantRegistration/SubmissionDetails";
import iconTenantRegistration from "../images/pageIcons/Forms/Tenant.svg";
import EStatements from "../pages/AccountSummaryGroup/EStatements";
import iconEStatements from "../images/pageIcons/AccountSummary/eStatements.png";
import BoardMemberApplication from "../pages/FormsGroup/BoardMemberApplication";
import CreateContact from "../pages/AccountSummaryGroup/AccountContacts/CreateContact";
import EditContact from "../pages/AccountSummaryGroup/AccountContacts/EditContact";

const iconStyle:React.CSSProperties = {width:"1.2em", marginLeft: "10px"}

const formsChildrenRoutes: RouteElement[] = [
  {
    key: "/",
    path: "",
    element: <Navigate to={"/"} />,
    featureID: null,
  },
  {
    key: "/AccountContacts",
    path: "/AccountContacts",
    icon: <Flex><Image style={iconStyle} src={iconAccountSummary} preview={false} /></Flex>,
    label: "Account Contacts",
    element: <AccountContacts />,
    featureID: null,
  },
  {
    key: "/AccountContacts/Add",
    path: "/AccountContacts/Add",
    element: <CreateContact />,
    featureID: null,
  },
  {
    key: "/AccountContacts/Edit/:residentKey/:contactKey",
    path: "/AccountContacts/Edit/:residentKey/:contactKey",
    element: <EditContact />,
    featureID: null,
  },
  {
    key: "/ContactInfoChange",
    path: "/ContactInfoChange",
    icon: <Flex><Image style={iconStyle} src={iconContactInfoChange} preview={false} /></Flex>,
    label: "Contact Info Change",
    element: <ContactInfoChange />,
    featureID: 20,
  },
  {
    key: "/ComplianceConcernEmail",
    path: "/ComplianceConcern",
    icon: <Flex><Image style={iconStyle} src={iconComplianceConcern} preview={false} /></Flex>,
    label: "Compliance Concern",
    element: <ComplianceConcern />,
    featureID: 18,
  },
  {
    key: "/ComplianceConcernSP",
    path: "/ComplianceConcern",
    icon: <Flex><Image style={iconStyle} src={iconComplianceConcern} preview={false} /></Flex>,
    label: "Compliance Concern",
    element: <ComplianceConcern />,
    featureID: 23,
  },
  {
    key: "/ComplianceConcernSPHistory",
    path: "/ComplianceConcern/History",
    element: <ComplianceConcernHistory />,
    featureID: 23,
  },
  {
    key: "/eStatements",
    path: "/eStatements",
    icon: <Flex><Image style={iconStyle} src={iconEStatements} preview={false} /></Flex>,
    label: "Electronic Communication",
    element: <EStatements />,
    featureID: 4,
  },
  {
    key: "/MaintenanceRequestEmail",
    path: "/MaintenanceRequest",
    icon: <Flex><Image style={iconStyle} src={iconMaintenanceRequest} preview={false} /></Flex>,
    label: "Maintenance Request",
    element: <MaintenanceRequest />,
    featureID: 19,
  },
  {
    key: "/MaintenanceRequestSP",
    path: "/MaintenanceRequest",
    icon: <Flex><Image style={iconStyle} src={iconMaintenanceRequest} preview={false} /></Flex>,
    label: "Maintenance Request",
    element: <MaintenanceRequest />,
    featureID: 24,
  },
  {
    key: "/MaintenanceRequestSPHistory",
    path: "/MaintenanceRequest/History",
    element: <MaintenanceRequestHistory />,
    featureID: 24,
  },
  {
    key: "/TenantRegistration",
    path: "/TenantRegistration",
    icon: <Flex><Image style={iconStyle} src={iconTenantRegistration} preview={false} /></Flex>,
    label: "Tenant Request",
    element: <TenantRegistration />,
    featureID: 31,
  },
  {
    key: "/FeeWaiver",
    path: "/FeeWaiver",
    icon: <Flex><Image style={iconStyle} src={iconFeeWaiver} preview={false} /></Flex>,
    label: "Fee Waiver",
    element: <FeeWaiver />,
    featureID: 21,
  },
  {
    key: "/EntryAccessRequest",
    path: "/EntryAccessRequest",
    icon: <Flex><Image style={iconStyle} src={iconEntryAccessRequest} preview={false} /></Flex>,
    label: "Access Device Request",
    element: <EntryAccessRequest />,
    featureID: 25,
  },
  {
    key: "/Submissions",
    path: "/Submissions",
    element: <Submissions />,
    featureID: null,
  },
  {
    key: "/SubmissionDetails",
    path: "/SubmissionDetails/:id",
    element: <SubmissionDetails />,
    featureID: null,
  },
  {
    key: '/BoardMemberApplication',
    path: '/BoardMemberApplication',
    icon: <Flex><Image style={iconStyle} src={form} preview={false} /></Flex>,
    element: <BoardMemberApplication />,
    featureID: null,
    label: "Board Member Application",
  },
];
const FormsRoutes: RouteGroupElement[] = [
  {
    key: "/Forms",
    path: "/Forms",
    label: "Forms",
    children: formsChildrenRoutes,
    role: [Role.BoardMember, Role.Homeowner],
  },
];

export default FormsRoutes;
