import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { CommunityProxy } from "../../../api/Common/CommonTypes";
import { ContactInfoChangeItem, ContactInfoItem } from "../../../api/Forms/FormsTypes";

class ContactInfoChangeStore {
    submittableForm: boolean = false;
    isLoading: boolean = false;
    contactInfoChangeItem: ContactInfoChangeItem = {
    DaytimePhone: "",
    CellPhone: "",
    EmailAddress: "",
    NewAddress: "",
    NewAddress2: "",
    NewCity: "",
    NewState: "",
    NewZip: "",
    } as ContactInfoChangeItem;
    shouldInitAttachments: boolean = false;
    shouldResetForm: boolean = false;
    private _api: ApiStore;



    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api
    }

    resetValue = () => {
        this.contactInfoChangeItem = {
            DaytimePhone: "",
            CellPhone: "",
            EmailAddress: "",
            NewAddress: "",
            NewAddress2: "",
            NewCity: "",
            NewState: "",
            NewZip: "",
        } as ContactInfoChangeItem;
        this.shouldResetForm = true;
    }

    editLoading = (loading: boolean) => {
        this.isLoading = loading
    }

    setSubmittableForm = (submittable: boolean) => {
        this.submittableForm = submittable;
    }

    setDaytimePhone = (phone: string) => {
        this.contactInfoChangeItem.DaytimePhone = phone;
    }
    setCellPhone = (phone: string) => {
        this.contactInfoChangeItem.CellPhone = phone;
    }

    setNewEmail = (email: string) => {
        this.contactInfoChangeItem.EmailAddress = email;
    }

    setContactInfoChangeItem = (newMaintenanceRequestItem: ContactInfoChangeItem) => {
        this.contactInfoChangeItem = newMaintenanceRequestItem;
    }

    addContactInfoChangeItem = async (selectedCommunity: CommunityProxy, userId: string) => {
        this.isLoading = true;
        await this._api.FormsApiClient.addContactInfoChange({
            ...this.contactInfoChangeItem,
            Community: selectedCommunity,
            UserId: userId
        });
        this.isLoading = false;
        return true
    }

    public async getOwnerContactInfo(): Promise<ContactInfoItem | null> {
        return await this._api.FormsApiClient.GetOwnerContactInfo();
    } 
}

export default ContactInfoChangeStore

