import { makeAutoObservable, runInAction } from "mobx";
import ApiStore from "../ApiStore";
import { ArchitecturalSubmissionItem } from "../../../api/AccountSummary/AccountSummaryTypes";
import { Attachment, CommunityProxy } from "../../../api/Common/CommonTypes";

class ArchitecturalRequestStore {
    submittableForm: boolean = false;
    isLoading: boolean = false;
    architecturalItem: ArchitecturalSubmissionItem = {
        AdditionalNotes: "",
        EmailAddress: "",
        UserId: "",
        DaytimePhone: "",
        Attachments: [] as Attachment[],
        ColorFiles: [] as Attachment[],
        PlotPlans: [] as Attachment[]
    } as ArchitecturalSubmissionItem;
    Attachments: Array<Attachment> = [];
    ColorFiles: Array<Attachment> = [];
    PlotPlans: Array<Attachment> = [];
    shouldInitAttachments: boolean = false;
    shouldResetForm: boolean = false;
    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api
    }

    resetValue = () => {
        this.Attachments = [];
        this.ColorFiles = [];
        this.PlotPlans = [];
        this.architecturalItem = {
            AdditionalNotes: "",
            EmailAddress: "",
            UserId: "",
            DaytimePhone: "",
            Attachments: [] as Attachment[],
            ColorFiles: [] as Attachment[],
            PlotPlans: [] as Attachment[]
        } as ArchitecturalSubmissionItem
    }
    editLoading = (loading: boolean) => {
        this.isLoading = loading
    }

    setSubmittableForm = (submittable: boolean) => {
        this.submittableForm = submittable;
    }

    addAttachmentToBuffer = async (attachment: Attachment): Promise<string | void> => {
        return this._api.AccountSummaryApiClient.addAttachmentToBuffer(attachment)
    }
    setNewPhone = (phone: string) => {
        this.architecturalItem.DaytimePhone = phone;
    }
    setArchitecturalItem = (architecturalItem: ArchitecturalSubmissionItem) => {
        this.architecturalItem = architecturalItem;
    }

    addArchitecturalRequestStore = async () => {
        this.isLoading = true;
        const res = await this._api.AccountSummaryApiClient.addArchitecturalRequest({
            ...this.architecturalItem,
            Community: this._api.configApi.community,
            UserId: this._api.configApi.userId
        });
        if (res.data) {
            this.architecturalItem = {} as ArchitecturalSubmissionItem;
            this.Attachments = [];
            this.ColorFiles = [];
            this.PlotPlans = [];
        }

        this.isLoading = false;
        return {} as ArchitecturalSubmissionItem;
    }
}

export default ArchitecturalRequestStore

