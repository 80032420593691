import React, { ReactElement } from 'react';
import { Button, UploadFile } from 'antd';
import { DownloadOutlined, DeleteOutlined } from '@ant-design/icons';

const CustomUploadItem = (originNode: ReactElement, file: UploadFile, fileList: object[], actions: { download: Function, preview: Function, remove: Function }) => {
    let actionButtons = [];
    if (file.status === "done") {
        actionButtons.push(
            <Button
                key="download"
                onClick={(e) => {
                    e.stopPropagation();
                    actions.download();
                }}
                className='ant-btn css-oxud1y ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action'
                icon={<DownloadOutlined />}
            />
        );
    }

    if (file.status !== "done") {
        actionButtons.push(
            <Button
                key="remove"
                onClick={(e) => {
                    e.stopPropagation();
                    actions.remove();
                }}
                className='ant-btn css-oxud1y ant-btn-text ant-btn-sm ant-btn-icon-only ant-upload-list-item-action'
                icon={<DeleteOutlined />}
            />
        );
    }

    const updatedOriginNode = React.cloneElement(originNode, {
        children: originNode.props.children.filter((child:any) =>
            !(child.props && child.props.className === 'ant-upload-list-item-actions')
        ).concat(<div key={file.uid} className='ant-upload-list-item-actions'>{actionButtons}</div>)
    });

    return (
        <div onClick={() => actions.download()} className='custom-upload-item'>
            {updatedOriginNode}
        </div>
    );
};

export default CustomUploadItem;
