import React, { useState } from 'react';
import { App, Button, Col, DatePicker, Divider, Image, Form, Input, Row, Select, Space, Spin, UploadFile, UploadProps } from 'antd';

import { InboxOutlined } from '@ant-design/icons';
import { useStore } from '../../../root-store-context';
import TextArea from 'antd/es/input/TextArea';
import { observer } from 'mobx-react-lite';
import FileHelper from '../../../helpers/FileHelper';
import { useNavigate } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import guestPassesForm from "../../../images/pageIcons/Association/GuestPasses.png"
import Title from 'antd/es/typography/Title';
import { validateMessages } from '../../../helpers/validationMessages';
import { PostGuestPass } from '../../../api/Association/AssociationTypes';

const CreateGuestPass = observer(() => {
    const [form] = Form.useForm();
    const { guestPassesStore, applicationStore } = useStore();
    const navigate = useNavigate();
    const { message } = App.useApp();
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const success = () => {
        message.open({
            type: 'success',
            content: 'New Guest Pass added successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    const onSubmit = async (guestPass: PostGuestPass) => {
        setIsLoading(true);
        guestPass.startDate = guestPass.startDate.format('YYYY-MM-DD');
        guestPass.endDate = guestPass.endDate.format('YYYY-MM-DD');
        guestPass.restricted = false;
        guestPassesStore.createGuestPass(guestPass).then((res) => {
            if (res) {
                success()
                navigate('/Association/GuestPasses');
            }
            else {
                error()
            }
            setIsLoading(false);
        })
    }

    const customizeRequiredMark = (label: React.ReactNode, { required }: { required: boolean }) => (
        <>
            <span style={{ fontWeight: 500 }}>{label}</span>
        </>
    );

    return (
        <Spin tip="Loading" size="small" spinning={isLoading}>
            <Row>
                <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Space>
                        <Image src={guestPassesForm} preview={false} height={25}/>
                        <Title level={2} style={{ marginTop: "10px" }}>Add New Guest Pass</Title>
                    </Space>
                    <Form
                        form={form}
                        variant='outlined'
                        layout="vertical"
                        labelCol={{ span: 24 }}
                        style={{ maxWidth: 900 }}
                        validateMessages={validateMessages}
                        requiredMark={customizeRequiredMark}
                        autoComplete="off"
                        onFinish={onSubmit}
                        scrollToFirstError={{ block: 'center', inline: 'nearest' }}>

                        <Row>
                            <Col span={24}>
                                <Form.Item<PostGuestPass> name="passTypeID" hidden >
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ span: 15, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<PostGuestPass> label="Pass Type" name="passTypeID" rules={[{ required: true }]}>
                                    <Select options={applicationStore.guestPassTypes?.map((type) => ({ value: type.typeID, label: type.name }))} />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ span: 15, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<PostGuestPass> label="Guest Email" name="email" rules={[{ type: "email" }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ span: 7, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<PostGuestPass> label="Guest First Name" name="firstName" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                            <Col md={{ span: 7, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<PostGuestPass> label="Guest Last Name" name="lastName" rules={[{ required: true }]}>
                                    <Input />
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row>
                            <Col md={{ span: 7, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<PostGuestPass>
                                    label="Start Date"
                                    name="startDate"
                                    rules={[{ required: true }]}>
                                    <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                                </Form.Item>
                            </Col>

                            <Col md={{ span: 7, offset: 1 }} xs={{ span: 24, offset: 0 }}>
                                <Form.Item<PostGuestPass>
                                    label="End Date"
                                    name="endDate"
                                    dependencies={["startDate"]}
                                    rules={[{ required: true }, ({ getFieldValue }) => ({
                                        validator(_, value) {
                                            if (value && getFieldValue('startDate') && value.startOf('day').diff(getFieldValue('startDate').startOf('day')) > 0) {
                                                return Promise.resolve();
                                            }
                                            return Promise.reject(new Error('The End Date must be greater than the Start Date'));
                                        },
                                    }),]}>
                                    <DatePicker style={{ width: "100%" }} format={"M/D/YYYY"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={{ span: 15 }} xs={{ span: 24 }} style={{ textAlign: 'right', marginTop: "16px" }}>
                                <Space>
                                    <Button size="middle" onClick={() => navigate('/Association/GuestPasses')}>
                                        Cancel
                                    </Button>
                                    <Button size="middle" type="primary" htmlType="submit">Save</Button>
                                </Space>
                            </Col>
                        </Row>
                    </Form>
                </Col>
            </Row>
        </Spin>
    )
})

export default CreateGuestPass