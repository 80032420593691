import { ColumnsType } from "antd/es/table";
import { DisclosureDocumentItem } from "../../../api/BoardVue";
import { FileExplorerColumns, FileItem } from "../../../components/FileExplorer/FileExplorerList";
import { dateValidation } from "../../../helpers/dateValidation";
import { Flex } from "antd";
import { FolderFilled, FileTextOutlined } from '@ant-design/icons'
import { BoolSort, DateSort, FileNaturalCompare, TextSort } from "../../../helpers/SorterHelper";

const columns: ColumnsType<DisclosureDocumentItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "isFolder",
        render: (isFolder: any) => (
            <Flex align="center" justify="center">
                {isFolder ? <FolderFilled style={{ fontSize: 20, color: "#5B7B94" }} /> : <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />}
            </Flex>
        )
    },
    {
        key: "2",
        title: "Name",
        colSpan: 2,
        align: 'left',
        width: "35%",
        dataIndex: "localName",
        sorter: (a: DisclosureDocumentItem, b: DisclosureDocumentItem) => FileNaturalCompare(a.localName, b.localName, 'ascend', a.isFolder, b.isFolder),
        defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>,
    },
    // {
    //     key: "3",
    //     title: "Expiration Date",
    //     width: "8%",
    //     dataIndex: "disclosureExpDate",
    //     sorter: (a: DisclosureDocumentItem, b: DisclosureDocumentItem) => DateSort(a,b,"disclosureExpDate"),
    //     render: (date: any) => dateValidation(date),
    // },
    // {
    //     key: "4",
    //     title: "Current Version",
    //     width: "10%",
    //     dataIndex: "currentVersion",
    //     render: (currentVersion: any) => (currentVersion ? "Yes" : "No"),
    //     sorter: (a: DisclosureDocumentItem, b: DisclosureDocumentItem) => BoolSort(a,b,"currentVersion"),
    // },
    {
        key: "5",
        title: "Modified",
        width: "8%",
        dataIndex: "modified",
        sorter: (a: DisclosureDocumentItem, b: DisclosureDocumentItem) => DateSort(a,b,"modified"),
        render: (date: any) => dateValidation(date),
    },
];

const small: ColumnsType<DisclosureDocumentItem> = [
    {
        key: "1",
        colSpan: 0,
        width: "5%",
        dataIndex: "isFolder",
        render: (isFolder: any) => (
            <Flex align="center" justify="center">
                {isFolder ? <FolderFilled style={{ fontSize: 20, color: "#5B7B94" }} /> : <FileTextOutlined style={{ fontSize: 20, color: "#5B7B94" }} />}
            </Flex>
        )
    },
    {
        key: "2",
        colSpan: 2,
        align: 'left',
        title: "Name",
        dataIndex: "localName",
        sorter: ((a: DisclosureDocumentItem, b: DisclosureDocumentItem, direction: string) => FileNaturalCompare(a.localName, b.localName, direction, a.isFolder, b.isFolder)) as any,
        defaultSortOrder: 'ascend',
        render: (text: any) => <div className="wrap-text-anywhere">{text}</div>
    },
    // {
    //     key: "3",
    //     title: "Expiration Date",
    //     width: "8%",
    //     dataIndex: "disclosureExpDate",
    //     sorter: (a: DisclosureDocumentItem, b: DisclosureDocumentItem) => DateSort(a,b,"disclosureExpDate"),
    //     render: (date: any) => dateValidation(date),
    // },
    {
        key: "4",
        width: "8%",
        title: "Modified",
        dataIndex: "modified",
        sorter: (a: DisclosureDocumentItem, b: DisclosureDocumentItem) => DateSort(a,b,"modified"),
        render: (date: any) => dateValidation(date)
    }
];

const DisclosuresColumns: FileExplorerColumns<DisclosureDocumentItem> = {
    large: columns,
    small: small,
};

export default DisclosuresColumns;