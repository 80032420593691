import { AxiosResponse } from "axios";
import MobileWebApiClient from "../base/MobileWebApiClient";
import { AllFeaturesForCommunity, Attachment, CommunityProxy } from "./CommonTypes";

export default class CommonApiClient extends MobileWebApiClient {
    public addAttachmentToBuffer = async (attachment: Attachment): Promise<string | void> => {
        try {
            const response = await this.tryPost('user/addattachmenttobuffer', {
                Data: attachment.Data, Name: attachment.Name
            });
            if (response.status === 200) {
                return response.data;
            } else {
                console.log(`addattachmenttobuffer ${response}`);
                return;
            }
        } catch (error) {
            console.log(`addattachmenttobuffer ${JSON.stringify(error, undefined, 2)}`);
            return;
        }
    }

    public async getAllForBoardMember(): Promise<
        AxiosResponse<Array<CommunityProxy>>
    > {
        return await this.tryPost("community/GetAllForBoardMember", {
            id: this._configApi.userId,
            lastName: "string",
            firstName: "string",
            streetAndUnitNumber: "string",
            city: "string",
            state: "string",
            zip: "string",
            email: "string",
            password: "string",
            salt: "string",
            modified: new Date().toISOString(),
        });
    }

    public getAllFeaturesForCommunity = async (): Promise<Array<AllFeaturesForCommunity>> => {
        const res = await this.tryPost('community/GetAllFeaturesForCommunity', this._configApi.community)
        .then((res) => (res.statusText === "OK" && res.data ? res.data : []))
        .catch(() => []);;
        return res;
      }

    public async getLandingPageInfo(): Promise<AxiosResponse> {
        return await this.tryPost("community/GetLandingPageInfo", this._configApi.community);
    }

    public async getCommunityWebSite(): Promise<string> {
        const communityWebSite = await this.tryPost(
          "community/getcommunitywebsite",
          this._configApi.community
        )
          .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
          .catch(() => null);
        return communityWebSite;
      }
    
}