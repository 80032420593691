import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";


class PayAndViewBalanceStore {

    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

}
export default PayAndViewBalanceStore