import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";

class ComplianceStore {
    private _api: ApiStore;
    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }
}
export default ComplianceStore