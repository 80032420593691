import React, { useEffect } from 'react';
import { Col, Row, Space, Image, Spin, App } from 'antd';
import { useStore } from '../../../root-store-context';
import Title from 'antd/es/typography/Title';
import newContact from "../../../images/pageIcons/AccountSummary/AccountSummary.png"
import { observer } from 'mobx-react-lite';
import { useNavigate } from 'react-router-dom';
import ContactForm from './ContactForm';

const CreateContact = observer(() => {
    const { accountContactsStore } = useStore();
    const navigate = useNavigate();
    const { message } = App.useApp();
    const { apiStore } = useStore();

    useEffect(() => {
        accountContactsStore.resetValue();
        setContactData();
    }, [])

    useEffect(() => {
        return () => {
            accountContactsStore.resetValue();
        };
    }, [])

    const setContactData = async () => {
        const isAgeRestricted = await apiStore.AccountSummaryApiClient.getIsCommunityAgeRestricted() || false;
        const res = await apiStore.AccountSummaryApiClient.getCommuityUserContactsData();
        accountContactsStore.setContactModel({
            ...accountContactsStore.contactModel,
            contactData: {
                ...accountContactsStore.contactModel.contactData,
                contactAddress: {
                    ...accountContactsStore?.contactModel?.contactData?.contactAddress,
                    mailingAddress1: res?.address || "",
                    mailingCity: res?.genCity || "",
                    mailingPostalCode: res?.genZip || "",
                    mailingRegion: res?.genState || "",
                },
            }
        })
        const isPrimaryOwner = res?.isPrimaryOwner || false;
        const isAlreadyTennat = res?.conactsList?.some(el => el.isTenant === true) || false;
        accountContactsStore.setResidentKey(res?.residentKey);
        accountContactsStore.setReferenceID(res?.residentKey?.toString());
        accountContactsStore.setIsAllowCreateTennat(isPrimaryOwner && !isAlreadyTennat);
        accountContactsStore.setIsAgeRestricted(isAgeRestricted);
        
    }
    const success = () => {
        message.open({
            type: 'success',
            content: 'New contact added successfully!',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during creation. Please check the entered data.',
        });
    };

    const onSubmit = async () => {
        accountContactsStore.editLoading(true);
        accountContactsStore.addContact().then((res) => {
            if (res) {
                success()
                navigate('/AccountContacts');
            }
            else {
                error()
            }
        }).catch(() => {
            accountContactsStore.editLoading(false);
        })
    }
    return (
        <>
            <Spin tip="Loading" size="small" spinning={accountContactsStore.isLoading}>
                <Row>
                    <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                        <Space>
                            <Image src={newContact} preview={false} height={25} />
                            <Title level={2} style={{ marginTop: "10px" }}>New Contact</Title>
                        </Space>
                        <ContactForm submitBtnText='Create Contact' submit={onSubmit} isCreate={true} />
                    </Col>
                </Row>
            </Spin>
        </>
    )
})

export default CreateContact