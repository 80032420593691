import React, { useEffect, useState } from "react";
import {
  App,
  Button,
  Checkbox,
  Col,
  DatePicker,
  Flex,
  Form,
  Input,
  Popconfirm,
  Radio,
  Row,
  Select,
  Space,
  Switch,
  TimePicker,
} from "antd";
import { useStore } from "../../../root-store-context";
import TextArea from "antd/es/input/TextArea";
import { observer } from "mobx-react-lite";
import { useNavigate } from "react-router-dom";
import { CalendarEvent } from "../../../api/BoardVue";
import dayjs, { Dayjs } from "dayjs";
import RecurrenceForm from "./RecurrenceForm";

const EventForm = observer(
  (props: {
    submitBtnText: string;
    submit: () => void;
    delete?: () => void;
  }) => {
    const [form] = Form.useForm();
    const { applicationStore, calendarStore, apiStore } = useStore();
    const navigate = useNavigate();
    const { message } = App.useApp();

    const validateMessages = {
      required: "${label} is required!",
      types: {
        email: "${label} is not a valid email!",
        number: "${label} is not a valid number!",
      },
      number: {
        range: "${label} must be between ${min} and ${max}",
      },
    };

    const values = Form.useWatch([], form);

    React.useEffect(() => {
      form.validateFields({ validateOnly: true }).then(
        () => {
          calendarStore.setSubmittableForm(true);
        },
        () => {
          calendarStore.setSubmittableForm(false);
        }
      );
    }, [values]);

    React.useEffect(() => {
      if (calendarStore.shouldResetForm) {
        form.resetFields();
        calendarStore.shouldResetForm = false;
      }
    }, [calendarStore, calendarStore.eventModel, form]);

    const customizeRequiredMark = (
      label: React.ReactNode,
      { required }: { required: boolean }
    ) => (
      <>
        <span style={{ fontWeight: 500 }}>{label}</span>
      </>
    );

    return (
      <Form
        form={form}
        variant="outlined"
        layout="vertical"
        initialValues={calendarStore.eventModel}
        labelCol={{ span: 24 }}
        style={{ maxWidth: 900 }}
        validateMessages={validateMessages}
        onValuesChange={(changedValues, allValues: CalendarEvent) => {
          let newEventDate = undefined;
          let newEndDate = undefined;
          let newRecurrenceEndDate = undefined;

          var x = new Date();
          var currentTimeZoneOffsetInHours = (x.getTimezoneOffset() / 60) * -1;

          if (allValues.fAllDayEvent && !allValues.fRecurrence) {
            if (allValues.eventDate.diff(allValues.endDate) !== 0) {
              newEventDate = dayjs(allValues.eventDate)
                .set("hour", 12 + currentTimeZoneOffsetInHours)
                .set("minute", 0);

              newEndDate = dayjs(allValues.eventDate)
                .set("hour", 12 + currentTimeZoneOffsetInHours)
                .set("minute", 0);
            }
          } else {
            if (
              allValues.fRecurrence &&
              allValues.endDate &&
              allValues.endDate.hour() === 0 &&
              allValues.endDate.minute() === 0 &&
              calendarStore.eventModel.endDate
            ) {
              newEndDate = dayjs(allValues.endDate)
                .set("hour", calendarStore.eventModel.endDate.hour())
                .set("minute", calendarStore.eventModel.endDate.minute());
            }

            if (
              allValues.fRecurrence &&
              allValues.eventDate &&
              allValues.eventDate.hour() === 0 &&
              allValues.eventDate.minute() === 0 &&
              calendarStore.eventModel.eventDate
            ) {
              newEventDate = dayjs(allValues.eventDate)
                .set("hour", calendarStore.eventModel.eventDate.hour())
                .set("minute", calendarStore.eventModel.eventDate.minute());
            }
          }

          calendarStore.eventModel = {
            ...allValues,
            ...(newEventDate && {
              eventDate: newEventDate,
            }),
            ...(newEndDate && {
              endDate: newEndDate,
            }),
            recurrencePattern: {
              ...calendarStore.eventModel.recurrencePattern,
            },
          };

          if (newEventDate) form.setFieldValue("eventDate", newEventDate);
          if (newEndDate) form.setFieldValue("endDate", newEndDate);
        }}
        requiredMark={customizeRequiredMark}
        autoComplete="off"
        onFinish={() => {
          if (calendarStore.isCustomCategory) {
            calendarStore.eventModel.category =
              calendarStore.eventModel.categoryCustom || "";
            delete calendarStore.eventModel.categoryCustom;
          }

          if (calendarStore.eventModel.fAllDayEvent) {
            var x = new Date();
            var currentTimeZoneOffsetInHours =
              (x.getTimezoneOffset() / 60) * -1;
            calendarStore.eventModel = {
              ...calendarStore.eventModel,
              eventDate: dayjs(calendarStore.eventModel.eventDate)
                .set("hour", 0 + currentTimeZoneOffsetInHours)
                .set("minute", 10),
              endDate: dayjs(calendarStore.eventModel.endDate)
                .set("hour", 23 + currentTimeZoneOffsetInHours)
                .set("minute", 50),
            };
          }

          props.submit();
        }}
        scrollToFirstError={{ block: "center", inline: "nearest" }}
      >
        <Row>
          <Col span={24}>
            <Form.Item<CalendarEvent> name="id" hidden>
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col span={24}>
            <Form.Item<CalendarEvent>
              label="Title"
              name="title"
              style={{ width: "100%" }}
              rules={[{ required: true }]}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item<CalendarEvent>
              label="Location"
              name="location"
              style={{ width: "100%" }}
            >
              <Input />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 11, offset: 0 }} xs={{ span: 24, offset: 0 }}>
            <Form.Item<CalendarEvent>
              label="Start Time"
              name="eventDate"
              rules={[{ required: true }]}
              hidden={
                calendarStore.eventModel.fRecurrence &&
                calendarStore.eventModel.fAllDayEvent
              }
            >
              {calendarStore.eventModel.fRecurrence ? (
                <TimePicker format={"HH:mm"} />
              ) : calendarStore.eventModel.fAllDayEvent ? (
                <DatePicker style={{ width: "100%" }} format={"MM/DD/YYYY"} />
              ) : (
                <DatePicker
                  style={{ width: "100%" }}
                  showTime
                  format={"M/D/YYYY HH:mm"}
                />
              )}
            </Form.Item>
          </Col>
          <Col md={{ span: 11, offset: 2 }} xs={{ span: 24, offset: 0 }}>
            <Form.Item<CalendarEvent>
              label="End Time"
              name="endDate"
              dependencies={["eventDate"]}
              rules={[
                { required: !calendarStore.eventModel.fRecurrence },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (calendarStore.eventModel.fRecurrence) {
                      return Promise.resolve();
                    }
                    if (!calendarStore.eventModel.fAllDayEvent) {
                      if (
                        value &&
                        getFieldValue("eventDate") &&
                        value.isAfter(getFieldValue("eventDate"))
                      ) {
                        return Promise.resolve();
                      }
                      return Promise.reject(
                        new Error(
                          "The Due Date must be greater than the Start Date"
                        )
                      );
                    } else {
                      return Promise.resolve();
                    }
                  },
                }),
              ]}
              hidden={
                calendarStore.eventModel.fRecurrence &&
                calendarStore.eventModel.fAllDayEvent
              }
            >
              {calendarStore.eventModel.fRecurrence ? (
                <TimePicker format={"HH:mm"} />
              ) : calendarStore.eventModel.fAllDayEvent ? (
                <DatePicker
                  style={{ width: "100%" }}
                  disabled
                  format={"MM/DD/YYYY"}
                />
              ) : (
                <DatePicker
                  style={{ width: "100%" }}
                  showTime
                  format={"MM/DD/YYYY HH:mm"}
                />
              )}
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col md={{ span: 11, offset: 0 }} xs={{ span: 24, offset: 0 }}>
            <Flex>
              <Radio
                checked={!calendarStore.isCustomCategory}
                onClick={() => {
                  calendarStore.setIsCustomCategory(false);
                }}
              ></Radio>
              <Form.Item<CalendarEvent>
                label="Category"
                name="category"
                rules={[{ required: false }]}
                style={{ width: "100%" }}
              >
                <Select
                  disabled={calendarStore.isCustomCategory}
                  options={calendarStore.calendarCategories?.map((text) => ({
                    value: text,
                    label: text,
                  }))}
                  style={{ width: "100%" }}
                />
              </Form.Item>
            </Flex>
          </Col>
          <Col md={{ span: 11, offset: 2 }} xs={{ span: 24, offset: 0 }}>
            <Flex>
              <Radio
                checked={calendarStore.isCustomCategory}
                onClick={() => {
                  calendarStore.setIsCustomCategory(true);
                }}
              ></Radio>
              <Form.Item<CalendarEvent>
                label="Specify your own value:"
                name="categoryCustom"
                style={{ width: "100%" }}
                rules={[{ required: false }]}
              >
                <Input disabled={!calendarStore.isCustomCategory} />
              </Form.Item>
            </Flex>
          </Col>
        </Row>
        <Row>
          <Col xs={{ span: 24, offset: 0 }}>
            <Form.Item<CalendarEvent> label="Description" name="description">
              <TextArea rows={4} />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 24 }} xs={{ span: 24, offset: 0 }}>
            <Form.Item<CalendarEvent>
              label={
                <label>
                  <b>All Day Event</b> (Make this an all-day activity that
                  doesn't start or end at a specific hour.)
                </label>
              }
              name="fAllDayEvent"
              rules={[{ required: false }]}
            >
              <Switch
                onChange={(checked) => {
                  if (checked) {
                    form.validateFields();
                  }
                }}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 24 }} xs={{ span: 24, offset: 0 }}>
            <Form.Item<CalendarEvent>
              label={
                <label>
                  <b>Recurrence</b> (Make this a repeating event.)
                </label>
              }
              name="fRecurrence"
              rules={[{ required: false }]}
            >
              <Switch />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 24 }} xs={{ span: 24, offset: 0 }}>
            {calendarStore.eventModel.fRecurrence && (
              <RecurrenceForm form={form} />
            )}
          </Col>
        </Row>
        <Row>
          <Col span={24} style={{ textAlign: "right", marginTop: "16px" }}>
            <Space>
              <Button
                size="middle"
                onClick={() => navigate("/BoardVue/Calendar")}
              >
                Cancel
              </Button>
              {props.delete ? (
                <Popconfirm
                  title="Delete the event"
                  description={
                    <div style={{ width: 250 }}>
                      Are you sure you want to remove this event?
                    </div>
                  }
                  okText="Delete"
                  cancelText="Cancel"
                  onConfirm={props.delete}
                >
                  <Button size="middle" danger>
                    Delete
                  </Button>
                </Popconfirm>
              ) : (
                <></>
              )}
              <Button size="middle" type="primary" htmlType="submit">
                {props.submitBtnText}
              </Button>
            </Space>
          </Col>
        </Row>
      </Form>
    );
  }
);

export default EventForm;
