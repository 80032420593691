import { observer } from "mobx-react-lite";
import { Space, Button, Input, Flex } from 'antd';
import { SearchOutlined, CaretLeftFilled } from '@ant-design/icons';
import FileExplorerList, { FileItem } from "../../../components/FileExplorer/FileExplorerList";
import { useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import useBreakpoint from "use-breakpoint";
import { MailArchiveItem } from "../../../api/AccountSummary/AccountSummaryTypes";
import MailArchiveDocumentsColumns from "./MailArchiveDocumentsColumns";

export type MailArchiveFileItem = (MailArchiveItem & FileItem);

const MailArchive = observer(() => {
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const { apiStore, mailArchiveStore, modalViewerStore } = useStore();
    const [files, setFiles] = useState<MailArchiveFileItem[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<MailArchiveFileItem[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [mailArchiveDocuments, setMailArchiveDocuments] = useState<{ [type: string]: MailArchiveItem[] }>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);

    useEffect(() => {
        if (searchText === "") {
            setFilteredFiles([]);
            return;
        }
        const allFiles = Object.values(mailArchiveDocuments).flatMap(item => item)
        let filteredItems = allFiles.filter(i=>i.documentType.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        let newFilteredFiles: MailArchiveFileItem[] = filteredItems.map((data: MailArchiveItem): MailArchiveFileItem => ({
            ...data,
            name: data.documentType,
            dateSubmitted: new Date(data.dateSubmitted),    
            dateProcessed: new Date(data.dateProcessed),  
            isFolder: false,
            date: new Date(data.dateProcessed),
        }));
        setFilteredFiles(newFilteredFiles.sort((a, b) => a.dateSubmitted.getDate() - b.dateSubmitted.getDate()));
    }, [searchText])

    useEffect(() => {
        setSearchText("");
    }, [mailArchiveStore.currentDirectory])

    useEffect(() => {
        setIsLoading(true);
        const mail = apiStore.AccountSummaryApiClient.getMailArchive()
            .then(res => {
                const groupedData: { [type: string]: MailArchiveItem[] } = {};
                
                res.forEach((mail) => {
                    console.log(mail);
                    const type = mail.name  || "null";
                    if (!groupedData[type]) {
                        groupedData[type] = [];
                    }
                    groupedData[type].push(mail);
                });
                setMailArchiveDocuments(groupedData);
                setIsLoading(false);
            }).catch((error) => { 
                setIsLoading(false);
                console.log("getMailArchive: ", error);
            });
    }, [])

    useEffect(()=>{
        openFolderOrFile(null);
    },[mailArchiveDocuments])

    const onRow = (record:MailArchiveFileItem, rowIndex: number) => {
        return {
          onClick: (event: MouseEvent) => {
            event.stopPropagation();
            openFolderOrFile(record);
          }
        };
    }

    const openFolderOrFile = async (fileItem: null | MailArchiveFileItem, isBack: boolean = false) => {
        if (fileItem == null) {
            let newListFiles: MailArchiveFileItem[] = [];
            for (const groupeItem in mailArchiveDocuments) {
                if (groupeItem != "null") {
                    const folder: MailArchiveFileItem = {
                        isFolder: true,
                        name: groupeItem,
                        documentType: "Folder",
                    } as MailArchiveFileItem;
                    newListFiles.push(folder)
                } else {
                    let ungroupedItems: MailArchiveFileItem[] = mailArchiveDocuments["null"].map((data: MailArchiveItem): MailArchiveFileItem => ({
                        ...data,
                        name: data.documentType,
                        dateSubmitted: new Date(data.dateSubmitted), 
                        dateProcessed: new Date(data.dateProcessed),     
                        isFolder: false,
                        date: new Date(data.dateProcessed),
                    }));
                    newListFiles = newListFiles.concat(ungroupedItems.sort((a, b) => a.dateSubmitted.getDate() - b.dateSubmitted.getDate()))
                }
            }
            mailArchiveStore.setDirectory(fileItem, isBack)
            setFiles(newListFiles)
        } else {
            if (fileItem.isFolder) {
                let newFilteredFiles: MailArchiveFileItem[] = mailArchiveDocuments[fileItem.documentType].map((data: MailArchiveItem): MailArchiveFileItem => ({
                    ...data,
                    name: data.documentType,
                    dateSubmitted: new Date(data.dateSubmitted),  
                    dateProcessed: new Date(data.dateProcessed),  
                    isFolder: false,
                    date: new Date(data.dateProcessed),
                }));
                mailArchiveStore.setDirectory(fileItem, isBack)
                setFiles(newFilteredFiles.sort((a, b) => a.dateSubmitted.getDate() - b.dateSubmitted.getDate()))
            } else {
                if (fileItem.url){
                    window.open(fileItem.url, '_blank');
                }
                else{
                    const fetchedUrl: string = await apiStore.AccountSummaryApiClient.getPdfByDataRecordId(fileItem.dataRecordId);
                    fileItem.url=fetchedUrl;
                    window.open(fetchedUrl, '_blank');               
                }
            }
        }
    }

    const prevOnClick = () => {
        openFolderOrFile(mailArchiveStore.getLatestDocumentName(),true)
    }

    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{padding:10}}>
            <div>
                <Space direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Mail Archive</h2>
                    <Input value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px' }}/>
                </Space>
            </div>
        </Flex>
        {mailArchiveStore.currentDirectory !== null  && (
            <div style={{ padding: 10, height: 60 }}>
                <Button size="middle" onClick={prevOnClick}>
                    <CaretLeftFilled /> Back
                </Button>
            </div>)
        }
        <FileExplorerList data={searchText !== "" ? filteredFiles.sort((a, b) => a.dateSubmitted.getDate() - b.dateSubmitted.getDate()) : files.sort((a, b) => a.dateSubmitted.getDate() - b.dateSubmitted.getDate())} onRowFunc={onRow} isLoading={isLoading} columns={MailArchiveDocumentsColumns}/>
    </>
})

export default MailArchive