import { Button, Flex, Input, Space, Table } from "antd";
import { observer } from "mobx-react-lite";
import { ReactElement, useEffect, useState } from "react";
import { SearchOutlined} from '@ant-design/icons';
import { useStore } from "../../../root-store-context";
import useBreakpoint from "use-breakpoint";
import { useLocation, useNavigate } from "react-router-dom";
import Column from "antd/es/table/Column";
import { DateSort, FileNaturalCompare } from "../../../helpers/SorterHelper";
import { dateValidation } from "../../../helpers/dateValidation";
import dayjs from 'dayjs';
import { GuestPassItem, PostGuestPass } from "../../../api/Association/AssociationTypes";

const GuestPasses = observer((): ReactElement => {
    const { guestPassesStore } = useStore();
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const [searchText, setSearchText] = useState("");
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [guestPasses, setGuestPasses] = useState<Array<GuestPassItem>>([]);
    const [currentPage, setCurrentPage] = useState(1);
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        guestPassesStore.getDwGuestsInCommunity().then((res) => {
            setGuestPasses(res);
            setIsLoading(false);
        });
    }, [])

    const onRowClck = (item: GuestPassItem) => {
        const formModel:PostGuestPass = {
            passTypeID: item.passTypeID,
            firstName: item.firstName,
            lastName: item.lastName,
            startDate: dayjs(item.startDate),
            endDate: dayjs(item.endDate),
            email: item.email,
            restricted: item.restricted.toLowerCase() === "true"
        }
        guestPassesStore.editGuestPassModel = formModel;
        guestPassesStore.guestID = item.guestID;
        navigate(location.pathname+"/Edit")
    }

    return (
        <>
            <Flex justify="space-between" wrap="wrap" align="center" gap="middle" style={{ padding: 10 }}>
                <div style={{ display: "flex", width: "100%", flexWrap: "wrap", justifyContent: "space-between" }}>
                    <Space style={{ flexWrap: "wrap" }}>
                        <h2 style={{ width: "280px" }}>Guest Passes</h2>
                    </Space>
                    <Space style={{ flex: "1 1 300px", justifyContent: "space-between" }}>
                        <Input value={searchText}
                            onChange={(e) => { setSearchText(e.target.value) }}
                            prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                            variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px', display: "flex", flex: "1 1 300px" }} />
                        <Space>
                            <Button size="middle" type="primary" onClick={()=>navigate(location.pathname+"/Create")}>Create</Button>
                        </Space>
                    </Space>
                </div>
            </Flex>
            <Table dataSource={guestPasses.map((el, index) => { return { ...el, key: index } })}
            scroll={{ x: process.env.REACT_APP_TABLE_SCROLL_WIDTH }}
            pagination={{ defaultPageSize: 10, showSizeChanger: false, responsive: true, current: currentPage, onChange: (pageNum) => setCurrentPage(pageNum) }}
            loading={isLoading}
            size={breakpoint === "mobile" ? "small" : "large"}
            onRow={(record, rowIndex) => {
                return {
                    onClick: (event) => { onRowClck(record) },
                };
            }}
            >
                <Column align='left' title="Guest"
                    sorter={(a: GuestPassItem, b: GuestPassItem, direction) => FileNaturalCompare(a.company, b.company, direction)}
                    filteredValue={[searchText]}
                    onFilter={(text, r) => { return String(`${r.firstName}, ${r.lastName}`).toLowerCase().includes(String(text).toLowerCase()) }}
                    render={(v,r)=> `${r.firstName}, ${r.lastName}`}
                />
                <Column title="Start Date" dataIndex="startDate" key="startDate"
                    sorter={(a, b) => DateSort(a, b, "startDate")}
                    render={(date) => dateValidation(date)}
                />
                <Column title="End Date" dataIndex="endDate" key="endDate"
                    sorter={(a, b) => DateSort(a, b, "endDate")}
                    render={(date) => dateValidation(date)}
                />
                       
            {/* <Column width="8%" title="Expires" dataIndex="expires" key="expires"
            sorter={(a, b) => DateSort(a, b, "expires")}
                render={(date) => dateValidation(date)}
            /> */}
        </Table>
        </>
    )
})

export default GuestPasses;