export const BoolSort = (a: any, b: any, fieldName: string): number => {
  const aBool: boolean = a[fieldName] ? a[fieldName] as boolean : false
  const bBool: boolean = b[fieldName] ? b[fieldName] as boolean : false
  return (aBool === bBool) ? 0 : aBool ? -1 : 1
}

export const TextSort = (a: any, b: any, fieldName: string): number => {
  const aStr: string = a[fieldName] ? a[fieldName].toString() as string : ""
  const bStr: string = b[fieldName] ? b[fieldName].toString() as string : ""
  return aStr.localeCompare(bStr)
}

export const DateSort = (a: any, b: any, fieldName: string): number => {
  const aStr: string = a[fieldName] ? a[fieldName] as string : ""
  const bStr: string = b[fieldName] ? b[fieldName] as string : ""
  let dateA = new Date(aStr).getTime();
  let dateB = new Date(bStr).getTime();
  if (isNaN(dateA)) {
    dateA = 0;
  }
  if (isNaN(dateB)) {
    dateB = 0;
  }
  return dateA - dateB;
}

export const FileNaturalCompare = (a: string, b: string, direction: any, favoriteA: boolean = false, favoriteB: boolean = false): number => {
  a = a?.toLowerCase().trim();
  b = b?.toLowerCase().trim();
  let i: number = 0,
    codeA: number | undefined,
    codeB: number | undefined = 1,
    posA: number = 0,
    posB: number = 0,
    alphabet: string | undefined = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

  function getCode(str: string, pos: number, code?: any): number {
    if (code) {
      for (i = pos; (code = getCode(str, i)), code! < 76 && code! > 65;) ++i;
      return +str.slice(pos - 1, i);
    }
    code = alphabet && alphabet.indexOf(str.charAt(pos));
    return code! > -1 ? code! + 76 : ((code = str.charCodeAt(pos) || 0), code! < 45 || code! > 127) ? code!
      : code! < 46 ? 65               // -
        : code! < 48 ? code! - 1
          : code! < 58 ? code! + 18        // 0-9
            : code! < 65 ? code! - 11
              : code! < 91 ? code! + 11        // A-Z
                : code! < 97 ? code! - 37
                  : code! < 123 ? code! + 5        // a-z
                    : code! - 63;
  }

  if (favoriteA !== favoriteB) {
    // If one is favorite and the other is not, prioritize the favorite one
    if (direction === "ascend") {
      return favoriteA ? -1 : 1;
    } else {
      return favoriteA ? 1 : -1;
    }
  }

  if ((a += "") !== (b += "")) {
    for (; codeB!;) {
      codeA = getCode(a, posA++);
      codeB = getCode(b, posB++);

      if (codeA! < 76 && codeB! < 76 && codeA! > 66 && codeB! > 66) {
        codeA = getCode(a, posA, posA);
        codeB = getCode(b, posB, (posA = i));
        posB = i;
      }

      if (codeA! !== codeB!) return (codeA! < codeB!) ? -1 : 1;
    }
  }

  // If both are favorite or both are not, or the strings are the same, return 0
  return 0;
};