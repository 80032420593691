import { App } from "antd";
import { DropTargetMonitor, useDrop } from "react-dnd";
import { NativeTypes } from "react-dnd-html5-backend";
export interface DraggableBoxProps {
  tag: DraggableBoxTag;
  children: React.ReactNode;
  onLoadFiles: (files: File[]) => void;
  disable?: boolean;
  asTableRow?: boolean;
  className?: string;
}

//after adding the tag you must to add style to ./DraggableBox.scss
export enum DraggableBoxTag {
  tr = 'tr',
  div = 'div'
}

const DraggableBox: React.FC<DraggableBoxProps> = ({
  tag,
  children,
  disable,
  asTableRow,
  onLoadFiles,
  ...props
}) => {
  const {message} = App.useApp();
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: [NativeTypes.FILE],
      drop(e : any, monitor) {
        const didDrop = monitor.didDrop();
        if (didDrop && true) {
          return;
        }

        if(e.files.some((i: File) => i.size > 25 * 1024 * 1024)){
          message.error('Unacceptable file size. File upload are limited to 25MB.');
          return;
        }

        let isAllFiles = true;
        let itemsList:DataTransferItemList = e.dataTransfer.items;
        for (let index = 0; index < itemsList.length; index++) {
          const dataTransferItem:any = itemsList[index];
          const entry = dataTransferItem.webkitGetAsEntry()
          if(entry==null || !entry.isFile)
          {
            isAllFiles = false;
            break;
          }
        }
        if (isAllFiles) {
          onLoadFiles(e.files as File[]);
        }
        else {
          message.error('Folder upload is not supported.');
        }
      },
      canDrop(item: any) {
        // console.log("canDrop", item.files, item.items);
        return true;
      },
      hover(item: any) {
        // console.log("hover", item.files, item.items);
      },
      collect: (monitor: DropTargetMonitor) => {
        // const e = monitor.getItem() as any;
        return {
          isOver: monitor.isOver({ shallow: true }),
          canDrop: monitor.canDrop(),
        };
      },
    }),
    [props]
  );
  const isActive = canDrop && isOver;
  const ContainerTag = tag;  
  if(disable){
    return <ContainerTag {...props}>{children}</ContainerTag>
  }
  return (
    <>
      <ContainerTag
        {...props}
        className={`${props.className} draggable-container ${isActive && `draggable-over-${tag}`}`}
        ref={drop}
      >
        {children}
      </ContainerTag>
    </>
  );
};

export default DraggableBox