import { makeAutoObservable, runInAction } from "mobx";
import ApiStore from "../ApiStore";

import { Attachment, CommunityProxy } from "../../../api/Common/CommonTypes";
import { MaintenanceRequestHistoryItem, MaintenanceRequestItem, ResidentDirectoryProfile } from "../../../api/Forms/FormsTypes";

class MaintenanceRequestStore {
    submittableForm: boolean = false;
    isLoading: boolean = false;
    maintenanceRequestItem: MaintenanceRequestItem = {
        DaytimePhone: "",
        Description: "",
        EmailAddress: "",
        MaintenanceLocation: "",
        Attachments: [] as Array<Attachment>
    } as MaintenanceRequestItem;
    Attachments: Array<Attachment> = [];
    shouldInitAttachments: boolean = false;
    shouldResetForm: boolean = false;
    private _api: ApiStore;

    maintenanceHistory: MaintenanceRequestHistoryItem[] = [];


    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api
    }

    resetValue = () => {
        this.Attachments = []
        this.maintenanceRequestItem = {
            DaytimePhone: "",
            Description: "",
            EmailAddress: "",
            MaintenanceLocation: "",
            Attachments: [] as Array<Attachment>
        } as MaintenanceRequestItem;
        this.shouldResetForm = true;
    }

    getHistory = async () => {
        this.isLoading = true;
        try {
            const response = await this._api.FormsApiClient.getMaintenanceHistory();
            let localHistoryItems: MaintenanceRequestHistoryItem[] = [];
            const history: MaintenanceRequestHistoryItem[] = response.taskList?.map((item) => ({
                ...item,
                created: new Date(item.created)
            }))
            localHistoryItems = history;
            this.maintenanceHistory = localHistoryItems;
        } catch (error) {
            console.error(error);
        }
        this.isLoading = false;
    };

    editLoading = (loading: boolean) => {
        this.isLoading = loading
    }

    setSubmittableForm = (submittable: boolean) => {
        this.submittableForm = submittable;
    }

    addAttachmentToBuffer = async (attachment: Attachment): Promise<string | void> => {
        return this._api.CommonApiClient.addAttachmentToBuffer(attachment)
    }
    setNewPhone = (phone: string) => {
        this.maintenanceRequestItem.DaytimePhone = phone;
    }
    setNewEmail = (email: string) => {
        this.maintenanceRequestItem.EmailAddress = email;
    }
    setMaintenanceItem = (newMaintenanceRequestItem: MaintenanceRequestItem) => {
        this.maintenanceRequestItem = newMaintenanceRequestItem;
    }

    addMaintenanceRequestItem = async (selectedCommunity: CommunityProxy, userId: string) => {
        this.isLoading = true;
        const res = await this._api.FormsApiClient.addMaintenanceRequest({
            ...this.maintenanceRequestItem,
            Community: selectedCommunity,
            UserId: userId
        });
        if (res.data) {
            this.maintenanceRequestItem = {} as MaintenanceRequestItem;
            this.Attachments = [];
        }
        this.isLoading = false;
        return true
    }

    public async getCommunityResidentDirectoryProfile(): Promise<ResidentDirectoryProfile> {
        return this._api.FormsApiClient.getResidentDirectoryProfile();
    }
}

export default MaintenanceRequestStore


