import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import CryptoJS from "crypto-js";
import { CommunityFeature } from "../../../api/Association/AssociationTypes";

class TicketsAndFitnessStore {
    private _api: ApiStore;
    private communityFeature?: CommunityFeature;
    private pageToken?: string;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api;
    }

    private async getCommunityFeature(): Promise<CommunityFeature> {
        if(!this.communityFeature){
            this.communityFeature = await this._api.AssociationApiClient.getCommunityFeatureById(10);
        }
        return this.communityFeature;
    }

    private getHash(str: string) {
        const hash = CryptoJS.SHA256(str);
        const hexString = hash.toString(CryptoJS.enc.Hex);
        return hexString.toUpperCase();
    }

    public async getIframeLink(): Promise<string | null> {
        const time = new Date().getTime();
        const community = await this.getCommunityFeature();
        const hash =  this.getHash(`${this._api.configApi.community.residentContactKey}${time}${this.communityFeature?.sharedSecret}`);
        const url = `${community.endpoint}&vmsid=${this._api.configApi.community.residentContactKey}&time=${time}&auth=${hash}`
        return url;
    }
}
export default TicketsAndFitnessStore