import { makeAutoObservable, runInAction } from "mobx";
import ApiStore from "../ApiStore";
import {
  CalendarEvent,
  EventRepeatInfo,
  RepeatType,
  WeekdayOfMonth,
  WeeklyDays,
} from "../../../api/BoardVue";
import dayjs, { Dayjs } from "dayjs";

class CalendarStore {
  isLoading: boolean = false;
  isCustomCategory: boolean = false;
  calendarEvents: CalendarEvent[] = [];
  calendarCategories: string[] = [
    "",
    "Meeting",
    "Work hours",
    "Business",
    "Holiday",
    "Get-together",
    "Gifts",
    "Birthday",
    "Anniversary",
  ];

  eventModel: CalendarEvent = {
    recurrencePattern: {
      repeatType: RepeatType.daily,
      frequency: 1,
      oneDayOfWeek: WeeklyDays.mo,
      monthNumber: dayjs().month(),
      weeklyDays: [dayjs().format("dd").toLocaleLowerCase()],
      weekdayOfMonth: WeekdayOfMonth.first,
      endlessRepeating: true,
      dailyWeekday: false,
    },
  } as CalendarEvent;
  submittableForm: boolean = false;
  shouldResetForm: boolean = false;

  private _api: ApiStore;

  constructor(api: ApiStore) {
    makeAutoObservable(this);
    this._api = api;
  }
  setDefault() {
    this.eventModel = {
      fRecurrence: false,
    } as CalendarEvent;
    this.shouldResetForm = true;
    this.setDefaultEventRepeatInfo();
  }
  setIsCustomCategory(status: boolean) {
    this.isCustomCategory = status;
  }
  setDefaultEventRepeatInfo() {
    this.eventModel.recurrencePattern = {
      repeatType: RepeatType.daily,
      frequency: 1,
      oneDayOfWeek: WeeklyDays.mo,
      monthNumber: dayjs().month(),
      weeklyDays: [dayjs().format("dd").toLocaleLowerCase()],
      weekdayOfMonth: WeekdayOfMonth.first,
      endlessRepeating: true,
      dailyWeekday: false,
    } as EventRepeatInfo;
  }
  editLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  setCalendarItems = (newCalendarEvents: CalendarEvent[]) => {
    this.calendarEvents = newCalendarEvents;
  };

  updateCalendarItems = async (
    startDate: Date,
    endDate: Date
  ): Promise<void> => {
    try {
      const result = await this._api.BoardVueApiClient.getGetCalendarItems(
        startDate
      );
      if (result.status === 200) {
        this.calendarEvents = result.data;
      } else {
        console.log("Error getCalendarItems: ", result);
      }
    } catch (error) {
      console.log("Error getCalendarItems: ", error);
    }
  };

  setEventModel = async (model: CalendarEvent) => {
    this.isLoading = true;
    await runInAction(async () => {
      if (model.id > 0) {
        this.shouldResetForm = true;
      }
      if (!this.calendarCategories.some((el) => el === model.category)) {
        model.categoryCustom = model.category;
        model.category = "";
        this.isCustomCategory = true;
      }
      if (model.recurrencePattern) {
        this.eventModel = { ...model };
      } else {
        this.eventModel = { ...model };
        this.setDefaultEventRepeatInfo();
      }
      this.shouldResetForm = true;
    });
    this.isLoading = false;
  };

  setRepeatTypeForm = (type: RepeatType) => {
    if (this.eventModel.recurrencePattern.repeatType !== type) {
      this.eventModel.recurrencePattern.repeatType = type;
    }
  };

  setSubmittableForm = (submittable: boolean) => {
    this.submittableForm = submittable;
  };

  setFrequency = (number: number) => {
    this.eventModel.recurrencePattern.frequency = number;
  };

  setEndlessRepeating = (endlessRepeating: boolean): void => {
    this.eventModel.recurrencePattern.endlessRepeating = endlessRepeating;
  };

  setRepeatCount = (number: number) => {
    this.eventModel.recurrencePattern.repeatCount = number;
  };

  setDayNumber = (number: number) => {
    this.eventModel.recurrencePattern.dayNumber = number;
  };

  setWeekdayOfMonth = (day: WeekdayOfMonth) => {
    this.eventModel.recurrencePattern.weekdayOfMonth = day;
  };

  setMonthNumber = (monthNumber: number) => {
    this.eventModel.recurrencePattern.monthNumber = monthNumber;
  };

  setWeeklyDays = (newWeeklyDays: WeeklyDays[]) => {
    this.eventModel.recurrencePattern.weeklyDays = newWeeklyDays;
  };

  setOneDayOfWeek = (newOneDayOfWeek: WeeklyDays) => {
    this.eventModel.recurrencePattern.oneDayOfWeek = newOneDayOfWeek;
  };

  setEndDate = (date: Dayjs) => {
    this.eventModel.endDate = date;
  };

  setRepeatingEndDate = (date?: Dayjs) => {
    this.eventModel.recurrencePattern.repeatingEndDate = date;
  };

  setDailyWeekday = (dailyWeekday: boolean): void => {
    this.eventModel.recurrencePattern.dailyWeekday = dailyWeekday;
  };

  addEvent = async () => {
    var res = await this._api.BoardVueApiClient.addCalendarEvent(
      this.eventModel
    );
    if (res.data) {
      this.setDefaultEventRepeatInfo();
    }
    this.isLoading = false;
    return res.data;
  };

  preparateData = (eventModel: CalendarEvent) => {
    const data = JSON.parse(JSON.stringify(eventModel));
    switch (eventModel.recurrencePattern.repeatType) {
      case RepeatType.daily:
        data.recurrencePattern = {
          ...data.recurrencePattern,
          repeatType: data.recurrencePattern.repeatType,
          frequency: data.recurrencePattern.frequency,
          ...(data.recurrencePattern.repeatCount > 0 && {
            repeatCount: data.recurrencePattern.repeatCount,
          }),
          weeklyDays: [],
        } as EventRepeatInfo;
        break;
      case RepeatType.weekly:
        data.recurrencePattern = {
          ...data.recurrencePattern,
          repeatType: data.recurrencePattern.repeatType,
          weeklyDays: data.recurrencePattern.weeklyDays,
          ...(data.recurrencePattern.repeatCount > 0 && {
            repeatCount: data.recurrencePattern.repeatCount,
          }),
          dailyWeekday: true,
        } as EventRepeatInfo;
        break;
      case RepeatType.monthly:
        data.recurrencePattern = {
          ...data.recurrencePattern,
          repeatType: data.recurrencePattern.repeatType,
          frequency: data.recurrencePattern.frequency,
          dayNumber: data.recurrencePattern.dayNumber,
          ...(data.recurrencePattern.repeatCount > 0 && {
            repeatCount: data.recurrencePattern.repeatCount,
          }),
        } as EventRepeatInfo;
        break;
      case RepeatType.monthlyByDay:
        data.recurrencePattern = {
          ...data.recurrencePattern,
          repeatType: data.recurrencePattern.repeatType,
          frequency: data.recurrencePattern.frequency,
          oneDayOfWeek: data.recurrencePattern.oneDayOfWeek,
          weekdayOfMonth: data.recurrencePattern.weekdayOfMonth,
          ...(data.recurrencePattern.repeatCount > 0 && {
            repeatCount: data.recurrencePattern.repeatCount,
          }),
        } as EventRepeatInfo;
        break;
      case RepeatType.yearly:
        data.recurrencePattern = {
          ...data.recurrencePattern,
          repeatType: data.recurrencePattern.repeatType,
          monthNumber: data.recurrencePattern.monthNumber,
          dayNumber: data.recurrencePattern.dayNumber,
          ...(data.recurrencePattern.repeatCount > 0 && {
            repeatCount: data.recurrencePattern.repeatCount,
          }),
        } as EventRepeatInfo;
        break;
      case RepeatType.yearlyByDay:
        data.recurrencePattern = {
          ...data.recurrencePattern,
          repeatType: data.recurrencePattern.repeatType,
          weekdayOfMonth: data.recurrencePattern.weekdayOfMonth,
          oneDayOfWeek: data.recurrencePattern.oneDayOfWeek,
          monthNumber: data.recurrencePattern.monthNumber,
          ...(data.recurrencePattern.repeatCount > 0 && {
            repeatCount: data.recurrencePattern.repeatCount,
          }),
        } as EventRepeatInfo;
        break;
    }
    return data;
  };

  updateEvent = async () => {
    this.isLoading = true;
    const dataModel = this.preparateData(this.eventModel);
    if (!this.eventModel.fRecurrence) {
      delete dataModel.recurrencePattern;
    }

    const res = await this._api.BoardVueApiClient.updateCalendarEvent(
      dataModel
    );
    if (res.data) {
      this.setDefaultEventRepeatInfo();
    }
    this.isLoading = false;
    return res.data;
  };

  deleteEvent = async () => {
    this.isLoading = true;
    const dataModel = this.preparateData(this.eventModel);

    const res = await this._api.BoardVueApiClient.deleteCalendarEvent(
      this.eventModel.id.toString()
    );
    if (res.data) {
      this.setDefault();
    }
    this.isLoading = false;
    return res.data;
  };
}
export default CalendarStore;
