import { AxiosResponse } from "axios";
import MobileWebApiClient from "../base/MobileWebApiClient";
import SpaToolsRicApiClient from "../base/SpaToolsRicApiClient";
import {
  AccountContactDataItem,
  AccountContactRentInfoItem,
  AccountTransactionItem,
  Appeal,
  ArchitecturalNote,
  ArchitecturalRequestsItem,
  ArchitecturalSubmissionItem,
  CommunityUserContactsData,
  ComplianceItem,
  ComplianceSequenceInfo,
  ExistInternalUser,
  MailArchiveItem,
  PayAccountFeesAndCharges,
  PayAccountItem,
} from "./AccountSummaryTypes";
import { EStatementsSettings } from "../../pages/AccountSummaryGroup/EStatements";
import { Attachment } from "../Common/CommonTypes";
import dayjs from "dayjs";
import ApiStore from "../../stores/appStores/ApiStore";
import { apiDefineProperty } from "mobx/dist/internal";


export default class AccountSummaryApiClient extends MobileWebApiClient {
  public async getArchitecturalRequests(): Promise<
    Array<ArchitecturalRequestsItem>
  > {
    const res = await this.tryPost(
      "community/getaccountarchitecturalrequests",
      this._configApi.community
    );
    return res.data.map(
      (item: ArchitecturalRequestsItem): ArchitecturalRequestsItem => ({
        ...item,
        requestDate: new Date(item.requestDate),
        approvedDate: new Date(item.approvedDate),
      })
    );
  }
  public async getAttachmentArchtectStringData(ArchitecturalNoteKey: string, fileName: string): Promise<string> {
    const res = await this.tryGet(`community/getattachmentstringdata?itemType=1&itemId=${ArchitecturalNoteKey}&filename=${fileName}`);
    return res.data
  }
  public async getArchitecturalAttaches(RequestNo: string): Promise<Array<ArchitecturalNote>> {
    const res = await this.tryGet(`community/getarchnotesforarch?archKey=${RequestNo}`);
    return res.data.map(
      (item: any): ArchitecturalNote => ({
        ...item,
        Created: new Date(item.an_Created),
        Entered: new Date(item.an_Entered),
        FileName: item.an_FileName,
        Note: item.an_Note
      })
    );
  }
  public addArchitecturalRequest = async (architecturalRequests: ArchitecturalSubmissionItem): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/architecturalsubmissionsend', architecturalRequests);
  }
  public addAttachmentToBuffer = async (attachment: Attachment): Promise<string | void> => {
    try {
      const response = await this.tryPost('user/addattachmenttobuffer', {
        Data: attachment.Data, Name: attachment.Name
      });
      if (response.status === 200) {
        return response.data;
      } else {
        console.log(`addattachmenttobuffer ${response}`);
        return;
      }
    } catch (error) {
      console.log(`addattachmenttobuffer ${JSON.stringify(error, undefined, 2)}`);
      return;
    }

  }
  public async getCompliancies(): Promise<Array<ComplianceItem>> {
    const res = await this.tryPost("community/getaccountviolations", {
      Item1: this._configApi.community,
      Item2: true, //TRUE (always, just to compatibility))
    });
    return res.data.map(
      (item: ComplianceItem): ComplianceItem => ({
        ...item,
        violationNo: item.violationNo.toString(),
        reportDate: new Date(item.reportDate),
      })
    );
  }
  public async getSequencesForCompliance(ViolationNo: string): Promise<Array<ComplianceSequenceInfo>> {
    const res = await this.tryGet(`community/getsequencesforcompliance?complianceKey=${ViolationNo}`);
    return res.data.map(
      (item: ComplianceSequenceInfo): ComplianceSequenceInfo => ({
        ...item,
      })
    );
  }
  public getPdfByDataRecordId = async (dataRecordId: number): Promise<string> => {
   // const respose = await this._api.RicApiClient.getPdfByDataRecordId(dataRecordId);
    const respose = await this.RicGet(`nordisdirect/generetePdfUrl?dataRecordId=${dataRecordId}`);
        return respose?.data;

}
  public async getMailArchive(): Promise<Array<MailArchiveItem>> {
    const res = await this.RicGet(`nordisdirect/get?accountID=${this._configApi.community.residentKey}`);
    // const res = await this.tryPost("community/nordisloadinvoices", {
    //   Item1: this._configApi.community.residentKey,
    //   Item2: this._configApi.userEmail,
    // });
    // console.log(res.data);
    return res.data;
  }
  public async getAccountsBalance(): Promise<Array<PayAccountItem>> {
    var res = await this.tryPost('community/getaccountbalancewithsubledgers', this._configApi.community);
    const accountItems: PayAccountItem[] = res.data.map((item: PayAccountItem): PayAccountItem => ({
      ...item,
    }));
    return accountItems.sort((a, b) => {
      if (a.sortOrder < b.sortOrder) return -1;
      if (a.sortOrder > b.sortOrder) return 1;
      return 0;
    });
  }
  public async getPayUri(residentAccount: string): Promise<string> {
    var res = await this.tryPost('community/getpayuri', { ResidentKey: residentAccount });
    return res.data
  }
  public async getAcccountTransactionByAccount(accountNumber: string): Promise<{ feesAndCharges: PayAccountFeesAndCharges[], transactions: AccountTransactionItem[] }> {
    var res = await this.tryPost('community/getAccounttransactionsandfeesbyaccount', accountNumber);
    return { feesAndCharges: res.data.feesAndCharges, transactions: res.data.transactions }
  }
  public async getAttachmentStringData(complianceSequenceKey: string, fileName: string): Promise<string> {
    const res = await this.get(`community/getattachmentstringdata?itemType=2&itemId=${complianceSequenceKey}&filename=${fileName}`);
    return res?.data;
  }
  public addAppeal = async (appeal: Appeal): Promise<AxiosResponse<boolean>> => {
    return await this.tryPost('user/appealsend', appeal);
  }
  public async getEStatements(): Promise<EStatementsSettings | null> {
    return await this.get(`user/eStatementsGetResidentData?residentKey=${this._configApi.community.residentKey}`)
    .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
    .catch(() => null);
  }
  public async updateStatements(settings: EStatementsSettings): Promise<AxiosResponse> {
    var res = await this.post('user/eStatementsUpdateForResident', {
      "Item1": this._configApi.community,
      "Item2": settings.contactKey,
      "Item3": settings.email,
      "Item4": settings.isSendEmail,
    });
    return res
  }
  public getFile = async (url: string): Promise<AxiosResponse> => {
    return await this.tryPost('sharepoint/getfile', {
      "Item1": url,
      "Item2": this._configApi.community.communityId,
      "Item3": this._configApi.community.office,
    });
  }
  public async getCommuityUserContactsData(): Promise<CommunityUserContactsData> {
    const res = await this.post("user/GetCommuityUserContactsData", this._configApi.community);
    return res.data;
  }
  public async removeCommunityUserContact(contactKey : number): Promise<boolean> {
    const res = await this.tryPost("user/RemoveCommunityUserContact", contactKey.toString());
    return res.data;
  }
  public async removeCommunityUserTenant(residentKey: number): Promise<boolean> {
    const res = await this.tryPost("user/RemoveCommunityUserTenant", residentKey.toString());
    return res.data;
  }
  public async getExistInternalUserByEmail(email: string): Promise<ExistInternalUser | null> {
    return await this.post('user/ExistInternalUserByEmail', email)
    .then((res) => (res.statusText === "OK" && res.data ? res.data : null))
    .catch(() => null);
  }
  public async getExistInternalUserByEmailForTenant(email: string): Promise<number | null> {
    let status = null;
    await this.post('user/ExistInternalUserByEmailForTenant', email).then(res => {
      //TODO for responst 1
      console.log(res);
    }).catch(error => {
      if (error.response.status === 404) {
        status = 0;
      }
    });
    return status
  }
  public async updateContactData(contact: AccountContactDataItem): Promise<AxiosResponse> {
    var res = await this.tryPost('user/UpdateCommunityUserContactData',
      {
        ...contact,
        birthDate: dayjs(contact.birthDate).format('YYYY-MM-DD')
      });
    return res
  }
  public async addContact(contactData: AccountContactDataItem, contactRentInfo: AccountContactRentInfoItem | null): Promise<AxiosResponse> {
    var res = await this.tryPost('user/AddNewCommunityUserContactData', {
      "Item1": contactData,
      "Item2": contactRentInfo,
    });
    return res
  }
  public async getContactDataByContactId(residentKey: number, contactKey: number): Promise<AccountContactDataItem> {
    const res = await this.tryPost("user/GetContactDataByContactId", {
      "Item1": residentKey,
      "Item2": contactKey,
    });
    return {
      ...res.data,
    };
  }
  public async getIsCommunityPrimaryOwner(): Promise<boolean> {
    let status = false;
    await this.tryPost("user/IsCommunityPrimaryOwner", this._configApi.community.id.toString()).then(result => {
      status = true;
    }).catch(error => {
      if (error.response.status === 404) {
        status = false;
      }
    });;

    return status
  }
  public async getIsCommunityAgeRestricted(): Promise<boolean> {
    let status = false;
    await this.post("user/IsCommunityAgeRestricted", this._configApi.community.id.toString()).then(result => {
      status = true;
    }).catch(error => {
      if (error.response.status === 404) {
        status = false;
      }
    });;

    return status
  }
}

export { AccountSummaryApiClient };
