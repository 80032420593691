import { Col, Row, Space, Image, Spin, App, Result } from 'antd';
import { observer } from 'mobx-react-lite';
import Title from 'antd/es/typography/Title';
import newContact from "../../../images/pageIcons/AccountSummary/AccountSummary.png"
import { useStore } from '../../../root-store-context';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';
import ContactForm from './ContactForm';
import { getPhoneFormat } from '../../../helpers/phoneFormat';
import { ContactPhone } from '../../../api/AccountSummary/AccountSummaryTypes';
import dayjs from "dayjs";

const EditContact = observer(() => {

    const { apiStore } = useStore();

    const { accountContactsStore } = useStore();
    const navigate = useNavigate();
    const { residentKey, contactKey } = useParams();
    const [isNotFound, setIsNotFound] = useState(false);
    const { message } = App.useApp();

    useEffect(() => {
        setContactData(parseInt(residentKey!), parseInt(contactKey!));
    }, [])
    const addDisabledToObject = (phones: ContactPhone[]): ContactPhone[] => {
        let uniqueTypes: Record<string, ContactPhone> = {};

        phones.forEach(obj => {
            if (!uniqueTypes[obj.type]) {
                uniqueTypes[obj.type] = obj;
            } else if (obj.contactNumber !== "" && !uniqueTypes[obj.type].contactNumber) {
                uniqueTypes[obj.type] = obj;
            }
        });

        Object.values(uniqueTypes).forEach(obj => {
            obj.disabledRemove = true;
        });

        return phones;
    }
    const setContactData = async (residentKey: number, contactKey: number) => {
        if (accountContactsStore.contactModel.contactKey !== contactKey) {
            accountContactsStore.editLoading(true);
            const isAgeRestricted = await apiStore.AccountSummaryApiClient.getIsCommunityAgeRestricted() || false;
            accountContactsStore.setIsAgeRestricted(isAgeRestricted);
            const contactData = await apiStore.AccountSummaryApiClient.getContactDataByContactId(residentKey, contactKey);
            if (contactData != null) {
                const phones = contactData.phones.map(phone => {
                    const newPhone = getPhoneFormat(phone.contactNumber || "");
                    return { ...phone, contactNumber: newPhone };
                });
                const newPhones = addDisabledToObject(phones);
                accountContactsStore.setContactModel({
                    ...contactData,
                    phones: newPhones,
                    birthDate: dayjs(contactData.birthDate),
                });
                accountContactsStore.editLoading(false);

            } else {
                setIsNotFound(true);
            }
        }
    }

    const success = () => {
        message.open({
            type: 'success',
            content: 'Contact updated successfully.',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during updating. Please check the entered data.',
        });
    };

    const onSubmit = async () => {
        message.open({
            type: 'loading',
            content: 'Saving...',
            key: 'saving'
        });
        accountContactsStore.editLoading(true);
        accountContactsStore.updateContact().then((res) => {
            message.destroy("saving");
            if (res) {
                success()
            }
            else {
                error()
            }
            accountContactsStore.editLoading(false);
        }).catch(() => {
            message.destroy("saving");
            error()
            accountContactsStore.editLoading(false);
        })
    }

    if (isNotFound) {
        return <Result status="404" title="The contact does not exist" />
    }

    return (<>
        <Spin tip="Loading" size="small" spinning={accountContactsStore.isLoading} style={{ margin: 10 }}>
            <Row>
                <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Space>
                        <Image src={newContact} preview={false} height={25}/>
                        <Title level={2} style={{ marginTop: "10px" }}>Account #{accountContactsStore.contactModel.contactKey}</Title>
                    </Space>
                    <ContactForm submitBtnText='Update Contact' submit={onSubmit} />
                </Col>
            </Row>
        </Spin>
    </>
    )
})

export default EditContact
