import { Col, Row, Space, Image, Spin, App, Result } from 'antd';
import TaskForm from './TaskForm';
import { observer } from 'mobx-react-lite';
import Title from 'antd/es/typography/Title';
import newBoardTask from "../../../images/NewBoardTask.png"
import { useStore } from '../../../root-store-context';
import { useNavigate, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

const EditTask = observer(() => {

    const { apiStore } = useStore();

    const { boardTaskStore: boardTaskStore } = useStore();
    const navigate = useNavigate();
    const { taskId } = useParams();
    const [isNotFound, setIsNotFound] = useState(false);
    const {message} = App.useApp();

    useEffect(()=>{
        setBoardTask(parseInt(taskId!));
    },[])

    const setBoardTask = async (id:number) => {
        if(boardTaskStore.taskModel.id != id)
        {
            boardTaskStore.editLoading(true);
            var task = await apiStore.BoardVueApiClient.getTaskById(id);
            if(task != null){
                boardTaskStore.setTaskModel(task);
            }else{
                setIsNotFound(true);
            }
        }
    }

    const success = () => {
        message.open({
            type: 'success',
            content: 'Task updated successfully.',
        });
    };

    const error = () => {
        message.open({
            type: 'error',
            content: 'Error occurred during updating. Please check the entered data.',
        });
    };

    const onSubmit = async () => {
        boardTaskStore.editLoading(true);
        boardTaskStore.taskModel.attachments = boardTaskStore.attachments.filter(i => i.Data != null);
        const allPromisesAttach = boardTaskStore.taskModel?.attachments?.map(async item=>{
            const attachGUID = await boardTaskStore.addAttachmentToBuffer(item);
            if (typeof attachGUID === "string") {
                item.Guid = attachGUID;
                item.Data = null as any;
                item.Url = null as any;
            }
        })
        if(allPromisesAttach){
            await Promise.all(allPromisesAttach);
        }
        if(!Array.isArray(boardTaskStore.taskModel.assignedTo)){
            boardTaskStore.taskModel.assignedTo = [boardTaskStore.taskModel.assignedTo];
        }
        boardTaskStore.updateSharePointTask().then((res) => {
            if (res) {
                success()
                navigate('/BoardVue/BoardTasks');
            }
            else {
                error()
            }
        })
    }

    if (isNotFound) {
        return <Result status="404" title="The task does not exist"/>
    }

    return (<>
        <Spin tip="Loading" size="small" spinning={boardTaskStore.isLoading} style={{margin:10}}>
            <Row>
                <Col xxl={{ span: 12, offset: 6 }} xl={{ span: 16, offset: 4 }} lg={{ span: 24, offset: 0 }} xs={{ span: 24, offset: 0 }}>
                    <Space>
                        <Image src={newBoardTask} preview={false} />
                        <Title level={2} style={{ marginTop: "10px" }}>Board Task {boardTaskStore.taskModel.id}</Title>
                    </Space>

                    <TaskForm submitBtnText='Save' submit={onSubmit}/>
                </Col>
            </Row>
        </Spin>
    </>
    )
})

export default EditTask