import { Link } from "react-router-dom";

const Logo = () => {
    return (<div style={{ background: '#ffffff', height: '64px' }}>
        <Link to="/">
            <div style={{ height: '64px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}>
                <img
                    id="main-logo"
                    style={{ maxWidth: '90%', margin: '10px', height: "45px" }}
                    src={require("../../../images/all_access_logo.png")}
                    alt="AAM All Access Logo"
                />
                <img
                    id="main-logo"
                    style={{ maxWidth: '90%', margin: '10px', height: "45px" }}
                    src={require("../../../images/Item.png")}
                    alt="AAM All Access Logo"
                />
            </div>
        </Link>
    </div>);
};

export default Logo;