import { makeAutoObservable } from "mobx";
import ApiStore from "../ApiStore";
import { CommunityProxy } from "../../../api/Common/CommonTypes";
import { FeeWaiverItem } from "../../../api/Forms/FormsTypes";

class FeeWaiverStore {
    submittableForm: boolean = false;
    isLoading: boolean = false;
    feeWaiverItem: FeeWaiverItem = {} as FeeWaiverItem;
    shouldInitAttachments: boolean = false;
    shouldResetForm: boolean = false;
    private _api: ApiStore;

    constructor(api: ApiStore) {
        makeAutoObservable(this);
        this._api = api
    }

    resetForm = async () => {
        this.isLoading = true;
        const residentDirectoryProfile = await this._api.FormsApiClient.getResidentDirectoryProfile();
        const newFormData = {...residentDirectoryProfile, FeeType: "Demand Fee"} as FeeWaiverItem;
        this.setFeeWaiverItem(newFormData);
        this.isLoading = false;
    }

    editLoading = (loading: boolean) => {
        this.isLoading = loading
    }

    setSubmittableForm = (submittable: boolean) => {
        this.submittableForm = submittable;
    }

    setDaytimePhone = (phone: string) => {
        this.feeWaiverItem.DaytimePhone = phone;
    }

    setNewEmail = (email: string) => {
        this.feeWaiverItem.EmailAddress = email;
    }

    setFeeWaiverItem = (newMaintenanceRequestItem: FeeWaiverItem) => {
        this.feeWaiverItem = newMaintenanceRequestItem;
    }

    addFeeWaiverItem = async (selectedCommunity: CommunityProxy, userId: string) => {
        this.isLoading = true;
        const res = await this._api.FormsApiClient.addFeeWaiverItem({
            ...this.feeWaiverItem,
            Community: selectedCommunity,
            UserId: userId
        });
        this.isLoading = false;
        return res;
    }
}

export default FeeWaiverStore
