import { observer } from "mobx-react-lite";
import { Space, Button, Input, Flex } from 'antd';
import { SearchOutlined, CaretLeftFilled } from '@ant-design/icons';
import FileExplorerList, { FileItem } from "../../../components/FileExplorer/FileExplorerList";
import { VendorAgreementItem } from "../../../api/BoardVue";
import { useEffect, useState } from "react";
import { useStore } from "../../../root-store-context";
import useBreakpoint from "use-breakpoint";
import VendorAgreementsColumns from "./VendorAgreementsColumns";

export type VendorAgreementFileItem = (VendorAgreementItem & FileItem);

const VendorAgreements = observer(() => {
    const BREAKPOINTS = { mobile: 0, desktop: 768 }
    const { breakpoint } = useBreakpoint(BREAKPOINTS, 'mobile')
    const { vendorAgreementsStore, modalViewerStore } = useStore();
    const [files, setFiles] = useState<VendorAgreementFileItem[]>([]);
    const [filteredFiles, setFilteredFiles] = useState<VendorAgreementFileItem[]>([]);
    const [searchText, setSearchText] = useState<string>("");
    const [vendorAgreements, setVendorAgreements] = useState<{ [type: string]: VendorAgreementItem[] }>({});
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const { apiStore } = useStore();

    useEffect(() => {
        if (searchText === "") {
            setFilteredFiles([]);
            return;
        }
        const allFiles = Object.values(vendorAgreements).flatMap(item => item)
        let filteredItems = allFiles.filter(i=>i.title.toLocaleLowerCase().includes(searchText.toLocaleLowerCase()));
        let newFilteredFiles: VendorAgreementFileItem[] = filteredItems.map((data):VendorAgreementFileItem => ({
                ...data,
                isFolder: false,
            }));
        setFilteredFiles(newFilteredFiles);
    }, [searchText])

    useEffect(() => {
        setSearchText("");
    }, [vendorAgreementsStore.currentDirectory])

    useEffect(() => {
        setIsLoading(true);
        apiStore.BoardVueApiClient.getVendorAgreements()
            .then(res => {
                const groupedData: { [type: string]: VendorAgreementItem[] } = {};
                res.forEach((item) => {
                    const type = item.CommunityContractType;
                    if (!groupedData[type]) {
                        groupedData[type] = [];
                    }
                    groupedData[type].push(item);
                });
                setVendorAgreements(groupedData);
                setIsLoading(false)
            }).catch(() => { setIsLoading(false) });
    }, [])

    useEffect(()=>{
        openFolderOrFile(null);
    },[vendorAgreements])

    const onRow = (record:VendorAgreementFileItem, rowIndex: number) => {
        return {
          onClick: (event: MouseEvent) => {
            event.stopPropagation();
            openFolderOrFile(record);
          }, // click row
          //onDoubleClick: (event: MouseEvent) => {
          //  openFolderOrFile(record.localName)
          //}, // double click row
          //onContextMenu: (event) => {console.log(event,rowIndex,record)}, // right button click row
          //onMouseEnter: (event) => {console.log(event,rowIndex,record)}, // mouse enter row
          //onMouseLeave: (event) => {console.log(event,rowIndex,record)}, // mouse leave row
        };
    }

    const openFolderOrFile = (fileItem: null | VendorAgreementFileItem, isBack: boolean = false) => {
        if (fileItem == null) {
            let newListFiles: VendorAgreementFileItem[] = [];
            console.log(vendorAgreements);
            for (const groupeItem in vendorAgreements) {
                if (groupeItem != "null") {
                    const folder: VendorAgreementFileItem = {
                        isFolder: true,
                        title: groupeItem,
                        CommunityContractType: "Folder",
                    } as VendorAgreementFileItem;
                    newListFiles.push(folder)
                } else {
                    let ungroupedItems: VendorAgreementFileItem[] = vendorAgreements["null"].map((data: VendorAgreementItem): VendorAgreementFileItem => ({
                        ...data,
                        isFolder: false,
                        modified: new Date(data.modified),
                    }));
                    newListFiles = newListFiles.concat(ungroupedItems)
                }
            }
            vendorAgreementsStore.setDirectory(fileItem, isBack)
            setFiles(newListFiles)
        } else {
            if (fileItem.isFolder) {
                let newFilteredFiles: VendorAgreementFileItem[] = vendorAgreements[fileItem.title].map((data: VendorAgreementItem): VendorAgreementFileItem => ({
                    ...data,
                    isFolder: false,
                    modified: new Date(data.modified),
                }));
                vendorAgreementsStore.setDirectory(fileItem, isBack)
                setFiles(newFilteredFiles)
            } else {
                setIsLoading(true);
                apiStore.BoardVueApiClient.getFile(fileItem.fileRef)
                    .then(res => {
                        modalViewerStore.openFileByData(res.data, fileItem.title);
                        setIsLoading(false);
                    })
                    .catch(error => {
                        console.log(error);
                        setIsLoading(false);
                    });
            }
        }
    }

    const prevOnClick = () => {
        openFolderOrFile(vendorAgreementsStore.getLatestDocumentName(),true)
    }

    return <>
        <Flex justify="space-between" wrap="wrap" align="center" gap="large" style={{padding:10}}>
            <div>
                <Space direction={breakpoint === 'mobile' ? 'vertical' : 'horizontal'} >
                    <h2 style={{ width: "280px" }}>Vendor Agreements</h2>
                    <Input value={searchText}
                        onChange={(e) => { setSearchText(e.target.value) }}
                        prefix={<Button shape="circle" type="text" icon={<SearchOutlined />} />}
                        variant="borderless" placeholder="Search" allowClear style={{ marginLeft: '-20px' }}/>
                </Space>
            </div>
        </Flex>
        {/* <h4 style={{padding:10,margin:0}}>Path: {vendorAgreementsStore.breadСrumbs.map(i=> `/${i}`)}</h4> */}
        {vendorAgreementsStore.currentDirectory !== null && (
            <div style={{ padding: 10, height: 60 }}>
                <Button size="middle" onClick={prevOnClick}>
                    <CaretLeftFilled /> Back
                </Button>
            </div>)
        }
        <FileExplorerList data={searchText !== "" ? filteredFiles : files} onRowFunc={onRow} isLoading={isLoading} columns={VendorAgreementsColumns}/>
    </>
})

export default VendorAgreements